import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import images from '../../images/images';

import { Button, InlineLoading } from 'carbon-components-react';
import {
  Gateway,
  NetworkSegment,
  ProceduralActivityType,
} from '../../models/master';
import { ArrowRight32, Launch16 } from '@carbon/icons-react';
import { Tooltip } from '@carbon/react';
import { Link } from '@carbon/react';
import { getProceduralActivityMessegeIconConfig } from './config';
import ConnectGatewayCluster from '../ConnectGatewayCluster/ConnectGatewayCluster';
import { useNavigate } from 'react-router-dom';

import './GatewayOnboardingStatus.scss';

interface Props {
  gateway?: Gateway | null;
  networkSegment?: NetworkSegment;
  refreshData: () => void;
}

const GatewayOnboardingStatus: React.FC<Props> = ({
  gateway,
  networkSegment,
  refreshData,
}) => {
  const { t } = useTranslation('gatewayOnboardingStatusDetails');

  const [isConnectClusterOpen, setIsConnectClusterOpen] = useState(false);
  const proceduralActivity =
    networkSegment?.procedural_activity === 'finalizing-onboarding'
      ? networkSegment?.procedural_activity
      : (gateway?.procedural_activity as ProceduralActivityType);

  const config = getProceduralActivityMessegeIconConfig(t, proceduralActivity);
  const currentStep = config?.step;
  const Icon = config?.icon;
  const OnboardStatusMessegeIcon = () => {
    const stepClassname: Record<number, string> = {
      1: 'step-1',
      2: 'step-2',
      3: 'step-3',
      4: 'step-4',
      5: 'step-5',
    };

    const navigate = useNavigate();

    return (
      <div
        className={
          Icon
            ? 'onboard-status-messege'
            : 'onboard-status-messege without-icon'
        }
      >
        <div className={`status-title ${stepClassname[currentStep]}`}>
          {t('gatewayOnboardingTitle')}
        </div>
        {Icon ? (
          <div className='icon-messege'>
            <div className='loading-icon'>{Icon}</div>
            <div
              className={`onboard-description ${stepClassname[currentStep]}`}
            >
              {config?.status}
            </div>
          </div>
        ) : (
          <div className={`icon-messege-cluster ${stepClassname[currentStep]}`}>
            <div
              className={`onboard-description-cluster ${stepClassname[currentStep]}`}
            >
              {config?.status}
            </div>
            <div>
              {proceduralActivity ===
              t('gatewayProgressStepTwo.ProceduralActivityType') ? (
                <Link
                  renderIcon={Launch16}
                  onClick={() =>
                    window.open(
                      'https://www.ibm.com/docs/en/hybrid-cloud-mesh?topic=interconnect-installing-open-horizon-agent'
                    )
                  }
                  className='tooltip-text'
                >
                  {t('learnMoreLink')}
                </Link>
              ) : (
                <Button
                  size='small'
                  renderIcon={
                    proceduralActivity ===
                      t('gatewayProgressStepFour.ProceduralActivityType') &&
                    ArrowRight32
                  }
                  onClick={
                    proceduralActivity ===
                    t('gatewayProgressStepThree.ProceduralActivityType')
                      ? () => setIsConnectClusterOpen(true)
                      : () =>
                          navigate(
                            `/networkSegmentDetails?nwSegId=${gateway?.network_segment_id}`
                          )
                  }
                >
                  {config?.button}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='gateway-onboarding-status-component'>
      <ConnectGatewayCluster
        open={isConnectClusterOpen}
        onClose={() => setIsConnectClusterOpen(false)}
        onGatewayConnect={() => {
          setIsConnectClusterOpen(false);
          refreshData();
        }}
        gateway={gateway ?? null}
      ></ConnectGatewayCluster>
      <OnboardStatusMessegeIcon />
      <div className='center-alignment'></div>
      <div className='onboard-progress-indicator'>
        <div className='progress-steps'>
          <div className='progress-step'>
            <div>
              {proceduralActivity ===
              t('gatewayProgressStepOne.ProceduralActivityType') ? (
                <InlineLoading status='active' className='step-loading-icon' />
              ) : (
                images.stepCheckmarkDashIcon()
              )}
            </div>
            <div
              className={
                currentStep >= 1 ? 'step-label complete' : 'step-label'
              }
            >
              <div className='step'>{t('gatewayProgressStepOne.label')}</div>
              <div className='status'>
                {currentStep > 1
                  ? t('gatewayProgressStepOne.completed')
                  : t('gatewayProgressStepOne.incomplete')}
              </div>
            </div>
            <div className={currentStep === 1 ? 'line' : 'line complete'}></div>
          </div>

          <div className='progress-step'>
            <div>
              {proceduralActivity ===
              t('gatewayProgressStepTwo.ProceduralActivityType') ? (
                <Tooltip label={t('gatewayProgressStepTwo.warningTooltipText')}>
                  <div className='tooltip-text'>{images.stepWarningIcon()}</div>
                </Tooltip>
              ) : currentStep > 2 ? (
                images.stepCheckmarkDashIcon()
              ) : (
                images.stepLoadingDashIcon()
              )}
            </div>
            <div
              className={
                currentStep >= 2 ? 'step-label complete' : 'step-label'
              }
            >
              <div className='step'>{t('gatewayProgressStepTwo.label')}</div>
              <div className='status'>
                {currentStep > 2 ? (
                  t('gatewayProgressStepTwo.completed')
                ) : (
                  <Tooltip
                    label={t('gatewayOnboardingMessege.waiting-for-closure')}
                    align='bottom'
                    className='waiting-for-closure-tooltip-messege'
                  >
                    <text className='tooltip-text'>
                      {t('gatewayProgressStepTwo.incomplete')}
                    </text>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className={currentStep > 2 ? 'line complete' : 'line'}></div>
          </div>

          <div className='progress-step'>
            <div>
              {proceduralActivity ===
              t('gatewayProgressStepThree.ProceduralActivityType') ? (
                <Tooltip
                  label={t('gatewayProgressStepThree.warningTooltipText')}
                >
                  <div className='tooltip-text'>{images.stepWarningIcon()}</div>
                </Tooltip>
              ) : currentStep > 3 ? (
                images.stepCheckmarkDashIcon()
              ) : (
                images.stepLoadingDashIcon()
              )}
            </div>
            <div
              className={
                currentStep >= 3 ? 'step-label complete' : 'step-label'
              }
            >
              <div className='step'>{t('gatewayProgressStepThree.label')}</div>
              <div className='status'>
                {currentStep > 3
                  ? t('gatewayProgressStepThree.completed')
                  : t('gatewayProgressStepThree.incomplete')}
              </div>
            </div>
            <div className={currentStep > 3 ? 'line complete' : 'line'}></div>
          </div>

          <div className='progress-step'>
            <div>
              {proceduralActivity ===
              t('gatewayProgressStepFour.ProceduralActivityType') ? (
                <Tooltip
                  label={t('gatewayProgressStepFour.warningTooltipText')}
                >
                  <div className='tooltip-text'>{images.stepWarningIcon()}</div>
                </Tooltip>
              ) : currentStep > 4 ? (
                images.stepCheckmarkDashIcon()
              ) : (
                images.stepLoadingDashIcon()
              )}
            </div>
            <div
              className={
                currentStep >= 4 ? 'step-label complete' : 'step-label'
              }
            >
              <div className='step'>{t('gatewayProgressStepFour.label')}</div>
              <div className='status'>
                {currentStep > 4
                  ? t('gatewayProgressStepFour.completed')
                  : t('gatewayProgressStepFour.incomplete')}
              </div>
            </div>
            <div className={currentStep > 4 ? 'line complete' : 'line'}></div>
          </div>

          <div className='progress-step'>
            <div>
              {proceduralActivity ===
              t('gatewayProgressStepFive.ProceduralActivityType') ? (
                <InlineLoading status='active' className='step-loading-icon' />
              ) : (
                images.stepLoadingDashIcon()
              )}
            </div>
            <div
              className={
                currentStep >= 5 ? 'step-label complete' : 'step-label'
              }
            >
              <div className='step'>{t('gatewayProgressStepFive.label')}</div>
              <div className='status'>
                {t('gatewayProgressStepFive.incomplete')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GatewayOnboardingStatus;
