import { axiosInstance } from './api';
import { MCNM_NETWORK_API_URL } from './config';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../lib/constants';
import { NetworkSegment } from '../models/master';

export async function getNetworkSegments() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/networksegment`,
    });

    return (
      response?.data?.network_segments?.filter(
        (ns: NetworkSegment) => ns.resource_id !== DEFAULT_NETWORK_SEGMENT_ID
      ) ?? []
    );
  } catch (error) {
    throw error;
  }
}
