import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';
import dateUtils from '../../../lib/dates';
import './ApplicationsTable.scss';
import LabelTag from '../../../components/LabelTag/LabelTag';
import { Link } from 'react-router-dom';
import {
  ApplicationData,
  AppliedFilter,
  NetworkSegment,
  ResourceGroup,
} from '../../../models/master';
import images from '../../../images/images.js';

import './ApplicationsTable.scss';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import GenericStatusField from '../../../components/GenericStatusField/GenericStatusField';

import { Filter } from '../../../components/FindAndFilterBar/FindAndFilterBar';
import { filterTypes } from '../../../lib/enums';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../lib/constants';
interface ApplicationTableRows {
  id: any;
  name:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  name__format: JSX.Element;
  resource_group_name: string | JSX.Element;
  lastUpdated: string;
  deployments: number | string | JSX.Element;
  allLabels: JSX.Element | null | string;
  network_segment_name: string | JSX.Element;
  networkSegment_format: JSX.Element;
}

const ApplicationsTable = (props: {
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  rows: ApplicationData[] | null;
  data: ApplicationData[] | null;
  filteredDataSet: ApplicationData[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  applicationGroupsList: ResourceGroup[] | null;
  networkSegmentList: NetworkSegment[] | null;
  persistFilter?: boolean;
  error403Flag: boolean;
  error500Flag: boolean;
  resourceGroupNotAuthorized: boolean;
  networkSegmentNotAuthorized: boolean;
  leftInlineMultiSelectFilter?: Filter[];
}) => {
  const { t } = useTranslation('applicationsContainer');
  const { rows, onRefresh } = props;
  const error403Flag = props.error403Flag;
  const error500Flag = props.error500Flag;

  const formatTags = (
    labels: string[] | null,
    discoverdLabels: string[] | null
  ) => {
    const labelsList: any = [];
    if (labels) {
      labels.forEach(el => {
        labelsList.push(el);
      });
    }
    if (discoverdLabels) {
      discoverdLabels.forEach(el => {
        labelsList.push({
          default: true,
          value: el,
        });
      });
    }
    return <LabelTag labelArray={labelsList} count={3} />;
  };
  const navigate = useNavigate();

  const myheaders = [
    {
      key: 'name__format',
      header: t('tableHeaders.name'),
      sort: 'sortByName',
      originalKey: 'name',
    },
    {
      key: 'networkSegment_format',
      header: t('tableHeaders.networkSegment'),
      sort: 'sortByNetworkSegment',
      originalKey: 'network_segment_name',
    },
    {
      key: 'resource_group_name',
      originalKey: 'resource_group_name',
      header: t('tableHeaders.applicationGroup'),
      sort: 'sortByApplicationGroup',
    },
    {
      key: 'deployments',
      originalKey: 'deployments',
      header: t('tableHeaders.deployments'),
      sort: 'sortByDeployments',
    },
    {
      key: filterTypes.ALLLABELS,
      originalKey: filterTypes.ALLLABELS,
      header: t('tableHeaders.labels'),
    },
    {
      key: 'lastUpdated',
      originalKey: 'updated_at',
      header: t('tableHeaders.lastUpdated'),
      sort: 'sortByUpdated',
      style: { minWidth: '12.5rem' },
    },
  ];

  const emptyStateData = {
    icon: images.noApplicationsIcon(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
  };

  /**
   * @description - Function to format the raw data into table rows format.
   * @returns {ApplicationTableRows[] | [] | null} - Returns the formatted data to be rendered as table rows.
   */

  const getResourceGroupName = (id: string) => {
    const resouceGroup =
      id &&
      Array.isArray(props.applicationGroupsList) &&
      props.applicationGroupsList.find(
        resouceGroup => resouceGroup.resource_id === id
      );

    return resouceGroup ? resouceGroup.name : '';
  };

  const getNotAuthorizedContent = () => {
    return <GenericStatusField status='notAuthorised'></GenericStatusField>;
  };

  const setRowsData = () => {
    let formattedRows: ApplicationTableRows[] = [];
    if (error403Flag) return [];
    if (error500Flag) return [];
    if (rows && rows.length === 0) return [];
    if (rows)
      rows.map((row: ApplicationData) => {
        formattedRows.push({
          id: row.resource_id,
          name: !!row.name ? row.name : '—',
          name__format: (
            <Link
              className='no-underline-link'
              to={`/applicationDetails?appId=${row.resource_id}`}
            >
              <GenericTruncateString str={row?.name} tableView={true} />
            </Link>
          ),
          resource_group_name: !props.resourceGroupNotAuthorized
            ? getResourceGroupName(row.resource_group_id)
            : getNotAuthorizedContent(),
          lastUpdated: !!row.updated_at
            ? dateUtils.getUserFriendlyDate(row.updated_at)
            : '—',
          deployments: row?.deploymentPermission
            ? !!row.deployments
              ? Array.isArray(row.deployments)
                ? row.deployments.length
                : 0
              : '—'
            : getNotAuthorizedContent(),
          network_segment_name: !props.networkSegmentNotAuthorized
            ? !!row.network_segment_name
              ? row.network_segment_name
              : '—'
            : getNotAuthorizedContent(),
          networkSegment_format: !props.networkSegmentNotAuthorized ? (
            row.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID ? (
              <GenericTruncateString
                str={row?.network_segment_name as string}
                tableView={true}
              />
            ) : (
              <Link
                className='no-underline-link'
                to={`/networkSegmentDetails?nwSegId=${row.network_segment_id}`}
              >
                <GenericTruncateString
                  str={row?.network_segment_name as string}
                  tableView={true}
                />
              </Link>
            )
          ) : (
            getNotAuthorizedContent()
          ),
          allLabels:
            row.labels || row?.discovered_labels
              ? formatTags(row.labels, row?.discovered_labels)
              : '—',
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  return (
    <GenericTableWithFilters
      id='table-with-filter-panel'
      rows={setRowsData()}
      data={props.filteredDataSet}
      headers={myheaders}
      isSortable={true}
      totalElementsCount={props.elementCount ? props.elementCount : 0}
      fullData={props.data}
      onTableRefresh={() => onRefresh()}
      filteredDataCallback={(data: ApplicationTableRows[]) =>
        props.filteredDataCallback(data)
      }
      selectedFiltersVal={props.filtersSelected as any}
      setFilterApplied={(data: AppliedFilter[]) =>
        props.filtersAppliedCallback(data)
      }
      filters={props.filters}
      currentPageNumber={props.currentPageNumber}
      currentPageSize={props.currentPageSize}
      onPageChange={(pageData: any) => props.onPageChange(pageData)}
      emptyState={emptyStateData}
      sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
      persistFilter={props.persistFilter}
      render403Container={props.error403Flag}
      render500Container={props.error500Flag}
      leftInlineMultiSelectFilter={props.leftInlineMultiSelectFilter}
    />
  );
};

export default ApplicationsTable;
