import React from 'react';
import { useSearchParams } from 'react-router-dom';

const PageTitle: React.FC<{
  title: React.ReactElement | string | any;
  headerIcon?: React.ReactElement | boolean;
}> = ({ title, headerIcon }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('gatewayId');

  return headerIcon ? (
    <div className='page-title-main'>
      <h1 className='page-title-text' data-testid='page-title-text'>
        {title}
      </h1>
      <span className='page-title-icon' data-testid='page-title-icon'>
        {headerIcon}
      </span>
    </div>
  ) : id === null ? (
    <div className='page-title-main'>
      <span className='page-title-only' data-testid='page-title-text'>
        {title}
      </span>
    </div>
  ) : (
    <div className='page-title-main gw-health-status'>
      <span className='page-title-only' data-testid='page-title-text'>
        {title?.gwDetails?.name}
      </span>
    </div>
  );
};

export default PageTitle;
