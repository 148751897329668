import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'carbon-components-react';
import { EdgeCluster16 } from '@carbon/icons-react';

import images from '../../images/images';
import LabelTag from '../LabelTag/LabelTag';
import IconWithToolTip from '../IconWithToolTip/IconWithToolTip';
import GenericTruncateString from '../GenericTruncateString/GenericTruncateString';
import GenericTableWithFilters from '../GenericTableWithFilters/GenericTableWithFilters';

import {
  AppliedFilter,
  DeploymentEnvironmentSubtype,
  ResourceGroup,
} from '../../models/master';
import sortData from '../../lib/tableSort';
import { Cluster } from './ConnectGatewayCluster';
import { Direction } from '../../lib/enums';

interface Props {
  isLoading: boolean;
  show403Container: boolean;
  show500Container: boolean;
  clusters: Cluster[] | null;
  resourceGroups: ResourceGroup[] | null;
  deploymentEnvironmentSubtypes: DeploymentEnvironmentSubtype[] | null;
  selectedCluster: Cluster | null;
  onClusterSelect: (cluster: Cluster) => void;
  onClusterRefresh: () => void;
}

const ClusterTable: React.FC<Props> = ({
  isLoading,
  show403Container,
  show500Container,
  clusters,
  resourceGroups,
  deploymentEnvironmentSubtypes,
  selectedCluster,
  onClusterSelect,
  onClusterRefresh,
}) => {
  const { t } = useTranslation('connectGatewayCluster');

  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [filteredData, setFilteredData] = useState<Cluster[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(5);
  const [cloudFilterName, setCloudFilterName] = useState('');

  const getSubTypeMap = () => {
    const subTypeMap: { [key: string]: string } = {};

    if (Array.isArray(deploymentEnvironmentSubtypes)) {
      for (const subType of deploymentEnvironmentSubtypes) {
        subTypeMap[subType.type_code] = subType.type_name;
      }
    }

    return subTypeMap;
  };

  const subTypeMap = useMemo(
    () => getSubTypeMap(),
    [deploymentEnvironmentSubtypes]
  );

  const getResourceGroupMap = () => {
    const resourceGroupMap: { [key: string]: string } = {};

    if (Array.isArray(resourceGroups)) {
      for (const resourceGroup of resourceGroups) {
        resourceGroupMap[resourceGroup.resource_id] = resourceGroup.name;
      }
    }

    return resourceGroupMap;
  };

  const resourceGroupMap = useMemo(
    () => getResourceGroupMap(),
    [resourceGroups]
  );

  const getDeploymentTableHeaders = () => {
    return [
      {
        key: 'checkbox',
        originalKey: 'checkbox',
        header: '',
      },
      {
        key: 'name__format',
        originalKey: 'name',
        header: t(`deploymentEnvTable.tableHeaders.name`),
        sort: 'sortByName',
        style: { minWidth: '11.5rem' },
      },
      {
        key: 'resourceGroup',
        originalKey: 'resourceGroup',
        sort: 'sortByName',
        header: t('deploymentEnvTable.tableHeaders.resourceGroup'),
      },
      {
        key: 'location',
        originalKey: 'location',
        header: t('deploymentEnvTable.tableHeaders.location'),
        sort: 'sortByLocation',
      },
      {
        key: 'cloudName',
        originalKey: 'cloudName',
        header: t('deploymentEnvTable.tableHeaders.cloudName'),
        sort: 'sortByCloudName',
      },
      {
        key: 'labels',
        originalKey: 'labels',
        header: t('deploymentEnvTable.tableHeaders.labels'),
      },
      {
        key: 'type',
        originalKey: 'type',
        header: t('deploymentEnvTable.tableHeaders.type'),
        sort: 'sortByType',
      },
    ];
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const handleTableRowClick = (id: string) => {
    if (id && Array.isArray(clusters)) {
      const selectedCluster = clusters.find(
        cluster => cluster.resource_id === id
      );

      if (selectedCluster) {
        onClusterSelect(selectedCluster);
      }
    }
  };

  const formatTags = (data: string[]) => {
    return <LabelTag labelArray={data} count={3} />;
  };

  const renderRadioButton = (item: Cluster, checked: boolean) => {
    return (
      <div>
        <RadioButton
          id={'' + item.resource_id}
          labelText=''
          hideLabel
          checked={checked}
          onClick={e => onClusterSelect(item)}
        />
      </div>
    );
  };

  const getFormattedRows = (rows: Cluster[] | null | []) => {
    const formattedData: {
      checkbox: JSX.Element;
      id: string;
      name: string;
      name__format: JSX.Element;
      resourceGroup: string;
      location: string;
      type: string | false;
      cloudName: string;
      labels: JSX.Element | string;
    }[] = [];

    if (Array.isArray(rows)) {
      rows.map((row: Cluster) => {
        const checked = selectedCluster
          ? row.resource_id === selectedCluster.resource_id
          : false;
        formattedData.push({
          checkbox: renderRadioButton(row, checked),
          id: row.resource_id,
          name: row.name,
          name__format: (
            <div className='name-column-value'>
              <div className='depl-env-icon'>
                {row.type === 'cluster' ? (
                  <EdgeCluster16 />
                ) : (
                  images.vpcIconSvg()
                )}
              </div>
              <GenericTruncateString
                str={row.name}
                maxLength={23}
                limit={9}
                tableView={false}
              />
              {row?.is_discovered && (
                <div className='icon'>
                  <IconWithToolTip
                    icon={images.AutoDiscoverdLockIconSmall()}
                    iconDescription={t(
                      'deploymentEnvTable.autoDiscoveredAccessLimited'
                    )}
                  />
                </div>
              )}
            </div>
          ),
          resourceGroup: resourceGroupMap[row.resource_group_id],
          location: row.location_name,
          cloudName: row.cloud_name,
          type: subTypeMap[row.subtype] ?? '',
          labels: row.labels ? formatTags(row.labels) : '—',
        });
        return 0;
      });
    } else {
      return null;
    }

    return formattedData;
  };

  const getFilteredClusters = () => {
    if (Array.isArray(clusters)) {
      if (cloudFilterName) {
        return clusters
          .filter(cluster => cluster.cloud_name === cloudFilterName)
          .map(cluster => ({
            ...cluster,
            resourceGroup: resourceGroupMap[cluster.resource_group_id],
            subTypeName: subTypeMap[cluster.subtype],
          }));
      } else {
        return clusters.map(cluster => ({
          ...cluster,
          resourceGroup: resourceGroupMap[cluster.resource_group_id],
          subTypeName: subTypeMap[cluster.subtype],
        }));
      }
    }

    return [];
  };

  const filteredClusters = useMemo(
    () => getFilteredClusters(),
    [clusters, cloudFilterName, resourceGroupMap, subTypeMap]
  );

  const leftInlineFilters: any = [
    {
      key: t('deploymentEnvTable.view'),
      label: t('deploymentEnvTable.view'),
      type: '',
      values: [t('deploymentEnvTable.leftFilterCloudsValue')]
        .concat(
          Array.from(new Set(clusters?.map(cluster => cluster.cloud_name)))
        )
        .map(item =>
          item === t('deploymentEnvTable.leftFilterCloudsValue')
            ? {
                label: t('deploymentEnvTable.leftFilterCloudsValue'),
                value: '',
              }
            : {
                label: item,
                value: item,
              }
        ),
      filterCallback: (e: any) => {
        if (e.selectedItem.label) {
          setCloudFilterName(e.selectedItem.value);
        }
      },
    },
  ];

  const emptyStateData = {
    icon: images.DeploymentEnvironmentEmpty(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('deploymentEnvTable.emptyState.emptyContainerHeader'),
    emptyStateDescription: t(
      'deploymentEnvTable.emptyState.emptyContainerDescription'
    ),
  };

  return (
    <GenericTableWithFilters
      id='connect-gateway-cluster-table'
      rows={
        Array.isArray(clusters)
          ? filterApplied.length > 0
            ? sortData(
                getFormattedRows(filteredData as any),
                sortKey,
                sortDirection
              )
            : sortData(
                getFormattedRows(filteredClusters),
                sortKey,
                sortDirection
              )
          : null
      }
      data={
        filteredClusters
          ? filterApplied.length > 0
            ? filteredData
            : filteredClusters
          : null
      }
      headers={getDeploymentTableHeaders()}
      isSortable={true}
      totalElementsCount={
        filteredClusters
          ? filterApplied.length > 0
            ? filteredData.length
            : filteredClusters?.length ?? 0
          : 0
      }
      fullData={filteredClusters}
      onTableRefresh={onClusterRefresh}
      showRefresh={true}
      filteredDataCallback={(data: Cluster[] | []) => {
        data && setFilteredData(data);
        setPageNumber(1);
      }}
      selectedFiltersVal={filterApplied as any}
      setFilterApplied={(data: AppliedFilter[]) => setFilterApplied(data)}
      filters={
        [
          {
            key: 'location',
            type: 'multi',
            mdWidth: 4,
            lgWidth: 4,
            label: t('deploymentEnvTable.tableHeaders.filter.location'),
            values: [
              ...Array.from(
                new Set(filteredClusters?.map(cluster => cluster.location_name))
              ),
            ],
          },
          {
            key: 'infrastructureGroup',
            type: 'single',
            mdWidth: 4,
            lgWidth: 4,
            label: t('deploymentEnvTable.tableHeaders.filter.resourceGroup'),
            values: [
              ...Array.from(
                new Set(filteredClusters?.map(cluster => cluster.resourceGroup))
              ),
            ],
          },
          {
            key: 'subTypeName',
            label: t('deploymentEnvTable.tableHeaders.filter.subType'),
            type: 'single',
            values: [
              ...Array.from(
                new Set(filteredClusters?.map(cluster => cluster.subTypeName))
              ),
            ],
          },
        ] as any[]
      }
      currentPageNumber={currentPageNumber}
      currentPageSize={currentPageSize}
      paginationSizeSelections={[5, 10, 25, 50, 100]}
      onPageChange={(pageData: any) => setPageChange(pageData)}
      emptyState={emptyStateData}
      sortRows={(
        data: { id: string; text: string },
        sortDirection: Direction
      ) => handleTableSort(data, sortDirection)}
      leftInlineFilters={leftInlineFilters}
      visibilityFlag={cloudFilterName}
      render403Container={show403Container}
      render500Container={show500Container}
      tableDataLoading={isLoading}
      onRowClick={handleTableRowClick}
    />
  );
};

export default ClusterTable;
