import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { Tooltip, Dropdown, Accordion, AccordionItem } from '@carbon/react';
import { Information16 } from '@carbon/icons-react';
import { ComboBox, TextArea, TextInput } from 'carbon-components-react';

import AddLabels from '../../../components/AddLabels/AddLabels';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import NarrowTearsheetComponent from '../../../components/NarrowTearsheet/NarrowTearsheet';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';

import { removeExtraSpace } from '../../../lib/utils';

import { updateNetworkSegments } from '../../../controllers/networksegmentsApi';

import { gatewaySet } from '../../NetworkSegmentDetails/config';
import { DEFAULT_INFRASTRUCTURE_ID } from '../../../lib/constants';
import { NetworkSegment, ResourceGroup } from '../../../models/master';

import './EditNetworkSegment.scss';

export interface FormData {
  name: {
    value: string;
    error?: boolean;
    errorMessage?: string;
  };
  gatewaySet: {
    name: string;
    value: string;
  };
  infrastructureGroup: {
    value: ResourceGroup | null;
    error?: boolean;
    errorMessage?: string;
  };
  description: {
    value: string;
    error?: boolean;
    errorMessage?: string;
  };
  labels: {
    value: string[];
    error?: boolean;
    errorMessage: string;
  };
}

const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  gatewaySet: {
    name: gatewaySet[1].name,
    value: gatewaySet[1].value,
  },
  infrastructureGroup: {
    value: null,
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
    error: false,
    errorMessage: '',
  },
  description: {
    value: '',
    error: false,
    errorMessage: '',
  },
};

const requiredFields = ['name', 'infrastructureGroup'];

interface Props {
  open: boolean;
  onClose: () => void;
  onUpdate: (data: NetworkSegment) => void;
  networkSegmentData?: NetworkSegment | null;
  networkSegments: NetworkSegment[] | null;
  infraResourceGroups: ResourceGroup[] | null;
}

const EditNetworkSegment: React.FC<Props> = ({
  open,
  onClose,
  onUpdate,
  networkSegmentData,
  networkSegments,
  infraResourceGroups,
}) => {
  const { t } = useTranslation('editNetworkSegment');

  const notification = useContext(NotificationContext);

  const [formData, setFormData] = useState<FormData>({ ...defaultFormValue });
  const [saving, setSaving] = useState(false);
  const [showErrorNotification, toggleErrorNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [errorType, setErrorType] = useState('default');

  useEffect(() => {
    if (open) {
      setEditFormData();
    }
  }, [open, networkSegmentData, infraResourceGroups]);

  const setEditFormData = () => {
    if (networkSegmentData) {
      const resourceGroupSelected = networkSegmentData.resource_group_id
        ? infraResourceGroups?.find(
            resourceGroup =>
              resourceGroup?.resource_id ===
              networkSegmentData?.resource_group_id
          )
        : infraResourceGroups?.find(
            resourceGroup =>
              resourceGroup?.resource_id === DEFAULT_INFRASTRUCTURE_ID
          );

      setFormData({
        name: {
          ...formData.name,
          value: networkSegmentData?.name,
        },
        gatewaySet: {
          ...formData.gatewaySet,
        },
        infrastructureGroup: {
          ...formData.infrastructureGroup,
          value: resourceGroupSelected ? resourceGroupSelected : null,
        },
        description: {
          ...formData.description,
          value: networkSegmentData?.description
            ? networkSegmentData?.description
            : '',
        },
        labels: {
          ...formData.labels,
          value: JSON.parse(JSON.stringify(networkSegmentData?.labels)),
        },
      });
    }
  };

  const isUniqueName = (networkSegments: NetworkSegment[], value: string) => {
    let notUnique = false;

    if (networkSegments && value) {
      notUnique =
        networkSegments?.filter(
          (networkSegment: NetworkSegment) =>
            networkSegment.name.trim() === value.trim() &&
            networkSegment.name.trim() !== networkSegmentData?.name.trim()
        ).length > 0
          ? true
          : false;
      return notUnique;
    }

    return notUnique;
  };

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';

    switch (name) {
      case 'name':
        const valueEmpty = value === '';
        let notUnique;
        notUnique = networkSegments && isUniqueName(networkSegments, value);
        const error = valueEmpty || notUnique;
        const message = valueEmpty
          ? t('validation.name.required')
          : t('validation.name.notUnique');
        errorMessage = error ? message : '';
        break;
      case 'infrastructureGroup':
        errorMessage = !value
          ? t('validation.infrastructureGroup.required')
          : '';
        break;
    }

    return errorMessage;
  };

  const validateForm = () => {
    for (const field of requiredFields) {
      const value = (formData as any)[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;

      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }

    return true;
  };

  const isFormValid = () => validateForm();

  const isChanged = () => {
    if (
      networkSegmentData?.name === formData?.name?.value &&
      networkSegmentData?.compatibility_set === formData?.gatewaySet?.value &&
      isLabelChanged() &&
      networkSegmentData?.resource_group_id ===
        formData?.infrastructureGroup?.value?.resource_id &&
      networkSegmentData?.description === formData?.description?.value
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isLabelChanged = () => {
    if (
      JSON.stringify(networkSegmentData?.labels) ===
      JSON.stringify(formData?.labels?.value)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnChange = (name: string, value: any) => {
    const errorMessage = checkFieldValidation(name, value);

    setFormData((prevState: any) => ({
      ...prevState,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      setSaving(true);

      const requestData = {
        name: removeExtraSpace(formData.name.value),
        compatibility_set: formData.gatewaySet.value,
        resource_group_id:
          formData.infrastructureGroup.value &&
          formData.infrastructureGroup.value.resource_id,
        labels: formData.labels.value,
        description: formData.description.value,
      };

      const networkSegmen: NetworkSegment = await updateNetworkSegments(
        networkSegmentData?.resource_id,
        requestData
      );

      notification.onTrigger('TOAST', {
        title: t('successNotification.title'),
        subtitle: t('successNotification.subTitle'),
      });

      handleOnClose();

      onUpdate(networkSegmen);
    } catch (error: any) {
      toggleErrorNotification(true);

      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);

      if (err.response?.status === 403) {
        setErrorMessage(t('failureNotification.authErrorTitle'));
        setErrorType('auth');
      } else {
        setErrorMessage(t('failureNotification.title'));
        setErrorType('default');
      }
    } finally {
      setSaving(false);
    }
  };

  const handleCloseErrorNotification = () => {
    toggleErrorNotification(false);
    setErrorType('default');
    setErrorMessage('');
    setSubTitleErrorMsg('');
  };

  const handleOnClose = () => {
    setFormData({ ...defaultFormValue });
    setSaving(false);
    setErrorType('default');
    setErrorMessage('');
    setSubTitleErrorMsg('');
    toggleErrorNotification(false);

    onClose();
  };

  return (
    <NarrowTearsheetComponent
      title={t('header', { networkSegment: networkSegmentData?.name })}
      description={t('subheader')}
      open={open}
      className={'edit-network-segment-tearsheet'}
      actions={[
        {
          kind: 'primary',
          label: t('save'),
          onClick: handleSubmit,
          disabled: !isFormValid() || !isChanged(),
          loading: saving,
        },
        {
          kind: 'secondary',
          label: t('cancel'),
          onClick: handleOnClose,
        },
      ]}
    >
      <div className='outer-container'>
        {showErrorNotification && (
          <InlineNotification
            onClose={() => handleCloseErrorNotification() as any}
            kind={'error'}
            title={errorMessage + '.'}
            subtitle={
              errorType === 'auth'
                ? (t('failureNotification.authErrorSubtitle') as string)
                : subTitleErrorMsg.length === 0
                ? (t('failureNotification.subtitle') as string)
                : subTitleErrorMsg
            }
          />
        )}

        <div className='title-container'>
          <div className='title'>{t('title')}</div>
          <div className='visibility'>
            <div className='title'>
              {networkSegmentData?.unmanaged ? t('unmanaged') : t('managed')}
            </div>
            <Tooltip
              className='icon'
              align={'bottom-right'}
              label={
                networkSegmentData?.unmanaged
                  ? t('unmanagedTooltip')
                  : t('managedTooltip')
              }
            >
              <Information16 />
            </Tooltip>
          </div>
        </div>

        <div className='form'>
          <div className='row'>
            <TextInput
              id='edit-network-segment-name'
              value={formData.name.value}
              name='segment-name'
              autoComplete='off'
              onChange={e => handleOnChange('name', e.target.value)}
              labelText={t('name.label')}
              placeholder={t('name.placeholder')}
              invalid={formData.name.error}
              invalidText={formData.name.errorMessage}
              maxLength={60}
            />
          </div>
          <div className='row'>
            <div className='gateway-set-label'>
              <span>{t('gatewaySet.label')}</span>
              <Tooltip label={t('gatewaySet.infoText')} align='bottom'>
                <div className='info-tooltip-text'>
                  <Information16 />
                </div>
              </Tooltip>
            </div>
            <Dropdown
              id='edit-segment-gateway-set'
              selectedItem={formData.gatewaySet}
              name='segment-gateway-set'
              itemToString={(item: any) => (item ? item.name : '')}
              onChange={() => {}}
              items={gatewaySet}
              readOnly
            />
          </div>
          <div className='row'>
            <ComboBox
              light
              id='edit-segment-resource-group'
              name='infrastructureGroup'
              selectedItem={formData?.infrastructureGroup?.value ?? ''}
              items={infraResourceGroups ?? []}
              itemToString={(item: ResourceGroup) => (item ? item.name : '')}
              titleText={t('infraGroup.label')}
              placeholder={t('infraGroup.placeholder')}
              onChange={(e: any) =>
                handleOnChange('infrastructureGroup', e.selectedItem)
              }
              invalid={formData.infrastructureGroup.error}
              invalidText={formData.infrastructureGroup.errorMessage}
            />
          </div>
        </div>

        <div className='optional-title-container'>
          <div className='title'>{t('opitionalSetting.title')}</div>
        </div>

        <Accordion className='accordion'>
          <AccordionItem title={t('opitionalSetting.subtitle')}>
            <div className='form'>
              <div className='row'>
                <AddLabels
                  id='edit-network-segment-add-labels'
                  labelText={t('addLabels.label')}
                  placeholder={t('addLabels.placeholder')}
                  onChange={data => handleOnChange('labels', data)}
                  btnText={t('addLabels.buttonText')}
                  btnKind='secondary'
                  tagType='green'
                  defaultValues={formData.labels.value}
                />
              </div>

              <div className='row'>
                <TextArea
                  id='edit-network-segment-description'
                  labelText={t('description.label')}
                  placeholder={t('description.placeholder')}
                  name='description'
                  value={formData.description.value}
                  onChange={e => handleOnChange('description', e.target.value)}
                  maxCount={300}
                  enableCounter={true}
                />
              </div>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    </NarrowTearsheetComponent>
  );
};

export default EditNetworkSegment;
