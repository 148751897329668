import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateTearsheetStep } from '@carbon/ibm-products';

import SelectedFromToCard from '../CreateConnectionAccessPolicy/PolicyAllowConnections/SelectedFromToCard/SelectedFromToCard';

import '../CreateConnectionAccessPolicy/PolicyAllowConnections/PolicyAllowConnections.scss';
import { NetworkSegment } from '../../../models/master';
import {
  ConnectionOptionTypes,
  ConnectionSearchItem,
  getConnectionConfig,
  Item,
} from '../CreateConnectionAccessPolicy/config';
import {
  Row,
  Column,
  ComboBox,
  SkeletonPlaceholder,
  FormLabel,
  TooltipDefinition,
} from 'carbon-components-react';
import { ConnectionSelectedTypes, PolicyDataType } from '../../../lib/enums';
import { Information16 } from '@carbon/icons-react';

interface Props {
  isDataLoading: boolean;
  fromType: PolicyDataType;
  selectedOptions: Item[];
  availableOptions: Item[];
  service: Item | null;
  onViewAllOptions: (open: boolean) => void;
  onRemoveOption: (data: Item) => void;
  onAddOption: (data: Item, type: ConnectionOptionTypes) => void;
  networkSegment: NetworkSegment;
  formValid: boolean;
}

const EditPolicyConnections: React.FC<Props> = ({
  fromType,
  isDataLoading,
  selectedOptions,
  availableOptions,
  service,
  onViewAllOptions,
  onRemoveOption,
  onAddOption,
  networkSegment,
  formValid,
  children,
}) => {
  const { t } = useTranslation('createPolicyAllowConnections');
  const [suggestionOptions, setSuggestionOptions] = useState<
    ConnectionSearchItem[]
  >([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(false);

  const connectionOptions = getConnectionConfig(
    ConnectionSelectedTypes.FROM,
    t
  );

  const getSuggestionOptions = (connectionOptions: any) => {
    const searchItemObj: ConnectionSearchItem[] = [];
    const optionValues = [
      ...availableOptions,
      {
        id: networkSegment?.resource_id ?? '',
        label: networkSegment?.name ?? '',
        type: PolicyDataType.NETWORKSEGMENT,
      },
    ];
    if (Array.isArray(connectionOptions)) {
      connectionOptions.forEach(connectionOption => {
        let matchedOptions: Item[] = [];
        optionValues.map(option => {
          if (option.type === connectionOption.name) {
            matchedOptions.push({
              ...option,
              label:
                option.type === PolicyDataType.NAMESPACE
                  ? `${option.parentName}/${option.label}`
                  : option.label,
            });
          }
        });
        searchItemObj.push({
          type: connectionOption.name,
          suggestionTitle: connectionOption.label,
          suggestionOptions: matchedOptions,
          icon: connectionOption.icon,
          suggestionDisplayKey: 'label',
          filterKey: 'label',
        });
      });
    }
    return searchItemObj;
  };

  const setSearchSuggestions = () => {
    const options: ConnectionSearchItem[] =
      getSuggestionOptions(connectionOptions);

    setSuggestionOptions(options);
  };

  useEffect(() => {
    setSearchSuggestions();
  }, [availableOptions]);

  useEffect(() => {
    const handleFocusChange = () => {
      if (document.activeElement === document.getElementById('search-1')) {
        setSearchClicked(true);
      } else {
        setSearchClicked(false);
      }
    };

    document.addEventListener('focusin', handleFocusChange);
    document.addEventListener('focusout', handleFocusChange);

    return () => {
      document.removeEventListener('focusin', handleFocusChange);
      document.removeEventListener('focusout', handleFocusChange);
    };
  }, []);

  return (
    <CreateTearsheetStep
      title={t('editsubtitle') as string}
      hasFieldset={false}
      className={'policy-allow-connection edit'}
      disableSubmit={!formValid}
    >
      {children}
      <div className='connections-container'>
        <Column lg={16}>
          <div className='policy-allow-connections-title'>{t('title')}</div>
          <div className='policy-allow-connections-subtitle'>
            {t('subtitle.subtitleStart')} <strong> {t('subtitle.from')}</strong>
            {t('subtitle.and')}
            <strong> {t('subtitle.to')}</strong> {t('subtitle.subtitleEnd')}
          </div>

          {isDataLoading ? (
            <Row className='policy-allowConnection-networkSegment'>
              <Column md={8} lg={8}>
                <div className='policy-allowConnection-dropdown'>
                  <div>{t('networkSegment')}</div>
                  <SkeletonPlaceholder className='policy-networkSegment-skelton' />
                </div>
              </Column>
            </Row>
          ) : (
            <Row className='policy-allowConnection-networkSegment'>
              <Column lg={8}>
                <ComboBox
                  translateWithId={t}
                  id='editConnectionNetworkSegment'
                  name='networkSegment'
                  className='policy-allowConnection-dropdown disabled'
                  selectedItem={networkSegment}
                  itemToString={(item: NetworkSegment) => item.name}
                  titleText={t('networkSegment')}
                  readOnly
                  items={[]}
                />
              </Column>
              <Column lg={4}>
                <div className='service-sync-setting'>
                  <FormLabel className='label'>
                    {t('serviceSync.label')}
                    <TooltipDefinition
                      tooltipText={
                        networkSegment?.service_sync
                          ? t('serviceSync.editPolicyTooltip.on')
                          : t('serviceSync.editPolicyTooltip.off')
                      }
                      direction='top'
                    >
                      <Information16 />
                    </TooltipDefinition>
                  </FormLabel>
                  <div className='value'>
                    {networkSegment?.service_sync
                      ? t('serviceSync.on')
                      : t('serviceSync.off')}
                  </div>
                </div>
              </Column>
            </Row>
          )}
          <Row>
            <Column className='policy-allowConnection-fromToContainer'>
              <div
                className={
                  'from-container selected' +
                  (searchClicked ||
                  buttonHovered ||
                  networkSegment?.service_sync
                    ? ' dontAllowHoverBackground'
                    : ' allowHoverBackground')
                }
              >
                <SelectedFromToCard
                  dataType={fromType}
                  cardActive={true}
                  heading={ConnectionSelectedTypes.FROM}
                  selectedData={selectedOptions}
                  onRemove={onRemoveOption}
                  actionButton={{
                    text: 'View all resources',
                    actionButtonCallback: () => onViewAllOptions(true),
                    onHover: (value: boolean) => setButtonHovered(value),
                  }}
                  cardDisabled={networkSegment?.service_sync ? true : false}
                  suggestionOptions={suggestionOptions as any}
                  onAddItem={onAddOption}
                  onButtonHover={(value: boolean) =>
                    networkSegment.service_sync ? null : setButtonHovered(value)
                  }
                />
              </div>
              <div className='connection-line'></div>
              <div className='to-container'>
                <SelectedFromToCard
                  dataType={PolicyDataType.SERVICE}
                  cardActive={false}
                  cardDisabled={true}
                  heading={ConnectionSelectedTypes.TO}
                  selectedData={service ? [service] : []}
                />
              </div>
            </Column>
          </Row>
        </Column>
      </div>
    </CreateTearsheetStep>
  );
};

export default EditPolicyConnections;
