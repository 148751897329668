import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ClickableTile,
  Row,
  Column,
  TooltipDefinition,
} from 'carbon-components-react';
import PolicyTargetField from '../../components/PolicyTargetField/PolicyTargetField';

import './ResourceCard.scss';
import LabelTag, { TagType } from '../LabelTag/LabelTag';
import { Deployment, NetworkSegment, Service } from '../../models/master';
import GenericTruncateString from '../GenericTruncateString/GenericTruncateString';
import IconWithToolTip from '../IconWithToolTip/IconWithToolTip';
import GenericStatusField from '../GenericStatusField/GenericStatusField';
import GenericResponsiveMiddleTruncation from '../GenericResponsiveMiddleTruncation/GenericResponsiveMiddleTruncation';
import { NotAuthorised, ResourceTypes, VisibilityFlags } from '../../lib/enums';

interface Application {
  services: Service[];
  deployments: Deployment[];
  deploymentsNotAuthorized: boolean;
  applicationGroup: string;
  networkSegmentName: string;
}

interface Policy {
  from_app_name: string;
  to_svc_name: string;
  resource_group_name?: string;
  encryption: string;
  from_type: 'application' | 'partition';
  network_segment_name?: string;
}

interface DeploymentEnv {
  location: string;
  type: string;
  unmanaged: boolean | string;
  resourceGroupName: string;
}

const ResourceCard = ({
  resourceType,
  header,
  subheader,
  tags,
  data,
  updated,
  path,
}: {
  resourceType: 'APPLICATION' | 'POLICY' | 'ENVIRONMENT' | 'NETWORKSEGMENT';
  header: string;
  subheader?: string;
  tags: string[] | TagType[];
  data: Application | Policy | DeploymentEnv | NetworkSegment | any;
  updated: string;
  path?: string;
}) => {
  const { t } = useTranslation('resourceCard');
  const navigate = useNavigate();
  const formatTooltip = (data: string[] | JSX.Element[] | string) => {
    // TODO: update once labels are populated
    if (data === '—') {
      return '—';
    }
    if (data.length > 0) {
      return (
        <TooltipDefinition tooltipText={data} direction='top'>
          {data.length}
        </TooltipDefinition>
      );
    } else return 0;
  };

  return (
    <ClickableTile
      className='resource-card'
      onClick={() => path && navigate(path)}
      data-testid='resource-card-tile'
    >
      <Row className='header'>
        <Column>
          {resourceType !== ResourceTypes.NETWORKSEGMENT && (
            <div className='name'>
              <GenericResponsiveMiddleTruncation
                str={header}
                isFullWidthText={true}
              />
              {resourceType === ResourceTypes.ENVIRONMENT &&
              (data as DeploymentEnv).unmanaged ? (
                <div className='icon'>
                  <IconWithToolTip
                    icon={<div className='unmanaged-icon' />}
                    iconDescription={t('unmanaged')}
                  />
                </div>
              ) : null}
            </div>
          )}
        </Column>
      </Row>
      {resourceType === 'POLICY' ? (
        <>
          <Row className='header'>
            <Column className='policy-app-resource'>
              <div className='label-text'>{t('appResourceGroup')}</div>

              {(data as Policy).resource_group_name && (
                <GenericResponsiveMiddleTruncation
                  str={(data as Policy).resource_group_name as string}
                  className='value'
                />
              )}
            </Column>
            <Column className='policy-network-segment'>
              <div className='label-text'>{t('networkSegment')}</div>
              {(data as Policy).network_segment_name && (
                <GenericResponsiveMiddleTruncation
                  str={(data as Policy).network_segment_name as string}
                  className='value'
                />
              )}
            </Column>
          </Row>
        </>
      ) : null}
      {resourceType === 'APPLICATION' ? (
        <Row className='header'>
          <Column>
            <div className='label-text'>{t('applicationGroup')}</div>
            <span className='value'>
              <GenericTruncateString
                str={(data as Application).applicationGroup}
                limit={6}
                maxLength={20}
                tableView={false}
              />
            </span>
          </Column>
          <Column>
            <div className='label-text'>{t('networkSegment')}</div>
            <span className='value'>
              <GenericTruncateString
                str={(data as Application).networkSegmentName}
                limit={6}
                maxLength={20}
                tableView={false}
              />
            </span>
          </Column>
        </Row>
      ) : null}
      {resourceType !== 'NETWORKSEGMENT' && (
        <Row className='data'>
          {resourceType === 'POLICY' ? (
            <Column className='policy-column'>
              <div className='from'>
                <div className='label-text'>{t('from')}</div>
                {/* TODO: 'type' value will come from api, 'label' can be array of policy targets */}
                <div className='value'>
                  {(data as Policy).from_app_name ? (
                    (data as Policy).from_app_name !== NotAuthorised ? (
                      <PolicyTargetField
                        label={(data as Policy).from_app_name}
                        type={(data as Policy).from_type}
                      />
                    ) : (
                      <GenericStatusField status={'notAuthorised'} />
                    )
                  ) : (
                    '—'
                  )}
                </div>
              </div>
              <div className='to'>
                <div className='label-text'>{t('to')}</div>
                {/* TODO: 'type' value will come from api, 'label' can be array of policy targets */}
                <div className='value'>
                  {(data as Policy).to_svc_name ? (
                    (data as Policy).to_svc_name !== NotAuthorised ? (
                      <PolicyTargetField
                        label={(data as Policy).to_svc_name}
                        type='service'
                      />
                    ) : (
                      <GenericStatusField status={'notAuthorised'} />
                    )
                  ) : (
                    '—'
                  )}
                </div>
              </div>
            </Column>
          ) : (
            <>
              <Column>
                <div className='label-text'>
                  {resourceType === 'APPLICATION'
                    ? t('deployments')
                    : t('resourceGroup')}
                </div>
                <span className='value'>
                  {resourceType === 'APPLICATION' ? (
                    !(data as Application).deploymentsNotAuthorized ? (
                      formatTooltip(
                        Array.isArray((data as Application).deployments)
                          ? //fetching deployment environment's name
                            (data as Application).deployments.map(
                              item =>
                                data?.allDepEnvs?.filter(
                                  (d: { resource_id: any }) =>
                                    d?.resource_id === item?.depl_env_id
                                )[0]?.name +
                                ((data as Application)?.deployments[
                                  (data as Application)?.deployments.length - 1
                                ]?.depl_env_id === item?.depl_env_id
                                  ? ''
                                  : ',')
                            )
                          : []
                      )
                    ) : (
                      <GenericStatusField status='notAuthorised'></GenericStatusField>
                    )
                  ) : (
                    (data as DeploymentEnv).resourceGroupName
                  )}
                </span>
              </Column>
            </>
          )}
        </Row>
      )}

      {resourceType === 'ENVIRONMENT' ? (
        <>
          <Row>
            <Column>
              <div className='label-text'>{t('location')}</div>
              <span className='value value-truncation'>
                <GenericTruncateString
                  str={(data as DeploymentEnv).location}
                  limit={6}
                  maxLength={20}
                  tableView={false}
                />
              </span>
            </Column>
            <Column>
              <div className='label-text'>{t('type')}</div>
              <span className='value'>
                <GenericTruncateString
                  str={(data as DeploymentEnv).type}
                  limit={6}
                  maxLength={20}
                  tableView={false}
                />
              </span>
            </Column>
          </Row>
        </>
      ) : null}

      <div className='bottom-card'>
        {' '}
        <Row className='tags'>
          {Array.isArray(tags) && tags.length > 0 ? (
            <Column className='label-col'>
              <LabelTag labelArray={tags} singleLine={true} />
            </Column>
          ) : (
            ''
          )}
        </Row>
        <Row>
          <Column>
            <div className='helper-text'>{t('updated', { date: updated })}</div>
          </Column>
        </Row>
      </div>
    </ClickableTile>
  );
};

export default ResourceCard;
