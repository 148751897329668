import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClickableTile, Row, Column } from 'carbon-components-react';
import LabelTag, { TagType } from '../../components/LabelTag/LabelTag';
import GenericResponsiveMiddleTruncation from '../../components/GenericResponsiveMiddleTruncation/GenericResponsiveMiddleTruncation';
import { VisibilityFlags } from '../../lib/enums';
import './NetworkSegmentsCard.scss';
export interface NetworkSegmentCardData {
  unmanaged?: boolean;
  description?: string;
  flowCollector: string;
  proceduralStatus: JSX.Element | string;
  gatewaySet: string;
  resourceGroupId: JSX.Element | string;
}

const NetworkSegmentsCards = ({
  resourceType,
  header,
  tags,
  data,
  updated,
  networkSegmentId,
}: {
  resourceType: 'NETWORKSEGMENT';
  header: string;
  tags: string[] | TagType[];
  data: NetworkSegmentCardData;
  updated: string;
  networkSegmentId?: string;
}) => {
  const { t } = useTranslation('resourceCard');
  const navigate = useNavigate();

  return (
    <ClickableTile
      className='networkSegment-clickable-card'
      onClick={() =>
        networkSegmentId &&
        navigate(`/networkSegmentDetails?nwSegId=${networkSegmentId}`)
      }
    >
      <Row className='header'>
        <Column>
          {!!header ? (
            <div
              className={
                resourceType === 'NETWORKSEGMENT' &&
                (data as NetworkSegmentCardData).unmanaged
                  ? VisibilityFlags.UNMANAGED
                  : ''
              }
            >
              {(data as NetworkSegmentCardData).unmanaged ? (
                <div className='unmanaged-icon'></div>
              ) : null}
              <GenericResponsiveMiddleTruncation
                str={header}
                isFullWidthText={true}
              />
            </div>
          ) : (
            '-'
          )}
        </Column>
      </Row>
      <Row className='first-row-data'>
        <Column>
          <div className='label-text'>{t('gatewaySet')}</div>
          <span className='value'>
            {data.gatewaySet && (
              <GenericResponsiveMiddleTruncation
                str={data.gatewaySet as string}
                className='value'
              />
            )}
          </span>
        </Column>
        <Column>
          <div className='label-text'>
            <GenericResponsiveMiddleTruncation
              str={t('resourceGroupId') as string}
              className='value'
            />
          </div>
          <span className='value'>
            {data.resourceGroupId ? (
              <GenericResponsiveMiddleTruncation
                str={data.resourceGroupId as string}
                className='value'
              />
            ) : (
              '-'
            )}
          </span>
        </Column>
      </Row>
      <Row className='data'>
        <Column>
          <div className='label-text'>{t('metricCollection')}</div>
          <span className='value'>
            {data?.flowCollector && (
              <GenericResponsiveMiddleTruncation
                str={data.flowCollector as string}
                className='value'
              />
            )}
          </span>
        </Column>
        <Column>
          <div className='label-text'>{t('description')}</div>
          <span className='value'>
            {data.description && (
              <GenericResponsiveMiddleTruncation
                str={data.description as string}
                className='value'
              />
            )}
          </span>
        </Column>
      </Row>
      <Row className='data'>
        <Column>
          <div className='label-text'>{t('proceduralStatus')}</div>
          <span className='value'>
            {data?.proceduralStatus && (
              <GenericResponsiveMiddleTruncation
                str={data.proceduralStatus as string}
                className='value'
              />
            )}
          </span>
        </Column>
      </Row>
      <div className='card-footer'>
        <Row className='tags'>
          <Column className='label-col'>
            {Array.isArray(tags) && tags.length > 0 ? (
              <LabelTag labelArray={tags} singleLine={true} />
            ) : (
              ''
            )}
          </Column>
        </Row>

        <Row>
          <Column>
            <div className='helper-text'>{t('updated', { date: updated })}</div>
          </Column>
        </Row>
      </div>
    </ClickableTile>
  );
};

export default NetworkSegmentsCards;
