import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';

import {
  ConnectionSelectedTypes,
  PolicyDataType,
} from '../../../../../lib/enums';
import { ConnectionOptionTypes, Item } from '../../config';
import images from '../../../../../images/images';

import { VerticalEmptyState } from '../../../../../components/EmptyState/EmptyState';
import PolicyAllowConnectionFilter from '../PolicyFilter/PolicyFilter';
import PolicyCard from '../PolicyCard/PolicyCard';

import './SelectedFromToCard.scss';

const SelectedFromToCard = (props: {
  onSelectCard?: () => void;
  cardActive: boolean;
  cardDisabled: boolean;
  selectedData: Item[];
  dataType: ConnectionOptionTypes;
  heading: string;
  onRemove?: (data: Item) => void;
  onAddItem?: (data: Item, dataType: ConnectionOptionTypes) => void;
  handleSearchClick?: () => void;
  actionButton?:
    | {
        text: string;
        actionButtonCallback: () => void;
        onHover?: (value: boolean) => void;
      }
    | undefined;
  suggestionOptions?: Item[];
  onButtonHover?: (value: boolean) => void;
  serviceSyncFlag?: boolean;
}) => {
  const { t } = useTranslation('createPolicyAllowConnections');
  const {
    onSelectCard,
    cardDisabled,
    cardActive,
    selectedData,
    dataType,
    heading,
    onRemove,
    onAddItem,
    actionButton,
    suggestionOptions,
    handleSearchClick,
    onButtonHover,
    serviceSyncFlag = false,
  } = props;

  const getEmptyState = () => {
    let emptyData: any = {};
    switch (dataType) {
      case 'service':
        emptyData = {
          icon: [images.noServicesIcon()],
          emptyStateHeader: t('emptyState.to.emptyContainerHeader'),
          emptyStateDescription: t('emptyState.to.emptyContainerDescription'),
          link: '/',
          buttonText: t('emptyState.to.buttonText'),
          click: () => props.actionButton?.actionButtonCallback(),
          btnDisabledText: t('emptyState.from.btnDisabledText'),
        };
        break;
      default:
        emptyData = {
          icon: [images.noPartitionsIcon()],
          emptyStateHeader: t('emptyState.from.emptyContainerHeader'),
          emptyStateDescription: t('emptyState.from.emptyContainerDescription'),
          link: '/',
          buttonText: t('emptyState.from.buttonText'),
          click: () => props.actionButton?.actionButtonCallback(),
          btnDisabledText: t('emptyState.from.btnDisabledText'),
        };
    }
    return emptyData;
  };

  return (
    <div
      className={
        'selected-from-to-card' +
        (cardActive ? ' active' : '') +
        (cardDisabled || serviceSyncFlag ? ' disabled' : '') +
        (heading === ConnectionSelectedTypes.FROM ? ' from' : ' to')
      }
      onClick={() =>
        onSelectCard && !serviceSyncFlag && !cardDisabled && onSelectCard()
      }
    >
      <div className={'card-header'}>
        <div className='heading'>{t(props.heading)}</div>
        <PolicyAllowConnectionFilter
          data={suggestionOptions as any}
          handleSearchClick={handleSearchClick}
          placeholderText={
            heading === ConnectionSelectedTypes.FROM
              ? t('searchPlaceholderText.from')
              : t('searchPlaceholderText.to')
          }
          onOptionAdd={(value: Item, type: ConnectionOptionTypes) =>
            onAddItem && onAddItem(value, type)
          }
          btnDisabled={cardDisabled || serviceSyncFlag}
          selectedData={selectedData}
        />
      </div>
      {selectedData && selectedData.length > 0 ? (
        <>
          <hr className='divider' />
          <div className='card-wrapper'>
            {selectedData.map(item => (
              <PolicyCard
                key={item.id}
                label={item.label}
                type={item.type}
                heading={props.heading}
                onRemove={() => onRemove && onRemove(item)}
                onButtonHover={(value: boolean) =>
                  onButtonHover && onButtonHover(value)
                }
                disabled={cardDisabled}
                serviceSyncFlag={serviceSyncFlag}
              />
            ))}
          </div>
          {dataType === PolicyDataType.NAMESPACE && (
            <div className='add-resources'>
              <Button
                className='add-resources-button'
                size='small'
                kind='ghost'
                renderIcon={Add16}
                onClick={props.actionButton?.actionButtonCallback}
                onMouseEnter={() => onButtonHover && onButtonHover(true)}
                onMouseLeave={() => onButtonHover && onButtonHover(true)}
              >
                {t('Add resources')}
              </Button>
            </div>
          )}
        </>
      ) : (
        <VerticalEmptyState
          icon={getEmptyState().icon}
          header={getEmptyState().emptyStateHeader}
          description={getEmptyState().emptyStateDescription}
          link={getEmptyState()?.link}
          buttonText={getEmptyState()?.buttonText}
          click={getEmptyState()?.click}
          btnDisabled={cardDisabled}
          btnDisabledText={getEmptyState()?.btnDisabledText}
          onHover={props.actionButton?.onHover}
        />
      )}
    </div>
  );
};

export default SelectedFromToCard;
