// eventContainer
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { OnChangeData } from 'carbon-components-react';
import Header from '../../components/Header/Header';
import sortData from '../../lib/tableSort';
import {
  getSessionStorageItem,
  manageFiltersFromStorage,
} from '../../lib/utils';
import { useSearchParams } from 'react-router-dom';
import {
  AppliedFilter,
  Cloud,
  EventData,
  Location,
  ResourceData,
  Error500Type,
} from '../../models/master';
import EventsTable from './EventsTable/EventsTable';
import { getAllEvents } from '../../controllers/eventsApis';
import useAnalytics from '../../lib/useAnalytics';
import { getResourceTypeIcon, resourceTypeIcon } from './config';

import './EventsContainer.scss';

const defaultPermissionMap = {
  event: true,
  cloud: true,
  location: true,
};

const EventsContainer = () => {
  const { t } = useTranslation('events');

  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [eventsData, setEventsData] = useState<EventData[] | null>(null);
  const [resourceDataLoading, toggleResourceDataLoading] = useState(false);
  const [filteredData, setFilteredData] = useState<EventData[] | []>([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [visibilityFlag, setVisibilityFlag] = useState('open');
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [eventsCustomData, setEventsCustomData] = useState<EventData[] | null>(
    null
  );
  const [error500, setError500] = useState<null | Error500Type>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const resourceInstance = searchParams.get('resourceInstance');
  const { pageViewed } = useAnalytics();

  const leftInlineFilters: any = [
    {
      key: 'view',
      label: t('filterView'),
      type: 'single',
      values: [
        { value: 'all', label: t('view.all') },
        { value: 'open', label: t('view.opened') },
        { value: 'closed', label: t('view.closed') },
      ],

      filterCallback: (e: OnChangeData<any>) => {
        if (e.selectedItem) {
          setEventsCustomData(null);
          setVisibilityFlag(e.selectedItem?.value);
          window.localStorage.setItem(
            'MCNM_VISIBILITY_FLAG',
            e.selectedItem?.value
          );
          applyVisibilityFlag(e.selectedItem?.value);
        }
      },
    },
  ];

  const applyVisibilityFlag = (flag: string) => {
    setCustomEventsData(flag, eventsData);
  };

  const setCustomEventsData = (
    flag: string,
    eventsList: EventData[] | null
  ) => {
    let filteredEventsList;
    switch (flag.toLowerCase()) {
      case 'all':
        setEventsCustomData(eventsList);
        break;
      case 'open':
        filteredEventsList = eventsList?.filter(
          event => !event?.is_handled && !event?.is_cleared
        );

        filteredEventsList && filteredEventsList?.length > 0
          ? setEventsCustomData(filteredEventsList)
          : setEventsCustomData([]);
        break;
      case 'closed':
        filteredEventsList = eventsList?.filter(
          event => event?.is_handled || event?.is_cleared
        );

        filteredEventsList && filteredEventsList?.length > 0
          ? setEventsCustomData(filteredEventsList)
          : setEventsCustomData([]);
        break;
    }
  };

  const refreshData = () => {
    try {
      toggleResourceDataLoading(true);
      setEventsData(null);
      setSortKey('');
      setSortDirection('NONE');
      setFilterApplied(manageFiltersFromStorage());
      const visibilityFlagFromStorage = getSessionStorageItem(
        'MCNM_VISIBILITY_FLAG'
      );
      let updatedVisibilityFlag = 'open';
      if (
        visibilityFlagFromStorage === 'closed' ||
        visibilityFlagFromStorage === 'all'
      ) {
        updatedVisibilityFlag = visibilityFlagFromStorage;
      }
      getAllEvents()
        .then(async response => {
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            event: true,
          }));

          if (error500) {
            setError500(null);
          }

          let severitySortedEvents = response?.sort(
            (a: EventData, b: EventData) => {
              const severityOrder = [
                'critical',
                'major',
                'minor',
                'information',
              ];
              const aSeverityIndex = severityOrder.indexOf(a?.severity);
              const bSeverityIndex = severityOrder.indexOf(b?.severity);
              if (aSeverityIndex < bSeverityIndex) {
                return -1;
              } else if (aSeverityIndex > bSeverityIndex) {
                return 1;
              } else if (!a.is_cleared && b.is_cleared) {
                return -1;
              } else if (!a.is_handled && b.is_handled) {
                return -1;
              } else {
                if (a.assigned_user < b.assigned_user) {
                  return -1;
                } else if (a.assigned_user > b.assigned_user) {
                  return 1;
                } else {
                  return b.created_at.localeCompare(a.created_at);
                }
              }
            }
          );
          setVisibilityFlag(updatedVisibilityFlag);

          if (resourceInstance) {
            severitySortedEvents = severitySortedEvents.filter(
              (rec: EventData) => rec.resource_instance === resourceInstance
            );
          }

          const eventItems: EventData[] = [];
          for (const item of severitySortedEvents) {
            const eventStatus =
              item?.is_handled || item?.is_cleared
                ? t('statusItems.closed')
                : item?.assigned_user
                ? t('statusItems.assigned')
                : t('statusItems.open');

            const eventsData = { ...item, event_status: eventStatus };

            eventItems.push({
              ...eventsData,
              resource_name: item.resource_name || '—',
              assigned_user: eventsData?.assigned_user || t('unassigned'),
            });
          }
          setEventsData(eventItems);
          setCustomEventsData(updatedVisibilityFlag, eventItems);
          toggleResourceDataLoading(false);
        })
        .catch(error => {
          console.error(error);
          const err = error as AxiosError;
          if (err.response?.status === 403) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              event: false,
            }));
          }

          if (err.response!?.status >= 500) {
            setError500(err.response!?.status?.toString() as Error500Type);
          }

          setEventsData([] as EventData[]);
          setEventsCustomData([]);
          toggleResourceDataLoading(false);
        });
    } catch (error) {
      console.error(error);
      setEventsData([] as EventData[]);
      setEventsCustomData([]);
      toggleResourceDataLoading(false);
    }
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  useEffect(() => {
    pageViewed('Events');
    toggleResourceDataLoading(true);
    refreshData();
  }, []);

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const getEventFilters = () => [
    {
      key: 'severity',
      label: t('filters.severity'),
      placeHolderText: t('filters.severityPlaceholder'),
      type: 'single',
      values: [
        ...Array.from(new Set(eventsCustomData?.map(event => event?.severity))),
      ],
    },
    {
      key: 'assigned_user',
      label: t('filters.assignedTo'),
      placeHolderText: t('filters.assignedToPlaceholder'),
      type: 'single',
      values: [
        ...Array.from(
          new Set(
            eventsCustomData?.map(event =>
              event?.assigned_user ? event?.assigned_user : t('unassigned')
            )
          )
        ),
      ],
    },
    {
      key: 'labels',
      type: 'multi',
      label: t('filters.label'),
      placeHolderText: t('filters.labelPlaceholder'),
      values: [
        ...Array.from(
          new Set(
            (eventsCustomData ? eventsCustomData : [])
              .map(event => event?.labels)
              .flat()
          )
        ),
      ],
    },
    {
      key: 'resource_type',
      type: 'single',
      label: t('filters.resourceType'),
      hasIcon: true,
      placeHolderText: t('filters.resourceTypePlaceholder'),
      values: [
        ...Array.from(
          new Set(
            eventsCustomData?.map(event => event?.resource_type).filter(Boolean)
          )
        ).map(value => ({
          label: value,
          icon: getResourceTypeIcon(value as keyof typeof resourceTypeIcon),
        })),
      ],
    },
  ];

  return (
    <div className='event-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
      />
      <div className='page-content'>
        <div className='body'>
          <EventsTable
            currentPageNumber={currentPageNumber}
            currentPageSize={currentPageSize}
            sortRows={(
              data: { id: string; text: string },
              direction: 'ASC' | 'DESC' | 'NONE'
            ) => handleTableSort(data, direction)}
            onPageChange={(pageData: { page: number; pageSize: number }) =>
              setPageChange(pageData)
            }
            rows={
              eventsCustomData
                ? filterApplied.length > 0
                  ? sortData(filteredData, sortKey, sortDirection)
                  : sortData(eventsCustomData, sortKey, sortDirection)
                : null
            }
            elementCount={
              eventsCustomData
                ? filterApplied.length > 0
                  ? filteredData.length
                  : eventsCustomData.length
                : 0
            }
            filteredDataSet={
              eventsCustomData
                ? filterApplied.length > 0
                  ? filteredData
                  : eventsCustomData
                : null
            }
            filteredDataCallback={data => {
              data && setFilteredData(data as EventData[] | []);
              setPageNumber(1);
            }}
            data={eventsCustomData}
            filtersSelected={filterApplied as any}
            filtersAppliedCallback={data => setFilterApplied(data)}
            filters={getEventFilters()}
            persistFilter
            onRefresh={() => refreshData()}
            leftInlineFilters={leftInlineFilters}
            visibilityFlag={visibilityFlag}
            error403Flag={!permissionMap['event']}
            eventsDataLoading={resourceDataLoading}
            error500Flag={!!error500}
          />
        </div>
      </div>
    </div>
  );
};

export default EventsContainer;
