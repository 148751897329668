import React, { createContext, useState } from 'react';
import { NotificationKind } from 'carbon-components-react';

interface Details {
  kind?: NotificationKind;
  title: string;
  subtitle: string;
  timeout?: number;
  action?: any;
  onActionClick?: Function;
  lowContrast?: boolean;
  customClassName?: string;
}

type NotificationType = 'TOAST' | 'ACTION';

interface NotificationContextInterface {
  details: Details[];
  notificationType: NotificationType | '';
  onTrigger: (type: NotificationType, data: Details) => void;
  onClose: (title?: string, subtitle?: string) => void;
}

export const NotificationContext = createContext<NotificationContextInterface>({
  details: [],
  notificationType: '',
  onTrigger: (type: NotificationType, data: Details) => {},
  onClose: (title?: string, subtitle?: string) => {},
});

const NotificationProvider: React.FC = ({ children }) => {
  const [details, setDetails] = useState<Details[]>([]);
  const [notificationType, setNotificationType] = useState<
    NotificationType | ''
  >('');

  const handleTriggerNotification = (type: NotificationType, data: Details) => {
    setNotificationType('');

    setDetails(prevDetails => {
      const filteredDetails = prevDetails.filter(
        detail =>
          detail.title !== data.title && detail.subtitle !== data.subtitle
      );

      return [...filteredDetails, data];
    });

    setNotificationType(type);
  };

  const handleCloseNotification = (title?: string, subtitle?: string) => {
    if (!title) {
      setDetails([]);
      setNotificationType('');
    } else {
      const newDetails = details.filter(
        detail => detail.title !== title && detail.subtitle !== subtitle
      );

      setDetails(newDetails);

      if (newDetails.length === 0) {
        setNotificationType('');
      }
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        details,
        notificationType,
        onTrigger: handleTriggerNotification,
        onClose: handleCloseNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
