import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown16, ChevronUp16 } from '@carbon/icons-react';
import images from '../../images/images';

import './AccordionCard.scss';

interface Props {
  accordionCardTitle: string;
  accordionCardSubTitle: string;
  expandText: string;
  collapseText: string;
  isReadMoreExpanded: boolean;
  handleReadMore: (checked: boolean) => void;
  children: React.ReactElement | string | any;
}

const AccordionCard: React.FC<Props> = ({
  accordionCardTitle,
  accordionCardSubTitle,
  expandText,
  collapseText,
  isReadMoreExpanded,
  children,
  handleReadMore,
}) => {
  return (
    <div
      className='accordionCard'
      style={isReadMoreExpanded ? { borderLeft: '3px solid #8A3FFC' } : {}}
    >
      <div className='accordionTitle'>{accordionCardTitle}</div>
      <div className='accordionSubTitle'>{accordionCardSubTitle}</div>
      {!isReadMoreExpanded ? (
        <div className='readMoreBlock' onClick={() => handleReadMore(true)}>
          <div className='expandText'>{expandText}</div>
          <ChevronDown16 className='icon-class' />
        </div>
      ) : null}
      {isReadMoreExpanded ? (
        <div className='contentContainer'>{children}</div>
      ) : null}
      {isReadMoreExpanded ? (
        <div className='readLessBlock' onClick={() => handleReadMore(false)}>
          <div className='expandText'>{collapseText}</div>
          <ChevronUp16 className='icon-class' />
        </div>
      ) : null}
    </div>
  );
};

export default AccordionCard;
