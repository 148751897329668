import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentProps } from '../config';

import './GatewayTooltip.scss';
import images from '../../../../images/images';
import {
  HealthStatusType,
  ProceduralActivityType,
  Procedure,
} from '../../../GatewayStatus/config';
import HealthStatus from '../../../GatewayStatus/HealthStatus';
import { getGatewayCompatibilitySet } from '../../../../controllers/topologyApis';
import { SkeletonPlaceholder } from 'carbon-components-react';
import ProceduralActivity from '../../../GatewayStatus/ProceduralActivity';
import GatewayProcedure from '../../../GatewayStatus/GatewayProcedure';
import { GatewayLifeCycleState, ProceduralStatus } from '../../../../lib/enums';

const GatewayTooltip: React.FC<ComponentProps> = ({ node }) => {
  const { t } = useTranslation('topologyTooltipGateway');

  const [loading, setLoading] = useState(false);
  const [compatibilityData, setCompatibilityData] = useState<any>();

  const fetchCompatibilityData = async () => {
    try {
      setLoading(true);
      const response = await getGatewayCompatibilitySet();
      const compatibilitySubtype = response?.subtypes.find(
        (e: any) => e?.resource_id === node?.subtype
      );
      setCompatibilityData(compatibilitySubtype);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getGatewayIcon = () => {
    return node?.type === 'edge'
      ? images.EdgeGatewayIcon()
      : images.WaypointGatewayIcon();
  };

  const getGatewayType = () => {
    const type = node?.type === 'edge' ? t('edge') : t('waypoint');

    if (
      node?.type &&
      compatibilityData &&
      (compatibilityData?.resource_id === 'AXON-EDGE' ||
        compatibilityData?.resource_id === 'AXON-WP' ||
        compatibilityData?.resource_id === 'RHSI-EDGE')
    ) {
      return compatibilityData?.name;
    }

    return type;
  };

  useEffect(() => {
    fetchCompatibilityData();
  }, []);

  const showGatewayStatus = () => {
    if (node?.procedural_status === ProceduralStatus.ERRORED) {
      return <ProceduralActivity status={node?.procedural_status} />;
    } else if (node?.procedural_activity) {
      return (
        <ProceduralActivity
          status={node?.procedural_activity as ProceduralActivityType}
        />
      );
    } else if (node?.procedural_status === ProceduralStatus.IN_PROGRESS) {
      return <GatewayProcedure status={node?.procedure as Procedure} />;
    } else if (
      node?.lifecycle_state === GatewayLifeCycleState.GW_NOT_DEPLOYED &&
      node?.unmanaged
    ) {
      return <ProceduralActivity status={node?.lifecycle_state} />;
    }
  };

  return (
    <div className='topology-tooltip-gateway'>
      <div className='header'>
        {node.name}

        {/* For future use when version we will get */}
        {/* <span className='version'>v 1.0</span> */}
      </div>
      {loading && (
        <div className='skeleton-container'>
          <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
          <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
          <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
          <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
        </div>
      )}
      {!loading && (
        <>
          {node && (
            <div className='body'>
              <div className='gw-type-info'>
                <span className='gw-type-icon'>{getGatewayIcon()}</span>
                <span className='gw-type-title'>{getGatewayType()}</span>
              </div>

              <HealthStatus
                status={node?.health_status as HealthStatusType}
                showLabel={true}
                gatewayName={node?.name}
                tooltipPosition='top'
              />
              {showGatewayStatus()}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GatewayTooltip;
