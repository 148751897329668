import React from 'react';
import {
  TextInput,
  Row,
  FormLabel,
  TooltipIcon,
  Column,
  TextArea,
  SkeletonPlaceholder,
  Button,
} from 'carbon-components-react';
import { Dropdown } from '@carbon/react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { useTranslation } from 'react-i18next';

import { Gateway, NetworkSegment, ResourceGroup } from '../../../models/master';
import images from '../../../images/images';
import AddLabels from '../../../components/AddLabels/AddLabels';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';

import './DeployGateway.scss';

interface Props {
  gatewayList: Gateway[] | [];
  formData: any;
  onChange: (name: string, value: any, subKey?: string) => void;
  resourceGroupList: ResourceGroup[];
  networkSegments: NetworkSegment[];
  openCreateSegment: () => void;
  disConnectGateway: () => void;
  appsDeployed?: any;
  mode: 'ADD' | 'EDIT';
  partitionData?: any;
  gatewayConnectedRemoteConnections: any;
  nwSegmentsDataLoading: boolean;
  dataLoading: boolean;
  existingCompactibilitySet: string;
}

const DeployGatewayToNamespace: React.FC<Props> = ({
  gatewayList,
  formData,
  onChange,
  resourceGroupList,
  networkSegments,
  openCreateSegment,
  disConnectGateway,
  appsDeployed,
  mode,
  partitionData,
  gatewayConnectedRemoteConnections,
  nwSegmentsDataLoading,
  dataLoading,
  existingCompactibilitySet,
}) => {
  const { t } = useTranslation('namespaceRegister');

  const labelNwSg = () => {
    return (
      <FormLabel className='label network-segment-label'>
        {t('form.networkSegment.label')}
        {appsDeployed?.length > 0 ? (
          <TooltipIcon
            className='networksegment-tooltip'
            tooltipText={t('form.networkSegment.tooltipText')}
            direction='right'
          >
            {images.questionIconSvg()}
          </TooltipIcon>
        ) : null}
      </FormLabel>
    );
  };

  return (
    <CreateTearsheetStep
      className='deploy-gateway-namespace'
      fieldsetLegendText={t('gatewaySelection')}
      title={t('deployGateway')}
      secondaryLabel={''}
      disableSubmit={
        nwSegmentsDataLoading || !formData?.networkSegment?.value
        // ||
        // (dataLoading &&
        //   existingCompactibilitySet ===
        //     NetworkSegmentCompatibilitySet.ServiceInterconnect) ||
        // Object.values(optionalParamInValid).some(Boolean) ||
        // !isFormValid()
      }
      // onNext={nextFn}
      // onMount={onMount}
    >
      {mode === 'EDIT' && partitionData?.gateway_id ? (
        <Row className='row'>
          <Column md={6}>
            <div className='details-subtitle'>
              {t('gatewaySelectedSubtitle')}
              <span className='bold-text'>{t('saveChangesBtn')}</span>
              {t('subtitleportion2')}
            </div>
          </Column>
        </Row>
      ) : null}
      <Row>
        <Column md={4}>
          {nwSegmentsDataLoading ? (
            <>
              <div className='form-field-segment'>
                {t('form.networkSegment.label')}
              </div>
              <SkeletonPlaceholder className='create-namespace-segment-skelton' />
            </>
          ) : (
            <Dropdown
              light
              items={
                networkSegments
                  ? [
                      ...networkSegments.filter(
                        networkSegment =>
                          networkSegment?.compatibility_set === 'RHSI'
                      ),
                      {
                        name: t('createNetworkSegmentButton'),
                        resource_id: 'create-network-segment',
                        labels: [],
                        resource_group_id: '',
                        compatibility_set: 'RHSI',
                      },
                    ]
                  : []
              }
              itemToString={(item: { name: any }) => item.name}
              renderSelectedItem={(item: { resource_id: string; name: any }) =>
                item.resource_id !== 'create-network-segment'
                  ? item.name
                  : t('form.networkSegment.placeholder')
              }
              itemToElement={(item: NetworkSegment) => {
                if (item.resource_id !== 'create-network-segment') {
                  return <span>{`${item?.name}`}</span>;
                } else {
                  return (
                    <div
                      className='create-network-segment-button'
                      onClick={openCreateSegment}
                    >
                      {`${item.name} +`}
                    </div>
                  );
                }
              }}
              id={`select-network-segment-register-namespace`}
              selectedItem={formData?.networkSegment?.value ?? ''}
              titleText={labelNwSg()}
              onChange={(e: any) =>
                e.selectedItem?.resource_id === 'createNetworkSegment'
                  ? null
                  : onChange('networkSegment', e.selectedItem)
              }
              label={t('form.networkSegment.placeholder')}
              readOnly={
                appsDeployed?.length > 0 ||
                (partitionData?.gateway_id && mode === 'EDIT') ||
                (partitionData?.gateway_id &&
                  gatewayConnectedRemoteConnections?.length > 0)
                  ? true
                  : false
              }
            />
          )}
        </Column>
        <Column md={4}>
          {mode === 'EDIT' && partitionData?.gateway_id ? (
            dataLoading || nwSegmentsDataLoading ? (
              <>
                <div className='form-field-segment'>
                  {t('form.gatewayList.label')}
                </div>
                <SkeletonPlaceholder className='create-namespace-segment-skelton' />
              </>
            ) : (
              <>
                <div className='gateway-section'>
                  <div className='form-field-segment'>
                    {t('form.gatewayList.label')}
                  </div>
                  <div className={'connected-gateway'}>
                    {formData?.gateway?.isDisconnected ? (
                      <div className='disconnected-gw'>
                        <TextInput
                          readOnly
                          labelText={''}
                          id='connected-gw-input'
                          className='disconnected-gw-input form-field'
                          value={''}
                          name='gatewayName'
                          placeholder={t(
                            'form.connectedGateway.disConnectedplaceholder'
                          )}
                        />
                      </div>
                    ) : (
                      <div className='text'>
                        {formData?.gateway?.value ? (
                          <GenericTruncateString
                            str={formData?.gateway?.value?.name as string}
                            limit={17}
                            maxLength={25}
                            tableView={false}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                    {!formData?.gateway?.isDisconnected &&
                      partitionData?.gateway_id &&
                      mode === 'EDIT' && (
                        <Button
                          className='disconnect-link'
                          kind='ghost'
                          onClick={disConnectGateway}
                          size='sm'
                        >
                          {t('undeployButton')}
                        </Button>
                      )}
                  </div>
                </div>
              </>
            )
          ) : dataLoading || nwSegmentsDataLoading ? (
            <>
              <div className='form-field-segment'>
                {t('form.gatewayList.label')}
              </div>
              <SkeletonPlaceholder className='create-namespace-segment-skelton' />
            </>
          ) : (
            <Dropdown
              light
              id='unmanaged-gateway-list'
              className='unmanageGateway'
              selectedItem={formData?.gateway?.value ?? ''}
              onChange={(data: { selectedItem: any }) =>
                onChange('gateway', data.selectedItem)
              }
              items={gatewayList ?? []}
              renderSelectedItem={(item: any) =>
                item
                  ? `${item.name} (${item?.namespace_name})`
                  : t('form.gatewayList.placeholder')
              }
              itemToString={(item: { name: string; namespace_name?: string }) =>
                `${item.name} (${item?.namespace_name})`
              }
              titleText={t('form.gatewayList.label')}
              label={t('form.connectedGateway.placeholder')}
              placeholder={t('form.gatewayList.placeholder')}
              translateWithId={t}
            />
          )}
        </Column>
      </Row>
      {mode === 'EDIT' && partitionData?.gateway_id ? null : formData?.gateway
          ?.value ? (
        <Row className='gateway-infra-row'>
          <Column md={4}>
            <TextInput
              labelText={
                t('connectGateway.infrastructureGroup.label') as string
              }
              id={'connected-gateway-infra-resource'}
              className='form-field infra-name-field'
              value={
                formData?.gateway?.value?.resource_group_id
                  ? resourceGroupList?.find(
                      rg =>
                        rg.resource_id ===
                        formData?.gateway?.value?.resource_group_id
                    )?.name
                  : ''
              }
              name='infrastructure_name'
              readOnly
            />
          </Column>
        </Row>
      ) : null}
      {mode === 'EDIT' && partitionData?.gateway_id ? null : formData?.gateway
          ?.value ? (
        <div className='optional-details'>
          <Row className='row form-heading'>
            {t('connectGateway.optionalDetailsHeading')}
          </Row>
          <Row className='row gateway-labels-row'>
            <Column md={8}>
              <AddLabels
                id='gateway-labels'
                labelText={t('connectGateway.gatewayLabels.label')}
                placeholder={t('connectGateway.gatewayLabels.placeholder')}
                onChange={data => onChange('gatewayLabels', data)}
                btnText={t('connectGateway.gatewayLabels.btnText')}
                btnKind='secondary'
                tagType='green'
                defaultValues={formData?.gatewayLabels?.value}
              />
            </Column>
          </Row>
          <Row className='row gateway-description-row'>
            <Column md={8}>
              <TextArea
                id='gateway-description'
                name='description'
                autoComplete='off'
                labelText={t('connectGateway.gatewayDescription.label')}
                placeholder={t('connectGateway.gatewayDescription.placeholder')}
                onChange={(e: any) =>
                  onChange('gatewayDescription', e?.target?.value)
                }
                value={formData?.gatewayDescription?.value}
                maxLength={300}
              />
            </Column>
          </Row>
        </div>
      ) : null}
    </CreateTearsheetStep>
  );
};

export default DeployGatewayToNamespace;
