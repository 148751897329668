import { useQuery } from '@tanstack/react-query';
import { getNetworkSegments } from '../controllers/networksegment';
import { QueryOptions, queryDefaultOptions } from './queryDefaults';
import { QueryKeys, VisibilityFlags } from '../lib/enums';

export const useNetworksegments = (
  view: string = VisibilityFlags.ALL,
  options?: QueryOptions
) => {
  return useQuery({
    queryKey: [QueryKeys.NETWORKSEGMENTS, view],
    queryFn: () => getNetworkSegments(),
    ...queryDefaultOptions,
    ...options,
    ...{ staleTime: 1000 * 30 },
  });
};
