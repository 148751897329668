import { useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import {
  ContentSwitcher,
  Button,
  Row,
  Column,
  SkeletonPlaceholder,
  OnChangeData,
} from 'carbon-components-react';
import { List32, Grid32 } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { FlexGrid } from '@carbon/react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppliedFilter,
  Error500Type,
  NetworkSegment,
  ResourceGroup,
} from '../../models/master';
import { manageFiltersFromStorage } from '../../lib/utils';
import sortData from '../../lib/tableSort';
import dateUtils from '../../lib/dates';
import useAnalytics from '../../lib/useAnalytics';
import { ProceduralStatus, VisibilityFlags } from '../../lib/enums';

import { defaultStaleTime } from '../../hooks/queryDefaults';
import { useNetworksegments } from '../../hooks/useNetworksegment';
import { useResourceGroupsData } from '../../hooks/useResourceGroups';

import Header from '../../components/Header/Header';
import SortDropDown from '../../components/SortDropdown/SortDropDown';
import Error403Card from '../../components/ErrorState/Error403Card';
import Error500Card from '../../components/ErrorState/Error500Card';
import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import GenericStatusField from '../../components/GenericStatusField/GenericStatusField';
import GatewayProcedure from '../../components/GatewayStatus/GatewayProcedure';
import ProceduralActivity from '../../components/ProceduralActivity/ProceduralActivity';
import CreateNetworkSegment from './CreateNetworkSegment/CreateNetworkSegment';
import NetworkSegmentsTable from './NetworkSegmentsTable';
import NetworkSegmentsCards from './NetworkSegmentsCard';

import './NetworkSegmentsContainer.scss';
import OnboardingNetworkSegment from './OnboardNetworkSegment/OnboardNetworkSegment';

type View = 'table' | 'card';

type LocationState = {
  resourceType: string;
  navigateBack: boolean;
};

const NetworkSegmentsContainer = () => {
  const { t } = useTranslation('networkSegments');

  let viewStateValue = localStorage.getItem('VIEW_STATE') as View;
  const [view, setView] = useState<View>(
    viewStateValue ? viewStateValue : 'table'
  );

  const [nwSegmentsDataLoading, setNwSegmentsDataLoading] = useState(false);
  const [networkSegments, setNetworkSegments] = useState<
    NetworkSegment[] | null
  >(null);

  const location = useLocation();
  const state = location.state as LocationState;
  const navigate = useNavigate();
  const { pageViewed } = useAnalytics();
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [filteredData, setFilteredData] = useState<NetworkSegment[] | []>([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [openCreateNetworkSegment, toggleCreateNetworkSegment] =
    useState(false);
  const [refetchNetworksegmentsState, setRefetchNetworksegmentsState] =
    useState(false);

  const [resourceGroupsData, setResourceGroupsData] = useState<
    ResourceGroup[] | null
  >(null);

  const [visibilityFilterFlag, setVisibilityFilterFlag] = useState(
    VisibilityFlags.ALL
  );

  let defaultPermissionMap = {
    networkSegments: true,
    resourceGroup: true,
  };

  let error500 = {
    networkSegments: true,
    resourceGroup: true,
  };

  const leftInlineFilters: any = [
    {
      key: 'view',
      label: t('view'),
      type: '',
      values: [
        {
          value: 'all',
          label: t('all'),
        },
        {
          value: 'managed',
          label: t('managed'),
        },
        {
          value: 'unmanaged',
          label: t('unmanaged'),
        },
      ],
      filterCallback: (e: OnChangeData<any>) => {
        if (e.selectedItem !== visibilityFilterFlag) {
          setNetworkSegments(null);
          setVisibilityFilterFlag(e.selectedItem?.value);
        }
      },
    },
  ];

  const {
    data: networkSegmentsData,
    isLoading: loadingNetworksegments,
    error: networkSegmentError,
    isError: isNetworkSegmentError,
    refetch: refetchNetworksegments,
    isRefetching: refetchingNetworksegments,
  } = useNetworksegments(VisibilityFlags.ALL, {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
    view: visibilityFilterFlag?.toLocaleLowerCase(),
  });

  if (isNetworkSegmentError) {
    const error = networkSegmentError as AxiosError;
    defaultPermissionMap.networkSegments =
      error?.response?.status === 403 ? false : true;
    error500.networkSegments = error.response!?.status >= 500 ? false : true;
  }

  const getFilteredNetworkSegments = () => {
    setFilterApplied(manageFiltersFromStorage());
    if (Array.isArray(networkSegmentsData)) {
      let networkSegmentsList = [];
      if (visibilityFilterFlag === VisibilityFlags.UNMANAGED) {
        networkSegmentsList = networkSegmentsData.filter(
          networkSegment => networkSegment.unmanaged
        );
        setNetworkSegments(networkSegmentsList);
      } else if (visibilityFilterFlag === VisibilityFlags.MANAGED) {
        networkSegmentsList = networkSegmentsData.filter(
          networkSegment => !networkSegment.unmanaged
        );
        setNetworkSegments(networkSegmentsList);
      } else {
        setNetworkSegments(networkSegmentsData);
      }
    }

    return null;
  };

  useMemo(
    () => getFilteredNetworkSegments(),
    [networkSegmentsData, visibilityFilterFlag]
  );
  const [openOnboardNetworkSegment, toggleOnboardNetworkSegment] =
    useState(false);

  useEffect(() => {
    pageViewed('Network Segments');
  }, []);

  useEffect(() => {
    if (!refetchingNetworksegments) setRefetchNetworksegmentsState(false);
  }, [refetchingNetworksegments]);

  // Queries resource groups
  const {
    data: resourceGroupsList,
    error: resourceGroupError,
    isError: isResourceGroupError,
    isLoading: isResourceGroupListLoading,
    refetch: refetchResourceGroupData,
  } = useResourceGroupsData({
    refetchOnWindowFocus: false,
  });

  if (isResourceGroupError) {
    const error = resourceGroupError as AxiosError;
    defaultPermissionMap.resourceGroup =
      error?.response?.status === 403 ? false : true;
    error500.resourceGroup = error.response!?.status >= 500 ? false : true;
  }

  const formatResourceGroup = () => {
    const resourceData = resourceGroupsList as ResourceGroup[];
    if (Array.isArray(resourceData)) {
      const filteredResourceGroups = resourceData?.filter(
        resource => resource.type === 'infrastructure'
      );

      setResourceGroupsData(filteredResourceGroups);
    }

    return null;
  };

  useMemo(() => formatResourceGroup(), [resourceGroupsList]);

  const refreshData = () => {
    setSortKey('');
    setSortDirection('NONE');
    setRefetchNetworksegmentsState(true);
    defaultPermissionMap = {
      ...defaultPermissionMap,
      networkSegments: true,
      resourceGroup: true,
    };

    error500 = {
      ...error500,
      networkSegments: true,
      resourceGroup: true,
    };
    refetchResourceGroupData();
    refetchNetworksegments();
  };

  const closeTearsheet = () => {
    toggleCreateNetworkSegment(false);
    if (state?.navigateBack) {
      navigate(-1);
    }
  };
  const openTearsheet = () => {
    toggleCreateNetworkSegment(true);
  };

  const handleSort = (data: { id: string; text: string }) => {
    if (Array.isArray(networkSegments)) {
      if (data.id === 'atoz') {
        setSortDirection('ASC');
        const sortedNwSegments = Array.isArray(networkSegments)
          ? networkSegments.sort((a, b) =>
              a.name?.trim()?.toLowerCase() > b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setNetworkSegments([...sortedNwSegments]);
      } else {
        setSortDirection('DESC');
        const sortedNwSegments = Array.isArray(networkSegments)
          ? networkSegments.sort((a, b) =>
              a.name?.trim()?.toLowerCase() < b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setNetworkSegments([...sortedNwSegments]);
      }
    }
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const renderFilter = () => {
    return (
      <FindAndFilterBar
        data={networkSegments ? networkSegments : []}
        filteredDataCallback={data =>
          setFilteredData(data as NetworkSegment[] | [])
        }
        persistFilter
        filteredData={
          networkSegments
            ? filterApplied.length > 0
              ? filteredData
              : networkSegments
            : null
        }
        filtersApplied={filterApplied as any}
        filtersAppliedCallback={data => setFilterApplied(data)}
        filters={[
          {
            key: 'labels',
            type: 'multi',
            label: t('filters.labels'),
            placeHolderText: t('filters.labelsPlaceholder'),
            values: [
              ...Array.from(
                new Set(
                  (networkSegments ? networkSegments : [])
                    ?.map(app => app.labels)
                    ?.flat()
                )
              ),
            ],
          },
        ]}
        onRefresh={() => refreshData()}
        leftInlineFilters={leftInlineFilters}
        visibilityFlag={visibilityFilterFlag}
      />
    );
  };

  const getResourceGroupName = (id: string) => {
    const resouceGroup =
      id &&
      Array.isArray(resourceGroupsData) &&
      resourceGroupsData.find(resouceGroup => resouceGroup.resource_id === id);

    return resouceGroup ? resouceGroup.name : '';
  };

  const getProceduralStatus = (row: NetworkSegment) => {
    if (row?.procedural_activity) {
      return <ProceduralActivity status={row?.procedural_activity} />;
    } else if (
      row?.procedural_status === ProceduralStatus.IN_PROGRESS &&
      row?.procedure
    ) {
      return <GatewayProcedure status={row.procedure} />;
    } else return <>—</>;
  };

  const isDataLoading =
    isResourceGroupListLoading ||
    loadingNetworksegments ||
    refetchNetworksegmentsState;

  const openOnboardNetworkSegmentTearsheet = (): void => {
    toggleOnboardNetworkSegment(true);
  };

  return (
    <div className='network-segments-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        actions={[
          {
            kind: 'primary',
            onClick: () => openTearsheet(),
            text: t('createNetworkSegment'),
          },
          {
            kind: 'tertiary',
            onClick: () => openOnboardNetworkSegmentTearsheet(),
            text: t('onboardNetworkSegment'),
            toolTip: t('onboardingSegmentTooltip'),
            showTooltip: true,
            dataTestid: 'onboardingBtn',
          },
        ]}
      />
      <div className='page-content'>
        <div className='nwSegments-switcher'>
          {view !== 'table' ? (
            <>
              {isDataLoading ? (
                <div className='skeleton-sort-drop-down'>
                  <SkeletonPlaceholder className={'sorting-skeleton'} />
                </div>
              ) : (
                <SortDropDown
                  id='application-sort-dropdown'
                  size='lg'
                  onSort={handleSort}
                  sortDir={sortDirection}
                />
              )}
            </>
          ) : null}
          <ContentSwitcher className='view-switcher'>
            <Button
              className={
                'switch-button' + (view === 'table' ? ' selected' : '')
              }
              onClick={() => {
                setView('table');
                localStorage.setItem('VIEW_STATE', 'table');
              }}
              renderIcon={List32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('table')}
            />
            <Button
              className={'switch-button' + (view === 'card' ? ' selected' : '')}
              onClick={() => {
                setView('card');
                localStorage.setItem('VIEW_STATE', 'card');
              }}
              renderIcon={Grid32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('card')}
            />
          </ContentSwitcher>
        </div>
        <div className='body'>
          {view === 'table' ? (
            <NetworkSegmentsTable
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              sortRows={(
                data: { id: string; text: string },
                direction: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, direction)}
              onPageChange={(pageData: any) => setPageChange(pageData)}
              rows={
                Array.isArray(networkSegments)
                  ? filterApplied.length > 0
                    ? sortData(filteredData, sortKey, sortDirection)
                    : sortData(networkSegments, sortKey, sortDirection)
                  : null
              }
              elementCount={
                networkSegments
                  ? filterApplied.length > 0
                    ? filteredData.length
                    : networkSegments.length
                  : 0
              }
              filteredDataSet={
                networkSegments
                  ? filterApplied.length > 0
                    ? filteredData
                    : networkSegments
                  : null
              }
              filteredDataCallback={data => {
                data && setFilteredData(data as NetworkSegment[] | []);
                setPageNumber(1);
              }}
              data={networkSegments}
              filtersSelected={filterApplied as any}
              filtersAppliedCallback={data => {
                setFilterApplied(data);
              }}
              persistFilter
              filters={[
                {
                  key: 'labels',
                  type: 'multi',
                  label: t('filters.labels'),
                  placeHolderText: t('filters.labelsPlaceholder'),
                  values: [
                    ...Array.from(
                      new Set(
                        (networkSegments ? networkSegments : [])
                          ?.map(app => app.labels)
                          ?.flat()
                      )
                    ),
                  ],
                },
              ]}
              onRefresh={() => refreshData()}
              resourceGroupsList={resourceGroupsData}
              error403Flag={!defaultPermissionMap['networkSegments']}
              error500Flag={!error500['networkSegments']}
              resourceGroupPermission={defaultPermissionMap.resourceGroup}
              leftInlineFilters={leftInlineFilters}
              visibilityFlag={visibilityFilterFlag}
              dataLoading={isDataLoading}
            />
          ) : !defaultPermissionMap['networkSegments'] ? (
            <Error403Card />
          ) : !error500['networkSegments'] ? (
            <Error500Card />
          ) : isDataLoading ? (
            <div>
              {renderFilter()}
              <div className='skeleton-card-view'>
                <SkeletonPlaceholder className={'application-skeleton'} />
                <SkeletonPlaceholder className={'application-skeleton'} />
                <SkeletonPlaceholder className={'application-skeleton'} />
                <SkeletonPlaceholder className={'application-skeleton'} />
              </div>
            </div>
          ) : (
            <div>
              {renderFilter()}
              <div className='card-view'>
                <FlexGrid>
                  <Row className='resource-card-alignment'>
                    {networkSegments &&
                    (filterApplied.length > 0 ? filteredData : networkSegments)
                      .length > 0 ? (
                      (filterApplied.length > 0
                        ? filteredData
                        : networkSegments
                      ).map(nwSegment => (
                        <Column lg={4} md={4} className='networkSegment-card'>
                          <NetworkSegmentsCards
                            key={nwSegment.resource_id}
                            resourceType={'NETWORKSEGMENT'}
                            header={nwSegment.name}
                            tags={nwSegment.labels}
                            data={{
                              unmanaged: nwSegment?.unmanaged as boolean,
                              description: nwSegment?.description
                                ? nwSegment?.description
                                : '-',
                              flowCollector: nwSegment?.flow_collector
                                ? t('enabled')
                                : '—',
                              proceduralStatus: getProceduralStatus(nwSegment),
                              gatewaySet: nwSegment?.compatibility_set
                                ? t(`${nwSegment?.compatibility_set}`)
                                : '—',
                              resourceGroupId:
                                !defaultPermissionMap.resourceGroup ? (
                                  <GenericStatusField
                                    status={'notAuthorised'}
                                  />
                                ) : (
                                  getResourceGroupName(
                                    nwSegment.resource_group_id
                                  )
                                ),
                            }}
                            updated={dateUtils.getUserFriendlyDate(
                              nwSegment.updated_at
                            )}
                            networkSegmentId={nwSegment.resource_id}
                          />
                        </Column>
                      ))
                    ) : (
                      <CardEmptyState
                        filterApplied={filterApplied}
                        emptyState={{
                          icon: <></>,
                          header: t('emptyState.emptyContainerHeader'),
                          description: t(
                            'emptyState.emptyContainerDescription'
                          ),
                        }}
                      />
                    )}
                  </Row>
                </FlexGrid>
              </div>
            </div>
          )}
        </div>
      </div>
      {openCreateNetworkSegment && (
        <CreateNetworkSegment
          open={openCreateNetworkSegment}
          onClose={closeTearsheet}
          networkSegments={networkSegments}
          groups={resourceGroupsData}
          refreshData={refreshData}
          actionType={'ADD'}
          className={'create-network-segment'}
        />
      )}
      <OnboardingNetworkSegment
        open={openOnboardNetworkSegment}
        onClose={() => toggleOnboardNetworkSegment(false)}
        networkSegments={networkSegments}
        refreshNetworkListData={refreshData}
      />
    </div>
  );
};

export default NetworkSegmentsContainer;
