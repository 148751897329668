import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import {
  SkeletonPlaceholder,
  DataTableSkeleton,
  TooltipDefinition,
  Button,
  Modal,
} from 'carbon-components-react';

import DetailsCard from '../../components/DetailsCard/DetailsCard';
import Header from '../../components/Header/Header';
import { VerticalEmptyState } from '../../components/EmptyState/EmptyState';
import images from '../../images/images';
import { getGateway } from '../../controllers/gateawayApis';
import { getNetworkSegments } from '../../controllers/networksegmentsApi';

import './DeploymentEnvDetails.scss';
import { useEffect } from 'react';
import {
  deleteDeploymentEnv,
  getDeploymentEnv,
  getDeploymentEnvs,
  getDeploymentEnvSubtypes,
  getPartitions,
} from '../../controllers/deploymentEnv.js';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import {
  DeploymentEnvironment,
  DeploymentEnvironmentSubtype,
  DeploymentEnvDetailsNameSpaceAndSecurityTableData,
  Gateway,
  Cloud,
  AppDeployment,
  Location,
  NetworkSegment,
  Error500Type,
} from '../../models/master';
import IconWithToolTip from '../../components/IconWithToolTip/IconWithToolTip';
import NamespaceAndSecurityGroupTable from '../../components/DeploymentEnvsDetailsTables/NamespaceAndSecurityGroupTable/NamespaceAndSecurityGroupTable';
import RegisterDeploymentEnv from '../../pages/DeploymentEnvsContainer/RegisterDeploymentEnv/RegisterDeploymentEnv';
import RegisterPartition from './RegisterPartiton/RegisterPartition';
import { getClouds } from '../../controllers/cloudApis';
import { getResourceGroups } from '../../controllers/resourceGroupApi';
import { getApplicationsDeployments } from '../../controllers/applicationApis';

import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import { AxiosError } from 'axios';

import dateUtils from '../../lib/dates.js';

import { getLocationDetails } from '../../controllers/locationApis';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import Error500 from '../Errors/Error500';
import { inValidateListCacheFn } from '../../lib/invalidateQueriesFunctions';
import { QueryKeys } from '../../lib/enums';
import NamespaceRegister from '../NamespaceRegister/NamespaceRegister';

type LocationState = {
  breadcrumb?: any;
};

type DetailsData = {
  key: string;
  value: any;
  type: string[];
  currentType?: string;
};

const defaultPermissionMap = {
  cloud: true,
  deploymentEnv: true,
};

const DeploymentEnvDetails = () => {
  const { t } = useTranslation('deploymentEnvDetails');

  const [deplEnvData, setDeplEnvData] = useState<
    DeploymentEnvironment[] | null
  >(null);

  const [deploymentEnv, setDeploymentEnv] =
    useState<DeploymentEnvironment | null>(null);
  const [deploymentEnvData, SetDeploymentEnvData] =
    useState<DeploymentEnvironment | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const deplId = searchParams.get('deplId');
  const [envDataLoading, toggleEnvDataLoading] = useState(false);
  const [deploymentType, setDeploymentType] = useState<string>('');
  const [createTearsheetOpen, toggleCreateTearsheet] = useState(false);
  const [disableEditIcon, setDisableEditIcon] = useState(true);
  const [deplEnvSubtypes, setDeplEnvSubtypes] = useState<
    DeploymentEnvironmentSubtype[] | null
  >(null);
  const [depEnvSubType, setDepEnvSubType] = useState<any>(null);
  const [partitionData, setPartitionData] = useState<any>(null);
  const [openDeleteDepEnvPopUp, setOpenDeleteDepEnvPopUp] =
    useState<boolean>(false);
  const notification = useContext(NotificationContext);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openRegisterPartitionModel, setOpenRegisterPartitionModel] =
    useState(false);
  const [resourceGroups, setResourceGroups] = useState<any>(null);
  const [cloudList, setCloudList] = useState<Cloud[] | null>(null);
  const [deplEnvCloud, setDeplEnvCloud] = useState<Cloud | null>(null);
  const [selectedVPC, setSelectedVPC] = useState<any>(null);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [appDeploymentsData, setAppDeploymentData] = useState<
    AppDeployment[] | null
  >(null);
  const [hasPartitionReadAccess, setHasPartitionReadAccess] =
    useState<boolean>(true);
  const [hasDepEnvDetailsReadAccess, setHasDepEnvDetailsReadAccess] =
    useState<boolean>(true);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );
  const [disableButton, setDisableButton] = useState(false);
  const [error500, setError500] = useState<null | Error500Type>(null);
  const [networkSegmentsData, setNetworkSegmentsData] = useState<
    NetworkSegment[] | null
  >(null);

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;

  const { trackButtonClicked, pageViewed } = useAnalytics();
  const queryClient = useQueryClient();

  const handleDeploymentDetailsCardData = (response: DeploymentEnvironment) => {
    let obj = {
      ...response,
    };
    if (response?.auto_discover) {
      obj.autoDiscovery = `${t('NodeOn')}`;
    } else {
      obj.autoDiscovery = `${t('NodeOff')}`;
    }
    const labels: any = [];
    if (Array.isArray(response?.labels)) {
      response.labels.forEach(el => {
        labels.push(el);
      });
    }
    if (Array.isArray(response?.discovered_labels)) {
      response.discovered_labels.forEach(el => {
        labels.push({
          default: true,
          value: el,
        });
      });
    }
    obj.labels = labels;

    return obj;
  };
  const refreshData = async () => {
    try {
      toggleEnvDataLoading(true);
      const response = await getDeploymentEnv(deplId)
        .then(response => {
          return response;
        })
        .catch(error => {
          const err = error as AxiosError;

          if (err.response?.status === 403)
            setHasDepEnvDetailsReadAccess(false);
          if (err.response?.status === 404) {
            navigate('/404');
          }

          if (err.response!?.status >= 500) {
            setError500(err.response!?.status?.toString() as Error500Type);
          }
        });

      let depEnvSubTypes;
      try {
        depEnvSubTypes = await getDeploymentEnvSubtypes();
      } catch (error) {
        console.error(error);
      }
      let subType = response?.subtype;
      if (response?.subtype === '' && response?.type !== '')
        subType = response?.type;
      const selectedDepEnvSubType = depEnvSubTypes?.find(
        (depEnv: { type_code: string | undefined }) =>
          depEnv.type_code === subType
      );
      setDepEnvSubType(selectedDepEnvSubType?.type_name ?? '');

      const type = selectedDepEnvSubType?.resource_type ?? response?.type;
      setDeplEnvSubtypes(depEnvSubTypes);
      if (response != null) {
        let allDeploymentEnvData;
        try {
          allDeploymentEnvData = await getDeploymentEnvs();
        } catch (error) {
          console.error(error);
        }
        setDeplEnvData(allDeploymentEnvData);
        setDisableEditIcon(false);

        setDeploymentEnv(response);

        fetchLocationDetails(response);

        let obj = handleDeploymentDetailsCardData(response);

        try {
          if (type === 'cluster' || type === 'vpc') {
            const partition: {
              partitions: DeploymentEnvDetailsNameSpaceAndSecurityTableData[];
            } | null = await getPartitions(deplId, 'all');

            const partitionType =
              type === 'cluster' ? 'namespace' : 'security group';

            setPartitionData(
              partition &&
                partition?.partitions &&
                partition?.partitions?.length > 0
                ? {
                    count: partition?.partitions?.length,
                    partitionType: partitionType,
                    data: partition?.partitions,
                  }
                : { count: 0, partitionType: partitionType, data: [] }
            );
          } else {
            setPartitionData({
              count: 0,
              partitionType: 'applications',
              data: [],
            });
          }
        } catch (error) {
          const err = error as AxiosError;
          if (err.response?.status === 403) setHasPartitionReadAccess(false);
          setPartitionData({
            count: 0,
            partitionType:
              type === 'cluster'
                ? 'namespace'
                : type === 'vpc'
                ? 'security group'
                : 'applications',
            data: [],
          });
        }

        try {
          const networkSegmentResponse = await getNetworkSegments();
          setNetworkSegmentsData(networkSegmentResponse);
        } catch (error) {
          console.error(error);
        }

        fetchGroups();
        SetDeploymentEnvData(obj as DeploymentEnvironment);
        setDeploymentType(type);
        fetchClouds();
        fetchAppDeployments();
      }
    } catch (error) {
      console.error(error);
      setDisableEditIcon(true);
    } finally {
      toggleEnvDataLoading(false);
    }
  };

  useEffect(() => {
    pageViewed('Deployment Environment Details');
    refreshData();
  }, []);

  const fetchLocationDetails = async (env: DeploymentEnvironment) => {
    try {
      const response = await getLocationDetails(env.cloud_id, env.location_id);
      setSelectedLocation(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClouds = async () => {
    try {
      const response = await getClouds();
      const cloudListData = Array.isArray(response) ? response : [];
      setCloudList(cloudListData);
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;
      console.log(err);
      if (Array.isArray(cloudList) && cloudList.length === 0) {
        setCloudList([]);
      }
      if (err.response?.status === 403) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          cloud: false,
        }));
        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // To prevent closing of modal we need to return promise rejection
        //return Promise.reject(() => console.error(err));
      }
    }
  };

  const fetchAppDeployments = async () => {
    try {
      const response = await getApplicationsDeployments();
      setAppDeploymentData(response);
    } catch (error) {
      console.log(error);
    }
  };

  const checkEnvAssociatedWithApps = () => {
    const appDeployment = appDeploymentsData?.filter(
      (deployment: AppDeployment) =>
        deployment?.depl_env_id === deploymentEnv?.resource_id
    );
    const isEnvAssociatedWithApp =
      appDeployment && appDeployment?.length > 0 ? true : false;
    return isEnvAssociatedWithApp;
  };

  useEffect(() => {
    if (deploymentEnvData !== null) {
      toggleEnvDataLoading(false);
    }
  }, [deploymentEnvData, openErrorModal]);

  useEffect(() => {
    fetchGroups();
    fetchVPC();
  }, [deploymentEnv]);

  useEffect(() => {
    if (deploymentEnv && Array.isArray(cloudList) && !deplEnvCloud) {
      const deplEnvCloudData = cloudList.find(
        cloud =>
          cloud.resource_id ===
          (deploymentEnv as DeploymentEnvironment)?.cloud_id
      );
      if (deplEnvCloudData) {
        setDeplEnvCloud(deplEnvCloudData);
      }
    }
  }, [deploymentEnv, cloudList]);

  const updateDeployment = () => {
    trackButtonClicked(
      analyticsData['Deployment Environment Details'].events.editDepEnv.props,
      analyticsData['Deployment Environment Details'].events.editDepEnv.event
    );
    toggleCreateTearsheet(true);
  };

  const closeTearsheet = () => {
    toggleCreateTearsheet(false);
  };

  const registerPartionApp = () => {
    setOpenRegisterPartitionModel(false);
    refreshData();
  };
  const filterLabels = (labels: string[]) => {
    return labels?.filter((el: object | string) => typeof el !== 'object');
  };

  const fetchGroups = async () => {
    if (deploymentEnv) {
      try {
        await getResourceGroups().then(response => {
          const infrastructureGroups = response?.resource_groups?.filter(
            (r: { type: string | undefined }) => r?.type === 'infrastructure'
          );

          setResourceGroups(infrastructureGroups);
        });
      } catch (error: any) {
        console.log(error);
      }
    }
  };
  const fetchVPC = async () => {
    if (deploymentEnv?.deployed_in_vpc) {
      try {
        const response = await getDeploymentEnv(deploymentEnv?.deployed_in_vpc);
        setSelectedVPC(response);
      } catch (error: any) {
        console.log(error);
      }
    }
  };
  const editFormData = {
    name: {
      value: deploymentEnv?.name || '',
      error: false,
      errorMessage: '',
    },
    description: deploymentEnv?.description || '',
    auto_discover: deploymentEnv?.auto_discover || false,
    location: {
      id: deploymentEnv?.location_id || '',
      name: deploymentEnvData?.location_name || '',
      type: selectedLocation?.type || '',
    },
    resource_group: {
      id: deploymentEnv?.resource_group_id || '',
      name:
        resourceGroups !== null &&
        resourceGroups?.filter(
          (resourceGroup: any) =>
            resourceGroup?.resource_id === deploymentEnv?.resource_group_id
        )[0]?.name,
    },
    is_discovered: deploymentEnv?.is_discovered || false,
    // This flag Unmanaged used to filter the resource list based on the flag. Mainly used to prevent resource from being modified after creation
    unmanaged: (deploymentEnv?.unmanaged as boolean) ?? true,
    labels: filterLabels(deploymentEnv?.labels || []),
    cloud_id: deploymentEnv?.cloud_id || '',
    type: depEnvSubType || '',
    subtype: deploymentEnvData?.subtype || '',
    deployed_in_vpc: {
      id: deploymentEnv?.deployed_in_vpc || '',
      name: selectedVPC?.name ?? '',
    },
    infra_only: deploymentEnv?.infra_only ? deploymentEnv?.infra_only : false,
  };

  const disableManagedToggle = () => {
    //If deployment env is unmanaged and location is managed then we don't need to disable it
    if (deploymentEnvData?.unmanaged && selectedLocation?.unmanaged) {
      return true;
    }

    const managedPartionCount = Array.isArray(partitionData?.data)
      ? partitionData.data.filter(
          (partition: any) => partition.unmanaged === false
        ).length
      : 0;

    return deploymentEnvData?.auto_discover || managedPartionCount > 0;
  };

  const managedToggle = !deploymentEnv?.unmanaged;

  const handleOpenRegisterPartitionModal = () => {
    trackButtonClicked(
      analyticsData['Deployment Environment Details'].events.registerNamespace
        .props,
      analyticsData['Deployment Environment Details'].events.registerNamespace
        .event
    );
    setOpenRegisterPartitionModel(true);
  };

  const closeRegisterPartitionModal = () => {
    setOpenRegisterPartitionModel(false);
  };

  const getHeaderIcon = () => {
    if (!managedToggle && deploymentEnvData?.is_discovered) {
      return (
        <div className='header-icon-contaniner'>
          <IconWithToolTip
            icon={images.AutoDiscoverdLockIcon()}
            iconDescription={t('autoDiscoveredAccessLimited')}
          />
        </div>
      );
    }

    if (deploymentEnvData?.is_discovered) {
      return (
        <div className='autodiscovered-icon-contaniner'>
          <IconWithToolTip
            icon={images.AutoDiscoverdLockIcon()}
            iconDescription={t('autoDiscoveredAccessLimited')}
          />
        </div>
      );
    }

    return false;
  };

  const renderDeleteBtn = (disabled: boolean) => (
    <div className='delete-deploymentEnv'>
      <Button
        kind='danger--ghost'
        className={disabled ? 'delete-deploymentEnv-disabled' : ''}
        onClick={() => {
          disabled
            ? setOpenDeleteDepEnvPopUp(false)
            : setOpenDeleteDepEnvPopUp(true);
          if (!disabled)
            trackButtonClicked(
              analyticsData['Deployment Environment Details'].events
                .deleteDepEnv.props,
              analyticsData['Deployment Environment Details'].events
                .deleteDepEnv.event
            );
        }}
        data-testid='delete-deployment-env-btn'
      >
        {t('delete.deleteDepEnvBtn')}
      </Button>
    </div>
  );

  const confirmDeleteDepEnv = async () => {
    try {
      setDisableButton(true);
      await deleteDeploymentEnv(
        deplId,
        deploymentEnvData?.type,
        deploymentEnvData?.cloud_id
      );
      notification.onTrigger('TOAST', {
        title: t('delete.successMsg.title'),
        subtitle: t('delete.successMsg.desc', {
          depEnvName: deploymentEnvData?.name ?? '',
        }),
      });
      inValidateListCacheFn(QueryKeys.DEPLOYMENTENVIRONMENTS);
      inValidateListCacheFn(QueryKeys.CLOUD_DEPLOYMENTENVIRONMENTS);
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.DEPLOYMENTENVIRONMENTS],
      });
      navigate('/deploymentenvironments');
    } catch (error: any) {
      setOpenErrorModal(true);
      const err = error as AxiosError;
      console.log(err);
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';

      notification.onTrigger('TOAST', {
        title:
          err.response?.status === 422
            ? t('delete.error.title')
            : err.response?.status === 403
            ? t('delete.error.authTitle')
            : t('delete.error.title'),
        kind: 'error',
        subtitle:
          err.response?.status === 422 &&
          (err.response?.data as any).error.includes(
            '"vpcs" violates foreign key constraint "fk_clusters_vpc"'
          )
            ? t('delete.error.deployedVpc')
            : err.response?.status === 403
            ? t('delete.error.authSubtitle')
            : errorMessage.length > 0
            ? errorMessage
            : t('delete.error.errorMsg', {
                depEnvName: deploymentEnvData?.name ?? '',
              }),
      });
    } finally {
      setDisableButton(false);
      setOpenDeleteDepEnvPopUp(false);
    }
  };

  const getDetailsData = () => {
    const detailsData: DetailsData[] = [
      {
        key: 'name',
        value: deploymentEnvData?.name,
        type: ['cluster', 'vpc', 'node'],
      },
      {
        key: 'cloud',
        value: deploymentEnvData?.cloud_name,
        type: ['cluster', 'vpc', 'node'],
      },
      {
        key: 'type',
        value: !!depEnvSubType ? depEnvSubType : '—',
        type: ['cluster', 'vpc', 'node'],
      },
      {
        key: 'resourceGroupLabel',
        value:
          resourceGroups !== null &&
          resourceGroups?.filter(
            (resourceGroup: any) =>
              resourceGroup?.resource_id === deploymentEnv?.resource_group_id
          )[0]?.name,
        type: ['cluster', 'vpc', 'node'],
      },
      {
        key: 'location',
        value: deploymentEnvData?.location_name,
        type: ['cluster', 'vpc', 'node'],
      },

      {
        key: 'updatedAt',
        value: dateUtils.getUserFriendlyDate(deploymentEnvData?.updated_at),
        type: ['cluster', 'node', 'vpc'],
      },
      {
        key: 'description',
        value: deploymentEnvData?.description
          ? deploymentEnvData?.description
          : '—',
        type: ['cluster', 'node', 'vpc'],
      },
      {
        key: 'labels',
        value:
          deploymentEnvData?.labels !== null
            ? deploymentEnvData?.labels || []
            : [],
        type: ['cluster', 'vpc', 'node'],
      },
    ];

    if (deploymentEnvData?.autoDiscovery === t('NodeOn'))
      detailsData.splice(7, 0, {
        key: 'credentialKey',
        value: deploymentEnvData?.credentials_key,
        type: ['cluster', 'node'],
      });
    if (
      deploymentEnvData?.type === 'cluster' ||
      deploymentEnvData?.type === 'node' ||
      (deploymentEnvData?.type === 'vpc' && !deploymentEnv?.infra_only)
    )
      detailsData.splice(6, 0, {
        key: 'autoDiscovery',
        value: !!deploymentEnvData?.autoDiscovery
          ? deploymentEnvData?.autoDiscovery
          : '—',
        type: ['cluster', 'vpc', 'node'],
        currentType: deploymentEnvData?.type,
      });

    return detailsData;
  };

  const getSubTitle = () => {
    return (
      <div className='sub-heading-section'>
        <div
          className={
            'header-icon-contaniner ' +
            (managedToggle ? 'managed' : 'unmanaged')
          }
        >
          <div className={managedToggle ? 'managed-icon' : 'unmanaged-icon'} />
          <div
            className={managedToggle ? 'icon-text' : 'unmanaged-icon-text'}
            data-testid='manage-status'
          >
            {managedToggle ? t('toggleLabelManaged') : t('unmanagedTooltip')}
          </div>
        </div>
      </div>
    );
  };

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='deployment-environment-details'>
      <Header
        loading={envDataLoading}
        title={deploymentEnvData?.name ?? ''}
        headerIcon={getHeaderIcon()}
        subTitle={getSubTitle()}
        breadcrumbs={
          state !== null && state.breadcrumb
            ? state.breadcrumb
            : [
                {
                  url: '/',
                  label: t('home'),
                },
                {
                  url: '/deploymentenvironments',
                  label: t('deploymentEnvironments'),
                },
              ]
        }
      />
      {envDataLoading ? (
        <div className='page-content'>
          <SkeletonPlaceholder className='details-tile-skeleton' />
          <DataTableSkeleton />
        </div>
      ) : (
        <div className='page-content'>
          {hasDepEnvDetailsReadAccess ? (
            <DetailsCard
              type='DEPLOYMENT_ENVIRONMENT'
              deploymentType={deploymentType}
              isEditable={true}
              isDisabled={disableEditIcon}
              openEditModal={updateDeployment}
              detailsCardName={t('detailsCardName')}
              data={getDetailsData()}
              isUnManaged={deploymentEnvData?.unmanaged}
            />
          ) : (
            <div className='emptyContainer'>
              <div className='emptyContainerCol'>
                <VerticalEmptyState
                  additionalClassName='depenvDetails_noaccess'
                  icon={images.noCloudAccessIcon()}
                  header={t('notAuthorizedHeader')}
                  description={t('notAuthorizedDescription')}
                />
              </div>
            </div>
          )}

          {/*
           Following table will not be available for experimental release
          {deploymentType === 'vpc' || deploymentType === 'node' ? (
            <ApplicationsTable type={deploymentType}></ApplicationsTable>
          ) : null} */}
          {deploymentType === 'cluster' || deploymentType === 'vpc' ? (
            <NamespaceAndSecurityGroupTable
              type={deploymentType}
              managed={managedToggle}
              depId={deplId}
              networkSegmentList={networkSegmentsData}
              openRegisterPartitionModel={handleOpenRegisterPartitionModal}
            ></NamespaceAndSecurityGroupTable>
          ) : null}

          {(partitionData && partitionData.count > 0) ||
          checkEnvAssociatedWithApps() ||
          deplEnvCloud?.auto_discover ||
          deploymentEnvData?.autoDiscovery !== t('NodeOff') ? (
            <TooltipDefinition
              tooltipText={
                t('delete.disableTooltipText', {
                  deplenvType: t(`deploymentType.${deploymentType}`),
                  type:
                    t(`partitionType.${partitionData?.partitionType}`) ?? '',
                }) as string
              }
              direction='top'
            >
              {renderDeleteBtn(true)}
            </TooltipDefinition>
          ) : (
            renderDeleteBtn(false)
          )}
        </div>
      )}
      <RegisterDeploymentEnv
        open={createTearsheetOpen}
        onClose={() => closeTearsheet()}
        refreshData={() => refreshData()}
        isEditMode={true}
        editFormData={editFormData}
        editDeploymentEnvData={deploymentEnv}
        selectTypeId={deploymentEnvData?.resource_id}
        deplEnvSubtypesList={deplEnvSubtypes}
        deploymentEnvList={deplEnvData}
        cloudList={cloudList ?? []}
        hasCloudAuthorization={permissionMap['cloud']}
        selectedLocationProps={selectedLocation}
        deploymentType={deploymentType}
        isManageMode={deploymentEnvData?.unmanaged as boolean}
      />
      <Modal
        open={openDeleteDepEnvPopUp}
        danger
        modalLabel={t('delete.deleteConfirmationModalHeading', {
          type: partitionData?.type ?? '',
        })}
        primaryButtonText={t('deletebtn')}
        secondaryButtonText={t('cancelBtn')}
        preventCloseOnClickOutside={true}
        onRequestSubmit={() => {
          confirmDeleteDepEnv();
        }}
        onRequestClose={() => {
          setOpenDeleteDepEnvPopUp(false);
        }}
        className='deleteDEpEnvModal'
        size='sm'
        primaryButtonDisabled={disableButton}
        data-testid='delete-deployment-env-confirm-modal'
      >
        {t('delete.deleteConfirmationModalText', {
          depEnvName: deploymentEnvData?.name ?? '',
        })}
      </Modal>

      <RegisterPartition
        open={openRegisterPartitionModel && deploymentType === 'vpc'}
        onClose={() => closeRegisterPartitionModal()}
        partitonType={deploymentType}
        deploymentdata={deploymentEnvData}
        creatPartition={() => registerPartionApp()}
      />
      <NamespaceRegister
        open={openRegisterPartitionModel && deploymentType === 'cluster'}
        onClose={() => closeRegisterPartitionModal()}
        partitonType={'namespace'}
        deploymentdata={deploymentEnvData}
        createPartition={() => registerPartionApp()}
        mode='ADD'
      />
    </div>
  );
};

export default DeploymentEnvDetails;
