import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Tile,
  Link as CarbonLink,
  Button,
  TooltipDefinition,
} from 'carbon-components-react';
import { Edit16 } from '@carbon/icons-react';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import images from '../../../images/images';
import './GatewayDetailsView.scss';
import LabelTag from '../../../components/LabelTag/LabelTag';
import ProceduralStatus from '../../../components/GatewayStatus/ProceduralStatus';
import {
  HealthStatusType,
  ProceduralStatusType,
  proceduralStatusField,
  showProceduralStatusField,
} from '../../../components/GatewayStatus/config';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import { Link } from 'react-router-dom';
import {
  LifecycleState,
  ProceduralActivityType,
  Procedure,
  NetworkSegment,
} from '../../../models/master';

type Data = {
  name: string;
  infrasturctureGroup: string;
  gatewaystatus: ProceduralStatusType;
  lastUpdated: string;
  labels: string[];
  description: string;
  cloud: { resource_id: string; name: string } | null;
  location: { resource_id: string; cloud_id: string; name: string } | null;
  healthStatus: HealthStatusType;
  gwType?: string;
  intendedVersion: string | number;
  gatewaySubTypeName: string;
  deployedIn: { name: string; depEnvId: string; partitionId: string } | null;
  deploymentType: string;
  terminate?: boolean;
  lifecycle_state: LifecycleState;
  procedure: Procedure;
  procedural_activity: ProceduralActivityType;
  unmanaged: boolean;
  network_segment_id: string;
};

const GatewayDetailsVew = (props: {
  data: Data | null;
  networkSegment?: NetworkSegment;
  openEditModal?: () => void;
  isEditable?: boolean;
  isEdge?: boolean;
  isWyPt?: boolean;
  openUpgradeTearsheet: () => void;
}) => {
  const { t } = useTranslation('gatewayDetails');
  const {
    data,
    networkSegment,
    isEditable,
    openEditModal,
    isEdge,
    isWyPt,
    openUpgradeTearsheet,
  } = props;

  return (
    <Tile className='gateway-details-tile'>
      <div className='header'>
        {isEdge ? (
          <div className='text'>{t('gwServicesHeader')}</div>
        ) : isWyPt ? (
          <div className='text'>{t('wayptGwServicesHeader')}</div>
        ) : null}
        {isEditable &&
          (!data?.terminate ? (
            <Button
              className='edit-link'
              renderIcon={Edit16}
              onClick={openEditModal ? () => openEditModal() : () => {}}
              iconDescription={t('edit')}
              disabled={false}
              hasIconOnly
              tooltipPosition='bottom'
            ></Button>
          ) : (
            <TooltipDefinition
              align='end'
              direction='top'
              className='edit-disabled-tooltip'
              tooltipText={t('editDisabledTooltip') as string}
            >
              <Button className='edit-link'>
                <Edit16 />
              </Button>
            </TooltipDefinition>
          ))}
      </div>
      {!data ? (
        <EmptyState
          icon={images.noServicesIcon()}
          header={t('emptyState.header')}
        />
      ) : (
        <div className='data-container'>
          <div className={`section ${isWyPt ? 'waypoint-section' : ''}`}>
            <div className='sub-section'>
              <div className='label'>{t('name')}</div>
              <div className='value'>
                <div>
                  <GenericTruncateString str={data?.name} />
                </div>
              </div>
            </div>
            <div className='sub-section'>
              <div className='label'>{t('gatewayType')}</div>
              <div className='value'>
                <div>{data?.gatewaySubTypeName}</div>
              </div>
            </div>
            <div className='sub-section'>
              <div className='label'>{t('networkSegment')}</div>
              <div className='value'>
                <div>{networkSegment?.name ?? '—'}</div>
              </div>
            </div>
            <div className='sub-section'>
              <div className='label'>{t('cloud')}</div>
              <div className='value'>
                {data?.cloud ? (
                  <Link
                    className='no-underline-link'
                    to={`/cloudDetails?cloudId=${data?.cloud?.resource_id}`}
                  >
                    <div>
                      <GenericTruncateString str={data?.cloud?.name} />
                    </div>
                  </Link>
                ) : (
                  '—'
                )}
              </div>
            </div>
            <div className='sub-section'>
              <div className='label'>{t('location')}</div>
              <div className='value'>
                {data?.location ? (
                  <Link
                    className='no-underline-link'
                    to={`/locationDetails?cloudId=${data?.location?.cloud_id}&locationId=${data?.location?.resource_id}`}
                  >
                    <div>
                      <GenericTruncateString str={data.location?.name} />
                    </div>
                  </Link>
                ) : (
                  '—'
                )}
              </div>
            </div>
            <div className='sub-section'>
              <div className='label'>
                {t('deployedEnvType', {
                  envType:
                    data?.gwType === 'RHSI-EDGE' ? t('namespace') : t('vpc'),
                })}
              </div>

              {data?.deployedIn ? (
                <div className='value'>
                  {data?.gwType === 'RHSI-EDGE' ? (
                    <Link
                      className='no-underline-link'
                      to={`/partitionDetails?depEnvId=${data?.deployedIn?.depEnvId}&partitionId=${data?.deployedIn?.partitionId}`}
                    >
                      <div>{data?.deployedIn?.name}</div>
                    </Link>
                  ) : (
                    <Link
                      className='no-underline-link'
                      to={`/deploymentEnvironmentDetails?deplId=${data?.deployedIn?.depEnvId}`}
                      state={{ from: 'namespaceDetailsPage' }}
                    >
                      <div>{data?.deployedIn?.name}</div>
                    </Link>
                  )}
                </div>
              ) : (
                <div className='value'>—</div>
              )}
            </div>
            <div className='sub-section'>
              <div className='label'>{t('infrasturctureGroup')}</div>
              <div className='value'>
                <div>{data?.infrasturctureGroup}</div>
              </div>
            </div>
            {data?.gwType !== 'RHSI-EDGE' && (
              <div className='sub-section'>
                <div className='label'>{t('deploymentType')}</div>
                <div className='value'>{data.deploymentType}</div>
              </div>
            )}

            {data.gatewaystatus &&
              data?.gwType === 'RHSI-EDGE' &&
              showProceduralStatusField(data) &&
              (data.gatewaystatus === 'in-progress' ||
                data.gatewaystatus === 'not-started' ||
                data.gatewaystatus === 'failed') && (
                <div className='sub-section'>
                  <div className='label'>{t('gatewaystatus')}</div>
                  <div className='value'>{proceduralStatusField(data)}</div>
                </div>
              )}
            <div className='sub-section'>
              <div className='label'>{t('lastUpdated')}</div>
              <div className='value'>
                <div>{data.lastUpdated}</div>
              </div>
            </div>

            {data?.gwType !== 'RHSI-EDGE' && (
              <div className='intended-section'>
                {
                  <div className='sub-section'>
                    <div className='label'>{t('intendedVersion')}</div>
                    <div className='value intended-version'>
                      {data.intendedVersion ? data.intendedVersion : '—'}
                    </div>
                  </div>
                }
                {/* Axon, removing? */}
                {data.gatewaystatus &&
                  data.gatewaystatus !== 'operational' &&
                  data.gatewaystatus !== 'notdeployed' &&
                  data.gatewaystatus !== 'terminated' &&
                  data.gatewaystatus !== 'errored' &&
                  data.gatewaystatus !== 'deployed' && (
                    <div className='sub-section'>
                      <div className='label'>{t('gatewaystatus')}</div>
                      <div className='value'>
                        <ProceduralStatus
                          status={data.gatewaystatus}
                          gatewayName={data.name}
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}
            <div className='description-section'>
              <div className='sub-section'>
                <div className='label'>{t('description')}</div>
                <div className='value'>
                  <div>{data.description}</div>
                </div>
              </div>

              <div className='sub-section'>
                <div className='label'>{t('labels')}</div>
                <div className='value'>
                  {data.labels ? (
                    <LabelTag
                      labelArray={data.labels as string[]}
                      count={3}
                    ></LabelTag>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Tile>
  );
};

export default GatewayDetailsVew;
