import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  SkeletonPlaceholder,
  Tab,
  Tabs,
  Dropdown,
  Link,
  OnChangeData,
} from 'carbon-components-react';
import { ArrowUpRight16, ArrowDownLeft16, Renew16 } from '@carbon/icons-react';
import CustomSidePanel from '../../../CustomSidePanel/CustomSidePanel';
import SidePanelDetailsCard from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsCard';
import SidePanelDetailsComponent from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsComponent';
import SidePanelTableComponent from '../../../CustomSidePanel/TableComponent/SidePanelTableComponent';

import images from '../../../../images/images';
import {
  Gateway,
  GatewayCustomData,
  Location,
  NetworkSegment,
} from '../../../../models/master';
import {
  getGateway,
  getGatewayCompatibilitySet,
  getGatewayRemoteConnections,
  getGateways,
} from '../../../../controllers/gateawayApis';
import { getLocationDetails } from '../../../../controllers/locationApis';
import { NodeSidePaneDetailsComponentProps } from '../config';
import SidePanelEmptyState from '../../../../components/CustomSidePanel/SidePanelEmptyState/SidePanelEmptyState';
import MetricsDetails, {
  ChartType,
} from '../../../../components/CustomSidePanel/MetricComponent/MetricComponent';
import {
  Data,
  DEFAULT_HISTORY,
  DEFAULT_RESOLUTION,
  DEFAULT_TYPE,
  getHistoryDropDown,
  getResolutionDropDown,
  getTypeDropDown,
  Group,
  typeToGroupMap,
  BYTES_UNIT,
  PACKETS_UNIT,
  getDefaultHistoryResolution,
  getMetricsQuery,
  getTotalMetricsQuery,
  getTunnelMetricsQuery,
  getTunnelTotalMetricsQuery,
  History,
  Resolution,
  getTunnelAverageLatencyQuery,
} from './config';
import moment from 'moment';
import dateUtil from '../../../../lib/dates';
import { getMetrics } from '../../../../controllers/metricsApis';
import { convertMetricsValueInUnits } from '../../../../lib/utils';
import HealthStatus from '../../../GatewayStatus/HealthStatus';
import {
  HealthStatusType,
  ProceduralActivityType,
  ProceduralStatusType,
  Procedure,
  showProcedureAttribute,
} from '../../../GatewayStatus/config';
import { getNetworkSegmentDetails } from '../../../../controllers/networksegmentsApi';

import './GatewayDetails.scss';
import {
  GatewayLifeCycleState,
  GatewaySubTypes,
  GatewayTypes,
  ProceduralStatus,
} from '../../../../lib/enums';
import GenericResponsiveMiddleTruncation from '../../../GenericResponsiveMiddleTruncation/GenericResponsiveMiddleTruncation';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  METRICS_TIMESTAMP_FORMAT,
} from '../../../../lib/constants';
import ProceduralActivity from '../../../GatewayStatus/ProceduralActivity';
import GatewayProcedure from '../../../GatewayStatus/GatewayProcedure';

interface InterGateway {
  name: string;
  remote_gw_id: string;
}

interface TotalData {
  incoming: number;
  outgoing: number;
}

const defaultPermissionMap = {
  gateway: true,
  location: true,
};

const defaultServerErrorMap = {
  gateway: '',
};

const GatewayDetails: React.FC<NodeSidePaneDetailsComponentProps> = ({
  node,
  open,
  onClose,
  sidePanelWidth,
  handleSidePanelWidth,
  handleHeaderWidth,
  initialHeaderWidth,
  visibility = 'managed',
  headerWidth,
}) => {
  const gatewayId =
    node != null && node?.entityType === 'gateway' ? node.resource_id : null;
  const navigate = useNavigate();
  const { t } = useTranslation('topologySidePanelGatewayDetails');

  const [interGateways, setInterGateways] = useState<InterGateway[]>([]);
  const [selectedInterGateway, setSelectedInterGateway] =
    useState<InterGateway | null>(null);
  const [gwTotalLoading, setgwTotalLoading] = useState(true);
  const [gwDataLoading, setgwDataLoading] = useState(true);
  const [tunnelTotalLoading, setTunnelTotalLoading] = useState(true);
  const [tunnelDataLoading, setTunnelDataLoading] = useState(true);
  const [selectedGatewayType, setSelectedGatewayType] =
    useState<Group>(DEFAULT_TYPE);
  const [selectedInterGatewayType, setSelectedInterGatewayType] =
    useState<Group>(DEFAULT_TYPE);
  const [selectedGatewayHistory, setSelectedGatewayHistory] =
    useState<History>(DEFAULT_HISTORY);
  const [selectedInterGatewayHistory, setSelectedInterGatewayHistory] =
    useState<History>(DEFAULT_HISTORY);
  const [selectedGatewayResolution, setSelectedGatewayResolution] =
    useState<Resolution>(DEFAULT_RESOLUTION);
  const [selectedInterGatewayResolution, setSelectedInterGatewayResolution] =
    useState<Resolution>(DEFAULT_RESOLUTION);
  const [gatewayGraphData, setGatewayGraphData] = useState<Data[]>([]);
  const [interGatewaygraphData, setInterGatewayGraphData] = useState<Data[]>(
    []
  );
  const [totalGatewayBytes24Hour, setTotalGatewayBytes24Hour] = useState<any>({
    incoming: '',
    outgoing: '',
  });
  const [totalGatewayBytesCurrent, setTotalGatewayBytesCurrent] = useState<any>(
    {
      incoming: '',
      outgoing: '',
    }
  );
  const [totalInterGatewayBytes24Hour, setTotalInterGatewayBytes24Hour] =
    useState<any>({
      incoming: '',
      outgoing: '',
    });
  const [totalInterGatewayBytesCurrent, setTotalInterGatewayBytesCurrent] =
    useState<any>({
      incoming: '',
      outgoing: '',
    });

  const [avgTunnelLatency, setAvgTunnelLatency] = useState('');
  const typeDropDown = getTypeDropDown(t);
  const historyDropDown = getHistoryDropDown(t);
  const resolutionGatewayDropDown = getResolutionDropDown(
    t,
    selectedGatewayHistory
  );
  const resolutionGatewayTunnelDropDown = getResolutionDropDown(
    t,
    selectedInterGatewayHistory
  );

  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [serverErrorMap, setServerErrorMap] = useState(defaultServerErrorMap);
  const [gatewayDetailsLoading, setGatewayDetailsLoading] = useState(false);
  const [remoteConnectionDataLoading, setRemoteConnectionDataLoading] =
    useState(true);
  const [gatewayData, setGatewayData] = useState<GatewayCustomData | null>(
    null
  );
  const [locationData, setLocationData] = useState<Location | null>(null);
  const [remoteConnections, setRemoteConnections] = useState([]);
  const [gatewaysList, setGatewaysList] = useState<Gateway[] | null>(null);
  const [waypointType, setWaypointType] = useState(false);
  const [compatibilityData, setCompatibilityData] = useState<any>();
  const [networkSegmentsData, setNetworkSegmentsData] =
    useState<NetworkSegment | null>(null);
  const [gatewayChartType, setGatewayChartType] =
    useState<ChartType>('multiLine');
  const [intergatewayChartType, setInterGatewayChartType] =
    useState<ChartType>('multiLine');

  useEffect(() => {
    const fetchGatewayData = async () => {
      try {
        setGatewayDetailsLoading(true);
        if (node) {
          try {
            const gateways = await getGateways();
            setGatewaysList(gateways);
            const gatewayDetails =
              node.uniqueId && (await getGateway(node.uniqueId));
            setGatewayData(gatewayDetails);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                gateway: false,
              }));
            }

            const errorCode = err.response!?.status;
            if (errorCode >= 500) {
              setServerErrorMap(permissionMap => ({
                ...permissionMap,
                gateway: errorCode.toString(),
              }));
            }
          }

          try {
            const response = await getGatewayCompatibilitySet();
            const compatibilitySubtype = response?.subtypes.filter(
              (e: any) => e?.resource_id === node?.subtype
            );
            setCompatibilityData(compatibilitySubtype[0]);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                gateway: false,
              }));
            }
          }

          try {
            const locationDetails =
              node.location_uniqueId &&
              node.cloud_id &&
              (await getLocationDetails(
                node.cloud_id,
                node.location_uniqueId,
                visibility,
                true
              ));
            setLocationData(locationDetails);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                location: false,
              }));
            }
          }
          //calling networkSegments API for future purpose to get networkSegment name
          try {
            const networkSegment: NetworkSegment =
              await getNetworkSegmentDetails(node?.network_segment_id);
            setNetworkSegmentsData(networkSegment);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                networkSegment: false,
              }));
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setGatewayDetailsLoading(false);
      }
    };
    fetchGatewayData();
  }, []);

  useEffect(() => {
    const fetchRemoteConnectionsData = async () => {
      try {
        setGatewayDetailsLoading(true);
        setRemoteConnectionDataLoading(true);
        const remoteConnections =
          node.uniqueId && (await getGatewayRemoteConnections(node.uniqueId));
        setRemoteConnections(formatRemoteConnections(remoteConnections));
      } catch (error) {
        console.error(error);
      } finally {
        setGatewayDetailsLoading(false);
        setRemoteConnectionDataLoading(false);
      }
    };
    fetchRemoteConnectionsData();

    if (gatewaysList !== null) {
      fetchInitialMetricsData();
    }
  }, [gatewaysList]);

  useEffect(() => {
    if (gatewayData?.type === 'waypoint') {
      setWaypointType(true);
    }
  }, [gatewayData]);

  useEffect(() => {
    selectedInterGateway !== null &&
      fetchGatewayTunnelMetrics(
        selectedInterGateway.name,
        selectedInterGatewayType,
        selectedInterGatewayHistory,
        selectedInterGatewayResolution
      );
    selectedInterGateway !== null &&
      fetchGatewayTunnelTotalMetrics(
        selectedInterGateway.name,
        selectedInterGateway.remote_gw_id
      );
  }, [selectedInterGateway]);

  const getHeaderTitle = () => {
    if (node) {
      return (
        <div className='side-panel-gateway-header'>
          <div className='header-gateway-name-sidepanel'>
            {waypointType
              ? images.WaypointGatewayIcon()
              : images.EdgeGatewayIcon()}
            <Link href='#'>
              <div className='gateway-header-text'>{node.name}</div>
            </Link>
          </div>
        </div>
      );
    }

    return '';
  };

  const title = getHeaderTitle();

  const fetchInitialMetricsData = async () => {
    try {
      setgwTotalLoading(true);
      setgwDataLoading(true);
      setTunnelTotalLoading(true);
      setTunnelDataLoading(true);

      fetchGatewayTotalMetrics();
      fetchGatewayMetrics(
        selectedGatewayType,
        selectedGatewayHistory,
        selectedGatewayResolution
      );

      let intergatewaysList: InterGateway[] = [];
      if (Array.isArray(node._references)) {
        for (const reference of node._references) {
          if (
            reference._edgeType === 'HAS_REMOTECONNECTION' &&
            reference._fromUniqueId === gatewayId
          ) {
            intergatewaysList.push({
              name: reference.name ?? '',
              remote_gw_id: reference.remote_gw_id ?? '',
            });
          }
        }

        Array.isArray(intergatewaysList) &&
          intergatewaysList.length > 0 &&
          setSelectedInterGateway({
            name: intergatewaysList[0].name,
            remote_gw_id: intergatewaysList[0].remote_gw_id,
          });

        setInterGateways(intergatewaysList);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setgwTotalLoading(false);
      setgwDataLoading(false);
      setTunnelTotalLoading(false);
      setTunnelDataLoading(false);
    }
  };

  const fetchGatewayMetrics = async (
    type: Group,
    history: History,
    resolution: Resolution
  ) => {
    try {
      setgwDataLoading(true);
      let transformedData: Data[] = [];
      const incomingQuery = gatewayId
        ? getMetricsQuery(gatewayId, type, history, resolution, 'incoming')
        : '';
      const outgoingQuery = gatewayId
        ? getMetricsQuery(gatewayId, type, history, resolution, 'outgoing')
        : '';
      const results = await Promise.allSettled([
        getMetrics(incomingQuery),
        getMetrics(outgoingQuery),
      ]);

      if (Array.isArray(results)) {
        for (let i = 0; i < results.length; i++) {
          if (results[i].status === 'fulfilled') {
            const response = (results as any)[i].value;
            if (Array.isArray(response.data?.result)) {
              const metrics = response.data.result[0];
              if (Array.isArray(metrics?.values)) {
                const data = metrics?.values.map(
                  (value: [number, string], index: number) => ({
                    id: index,
                    group:
                      type === 'bytes'
                        ? i === 0
                          ? t('legend.bytesIn')
                          : t('legend.bytesOut')
                        : i === 0
                        ? t('legend.packetsIn')
                        : t('legend.packetsOut'),
                    key: moment.unix(value[0]).toDate(),
                    value: convertMetricsValueInUnits(Number(value[1])),
                    displayTime: moment
                      .unix(value[0])
                      .format(METRICS_TIMESTAMP_FORMAT),
                  })
                );
                transformedData = [...transformedData, ...data];
              }
            }
          }
        }
      }

      setGatewayGraphData(transformedData);
    } catch (error) {
      console.error(error);
    } finally {
      setgwDataLoading(false);
    }
  };

  const fetchGatewayTotalMetrics = async () => {
    try {
      setgwTotalLoading(true);
      const start = moment().subtract(1, 'd').toISOString();
      const end = moment().toISOString();

      const bytesIncomingQuery24hour = gatewayId
        ? getTotalMetricsQuery(gatewayId, 'bytes', 'incoming', start)
        : '';
      const bytesIncomingQueryCurrent = gatewayId
        ? getTotalMetricsQuery(gatewayId, 'bytes', 'incoming', end)
        : '';
      const bytesOutgoingQuery24hour = gatewayId
        ? getTotalMetricsQuery(gatewayId, 'bytes', 'outgoing', start)
        : '';
      const bytesOutgoingQueryCurrent = gatewayId
        ? getTotalMetricsQuery(gatewayId, 'bytes', 'outgoing', end)
        : '';
      const results = await Promise.allSettled([
        getMetrics(bytesIncomingQuery24hour, false),
        getMetrics(bytesIncomingQueryCurrent, false),
        getMetrics(bytesOutgoingQuery24hour, false),
        getMetrics(bytesOutgoingQueryCurrent, false),
      ]);

      if (Array.isArray(results)) {
        for (let i = 0; i < results.length; i++) {
          if (results[i].status === 'fulfilled') {
            const response = (results as any)[i].value;

            if (
              Array.isArray(response.data?.result) &&
              response.data?.result.length > 0
            ) {
              const metrics = response.data.result[0];

              if (Array.isArray(metrics?.value)) {
                let index = metrics?.value.length - 1;
                let totalMetricData = metrics?.value[index];
                switch (i) {
                  case 0:
                    setTotalGatewayBytes24Hour((prevState: any) => ({
                      ...prevState,
                      incoming: totalMetricData,
                    }));
                    break;
                  case 1:
                    setTotalGatewayBytesCurrent((prevState: any) => ({
                      ...prevState,
                      incoming: totalMetricData,
                    }));
                    break;
                  case 3:
                    setTotalGatewayBytes24Hour((prevState: any) => ({
                      ...prevState,
                      outgoing: totalMetricData,
                    }));
                    break;
                  case 4:
                    setTotalGatewayBytesCurrent((prevState: any) => ({
                      ...prevState,
                      outgoing: totalMetricData,
                    }));
                    break;
                  default:
                    break;
                }
              }
            } else {
              switch (i) {
                case 0:
                  setTotalGatewayBytes24Hour((prevState: any) => ({
                    ...prevState,
                    incoming: '',
                  }));
                  break;
                case 1:
                  setTotalGatewayBytesCurrent((prevState: any) => ({
                    ...prevState,
                    incoming: '',
                  }));
                  break;
                case 3:
                  setTotalGatewayBytes24Hour((prevState: any) => ({
                    ...prevState,
                    outgoing: '',
                  }));
                  break;
                case 4:
                  setTotalGatewayBytesCurrent((prevState: any) => ({
                    ...prevState,
                    outgoing: '',
                  }));
                  break;
                default:
                  break;
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setgwTotalLoading(false);
    }
  };

  const fetchGatewayTunnelMetrics = async (
    tunnelName: string,
    type: Group,
    history: History,
    resolution: Resolution
  ) => {
    try {
      setTunnelDataLoading(true);
      let transformedData: Data[] = [];

      const incomingQuery =
        gatewayId && tunnelName
          ? getTunnelMetricsQuery(
              gatewayId,
              tunnelName,
              type,
              history,
              resolution,
              'incoming'
            )
          : '';
      const outgoingQuery =
        gatewayId && tunnelName
          ? getTunnelMetricsQuery(
              gatewayId,
              tunnelName,
              type,
              history,
              resolution,
              'outgoing'
            )
          : '';
      const results = await Promise.allSettled([
        getMetrics(incomingQuery),
        getMetrics(outgoingQuery),
      ]);

      if (Array.isArray(results)) {
        for (let i = 0; i < results.length; i++) {
          if (results[i].status === 'fulfilled') {
            const response = (results as any)[i].value;
            if (Array.isArray(response.data?.result)) {
              const metrics = response.data.result[0];
              if (Array.isArray(metrics?.values)) {
                const data = metrics?.values.map(
                  (value: [number, string], index: number) => ({
                    id: index,
                    group:
                      type === 'bytes'
                        ? i === 0
                          ? t('legend.bytesIn')
                          : t('legend.bytesOut')
                        : i === 0
                        ? t('legend.packetsIn')
                        : t('legend.packetsOut'),
                    key: moment.unix(value[0]).toDate(),
                    value: convertMetricsValueInUnits(Number(value[1])),
                    displayTime: moment
                      .unix(value[0])
                      .format(METRICS_TIMESTAMP_FORMAT),
                  })
                );
                transformedData = [...transformedData, ...data];
              }
            }
          }
        }
      }

      setInterGatewayGraphData(transformedData);
    } catch (error) {
      console.error(error);
    } finally {
      setTunnelDataLoading(false);
    }
  };

  const fetchGatewayTunnelTotalMetrics = async (
    tunnelName: string,
    tunnelId: string | number
  ) => {
    try {
      setTunnelTotalLoading(true);
      const start = moment().subtract(1, 'd').toISOString();
      const end = moment().toISOString();

      const bytesIncomingQuery24hour =
        gatewayId && tunnelName
          ? getTunnelTotalMetricsQuery(
              gatewayId,
              tunnelName,
              'bytes',
              'incoming',
              start
            )
          : '';
      const bytesIncomingQueryCurrent =
        gatewayId && tunnelName
          ? getTunnelTotalMetricsQuery(
              gatewayId,
              tunnelName,
              'bytes',
              'incoming',
              end
            )
          : '';
      const bytesOutgoingQuery24hour =
        gatewayId && tunnelName
          ? getTunnelTotalMetricsQuery(
              gatewayId,
              tunnelName,
              'bytes',
              'outgoing',
              start
            )
          : '';
      const bytesOutgoingQueryCurrent =
        gatewayId && tunnelName
          ? getTunnelTotalMetricsQuery(
              gatewayId,
              tunnelName,
              'bytes',
              'outgoing',
              end
            )
          : '';
      const avgTunnelLatencyQuery =
        gatewayId && tunnelId
          ? getTunnelAverageLatencyQuery(gatewayId, tunnelId)
          : '';
      const results = await Promise.allSettled([
        getMetrics(bytesIncomingQuery24hour, false),
        getMetrics(bytesOutgoingQuery24hour, false),
        getMetrics(bytesIncomingQueryCurrent, false),
        getMetrics(bytesOutgoingQueryCurrent, false),
        getMetrics(avgTunnelLatencyQuery, false),
      ]);

      if (Array.isArray(results)) {
        for (let i = 0; i < results.length; i++) {
          if (results[i].status === 'fulfilled') {
            const response = (results as any)[i].value;

            if (
              Array.isArray(response.data?.result) &&
              response.data.result.length > 0
            ) {
              const metrics = response.data.result[0];

              if (Array.isArray(metrics?.value)) {
                let index = metrics?.value.length - 1;
                switch (i) {
                  case 0:
                    setTotalInterGatewayBytes24Hour((prevState: any) => ({
                      ...prevState,
                      incoming: metrics?.value[index],
                    }));
                    break;
                  case 1:
                    setTotalInterGatewayBytes24Hour((prevState: any) => ({
                      ...prevState,
                      outgoing: metrics?.value[index],
                    }));
                    break;
                  case 2:
                    setTotalInterGatewayBytesCurrent((prevState: any) => ({
                      ...prevState,
                      incoming: metrics?.value[index],
                    }));
                    break;
                  case 3:
                    setTotalInterGatewayBytesCurrent((prevState: any) => ({
                      ...prevState,
                      outgoing: metrics?.value[index],
                    }));
                    break;
                  case 4:
                    // Since this is a round trip latency we need to divide it by 2
                    const value = metrics?.value[1]
                      ? (metrics?.value[1] / 1000 / 2)
                          .toFixed(2)
                          .replace(/[.,]00$/, '')
                      : '';
                    setAvgTunnelLatency(value);
                    break;
                  default:
                    break;
                }
              }
            } else {
              // Clear out the previously set states
              switch (i) {
                case 0:
                  setTotalInterGatewayBytes24Hour((prevState: any) => ({
                    ...prevState,
                    incoming: '',
                  }));
                  break;
                case 1:
                  setTotalInterGatewayBytes24Hour((prevState: any) => ({
                    ...prevState,
                    outgoing: '',
                  }));
                  break;
                case 2:
                  setTotalInterGatewayBytesCurrent((prevState: any) => ({
                    ...prevState,
                    incoming: '',
                  }));
                  break;
                case 3:
                  setTotalInterGatewayBytesCurrent((prevState: any) => ({
                    ...prevState,
                    outgoing: '',
                  }));
                  break;
                case 4:
                  setAvgTunnelLatency('');
                  break;
                default:
                  break;
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTunnelTotalLoading(false);
    }
  };

  const handleLocationClick = (data: Location | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/locationDetails?cloudId=${data?.cloud_id}&locationId=${data?.resource_id}`
    );
  };

  const handleNetSegmentEnvClick = (data: NetworkSegment | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/networkSegmentDetails?nwSegId=${networkSegmentsData?.resource_id}`
    );
  };

  const getGatewayType = () => {
    if (
      node?.type &&
      compatibilityData &&
      (compatibilityData?.resource_id === 'AXON-EDGE' ||
        'AXON-WP' ||
        'RHSI-EDGE')
    ) {
      return compatibilityData?.name;
    }
  };

  const formatGatewayDetailsData = () => {
    const formattedData = [
      {
        label: t('networkSegment'),
        value: networkSegmentsData?.name,
        valueType:
          networkSegmentsData?.resource_id === DEFAULT_NETWORK_SEGMENT_ID
            ? 'displayField'
            : 'link',
        linkCallback: () => {
          handleNetSegmentEnvClick(networkSegmentsData);
        },
        notAuthorized: !permissionMap['gateway'],
      },
      {
        label: t('type'),
        value: getGatewayType(),
        valueType: 'displayField',
        notAuthorized: !permissionMap['gateway'],
      },

      {
        label: t('version'),
        value: gatewayData?.intended_version,
        valueType: 'displayField',
        notAuthorized: !permissionMap['gateway'],
      },

      {
        label: t('location'),
        value: locationData?.name,
        valueType: 'link',
        linkCallback: () => {
          handleLocationClick(locationData);
        },
        notAuthorized: !permissionMap['location'],
      },
    ];

    if (gatewayData?.procedural_status === ProceduralStatus.ERRORED) {
      formattedData.splice(2, 0, {
        label: t('status'),
        value: (
          <ProceduralActivity status={gatewayData?.procedural_status} />
        ) as any,
        valueType: 'displayField',
        notAuthorized: !permissionMap['gateway'],
      });
    } else if (gatewayData?.procedural_activity) {
      formattedData.splice(2, 0, {
        label: t('status'),
        value: (
          <ProceduralActivity
            status={gatewayData?.procedural_activity as ProceduralActivityType}
          />
        ) as any,
        valueType: 'displayField',
        notAuthorized: !permissionMap['gateway'],
      });
    } else if (
      gatewayData?.procedural_status === ProceduralStatus.IN_PROGRESS
    ) {
      formattedData.splice(2, 0, {
        label: t('status'),
        value: (
          <GatewayProcedure status={gatewayData?.procedure as Procedure} />
        ) as any,
        valueType: 'displayField',
        notAuthorized: !permissionMap['gateway'],
      });
    } else if (
      gatewayData?.lifecycle_state === GatewayLifeCycleState.GW_NOT_DEPLOYED &&
      gatewayData?.unmanaged
    ) {
      formattedData.splice(2, 0, {
        label: t('status'),
        value: (
          <ProceduralActivity status={gatewayData?.lifecycle_state} />
        ) as any,
        valueType: 'displayField',
        notAuthorized: !permissionMap['gateway'],
      });
    } else {
      formattedData.splice(2, 0, {
        label: t('status'),
        value: <span>—</span>,
        valueType: 'displayField',
        notAuthorized: !permissionMap['gateway'],
      });
    }
    return formattedData;
  };

  const handleRemoteGatewayClick = (row: any) => {
    if (row) {
      window.open(
        `${window.location.origin}${process.env.PUBLIC_URL}/gatewayDetails?gatewayId=${row.remoteGatewayId}`
      );
    }

    return '';
  };

  const getLink = () => {
    if (gatewayId) {
      return `/gatewayDetails?gatewayId=${gatewayId}` ?? ' ';
    }

    return '';
  };

  const fetchRemoteGatewayId = (
    nodeType: string | undefined,
    item: { remote_gw_id: any; this_gw_ip?: any; gateway_id: any; cost?: any }
  ) => {
    return getRemoteGatewayName(
      nodeType === GatewaySubTypes?.RHSI_EDGE
        ? item?.remote_gw_id === node?.uniqueId
          ? item?.gateway_id
          : item?.remote_gw_id
        : item?.remote_gw_id ?? ''
    );
  };

  const formatRemoteConnections = (data: any) => {
    return data?.map(
      (item: {
        remote_gw_id: any;
        this_gw_ip: any;
        gateway_id: any;
        cost: any;
      }) => ({
        name:
          fetchRemoteGatewayId(node?.subtype, item).length > 20 ? (
            <GenericResponsiveMiddleTruncation
              str={fetchRemoteGatewayId(node?.subtype, item)}
              hasTooltip={true}
              strLength={20}
            />
          ) : (
            fetchRemoteGatewayId(node?.subtype, item)
          ),
        ip: item.this_gw_ip,
        gatewayId: item.gateway_id,
        remoteGatewayId: item.remote_gw_id,
        publicIp: getPublicIp(item?.remote_gw_id ?? ''),
        cost: item?.cost,
      })
    );
  };

  const handleGatewayTypeChange = (data: { text: string; id: string }) => {
    if (data?.id) {
      const group = (typeToGroupMap as any)[data?.id];

      setSelectedGatewayType(group);
      fetchGatewayMetrics(
        group,
        selectedGatewayHistory,
        selectedGatewayResolution
      );
    }
  };

  const handleInterGatewayTypeChange = (data: { text: string; id: string }) => {
    if (data?.id) {
      const group = (typeToGroupMap as any)[data?.id];

      setSelectedInterGatewayType(group);
      selectedInterGateway &&
        fetchGatewayTunnelMetrics(
          selectedInterGateway.name,
          group,
          selectedInterGatewayHistory,
          selectedInterGatewayResolution
        );
    }
  };

  const handleGatewayHistoryChange = (
    e: OnChangeData<{
      text: string;
      id: string;
    }>
  ) => {
    if (e.selectedItem?.id) {
      const history = e.selectedItem.id as History;
      setSelectedGatewayHistory(history);
      const resolution = getDefaultHistoryResolution(history);
      setSelectedGatewayResolution(resolution);
      fetchGatewayMetrics(selectedGatewayType, history, resolution);
    }
  };

  const handleInterGatewayHistoryChange = (
    e: OnChangeData<{
      text: string;
      id: string;
    }>
  ) => {
    if (e.selectedItem?.id) {
      const history = e.selectedItem.id as History;
      setSelectedInterGatewayHistory(history);
      const resolution = getDefaultHistoryResolution(history);
      setSelectedInterGatewayResolution(resolution);
      selectedInterGateway &&
        fetchGatewayTunnelMetrics(
          selectedInterGateway.name,
          selectedInterGatewayType,
          history,
          resolution
        );
    }
  };

  const handleGatewayResolutionChange = (
    e: OnChangeData<{
      text: string;
      id: string;
    }>
  ) => {
    if (e.selectedItem?.id) {
      const resolution = e.selectedItem.id as Resolution;
      setSelectedGatewayResolution(resolution);
      fetchGatewayMetrics(
        selectedGatewayType,
        selectedGatewayHistory,
        resolution
      );
    }
  };

  const handleInterGatewayResolutionChange = (
    e: OnChangeData<{
      text: string;
      id: string;
    }>
  ) => {
    if (e.selectedItem?.id) {
      const resolution = e.selectedItem.id as Resolution;
      setSelectedInterGatewayResolution(resolution);
      selectedInterGateway &&
        fetchGatewayTunnelMetrics(
          selectedInterGateway.name,
          selectedInterGatewayType,
          selectedInterGatewayHistory,
          resolution
        );
    }
  };

  const renderTotalMetrics = (
    category: 'gatewayTraffic' | 'interGatewayTraffic'
  ) => {
    let incomingTotalBytes,
      outgoingTotalBytes,
      incomingTotalPackets,
      outgoingTotalPackets;
    if (category === 'gatewayTraffic') {
      incomingTotalBytes =
        totalGatewayBytesCurrent.incoming - totalGatewayBytes24Hour.incoming;
      outgoingTotalBytes =
        totalGatewayBytesCurrent.outgoing - totalGatewayBytes24Hour.outgoing;
    } else {
      incomingTotalBytes =
        totalInterGatewayBytesCurrent.incoming -
        totalInterGatewayBytes24Hour.incoming;
      outgoingTotalBytes =
        totalInterGatewayBytesCurrent.outgoing -
        totalInterGatewayBytes24Hour.incoming;
    }

    return (
      <div className='summary'>
        {category === 'interGatewayTraffic' && (
          <div className='average-latency'>
            <div className='label'>{t('avgLatency')}</div>
            {avgTunnelLatency?.length > 0 && (
              <div className='value'>{`${avgTunnelLatency} ms`}</div>
            )}
          </div>
        )}
        <div className='session'>
          <div className='header'>{t('metricColumn')}</div>
          <div className='header'>{t('totalout')}</div>
          <div className='header'>{t('totalin')}</div>
        </div>
        {
          <div className='session'>
            <div className='value'>{t('metricDataKiloBytes')}</div>
            {outgoingTotalBytes && (
              <div className='value'>
                <ArrowUpRight16 />
                {convertMetricsValueInUnits(Math.abs(outgoingTotalBytes))}{' '}
                {BYTES_UNIT}
              </div>
            )}
            {incomingTotalBytes && (
              <div className='value'>
                <ArrowDownLeft16 />
                {convertMetricsValueInUnits(Math.abs(incomingTotalBytes))}{' '}
                {BYTES_UNIT}
              </div>
            )}
          </div>
        }
        {/* {
          <div className='session'>
            <div className='value'>{t('metricDataPackets')}</div>
            {outgoingTotalPackets && (
              <div className='value'>
                <ArrowUpRight16 fill={cyan[60]} />
                {outgoingTotalPackets} {PACKETS_UNIT}
              </div>
            )}
            {incomingTotalPackets && (
              <div className='value'>
                <ArrowDownLeft16 fill={cyan[60]} />
                {incomingTotalPackets} {PACKETS_UNIT}
              </div>
            )}
          </div>
        } */}
      </div>
    );
  };

  const handleInterGatewayChange = (
    e: OnChangeData<{
      name: string;
    }>
  ) => {
    if (e.selectedItem) {
      const item = e.selectedItem as InterGateway;
      if (item != null) {
        setSelectedInterGateway({
          name: item.name,
          remote_gw_id: item.remote_gw_id,
        });
      }
    }
  };

  const handleGatewayRefresh = () => {
    fetchGatewayMetrics(
      selectedGatewayType,
      selectedGatewayHistory,
      selectedGatewayResolution
    );
  };

  const handleInterGatewayRefresh = () => {
    selectedInterGateway &&
      fetchGatewayTunnelMetrics(
        selectedInterGateway.name,
        selectedInterGatewayType,
        selectedInterGatewayHistory,
        selectedInterGatewayResolution
      );
  };

  const getRemoteGatewayName = (resourceId: string) => {
    return gatewaysList && Array.isArray(gatewaysList)
      ? gatewaysList?.find(
          (resource: { resource_id: any }) =>
            resource.resource_id === resourceId ?? ''
        )?.name ?? ''
      : '';
  };

  const getPublicIp = (resourceId: string) => {
    return gatewaysList && Array.isArray(gatewaysList)
      ? gatewaysList?.find(
          (resource: { resource_id: any }) =>
            resource.resource_id === resourceId ?? ''
        )?.public_ip ?? ''
      : '';
  };

  const link = getLink();

  return (
    <div className='topology-side-panel-gateway-details'>
      <CustomSidePanel
        open={open}
        onClose={onClose}
        title={title}
        link={link}
        sidePanelWidth={sidePanelWidth}
        handleSidePanelWidth={handleSidePanelWidth}
        handleHeaderWidth={handleHeaderWidth}
        initialHeaderWidth={initialHeaderWidth}
        headerWidth={headerWidth}
      >
        <div className='gateway_healthstatus_header'>
          <HealthStatus
            status={gatewayData?.health_status as HealthStatusType}
            gatewayName={gatewayData?.name}
            showLabel={true}
          />
        </div>
        <Tabs>
          <Tab id='topology-details-tab-1' label={t('details')}>
            <SidePanelDetailsComponent
              title={
                node?.type === GatewayTypes.EDGE
                  ? t('edgeGatewayDetails')
                  : t('wayPointDetails')
              }
            >
              {(gatewayDetailsLoading ||
                !networkSegmentsData ||
                !locationData) && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                </div>
              )}
              {!gatewayDetailsLoading &&
                networkSegmentsData &&
                locationData && (
                  <SidePanelDetailsCard
                    data={formatGatewayDetailsData()}
                    notAuthorized={
                      !permissionMap['gateway'] && !permissionMap['location']
                    }
                    error500={!!serverErrorMap['gateway']}
                  />
                )}
            </SidePanelDetailsComponent>
            {remoteConnectionDataLoading ? (
              <SidePanelDetailsComponent title={`${t('remoteConnection')} `}>
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='gateway-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-details-skeleton' />
                </div>
              </SidePanelDetailsComponent>
            ) : remoteConnections?.length > 0 ? (
              <SidePanelDetailsComponent
                title={`${t('remoteConnection')} ${
                  Array.isArray(remoteConnections)
                    ? '(' + remoteConnections.length + ')'
                    : ''
                }`}
              >
                {!remoteConnectionDataLoading &&
                !remoteConnections[remoteConnections.length - 1]['name'] ? (
                  <div className='skeleton-container'>
                    <SkeletonPlaceholder className='gateway-details-skeleton' />
                    <SkeletonPlaceholder className='gateway-details-skeleton' />
                    <SkeletonPlaceholder className='gateway-details-skeleton' />
                  </div>
                ) : (
                  <SidePanelTableComponent
                    headers={[
                      {
                        key: 'name',
                        header: t('name'),
                        linkCallback: (row: any) => {
                          handleRemoteGatewayClick(row);
                        },
                      },
                      {
                        key: 'cost',
                        header: t('linkMetric'),
                      },
                    ]}
                    rows={remoteConnections}
                  />
                )}
              </SidePanelDetailsComponent>
            ) : (
              <SidePanelDetailsComponent title={`${t('remoteConnection')} `}>
                <SidePanelEmptyState
                  icon={images.SidePanelNoConnectionIcon()}
                  header={t('emptyState.header')}
                  message={t('emptyState.message')}
                  btnText={t('emptyState.btnText')}
                  disableBtn={true} //As we do not have option to add gateway making register gateway button disabled
                  btnUrl={''}
                  resourceType='GATEWAYS'
                />
              </SidePanelDetailsComponent>
            )}
          </Tab>
          {gatewayData &&
            gatewayData?.subtype !== GatewaySubTypes.RHSI_EDGE && (
              <Tab id='topology-details-tab-2' label='Metrics'>
                <SidePanelDetailsComponent title={t('gatewayTrafficTitle')}>
                  {!!serverErrorMap['gateway'] && (
                    <SidePanelEmptyState
                      icon={<></>}
                      header=''
                      message=''
                      error500={true}
                    />
                  )}

                  {gwTotalLoading && !serverErrorMap['gateway'] && (
                    <div className='skeleton-container summary-details '>
                      <SkeletonPlaceholder className='gateway-details-skeleton header' />
                      <SkeletonPlaceholder className='gateway-details-skeleton value' />
                      <SkeletonPlaceholder className='gateway-details-skeleton' />
                      <SkeletonPlaceholder className='gateway-details-skeleton' />
                    </div>
                  )}
                  {!gwTotalLoading && (
                    <div className='details-container'>
                      <div className='summary-time'>
                        <div className='label'>{t('summary')}</div>
                        <div className='value'>
                          {moment().format('hh:mm a')}{' '}
                          {dateUtil.getCurrentTimeZone()} ({t('24Hrs')})
                        </div>
                      </div>
                      {renderTotalMetrics('gatewayTraffic')}
                    </div>
                  )}
                  {gwDataLoading && !serverErrorMap['gateway'] && (
                    <div className='skeleton-container metric-details'>
                      <SkeletonPlaceholder className='gateway-details-skeleton' />
                    </div>
                  )}
                  {!gwDataLoading && (
                    <div>
                      <MetricsDetails
                        metricContainerId='metricContainer1'
                        metricTypeDropdownId='gateway_dropdownId'
                        chartType={gatewayChartType}
                        onChartTypeChange={(value: ChartType) => {
                          setGatewayChartType(value);
                        }}
                        data={gatewayGraphData}
                        typeDropDownOptions={typeDropDown}
                        typeDropdownTitile={t('dataType')}
                        selectedType={{
                          text: t(selectedGatewayType),
                          id: selectedGatewayType,
                          title:
                            selectedGatewayType === 'bytes'
                              ? t('bytesChartTitle')
                              : t('packetsChartTitle'),
                        }}
                        chartComponentId='chartComponentId'
                        handleMetricTypeChange={handleGatewayTypeChange}
                        chartAxesMapping={{
                          multiLine: {
                            left: {
                              title:
                                selectedGatewayType === 'bytes'
                                  ? t('kilobytesTransfered')
                                  : t('kilopacketsTransfered'),
                              mapsTo: 'value',
                            },
                            bottom: {
                              title: t('time'),
                              scaleType: 'time',
                              mapsTo: 'key',
                            },
                          },
                          groupedBar: {
                            left: {
                              title:
                                selectedGatewayType === 'bytes'
                                  ? t('kilobytesTransfered')
                                  : t('kilopacketsTransfered'),
                              mapsTo: 'value',
                            },
                            bottom: {
                              title: t('time'),
                              scaleType: 'time',
                              mapsTo: 'key',
                            },
                          },
                        }}
                        bottomChildren={
                          <div className='dropdown-container'>
                            <div className='left history-dropdown'>
                              <Dropdown
                                id='history'
                                titleText={t('history')}
                                label={t('history')}
                                selectedItem={{
                                  text: t(selectedGatewayHistory),
                                  id: selectedGatewayHistory,
                                }}
                                items={historyDropDown}
                                itemToString={item =>
                                  item ? item?.text ?? '' : ''
                                }
                                onChange={handleGatewayHistoryChange}
                              />
                            </div>
                            <div className='right resolution-dropdown'>
                              <Dropdown
                                id='resolution'
                                titleText={t('resolution')}
                                label={t('resolution')}
                                selectedItem={{
                                  text: t(selectedGatewayResolution),
                                  id: selectedGatewayResolution,
                                }}
                                items={resolutionGatewayDropDown}
                                itemToString={item =>
                                  item ? item?.text ?? '' : ''
                                }
                                onChange={handleGatewayResolutionChange}
                              />
                            </div>
                            <div className='refresh'>
                              <span
                                className='icon'
                                onClick={handleGatewayRefresh}
                              >
                                <Renew16 />
                              </span>
                            </div>
                          </div>
                        }
                      ></MetricsDetails>
                    </div>
                  )}
                </SidePanelDetailsComponent>
                <SidePanelDetailsComponent
                  title={t('intergatewayTrafficTitle')}
                >
                  {!!serverErrorMap['gateway'] && (
                    <SidePanelEmptyState
                      icon={<></>}
                      header=''
                      message=''
                      error500={true}
                    />
                  )}

                  {tunnelTotalLoading && !serverErrorMap['gateway'] && (
                    <div className='skeleton-container summary-details'>
                      <SkeletonPlaceholder className='gateway-details-skeleton header' />
                      <SkeletonPlaceholder className='gateway-details-skeleton value' />
                      <SkeletonPlaceholder className='gateway-details-skeleton' />
                      <SkeletonPlaceholder className='gateway-details-skeleton' />
                    </div>
                  )}
                  {!tunnelTotalLoading && (
                    <div className='details-container'>
                      <div className='summary-time'>
                        <div className='label'>{t('summary')}</div>
                        <div className='value'>
                          {moment().format('hh:mm a')}{' '}
                          {dateUtil.getCurrentTimeZone()} ({t('24Hrs')})
                        </div>
                      </div>
                      {renderTotalMetrics('interGatewayTraffic')}
                    </div>
                  )}
                  {tunnelDataLoading && !serverErrorMap['gateway'] && (
                    <div className='skeleton-container metric-details'>
                      <SkeletonPlaceholder className='gateway-details-skeleton' />
                    </div>
                  )}
                  {!tunnelDataLoading && (
                    <div>
                      <MetricsDetails
                        metricContainerId='metricContainer2'
                        metricTypeDropdownId='gateway_dropdownId'
                        chartType={intergatewayChartType}
                        onChartTypeChange={(value: ChartType) => {
                          setInterGatewayChartType(value);
                        }}
                        data={interGatewaygraphData}
                        typeDropDownOptions={typeDropDown}
                        typeDropdownTitile={t('dataType')}
                        selectedType={{
                          text: t(selectedInterGatewayType),
                          id: selectedInterGatewayType,
                          title:
                            selectedInterGatewayType === 'bytes'
                              ? t('bytesChartTitle')
                              : t('packetsChartTitle'),
                        }}
                        chartComponentId='chartComponentId'
                        handleMetricTypeChange={handleInterGatewayTypeChange}
                        chartAxesMapping={{
                          multiLine: {
                            left: {
                              title:
                                selectedInterGatewayType === 'bytes'
                                  ? t('kilobytesTransfered')
                                  : t('kilopacketsTransfered'),
                              mapsTo: 'value',
                            },
                            bottom: {
                              title: t('time'),
                              scaleType: 'time',
                              mapsTo: 'key',
                            },
                          },
                          groupedBar: {
                            left: {
                              title:
                                selectedInterGatewayType === 'bytes'
                                  ? t('kilobytesTransfered')
                                  : t('kilopacketsTransfered'),
                              mapsTo: 'value',
                            },
                            bottom: {
                              title: t('time'),
                              scaleType: 'time',
                              mapsTo: 'key',
                            },
                          },
                        }}
                        bottomChildren={
                          <div className='dropdown-container'>
                            <div className='left history-dropdown'>
                              <Dropdown
                                id='history'
                                titleText={t('history')}
                                label={t('history')}
                                selectedItem={{
                                  text: t(selectedInterGatewayHistory),
                                  id: selectedInterGatewayHistory,
                                }}
                                items={historyDropDown}
                                itemToString={item =>
                                  item ? item?.text ?? '' : ''
                                }
                                onChange={handleInterGatewayHistoryChange}
                              />
                            </div>
                            <div className='right resolution-dropdown'>
                              <Dropdown
                                id='resolution'
                                titleText={t('resolution')}
                                label={t('resolution')}
                                selectedItem={{
                                  text: t(selectedInterGatewayResolution),
                                  id: selectedInterGatewayResolution,
                                }}
                                items={resolutionGatewayTunnelDropDown}
                                itemToString={item =>
                                  item ? item?.text ?? '' : ''
                                }
                                onChange={handleInterGatewayResolutionChange}
                              />
                            </div>
                            <div className='refresh'>
                              <span
                                className='icon'
                                onClick={handleInterGatewayRefresh}
                              >
                                <Renew16 />
                              </span>
                            </div>
                          </div>
                        }
                      >
                        <div className='dropdown-container'>
                          <div className='left adjacent-gateway-dropdown'>
                            <Dropdown
                              id='adjacent-gateway'
                              titleText={'Adjacent gateway'}
                              label={'Adjacent gateway'}
                              selectedItem={selectedInterGateway}
                              items={interGateways}
                              itemToString={(item: InterGateway) =>
                                getRemoteGatewayName(item.remote_gw_id)
                              }
                              onChange={handleInterGatewayChange}
                            />
                          </div>
                        </div>
                      </MetricsDetails>
                    </div>
                  )}
                </SidePanelDetailsComponent>
              </Tab>
            )}
        </Tabs>
      </CustomSidePanel>
    </div>
  );
};

export default GatewayDetails;
