import React, { useContext, useEffect, useRef } from 'react';
import { ActionableNotification } from '@carbon/react';
import { NotificationContext } from '../Context/NotificationProvider';

import './Notification.scss';

const Notification = () => {
  const notification = useContext(NotificationContext);
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);

  const notificationDetails =
    Array.isArray(notification.details) && notification.details.length > 0
      ? notification.details[notification.details.length - 1]
      : null;

  useEffect(() => {
    if (notificationDetails) {
      timer.current = setTimeout(() => {
        notification.onClose(
          notificationDetails.title,
          notificationDetails.subtitle
        );
      }, notificationDetails.timeout ?? 5000);
    }

    // clear on component unmount
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  });

  const handleActionButtonClick = () => {
    if (notificationDetails?.onActionClick) {
      notificationDetails.onActionClick();
      notification.onClose();
    }
  };

  return (
    <>
      {notification.notificationType === 'ACTION' && (
        <div className='action-notification-container'>
          <ActionableNotification
            kind={notificationDetails?.kind ?? 'success'}
            subtitle={notificationDetails?.subtitle ?? ''}
            title={notificationDetails?.title ?? ''}
            onClose={() =>
              notification.onClose(
                notificationDetails?.title,
                notificationDetails?.subtitle
              ) as any
            }
            actionButtonLabel={notificationDetails?.action ?? ''}
            onActionButtonClick={handleActionButtonClick}
          ></ActionableNotification>
        </div>
      )}
    </>
  );
};

export default Notification;
