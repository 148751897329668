import { MCNM_APP_API_URL } from './config';
import { getApplication, getApplicationService } from './applicationApis';
import { axiosInstance } from './api';
import { getPartition, getDeploymentEnvsPartitions } from './deploymentEnv';
import { PolicyDataType } from '../lib/enums';

const getApplicationandServicesForPolicies = async (
  fetchServices = true,
  fetchApplications = true,
  policy
) => {
  const fromAppId = policy?.from?.application?.application_id;
  const toAppId = policy?.to?.service?.application_id;
  const svcId = policy?.to?.service?.service_id;
  const svcName = fetchServices
    ? await getApplicationService(toAppId, svcId).then(response => {
        return response?.name;
      })
    : '';
  const appName = fetchApplications
    ? await getApplication(fromAppId).then(response => {
        return response?.name;
      })
    : '';
  return {
    ...policy,
    from_app_name: appName,
    to_svc_name: svcName,
  };
};

//Get all of the policys that MCNM oversees
export async function getPolicies(
  fetchServices = true,
  fetchApplications = true,
  count = 0
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/policy`,
    });
    // need to get the names of the application and service that each policy is tied to:
    const fullPolicyData = await Promise.all(
      response.data.policys.map(async (policy, index) => {
        // fetch application and service only for given count
        if (count && index <= count - 1) {
          return await getApplicationandServicesForPolicies(
            fetchServices,
            fetchApplications,
            policy
          );
        } else if (!count) {
          return await getApplicationandServicesForPolicies(
            fetchServices,
            fetchApplications,
            policy
          );
        } else {
          return {
            ...policy,
          };
        }
      })
    );
    return fullPolicyData;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Add a new policy that MCNM should oversee
export async function addPolicy(data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_APP_API_URL}/policy`,
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Delete an existing policy that MCNM oversees
export async function deletePolicy(policy_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_APP_API_URL}/policy/${policy_id}`,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Get one of the policys that MCNM oversees
export async function getPolicy(
  policy_id,
  fetchFromData = true,
  fetchToData = true
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/policy/${policy_id}`,
    });

    const fromAppId = response?.data?.from?.application?.application_id;
    const toAppId = response?.data?.to?.service?.application_id;
    const svcId = response?.data?.to?.service?.service_id;
    const partitionId = response?.data?.from?.partition?.partition_id;
    const deplEnvId = response?.data?.from?.deployment_env?.deployment_env_id;
    const fromType = response?.data?.from?.type;
    let appData, serviceData, partitionData;
    let namespaces = [];
    let partitionPermission = true;
    let servicePermission = true;
    let namespaceLists =
      fromType === PolicyDataType.NAMESPACE
        ? response?.data?.from?.namespaces?.map(
            namespaceDatas => namespaceDatas.namespace_id
          )
        : [];

    if (fetchFromData && fromAppId)
      try {
        appData = await getApplication(fromAppId);
      } catch (error) {
        console.error(error);
      }

    if (fetchFromData && deplEnvId && partitionId)
      try {
        partitionData = await getPartition(deplEnvId, partitionId);
      } catch (error) {
        console.error(error);
      }
    if (fetchToData && toAppId && svcId)
      try {
        serviceData = await getApplicationService(toAppId, svcId, true);
        console.log('I am in');
      } catch (error) {
        console.log('I am in');
        console.error(error);
        if (
          error?.response?.status === 403 &&
          error?.response?.statusText === 'Forbidden'
        ) {
          servicePermission = false;
        }
      }
    if (fromType === PolicyDataType.NAMESPACE && namespaceLists.length > 0)
      try {
        const envsData = await getDeploymentEnvsPartitions();
        const partitions = [];
        for (const deplEnv of envsData) {
          const envPartitions = deplEnv.partitions.map(partition => ({
            ...partition,
            environment: deplEnv.name,
          }));
          partitions.push(...envPartitions);
        }

        namespaces = namespaceLists?.map(data =>
          partitions?.find(partition => partition.resource_id === data)
        );
      } catch (error) {
        console.error(error);
        if (
          error?.response?.status === 403 &&
          error?.response?.statusText === 'Forbidden'
        ) {
          partitionPermission = false;
        }
      }

    return {
      ...response.data,
      appData,
      serviceData,
      partitionData,
      namespaces,
      partitionPermission,
      servicePermission,
    };
  } catch (error) {
    throw error;
  }
}

//Update an existing policy that MCNM oversees
export async function updatePolicy(policy_id, data) {
  try {
    const response = await axiosInstance({
      method: 'PATCH',
      url: `${MCNM_APP_API_URL}/policy/${policy_id}`,
      data: data,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
