import moment from 'moment';
import 'moment/locale/fr';

const setLocale = () => {
  if (navigator.language.startsWith('fr')) {
    moment.locale(navigator.language);
  } else {
    moment.locale('en')
  }
}

const getTableFormattedDate = utcTimestamp => {
  setLocale();
  const localeData = moment.localeData(moment.locale());
  const llll = localeData.longDateFormat('LLLL');
  const lll = localeData.longDateFormat('LLL');
  const ll = localeData.longDateFormat('LL');

  return moment(utcTimestamp.split('[UTC]')[0]).format(
    llll.replace(lll.replace(ll, ''), '')
  );
};

const getUserFriendlyDate = (utcTimestamp, dateTimeFormat = false) => {
  if (!utcTimestamp) {
    return '';
  }
  setLocale();
  if (dateTimeFormat) {
    const parsedMoment = moment(utcTimestamp.split('[UTC]')[0]);
    return parsedMoment.format('MMM D, YYYY h:mm a');
  }

  const parsedMoment = moment(utcTimestamp.split('[UTC]')[0]);
  const currentMoment = moment(Date.now());
  const hourDifference = currentMoment.diff(parsedMoment, 'hours');

  if (hourDifference < 48) {
    let timeMoment = parsedMoment.fromNow();

    if (timeMoment.charAt(0).match(/[a-z]/i)) {
      return timeMoment.charAt(0).toUpperCase() + timeMoment.slice(1);
    } else {
      return timeMoment;
    }
  } else {
    if (navigator.language === 'fr-FR')
      return parsedMoment.format('Do MMM YYYY');
    return parsedMoment.format('MMM Do, YYYY');
  }
};

const isDate = date => {
  const checkValidDate = moment(date, 'DD-MM-YYYY', true);
  return checkValidDate.isValid() && !isNaN(new Date(date));
};

const getHoursFromTimeStamp = timestamp => {
  setLocale();
  const timestampDate = new Date(timestamp);
  const hour = timestampDate.getHours();
  let hr = `${hour}a`;
  if (hour === 12) {
    hr = `${hour}p`;
  }
  if (hour > 12) {
    hr = `${hour - 12}p`;
  }
  return hr;
};

const getCurrentTimeZone = () => {
  // Get a specific point in time (here, the current date/time):
  const date = new Date();

  // Get a DateTimeFormat object for the user's current culture (via undefined)
  // Ask specifically for the long-form of the time zone name in the options
  const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: 'long' });

  // Format the date to parts, and pull out the value of the time zone name
  const timezone = dtf
    ?.formatToParts(date)
    ?.find(part => part.type === 'timeZoneName')?.value;

  const shortenTimezone = timezone
    ?.split(' ')
    .map(val => val.charAt(0))
    ?.join('');

  return shortenTimezone ?? '';
};

const getTimeWithDate = (timestamp, utc) => {
  setLocale();
  const utcDateTime = moment.utc(timestamp);
  const localDateTime = moment(timestamp).local();

  // Get the browser's preferred language
  const browserLocale = window.navigator.language || 'en';

  moment.locale(browserLocale);
  
  let timeFormat;
  let onString = 'on'
  if (browserLocale.startsWith('en')) {
    timeFormat = 'h:mm A'; // AM/PM format for English
  } else {
    onString = 'sur'
    timeFormat = 'HH:mm'; // 24-hour format for all other languages
  }

  // Format the dates
  const formattedDate = utcDateTime.format(
    `${timeFormat} ${[utc]} MMM DD, YYYY`
  );
  
  const formattedLocalDate = localDateTime.format(`${timeFormat} ${onString} dddd, MMM DD YYYY`);

  return `${formattedLocalDate}`;
};

const getShortDate = timestamp => {
  setLocale();

  const parsedMoment = moment(timestamp);
  return parsedMoment.format('ll');
};

export default {
  getShortDate,
  getTimeWithDate,
  getTableFormattedDate,
  getUserFriendlyDate,
  isDate,
  getHoursFromTimeStamp,
  getCurrentTimeZone,
};
