import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import {
  Grid,
  Row,
  Column,
  SkeletonPlaceholder,
  ComboBox,
} from 'carbon-components-react';

import {
  ConnectionSearchItem,
  ConnectionSelectedType,
  Item,
  getConnectionConfig,
  PolicyOptionFromTypes,
  ConnectionOptionTypes,
} from '../config';
import { ConnectionSelectedTypes, PolicyDataType } from '../../../../lib/enums';

import SelectedFromToCard from './SelectedFromToCard/SelectedFromToCard';

import './PolicyAllowConnections.scss';
import { NetworkSegment } from '../../../../models/master';

interface Props {
  formValid: boolean;
  selected: ConnectionSelectedType;
  fromItems: Item[];
  selectedFromItems: Item[];
  toItems: Item[];
  selectedToItems: Item[];
  onViewAllOptions: (type: ConnectionSelectedType) => void;
  onRemoveOptions: (data: Item, type: ConnectionSelectedType) => void;
  onAddOptions: (data: Item, dataType: ConnectionOptionTypes) => void;
  onSelect: (type: ConnectionSelectedType) => void;
  onChange: (e: { target: { name: string; value: any } }) => void;
  isNwSegmentListLoading: boolean;
  selectedNetworkSegment: NetworkSegment | null;
  networkSegmentList: NetworkSegment[];
  selectedFromType: PolicyOptionFromTypes;
}

const PolicyAllowConnections: React.FC<Props> = ({
  selected,
  fromItems,
  selectedFromItems,
  toItems,
  selectedToItems,
  onViewAllOptions,
  onRemoveOptions,
  onAddOptions,
  onSelect,
  isNwSegmentListLoading,
  onChange,
  selectedNetworkSegment,
  networkSegmentList,
  selectedFromType,
  formValid,
}) => {
  const [suggestionOptions, setSuggestionOptions] = useState<
    ConnectionSearchItem[]
  >([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(false);

  const { t } = useTranslation('createPolicyAllowConnections');

  const connectionOptions = getConnectionConfig(selected, t);

  const handleSelect = (type: ConnectionSelectedType) => {
    onSelect(type);
  };

  const handleRemove = (data: Item, type: ConnectionSelectedType) => {
    if (selected !== type) handleSelect(type);
    onRemoveOptions(data, type);
  };

  const getSuggestionOptions = (connectionOptions: any) => {
    const ConnectionSearchItem: ConnectionSearchItem[] = [];
    let optionValues =
      selected === ConnectionSelectedTypes.FROM
        ? [
            ...fromItems,
            {
              id: selectedNetworkSegment?.resource_id ?? '',
              label: selectedNetworkSegment?.name ?? '',
              type: PolicyDataType.NETWORKSEGMENT,
            },
          ]
        : toItems;
    if (Array.isArray(connectionOptions)) {
      connectionOptions.forEach(connectionOption => {
        let matchedOptions: Item[] = [];
        optionValues.map(option => {
          if (option.type === connectionOption.name) {
            matchedOptions.push({
              ...option,
              label:
                option.type === PolicyDataType.NAMESPACE
                  ? `${option.parentName}/${option.label}`
                  : option.label,
            });
          }
        });

        ConnectionSearchItem.push({
          type: connectionOption.name,
          suggestionTitle: connectionOption.label,
          suggestionOptions: matchedOptions,
          icon: connectionOption.icon,
          suggestionDisplayKey: 'label',
          filterKey: 'label',
        });
      });
    }
    return ConnectionSearchItem;
  };

  const setSearchSuggestions = () => {
    const options: ConnectionSearchItem[] =
      getSuggestionOptions(connectionOptions);

    setSuggestionOptions(options);
  };

  useEffect(() => {
    const handleFocusChange = () => {
      if (document.activeElement === document.getElementById('search-1')) {
        setSearchClicked(true);
      } else {
        setSearchClicked(false);
      }
    };

    document.addEventListener('focusin', handleFocusChange);
    document.addEventListener('focusout', handleFocusChange);

    return () => {
      document.removeEventListener('focusin', handleFocusChange);
      document.removeEventListener('focusout', handleFocusChange);
    };
  });

  useEffect(() => {
    setSearchSuggestions();
  }, [selected, selectedNetworkSegment]);

  return (
    <React.Fragment>
      <CreateTearsheetStep
        title={t('header')}
        hasFieldset={false}
        className='policy-allow-connection'
        disableSubmit={!formValid}
        onNext={() => {}}
      >
        <Grid>
          <div className='policy-allow-connections-title'>{t('title')}</div>
          <div className='policy-allow-connections-subtitle'>
            {t('subtitle.subtitleStart')} <strong> {t('subtitle.from')}</strong>
            {t('subtitle.and')}
            <strong> {t('subtitle.to')}</strong> {t('subtitle.subtitleEnd')}
          </div>
          <Row className='policy-allowConnection-networkSegment'>
            <Column md={4} sm={4}>
              {isNwSegmentListLoading ? (
                <>
                  <div className='policy-allowConnection-dropdown'>
                    {t('networkSegment')}
                  </div>
                  <SkeletonPlaceholder className='policy-networkSegment-skelton' />
                </>
              ) : (
                <ComboBox
                  id='policyAllowConnectionNetworkSegment'
                  name='networkSegment'
                  className='policy-allowConnection-dropdown'
                  selectedItem={selectedNetworkSegment}
                  onChange={data =>
                    onChange({
                      target: {
                        name: 'networkSegment',
                        value: data.selectedItem,
                      },
                    })
                  }
                  translateWithId={t}
                  items={networkSegmentList ?? []}
                  itemToString={item =>
                    item ? `${item.name} (${t(item?.compatibility_set)})` : ''
                  }
                  titleText={t('networkSegment')}
                  placeholder={t('networkSegmentPlaceholder')}
                  data-testid='policy-allowConnection-networkSegment-dropdown'
                />
              )}
            </Column>
          </Row>
          <Row>
            <Column className='policy-allowConnection-fromToContainer'>
              <div
                className={
                  'from-container' +
                  (selected === ConnectionSelectedTypes.FROM &&
                  selectedNetworkSegment?.service_sync === false
                    ? ' selected'
                    : '') +
                  (searchClicked ||
                  buttonHovered ||
                  selectedNetworkSegment === null ||
                  selectedNetworkSegment?.service_sync === true
                    ? ' dontAllowHoverBackground'
                    : ' allowHoverBackground')
                }
              >
                <SelectedFromToCard
                  heading={ConnectionSelectedTypes.FROM}
                  cardActive={selected === ConnectionSelectedTypes.FROM}
                  cardDisabled={selectedNetworkSegment === null}
                  selectedData={selectedFromItems}
                  onRemove={(data: Item) =>
                    handleRemove(data, ConnectionSelectedTypes.FROM)
                  }
                  onSelectCard={() =>
                    handleSelect(ConnectionSelectedTypes.FROM)
                  }
                  suggestionOptions={suggestionOptions as any}
                  actionButton={{
                    text: t('viewAllResources'),
                    actionButtonCallback: () => onViewAllOptions(selected),
                    onHover: (value: boolean) => setButtonHovered(value),
                  }}
                  dataType={selectedFromType}
                  onButtonHover={(value: boolean) => setButtonHovered(value)}
                  serviceSyncFlag={
                    selectedNetworkSegment?.service_sync === true
                  }
                  onAddItem={(data: Item, type: PolicyDataType) =>
                    onAddOptions(data, type)
                  }
                />
              </div>
              <div className='connection-line'></div>
              <div
                className={
                  'to-container' +
                  (selected === ConnectionSelectedTypes.TO ||
                  selectedNetworkSegment?.service_sync === true
                    ? ' selected'
                    : '') +
                  (searchClicked || buttonHovered
                    ? ' dontAllowHoverBackground'
                    : ' allowHoverBackground')
                }
              >
                <SelectedFromToCard
                  heading={ConnectionSelectedTypes.TO}
                  cardActive={selected === ConnectionSelectedTypes.TO}
                  cardDisabled={selectedNetworkSegment === null}
                  selectedData={selectedToItems}
                  onRemove={(data: Item) =>
                    handleRemove(data, ConnectionSelectedTypes.TO)
                  }
                  onSelectCard={() => handleSelect(ConnectionSelectedTypes.TO)}
                  suggestionOptions={suggestionOptions as any}
                  actionButton={{
                    text: 'View all services',
                    actionButtonCallback: () => onViewAllOptions(selected),
                    onHover: (value: boolean) => setButtonHovered(value),
                  }}
                  dataType={PolicyDataType.SERVICE}
                  onAddItem={(data: Item, type: ConnectionOptionTypes) =>
                    onAddOptions(data, type)
                  }
                />
              </div>
            </Column>
          </Row>
        </Grid>
      </CreateTearsheetStep>
    </React.Fragment>
  );
};

export default PolicyAllowConnections;
