import React from 'react';
import { useTranslation } from 'react-i18next';
import { InlineLoading } from 'carbon-components-react';
import { getProceduralActivityConfig } from './config';
import { ProceduralActivityType } from '../../models/master';

import './ProceduralActivity.scss';

interface Props {
  status: ProceduralActivityType | 'gw-not-deployed' | undefined;
}

const ProceduralActivity: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation('proceduralActivity');

  const config = getProceduralActivityConfig(
    t,
    status ? status : 'gw-not-deployed'
  );

  if (config) {
    if (config.loadingIcon) {
      return (
        <div className='procedural-activity-component'>
          <InlineLoading description={config.label ?? ''} />
        </div>
      );
    }

    const Icon = config.icon ?? null;

    return (
      <div className='procedural-activity-component'>
        {Icon && <span className='icon'>{Icon}</span>}
        <span className='label'>{config.label}</span>
      </div>
    );
  }

  return <></>;
};

export default ProceduralActivity;
