import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  Checkbox,
  RadioButton,
  RadioButtonGroup,
  Row,
  Tag,
} from 'carbon-components-react';

import { AppliedFilter, NetworkSegment } from '../../../../models/master';
import {
  Item,
  ConnectionSelectedType,
  getEmptyStateValue,
  getTableFilters,
  getTableHeaders,
  ConnectionSelectedItemsTypes,
  PolicyOptionFromTypes,
} from '../config';
import { ConnectionSelectedTypes, PolicyDataType } from '../../../../lib/enums';
import sortData from '../../../../lib/tableSort';

import WideTearsheet from '../../../../components/WideTearsheet/WideTearsheet';
import GenericTableWithFilters from '../../../../components/GenericTableWithFilters/GenericTableWithFilters';
import PolicyTargetField from '../../../../components/PolicyTargetField/PolicyTargetField';
import PolicyCard from '../PolicyAllowConnections/PolicyCard/PolicyCard';
import { EditPolicyPermissionMap } from '../../EditConnectionAccessPolicy/EditPolicy';

import './PolicyOptions.scss';
interface Props {
  open: boolean;
  availableData: Item[];
  direction: ConnectionSelectedType;
  selectedData: Item[];
  selectedFromType: PolicyDataType;
  onClose: (save: boolean) => void;
  onRefresh: (type: PolicyDataType) => void;
  permissionMap?: EditPolicyPermissionMap;
  selectedNetworkSegment: NetworkSegment | null;
  onChangeFromType: (value: PolicyOptionFromTypes) => void;
  onItemSelect: (checked: boolean, item: Item) => void;
}

const PolicyOptions: React.FC<Props> = ({
  open,
  availableData,
  direction,
  selectedData,
  selectedFromType,
  onRefresh,
  onClose,
  permissionMap,
  selectedNetworkSegment,
  onChangeFromType,
  onItemSelect,
}) => {
  const { t } = useTranslation('createPolicyOptions');
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const navigate = useNavigate();
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilter[]>([]);
  const [partitionVisibilityFlag, setPartitionVisibilityFlag] = useState('all');
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [filteredData, setFilteredData] = useState<Item[] | null>(null);

  const renderLabels = (labels: string[]) => {
    return (
      <div>
        {labels.map((label, index) => (
          <Tag className='label' key={index} type='green'>
            {label}
          </Tag>
        ))}
      </div>
    );
  };

  const renderCheckBox = (item: Item, checked: boolean, disabled: boolean) => {
    if (item.type === PolicyDataType.NAMESPACE) {
      return (
        <Checkbox
          id={'' + item.id}
          labelText=''
          hideLabel
          checked={checked}
          disabled={disabled}
          onChange={(value: boolean) => onItemSelect(value, item)}
        />
      );
    }
    return (
      <RadioButton
        id={item.id}
        labelText=''
        hideLabel
        disabled={disabled ? true : false}
        checked={checked}
        onClick={e =>
          onItemSelect((e.target as HTMLInputElement).checked, item)
        }
      />
    );
  };

  const isChecked = (id: string | number) => {
    const item = selectedData.find(item => item.id === id);
    return item ? true : false;
  };

  const getFormattedRows = (items: Item[]) => {
    if (items) {
      return items.map((item: any) => {
        const checked = isChecked(item.id);
        // Note: For now we need to prevent multi selection by disabling the checkboxes. We may later remove this.
        const disabled =
          direction === ConnectionSelectedTypes.FROM
            ? false
            : selectedData.length > 0 && !checked;

        return {
          ...item,
          name: (
            <PolicyTargetField
              label={
                item.type === PolicyDataType.NAMESPACE
                  ? (item as Item).parentName + '/' + (item as Item).label
                  : (item as Item).label
              }
              type={item.type}
            />
          ),
          typeName: t(item.type),
          checkbox: renderCheckBox(item, checked, disabled),
          labels: item.labels?.length ? renderLabels(item.labels) : '—',
          portCount: item.ports?.length ?? 0,
        };
      });
    }
    return [];
  };

  const getInlineFilterData = (
    type: string,
    items: ConnectionSelectedItemsTypes
  ) => {
    if (
      Array.isArray(items) &&
      type === 'namespace' &&
      partitionVisibilityFlag !== 'all'
    ) {
      let filteredData = (items as any)?.filter(
        (item: any) => item.cloud_name === partitionVisibilityFlag
      );
      return filteredData;
    }
    return items;
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const handleRefresh = (type: PolicyDataType) => {
    setAppliedFilters([]);
    setFilteredData([]);
    setSortKey('');
    setSortDirection('NONE');
    onRefresh(type);
  };

  const getFilterValues = (filter: any, data: any) => {
    if (filter.key === 'labels') {
      return [
        ...Array.from(
          new Set(
            data
              ?.map(
                (eachData: { [x: string]: any }) => eachData[filter.columnKey]
              )
              .flat()
          )
        ),
      ];
    } else if (filter.key === 'ports') {
      const values: string[] = [];
      data?.forEach((rec: any) => {
        rec?.ports?.forEach((port: any) => {
          values.push(port?.port_number);
        });
      });
      return [...Array.from(new Set(values))];
    } else {
      return [
        ...Array.from(
          new Set(
            data?.map(
              (eachData: { [x: string]: any }) => eachData[filter.columnKey]
            )
          )
        ),
      ];
    }
  };

  const getFilters = (
    data: Item[],
    type: PolicyDataType.NAMESPACE | PolicyDataType.SERVICE
  ) => {
    if (data && data.length > 0) {
      const filters = getTableFilters(type, t);
      return filters.map(
        (filter: {
          key: string;
          type: string;
          label: string;
          columnKey: string;
        }) => ({
          key: filter.key,
          type: filter.type,
          label: filter.label,
          mdWidth: 3,
          lgWidth: 4,
          values: getFilterValues(filter, data),
        })
      );
    } else {
      return [];
    }
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const show403Container = (direction: string) => {
    if (
      (direction === ConnectionSelectedTypes.FROM &&
        !permissionMap?.namespace) ||
      (direction === ConnectionSelectedTypes.TO && !permissionMap?.service)
    ) {
      return true;
    }
  };

  const getTable = () => {
    const dataType =
      direction === ConnectionSelectedTypes.FROM
        ? PolicyDataType.NAMESPACE
        : PolicyDataType.SERVICE;
    const headers = getTableHeaders(dataType, t);
    const filters = getFilters(
      filteredData ?? [],
      direction === ConnectionSelectedTypes.FROM
        ? PolicyDataType.NAMESPACE
        : PolicyDataType.SERVICE
    ) as any;
    const emptyState = getEmptyStateValue(
      direction === ConnectionSelectedTypes.FROM
        ? PolicyDataType.NAMESPACE
        : PolicyDataType.SERVICE,
      t,
      navigate
    );
    return (
      <GenericTableWithFilters
        id={
          direction === ConnectionSelectedTypes.FROM
            ? 'namespaces-table'
            : 'services-table'
        }
        rows={getFormattedRows(filteredData ?? [])}
        data={filteredData}
        headers={headers}
        isSortable={true}
        totalElementsCount={filteredData?.length ?? 0}
        fullData={availableData}
        onTableRefresh={() =>
          handleRefresh(
            direction === ConnectionSelectedTypes.FROM
              ? PolicyDataType.NAMESPACE
              : PolicyDataType.SERVICE
          )
        }
        filteredDataCallback={(data: Item[]) => {
          if (JSON.stringify(filteredData) !== JSON.stringify(data)) {
            setFilteredData(data);
            setPageNumber(1);
          }
        }}
        selectedFiltersVal={appliedFilters as any}
        setFilterApplied={(filter: AppliedFilter[]) =>
          setAppliedFilters(prev => [...prev, ...filter])
        }
        filters={filters}
        currentPageNumber={currentPageNumber}
        currentPageSize={currentPageSize}
        onPageChange={(pageData: any) => setPageChange(pageData)}
        emptyState={emptyState}
        sortRows={(
          data: { id: string; text: string },
          sortDirection: 'ASC' | 'DESC' | 'NONE'
        ) => handleTableSort(data, sortDirection)}
        render403Container={show403Container(direction)}
        visibilityFlag={partitionVisibilityFlag}
      />
    );
  };

  useEffect(() => {
    setFilteredData(availableData);
  }, [availableData]);

  return (
    <WideTearsheet
      className='policy-options-tearsheet'
      title={
        direction === ConnectionSelectedTypes.FROM
          ? t('from.header')
          : t('to.header')
      }
      description={
        direction === ConnectionSelectedTypes.FROM
          ? t('from.sublabel')
          : t('to.sublabel')
      }
      open={open}
      actions={[
        {
          kind: 'primary',
          label: t('save'),
          onClick: () => onClose(true),
          disabled: selectedData && selectedData.length === 0,
        },
        {
          kind: 'secondary',
          label: t('cancel'),
          onClick: () => {
            onClose(false);
          },
        },
      ]}
    >
      <div className='policy-options-container'>
        {direction === ConnectionSelectedTypes.FROM && (
          <div className='from'>
            <div className='nwsegment-options-container'>
              <div className='nwsegment-options-title'>
                {t('from.nwsegmentTitle')}
              </div>
              <div className='nwsegment-options-section'>
                <RadioButtonGroup
                  name='policy-option'
                  onChange={(val: any) => {
                    onChangeFromType(val);
                  }}
                  valueSelected={selectedFromType}
                >
                  <RadioButton
                    labelText={
                      <>
                        <div className='nwsegment-options-section-label'>
                          {t('from.specificNamespaceTitle')}
                        </div>
                        <div className='nwsegment-options-section-subLabel'>
                          {t('from.specificNamespaceSubTitle')}
                        </div>
                      </>
                    }
                    value='namespace'
                    id='policy-options-namespaces'
                  />

                  <RadioButton
                    labelText={
                      <>
                        <div className='nwsegment-options-section-label'>
                          {t('from.entireNwsegmentTitle')}
                        </div>
                        <div className='nwsegment-options-section-subLabel'>
                          {t('from.entireNamespaceSubTitle')}
                        </div>
                      </>
                    }
                    value='networkSegment'
                    id='policy-options-nwsegment'
                  />
                </RadioButtonGroup>
              </div>
            </div>
            {selectedFromType === PolicyDataType.NETWORKSEGMENT && (
              <div className='nwsegment-options-card'>
                <div className='nwsegment-options-card-heading'>
                  {t('networkAdded')}
                </div>
                <PolicyCard
                  heading={selectedNetworkSegment?.name ?? ''}
                  key={selectedNetworkSegment?.resource_id}
                  label={selectedNetworkSegment?.name ?? ''}
                  type={PolicyDataType.NETWORKSEGMENT}
                  onRemove={() => {}}
                />
              </div>
            )}
          </div>
        )}

        {(selectedFromType === PolicyDataType.NAMESPACE ||
          direction === ConnectionSelectedTypes.TO) && (
          <div className='table-container'>
            <div className='table-heading'>
              {direction === ConnectionSelectedTypes.TO
                ? t('table.services.heading')
                : t('table.partitions.heading')}
            </div>
            {getTable()}
          </div>
        )}
      </div>
    </WideTearsheet>
  );
};

export default PolicyOptions;
