import React, { useContext } from 'react';
import { ToastNotification } from 'carbon-components-react';
import { NotificationContext } from '../Context/NotificationProvider';

import './Notification.scss';

const Notification = () => {
  const notification = useContext(NotificationContext);

  return (
    <>
      {notification.notificationType === 'TOAST' && (
        <div className='toast-notification-container'>
          {Array.isArray(notification.details) &&
            notification.details.map(
              (
                {
                  kind = 'success',
                  subtitle,
                  title,
                  timeout = 5000,
                  lowContrast = true,
                  customClassName = '',
                },
                index
              ) => (
                <ToastNotification
                  key={index + title + subtitle}
                  kind={kind}
                  subtitle={subtitle}
                  title={title}
                  lowContrast={lowContrast}
                  onClose={() => notification.onClose(title, subtitle) as any}
                  timeout={timeout}
                  data-testid='toast-notification'
                  className={customClassName}
                />
              )
            )}
        </div>
      )}
    </>
  );
};

export default Notification;
