import { EdgeService16, Locked16 } from '@carbon/icons-react';
import images from '../../../../../images/images';

import { PolicyDataType } from '../../../../../lib/enums';

export type IconType =
  | PolicyDataType.SERVICE
  | PolicyDataType.NAMESPACE
  | PolicyDataType.NETWORKSEGMENT
  | 'notAuthorised';

const iconConfig = {
  service: EdgeService16,
  namespace: images.partitionPolicyCardIcon,
  networkSegment: images.nwsegmentPolicyCardIcon,
  notAuthorised: Locked16,
};

export const getIcon = (type: IconType) => iconConfig[type];
