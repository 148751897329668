import React from 'react';
import { Row, Column } from 'carbon-components-react';
import { ConnectionOptionTypes, ConnectionSearchItem, Item } from '../../config';

import AutofillSearch from '../../../../../components/AutofillSearch/AutofillSearch';

import './PolicyFilter.scss';

const PolicyAllowConnectionFilter = (props: {
  data: ConnectionSearchItem[];
  btnDisabled: boolean;
  onOptionAdd: (item: any, type: any) => void;
  placeholderText: string;
  selectedData?: Item[];
  handleSearchClick?: () => void;
}) => {
  const { data, btnDisabled, selectedData, placeholderText } = props;

  return (
    <Row className='policy-filter'>
      <Column className={'searchBar'}>
        <AutofillSearch
          suggestions={data && (data as any)}
          onClick={(i: any, t: string) => {
            props.onOptionAdd(i, t);
          }}
          handleInputClick={props.handleSearchClick}
          btnDisabled={btnDisabled}
          placeholderText={placeholderText}
          selectedData={selectedData}
        ></AutofillSearch>
      </Column>
    </Row>
  );
};
export default PolicyAllowConnectionFilter;
