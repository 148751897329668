import { TFunction } from 'react-i18next';
import images from '../../images/images';
import { ProceduralActivityType } from '../../models/master';

export const getProceduralActivityMessegeIconConfig = (
  t: TFunction<'gatewayOnboardingStatusDetails'>,
  status: ProceduralActivityType
) => {
  const config = {
    caching: {
      icon: images.loadingGatewayIcon(),
      button: null,
      status: t('gatewayOnboardingMessege.caching'),
      step: 1,
    },
    'waiting-for-closure': {
      icon: null,
      button: null,
      status: t('gatewayOnboardingMessege.waiting-for-closure'),
      step: 2,
    },
    'waiting-connection': {
      icon: null,
      button: t('connectCluster'),
      status: t('gatewayOnboardingMessege.waiting-connection'),
      step: 3,
    },
    'waiting-nseg-completion': {
      icon: null,
      button: t('ConnectNextGateway'),
      status: t('gatewayOnboardingMessege.waiting-nseg-completion'),
      step: 4,
    },
    'finalizing-onboarding': {
      icon: images.finalizingGatewayIcon(),
      button: null,
      status: t('gatewayOnboardingMessege.finalizing-onboarding'),
      step: 5,
    },
    'deploying-gw-agent': {
      icon: images.loadingGatewayIcon(),
      button: null,
      status: t('gatewayOnboardingMessege.caching'),
      step: 1,
    },
    'discover-gw': {
      icon: images.loadingGatewayIcon(),
      button: null,
      status: t('gatewayOnboardingMessege.caching'),
      step: 1,
    },
    deploying: {
      icon: images.loadingGatewayIcon(),
      button: null,
      status: t('gatewayOnboardingMessege.caching'),
      step: 1,
    },
    'connecting-gateways': {
      icon: images.loadingGatewayIcon(),
      button: null,
      status: t('gatewayOnboardingMessege.caching'),
      step: 1,
    },
    'remove-mesh-agent': {
      icon: images.loadingGatewayIcon(),
      button: null,
      status: t('gatewayOnboardingMessege.caching'),
      step: 1,
    },
    'undeploying-gw': {
      icon: images.loadingGatewayIcon(),
      button: null,
      status: t('gatewayOnboardingMessege.caching'),
      step: 1,
    },
  };

  return config[status];
};
