import react from 'react';
import {
  Search,
  Row,
  Column,
  Dropdown,
  Button,
  MultiSelect,
  OnChangeData,
  Tag,
} from 'carbon-components-react';
import { FilterableMultiSelect } from '@carbon/react';
import { Filter16, Renew16, Add16, Search16 } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import './FindAndFilterBar.scss';
import {
  ApplicationData,
  DeploymentEnvironment,
  PolicyData,
  AppliedFilter,
  ServiceData,
  ResourceGroup,
  EventData,
  IdentityData,
  Role,
  NetworkSegment,
  DeploymentEnvDetailsNameSpaceAndSecurityTableData,
  GatewayCustomData,
  ServiceFilter,
} from '../../models/master';
import IconWithToolTip from '../IconWithToolTip/IconWithToolTip';
import { useSearchParams } from 'react-router-dom';
import { getSessionStorageItem, setSessionStorageItem } from '../../lib/utils';
import { Connections, filterTypes } from '../../lib/enums';

interface SelectEvent {
  selectedItems: string[];
}

export interface Filter {
  key: string;
  type: 'multi' | 'single';
  values: any[];
  mdWidth?: number;
  lgWidth?: number;
  label?: string;
  hasIcon?: boolean;
  placeHolderText?: string;
  translationReqd?: boolean;
  translationData?: any;
  filterCallback?: (e: OnChangeData<string>) => void;
}

const FindAndFilterBar = (props: {
  data:
    | ApplicationData[]
    | DeploymentEnvironment[]
    | PolicyData[]
    | ResourceGroup[]
    | EventData[]
    | IdentityData[]
    | Role[]
    | NetworkSegment[]
    | DeploymentEnvDetailsNameSpaceAndSecurityTableData[]
    | GatewayCustomData[]
    | ServiceFilter[]
    | null;
  filteredData?:
    | ApplicationData[]
    | DeploymentEnvironment[]
    | PolicyData[]
    | ResourceGroup[]
    | EventData[]
    | IdentityData[]
    | Role[]
    | ServiceFilter[]
    | NetworkSegment[]
    | DeploymentEnvDetailsNameSpaceAndSecurityTableData[]
    | GatewayCustomData[]
    | null;
  filteredDataCallback: (
    data:
      | ApplicationData[]
      | DeploymentEnvironment[]
      | PolicyData[]
      | EventData[]
      | Role[]
      | NetworkSegment[]
      | GatewayCustomData[]
      | ServiceFilter[]
      | []
  ) => void;
  actionButton?:
    | {
        text: string;
        actionButtonCallback: () => void;
      }
    | undefined;
  filters: Filter[];
  leftInlineFilters?: Filter[];
  leftInlineMultiSelectFilter?: Filter[];
  onRefresh: () => void;
  filtersApplied: AppliedFilter[];
  filtersAppliedCallback: (data: AppliedFilter[]) => void;
  visibilityFlag?: string;
  showRefresh?: boolean;
  showSearch?: boolean;
  placeHolder?: string;
  customKey?: string;
  filterValue?: string;
  hasIcon?: boolean;
  persistFilter?: boolean;
  customSearch?: JSX.Element;
}) => {
  const { t } = useTranslation('findAndFilterBar');
  const {
    data,
    filteredData,
    filteredDataCallback,
    filters,
    onRefresh,
    filtersApplied,
    filtersAppliedCallback,
    leftInlineFilters,
    leftInlineMultiSelectFilter,
    actionButton,
    visibilityFlag,
    placeHolder,
    showSearch = true,
    showRefresh = true,
    customKey,
    filterValue,
    persistFilter,
    customSearch,
  } = props;

  const [showFilterView, SetShowFilterView] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [urlQueryKeysList, setUrlQueryKeysList] = useState<string[] | []>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showClearButton, setShowClearButton] = useState(true);

  const [placeholderText, setPlaceholderText] = useState<string>('');
  const [showPlaceholderText, setShowPlaceholderText] = useState<boolean>(true);

  const handleMenuChange = (open: boolean) => {
    setShowPlaceholderText(!open);
  };

  const networkSegmentKeys: any = {
    applicationNetworkSegment: 'applicationNetworkSegment',
    namespaceNetworkSegment: 'namespaceNetworkSegment',
    policyNetworkSegment: 'policyNetworkSegment',
    gatewayNetworkSegment: 'gatewayNetworkSegment',
  };

  useEffect(() => {
    if (Array.isArray(filtersApplied) && filtersApplied.length > 0) {
      filterData(filtersApplied);
      setSearchInputVal();
      setUrlQueryKeysList([]);
    }
    setParamsFromURl();
    setPlaceholderText(
      `${t('networkSegments')} (${
        leftInlineMultiSelectFilter?.[0].placeHolderText
      })`
    );
    if (Array.isArray(filtersApplied) && filtersApplied.length > 1) {
      setShowClearButton(true);
    }
  }, [filtersApplied]);

  const setParamsFromURl = () => {
    const filtersList = [];
    const keysInUrlQuery = [];
    setUrlQueryKeysList([]);
    filters.forEach((eachFilter: Filter) => {
      if (searchParams?.has(eachFilter.key)) {
        const selectedValues = searchParams.getAll(eachFilter.key);
        let values = [...selectedValues];
        keysInUrlQuery.push(eachFilter.key);
        if (eachFilter?.translationReqd && values)
          values = values.map(
            value =>
              Object.keys(eachFilter?.translationData).find(
                key => eachFilter?.translationData[key] === value
              ) ?? ''
          );
        if (
          eachFilter.type === 'single' &&
          typeof eachFilter.values[0] === 'object'
        ) {
          const selectedFilter = eachFilter.values.filter(filter =>
            filter.value.includes(values[0])
          );
          if (selectedFilter.length > 0)
            filtersList.push({
              event: { selectedItem: selectedFilter[0] },
              key: eachFilter.key,
              type: 'single',
            });
        } else if (
          eachFilter.type === 'single' &&
          eachFilter.values.includes(values[0])
        ) {
          filtersList.push({
            event: { selectedItem: values[0] },
            key: eachFilter.key,
            type: 'single',
          });
        } else if (eachFilter.type === 'multi') {
          const multiSelectValue = values.filter(value =>
            eachFilter.values.includes(value)
          );
          if (Array.isArray(multiSelectValue) && multiSelectValue.length > 0)
            filtersList.push({
              event: { selectedItems: multiSelectValue },
              key: eachFilter.key,
              type: 'multi',
            });
        }
      }
    });
    if (searchParams?.has(customKey || 'name')) {
      const selectedValues = searchParams.get(customKey || 'name');
      if (customKey) keysInUrlQuery.push(customKey);
      else keysInUrlQuery.push('name');
      filtersList.push({
        event: { target: { value: selectedValues } },
        key: customKey || 'name',
        type: 'single',
      });
      if (persistFilter)
        setSessionStorageItem('MCNM_SEARCH_FILTER_VALUE', selectedValues ?? '');
      setSearchValue(selectedValues ?? '');
    }
    setUrlQueryKeysList(keysInUrlQuery);
    if (keysInUrlQuery.length > 0) {
      filtersAppliedCallback(filtersList);
      if (persistFilter)
        setSessionStorageItem(
          'MCNM_FILTERS_APPLIED',
          JSON.stringify(filtersList)
        );
      filterData(filtersList);
      setSearchParams('');
    }
  };

  const setSearchInputVal = () => {
    const searchValueFromStorage = getSessionStorageItem(
      'MCNM_SEARCH_FILTER_VALUE'
    );
    if (searchValueFromStorage) {
      setSearchValue(searchValueFromStorage);
    } else {
      filtersApplied.forEach(filter => {
        if (filter.key === (customKey || 'name') && filter.type === 'single') {
          setSearchValue(filter.event.target.value ?? '');
        }
      });
    }
  };

  const getInlineFilterValue = (items: any) => {
    const item = items.find(
      (item: any) =>
        item?.value?.toLocaleLowerCase() === visibilityFlag?.toLocaleLowerCase()
    );
    return item;
  };

  const filterDataFromInputSearch = (
    e: react.ChangeEvent<HTMLInputElement>,
    dataSet:
      | ApplicationData[]
      | DeploymentEnvironment[]
      | PolicyData[]
      | EventData[]
      | IdentityData[]
      | null
  ) => {
    let filteredTableData = [] as any;
    // const dataSet = filteredData || data;
    // e.target.value &&
    // e.target.value.toLowerCase().includes(previousSearchValue?.toLowerCase())
    //   ? filteredData
    //   : data;
    // setPreviousSearchValue(e.target.value);
    if (Array.isArray(dataSet) && e.target.value && e.target.value !== '') {
      dataSet.forEach(item => {
        let value =
          item?.name ||
          (item as EventData)?.message +
            (item as EventData)?.severity +
            (item as EventData)?.resource_name +
            (item as EventData)?.assigned_user;

        if (
          value &&
          value?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
          filteredTableData.push(item);
      });
    } else if (e.target?.value && e.target?.value !== '')
      filteredTableData = dataSet;
    return filteredTableData;
  };

  const filterData = (filtersApplied: AppliedFilter[]) => {
    let filteredTableData = [] as any;
    let currentData = data as any;
    filtersApplied.map(filter => {
      if (filter.type === 'multi') {
        filteredTableData = handleMultiSelect(
          filter.event,
          filter.key,
          currentData
        );
      } else {
        filteredTableData = handleSingleSelect(
          filter.event,
          filter.key,
          currentData
        );
      }
      if (filter.key === (customKey || 'name') && filter.type === 'single')
        filteredTableData = filterDataFromInputSearch(
          filter.event,
          currentData
        );
      currentData = filteredTableData;
      if (filter.key === (customKey || 'name')) {
        setShowClearButton(false);
      }
      return null;
    });
    filteredDataCallback(filteredTableData);
  };

  const actionButtonItem = () => {
    return (
      <Button
        className='action-button'
        kind='ghost'
        onClick={() => {
          actionButton?.actionButtonCallback();
        }}
        data-testid='find-and-filter-action-button'
      >
        {actionButton?.text}
        <Add16 className='action-button-icon' />
      </Button>
    );
  };

  const clearSearchParams = () => {
    urlQueryKeysList.map((key: string) => {
      searchParams.delete(key);
      return '';
    });
    setUrlQueryKeysList([]);
    setSearchParams(searchParams);
  };

  const ApplyFilter = (e: any, key: string, type: string) => {
    clearSearchParams();
    let currentFilter = filtersApplied.filter(item => item?.key !== key);
    let filterObj = {
      event: e,
      key: key,
      type: type,
    };
    if (
      (type === 'single' &&
        key !== (customKey || 'name') &&
        e.selectedItem !== '') ||
      (type === 'single' &&
        key === (customKey || 'name') &&
        e.target.value &&
        e.target.value !== '') ||
      (type === 'multi' && e.selectedItems.length > 0)
    ) {
      currentFilter.push(filterObj);
    }
    if (key === (customKey || 'name') && type === 'single') {
      persistFilter &&
        setSessionStorageItem('MCNM_SEARCH_FILTER_VALUE', e.target.value);
      setSearchValue(e.target.value ?? '');
      if (key === (customKey || 'name')) {
        setShowClearButton(false);
      }
    }

    filtersAppliedCallback(currentFilter);
    filterData(currentFilter);

    if (persistFilter) {
      // resolve circular dependency and save applied filters in local storage
      const currentFilterApplied = resolveCircularDependency(currentFilter);
      setSessionStorageItem(
        'MCNM_FILTERS_APPLIED',
        JSON.stringify(currentFilterApplied)
      );
    }
  };

  const resolveCircularDependency = (currentFilter: any[]) => {
    const filterArray: any[] = [];
    currentFilter?.forEach(el => {
      if (el?.key === (customKey || 'name') && el?.type === 'single') {
        filterArray.push({
          event: {
            selectedItem: el?.event?.target?.value,
            target: {
              value: el?.event?.target?.value,
            },
          },
          key: el?.key,
          type: el?.type,
        });
      } else {
        filterArray.push(el);
      }
    });
    return filterArray;
  };

  const handleMultiSelect = (
    e: SelectEvent,
    key: string,
    data:
      | ApplicationData[]
      | DeploymentEnvironment[]
      | PolicyData[]
      | EventData[]
      | IdentityData[]
      | null
      | ServiceData[]
      | GatewayCustomData[]
  ) => {
    let filteredTableData = [] as any;
    if (
      Array.isArray(data) &&
      Array.isArray(e.selectedItems) &&
      e.selectedItems.length > 0
    ) {
      data.forEach(item => {
        if (key === filterTypes.LABELS || key === filterTypes.ALLLABELS) {
          if (
            e.selectedItems.some(
              item1 =>
                item?.labels?.includes(item1) ||
                (item as DeploymentEnvironment)?.discovered_labels?.includes(
                  item1
                )
            )
          )
            filteredTableData.push(item);
          setShowClearButton(true);
        } else if (key === filterTypes.LOCATION) {
          if (
            e.selectedItems.some(
              item1 =>
                (item as DeploymentEnvironment)?.location_name.trim() ===
                item1.trim()
            )
          )
            filteredTableData.push(item);
          setShowClearButton(true);
        } else if (key === filterTypes.PORTS) {
          if (
            e.selectedItems.some(item1 => {
              const ports: string[] = [];
              (item as ServiceData)?.ports.forEach((port: any) => {
                ports.push(port?.port_number);
              });
              return ports.includes(item1);
            })
          ) {
            filteredTableData.push(item);
            setShowClearButton(true);
          }
        } else if (
          e.selectedItems.some(item1 => (item as any)[key]?.includes(item1))
        ) {
          filteredTableData.push(item);
          setShowClearButton(true);
        } else if (key === networkSegmentKeys.applicationNetworkSegment) {
          if (
            e.selectedItems.some(
              (item1: any) =>
                (item as ApplicationData)?.network_segment_name?.trim() ===
                item1.value.trim()
            )
          )
            filteredTableData.push(item);
          setShowClearButton(false);
        } else if (key === networkSegmentKeys.namespaceNetworkSegment) {
          if (
            e.selectedItems.some((item1: any) =>
              (item as any)?.network_segment_name?.includes(item1.value)
            )
          )
            filteredTableData.push(item);
          setShowClearButton(false);
        } else if (key === networkSegmentKeys.policyNetworkSegment) {
          if (
            e.selectedItems.some((item1: any) =>
              (item as PolicyData)?.network_segment_name?.includes(item1.value)
            )
          )
            filteredTableData.push(item);
          setShowClearButton(false);
        } else if (key === networkSegmentKeys.gatewayNetworkSegment) {
          if (
            e.selectedItems.some(
              (item1: any) =>
                (item as GatewayCustomData)?.network_segment_name?.trim() ===
                item1.value.trim()
            )
          )
            filteredTableData.push(item);
          setShowClearButton(false);
        }
      });
      return filteredTableData;
    } else return [];
  };

  const handleSingleSelect = (
    e: OnChangeData<any>,
    key: string,
    // hasIcon: boolean,
    data:
      | ApplicationData[]
      | DeploymentEnvironment[]
      | PolicyData[]
      | EventData[]
      | IdentityData[]
      | ServiceFilter[]
      | null
  ) => {
    let filteredTableData = [] as any;
    if (
      Array.isArray(data) &&
      e.selectedItem !== '' &&
      e.selectedItem?.label !== ''
    ) {
      data.forEach(item => {
        if (key === 'labels') {
          if (item?.labels?.includes(e.selectedItem as string))
            filteredTableData.push(item);
          setShowClearButton(true);
        } else if (key === 'applicationName') {
          if (
            (item as ServiceFilter)?.applicationName !== undefined &&
            (item as ServiceFilter)?.applicationName !== '' &&
            (item as ServiceFilter)?.applicationName.trim() ===
              (e.selectedItem as string).trim()
          )
            filteredTableData.push(item);
        } else if (key === Connections.FROM) {
          if (
            (item as PolicyData).from_app_name !== undefined &&
            (item as PolicyData).from_app_name !== null
          ) {
            if (typeof (item as PolicyData).from_app_name === 'string') {
              if (
                ((item as PolicyData).from_app_name as string).trim() ===
                (e.selectedItem as string).trim()
              ) {
                filteredTableData.push(item);
              }
            } else if (Array.isArray((item as PolicyData).from_app_name)) {
              const fromList = (item as PolicyData).from_app_name as string[];
              const matchingItems = fromList?.filter(
                (name: any) =>
                  typeof name === 'string' &&
                  typeof name === 'string' &&
                  name.trim() === (e.selectedItem as string).trim()
              );
              if (matchingItems.length > 0) {
                filteredTableData.push(item);
              }
            }
          }
          setShowClearButton(true);
        } else if (key === Connections.TO) {
          if (
            (item as PolicyData)?.to_svc_name !== undefined &&
            (item as PolicyData)?.to_svc_name.trim() ===
              (e.selectedItem as string).trim()
          )
            filteredTableData.push(item);
          setShowClearButton(true);
        } else if (key === 'cloud') {
          if (
            (item as DeploymentEnvironment)?.cloud_name.trim() ===
            (e.selectedItem as string).trim()
          )
            filteredTableData.push(item);
          setShowClearButton(true);
        } else if (key === 'resource_type') {
          if (
            (item as EventData)?.resource_type.includes(
              e.selectedItem?.label as string
            )
          )
            filteredTableData.push(item);
          setShowClearButton(true);
        } else if (key === 'applicationGroup') {
          if (
            (item as ApplicationData)?.resource_group_name?.trim() ===
            (e.selectedItem as string).trim()
          )
            filteredTableData.push(item);
          setShowClearButton(true);
        } else if (key === 'infrastructureGroup') {
          if (
            (item as DeploymentEnvironment)?.resourceGroup
              ?.toLocaleLowerCase()
              .includes(
                (e.selectedItem?.toLocaleLowerCase() as string).replace(
                  /\s+/,
                  ''
                )
              )
          )
            filteredTableData.push(item);
          setShowClearButton(true);
        } else if (key === 'applicationGroupPolicy') {
          if (
            (item as PolicyData)?.resource_group_name?.trim() ===
            (e.selectedItem as string).trim()
          )
            filteredTableData.push(item);
          setShowClearButton(true);
        } else if (key === 'type') {
          if (
            (item as DeploymentEnvironment)?.type
              ?.toLocaleLowerCase()
              .includes(
                (typeof e.selectedItem === 'object'
                  ? e.selectedItem?.value?.toLocaleLowerCase()
                  : e.selectedItem?.toLocaleLowerCase()) as string
              )
          )
            filteredTableData.push(item);
          setShowClearButton(true);
        } else if (key === 'view') {
          if (e.selectedItem === 'all') {
            filteredTableData.push(item);
          } else {
            // const val = e.selectedItem === 'Unmanaged' ? 'No' : 'Yes';
            // if ((item as DeploymentEnvironment)?.managed.includes(val))
            //   filteredTableData.push(item);

            const val = e.selectedItem === 'Unmanaged' ? true : false;

            if ((item as DeploymentEnvironment)?.unmanaged === val)
              filteredTableData.push(item);
            setShowClearButton(true);
          }
        }
        // check added to make events status filter work irrespective of translations
        else if (
          key === 'event_status' &&
          (item as any)[key]?.includes(e?.selectedItem?.label)
        ) {
          filteredTableData.push(item);
          setShowClearButton(true);
        } else if (key === 'intended_version') {
          if ((item as any)[key] === e?.selectedItem)
            filteredTableData.push(item);
        } else if ((item as any)[key]?.includes(e?.selectedItem))
          filteredTableData.push(item);
        else if ((item as any)[key]?.includes(e?.selectedItem?.label))
          filteredTableData.push(item);
        setShowClearButton(true);
      });
      return filteredTableData;
    } else return [];
  };

  const clearFilter = () => {
    SetShowFilterView(false);
    setSearchValue('');
    filtersAppliedCallback([]);
    filteredDataCallback([]);
    clearSearchParams();
  };

  const filterTitleText: any = (filter: Filter) => {
    return filter.label;
  };

  const filterDropdownLabelText = (label: string | undefined) => {
    if (label != null && label.length > 0) {
      let spaceIndex = label.indexOf(' ');
      return spaceIndex === -1
        ? label[0].toLowerCase() + label.slice(1)
        : label.substring(0, spaceIndex)[0].toLowerCase() + label.slice(1);
    }
    return '';
  };

  const getSelectedItems = (filter: Filter) => {
    const selectedValues = filtersApplied.filter(
      eachFilter => eachFilter.key === filter.key
    );

    return selectedValues;
  };

  const toggleFilterView = () => {
    showFilterView ? SetShowFilterView(false) : SetShowFilterView(true);
  };

  const handleTagClose = (filter: AppliedFilter, value: string) => {
    let currentFilter: AppliedFilter[];
    clearSearchParams();
    if (filter.type === 'single') {
      currentFilter = filtersApplied.filter(item => item.key !== filter.key);
      if (currentFilter.length === 0) {
        filtersAppliedCallback([]);
        filteredDataCallback([]);
        setSessionStorageItem('MCNM_FILTERS_APPLIED', '');
      } else {
        if (persistFilter) {
          // resolve circular dependency
          const currentFilterApplied = resolveCircularDependency(currentFilter);
          setSessionStorageItem(
            'MCNM_FILTERS_APPLIED',
            JSON.stringify(currentFilterApplied)
          );
        }
        setShowClearButton(true);
        filtersAppliedCallback(currentFilter);
        filterData(currentFilter);
      }
    } else {
      let index = filtersApplied.findIndex(item => item.key === filter.key);
      if (index !== -1) {
        currentFilter = filtersApplied.slice();
        currentFilter[index].event.selectedItems = filtersApplied[
          index
        ].event.selectedItems.filter((item: string) => item !== value);
        if (currentFilter[index].event.selectedItems.length === 0) {
          currentFilter.splice(index, 1);
        }
        if (currentFilter.length === 0) {
          filtersAppliedCallback([]);
          filteredDataCallback([]);
          setSessionStorageItem('MCNM_FILTERS_APPLIED', '');
        } else {
          if (persistFilter) {
            // resolve circular dependency
            const currentFilterApplied =
              resolveCircularDependency(currentFilter);
            setSessionStorageItem(
              'MCNM_FILTERS_APPLIED',
              JSON.stringify(currentFilterApplied)
            );
          }
          filtersAppliedCallback(currentFilter);
          filterData(currentFilter);
        }
      }
    }
  };

  const showFilterTags = () => {
    let tags: any = [],
      filterLabel: Filter[] | undefined = [],
      label: string | undefined = '',
      hasIcon: boolean | undefined,
      translationReqd: boolean | undefined,
      translationData: any;

    if (Array.isArray(filtersApplied) && filtersApplied.length > 0) {
      filtersApplied.forEach((filter: any, index: number) => {
        filterLabel = filters.filter(
          filterItem =>
            filterItem.key === filter.key && filterItem.type === filter.type
        );

        if (filterLabel != null && filterLabel.length > 0) {
          label = filterLabel[0]?.label;
          hasIcon = filterLabel[0].hasIcon;
          translationReqd = filterLabel[0]?.translationReqd;
          translationData = filterLabel[0]?.translationData;

          switch (filter.type) {
            case 'single':
              tags.push(
                <Tag
                  filter={true}
                  size='md'
                  onClose={() =>
                    handleTagClose(filter, filter.event.selectedItem)
                  }
                  data-testid={`filter-selected-tag-${index}`}
                >
                  {label != null && label.length > 0 ? (
                    hasIcon ? (
                      <>
                        <span className='filter-tag-label'>{`${label}:`}</span>{' '}
                        {translationReqd && translationData ? (
                          <span className='filter-tag-label'>
                            {translationData[
                              filter?.event?.selectedItem?.label
                            ] ?? filter?.event?.selectedItem?.label}{' '}
                          </span>
                        ) : (
                          <span className='filter-tag-label'>
                            {filter?.event?.selectedItem?.label}
                          </span>
                        )}
                      </>
                    ) : (
                      `${label}: ${
                        typeof filter?.event?.selectedItem === 'object'
                          ? filter.event.selectedItem.label
                          : translationReqd && translationData
                          ? translationData[filter?.event?.selectedItem] ??
                            filter?.event?.selectedItem
                          : filter?.event?.selectedItem
                      }`
                    )
                  ) : null}
                </Tag>
              );
              break;

            case 'multi':
              filter.event.selectedItems.forEach(
                (tag: string, selIndx: number) =>
                  tags.push(
                    <Tag
                      filter={true}
                      size='md'
                      onClose={() => handleTagClose(filter, tag)}
                      data-testid={`filter-selected-tag-${index}${selIndx}`}
                    >
                      {label != null &&
                        label.length > 0 &&
                        `${label}: ${
                          translationReqd && translationData
                            ? translationData[tag] ?? tag
                            : tag
                        }`}
                    </Tag>
                  )
              );
              break;

            default:
              break;
          }
        }
      });
    }

    return tags;
  };

  const isSelected = (filter: Filter) => getSelectedItems(filter)?.length > 0;

  const checkFilterApplied = () => {
    let name: string = '';

    if (Array.isArray(filtersApplied)) {
      if (
        filtersApplied.filter(
          filter => filter.key !== 'name' && filter.key !== customKey
        ).length > 0
      ) {
        name = 'filter-buttons-container filter-selected';
      } else {
        name = 'filter-buttons-container';
      }
    }

    return name;
  };

  const clearButtonClick = () => {
    const nwSegmentKeyFilters = filtersApplied.filter(
      item => networkSegmentKeys[item.key]
    );
    if (nwSegmentKeyFilters.length) {
      if (persistFilter) {
        // resolve circular dependency
        const currentFilterApplied =
          resolveCircularDependency(nwSegmentKeyFilters);
        setSessionStorageItem(
          'MCNM_FILTERS_APPLIED',
          JSON.stringify(currentFilterApplied)
        );
      }
      setShowClearButton(false);
      filtersAppliedCallback(nwSegmentKeyFilters);
      filterData(nwSegmentKeyFilters);
    } else {
      clearSearchParams();
      setSearchValue('');
      filtersAppliedCallback([]);
      filteredDataCallback([]);
      setSessionStorageItem('MCNM_FILTERS_APPLIED', '');
      setSessionStorageItem('MCNM_SEARCH_FILTER_VALUE', '');
    }
  };

  const showClearTagsButton = () => {
    if (Array.isArray(filtersApplied)) {
      let modifiedfiltersApplied = filtersApplied.filter(
        filter =>
          filter.key !== 'name' &&
          filter.key !== customKey &&
          !networkSegmentKeys[filter.key]
      );
      let clearButtonText = '';

      clearButtonText =
        modifiedfiltersApplied.filter(
          filter => filter.type === 'single' || filter.type === 'multi'
        ).length > 1
          ? t('clearAll')
          : modifiedfiltersApplied.length === 1 &&
            modifiedfiltersApplied.filter(
              filter =>
                filter.type === 'single' ||
                (filter.type === 'multi' &&
                  filter.event.selectedItems.length === 1)
            ).length === 1
          ? t('clear')
          : modifiedfiltersApplied.length === 1 &&
            modifiedfiltersApplied.filter(
              filter =>
                filter.type === 'multi' && filter.event.selectedItems.length > 1
            ).length === 1
          ? t('clearAll')
          : '';

      return (
        <span
          className='clearButton'
          onClick={() => clearButtonClick()}
          data-testid='find-filter-clear-btn'
        >
          {clearButtonText}
        </span>
      );
    }
  };

  const filterableLabel = (filter: Filter, filtersApplied: AppliedFilter[]) => {
    return (
      <FilterableMultiSelect
        id='default-label'
        titleText={filterTitleText(filter)}
        items={filter?.values.length > 0 ? filter?.values : []}
        translateWithId={t}
        itemToString={(item: any) =>
          filter?.translationReqd && filter?.translationData
            ? filter?.translationData[item] ?? item
            : item
        }
        useTitleInItem={true}
        placeholder={
          filter?.placeHolderText
            ? filter?.placeHolderText
            : t('filter') + ' ' + filterDropdownLabelText(filter?.label)
        }
        initialSelectedItems={
          filtersApplied?.length > 0
            ? isSelected(filter)
              ? getSelectedItems(filter)[0]?.event?.selectedItems
              : []
            : filtersApplied
        }
        selectionFeedback={'top-after-reopen'}
        onChange={(e: any) => ApplyFilter(e, filter.key, 'multi')}
        size='md'
      />
    );
  };

  return (
    <div
      className={'find-and-filter-container'}
      data-testid='find-and-filter-container'
    >
      <Row>
        {leftInlineFilters ? (
          <div
            className='leftFiltersContainer'
            data-testid='left-inline-filter'
          >
            {Array.isArray(leftInlineFilters) &&
              leftInlineFilters.map((filter, index) => (
                <div className='dropdown' key={`leftfilter${index}`}>
                  <Dropdown
                    id='inline'
                    titleText={filterTitleText(filter)}
                    label={
                      Array.isArray(filter.values) && filter.values.length
                        ? filter.values[0].label
                        : ''
                    }
                    items={filter.values}
                    translateWithId={t}
                    itemToString={item =>
                      item
                        ? filter?.translationReqd && filter?.translationData
                          ? filter?.translationData[item.label] ?? item?.label
                          : item.label
                        : ''
                    }
                    type='inline'
                    onChange={(e: any) => {
                      if (
                        filter.filterCallback &&
                        typeof filter.filterCallback === 'function'
                      ) {
                        filter.filterCallback?.(e);
                        clearFilter();
                      }
                    }}
                    data-testid='left-inline-filter-dropdown'
                    selectedItem={getInlineFilterValue(filter.values)}
                    initialSelectedItem={getInlineFilterValue(filter.values)}
                  />
                </div>
              ))}
          </div>
        ) : leftInlineMultiSelectFilter ? (
          <div className='leftInlineMultiSelectFilter'>
            {Array.isArray(leftInlineMultiSelectFilter) &&
              leftInlineMultiSelectFilter.map((filter, index) => (
                <div className='dropdown' key={`leftfilter${index}`}>
                  <FilterableMultiSelect
                    id='multiselect'
                    size='lg'
                    type='inline'
                    useTitleInItem={true}
                    titleText={t('view')}
                    items={filter.values}
                    itemToString={(item: any) =>
                      item
                        ? filter?.translationReqd && filter?.translationData
                          ? filter?.translationData[item.label] ?? item?.label
                          : item.label
                        : ''
                    }
                    selectionFeedback='top-after-reopen'
                    placeholder={showPlaceholderText ? placeholderText : ''}
                    initialSelectedItems={
                      isSelected(filter)
                        ? getSelectedItems(filter)[0]?.event?.selectedItems
                        : []
                    }
                    onMenuChange={handleMenuChange}
                    onChange={(e: any) => ApplyFilter(e, filter.key, 'multi')}
                  />
                </div>
              ))}
          </div>
        ) : null}

        {props.filters && props.filters.length > 0 ? (
          <Button
            className={
              showFilterView === true
                ? 'search-side-buttons active-filter'
                : 'search-side-buttons'
            }
            renderIcon={Filter16}
            onClick={() => toggleFilterView()}
            hasIconOnly
            tooltipPosition='bottom'
            iconDescription={t('filter')}
          ></Button>
        ) : null}

        {showSearch ? (
          <>
            {customSearch && customSearch}
            {!customSearch && (
              <Column className='searchBar'>
                <Search
                  size='lg'
                  labelText={t('search') as string}
                  className='tableSearch'
                  value={searchValue}
                  placeholder={t('search')}
                  onChange={e => ApplyFilter(e, customKey || 'name', 'single')}
                  renderIcon={
                    <IconWithToolTip
                      icon={<Search16 />}
                      iconDescription={t('search')}
                    />
                  }
                />
              </Column>
            )}
          </>
        ) : (
          <Column className='emptyContainer' />
        )}

        {showRefresh ? (
          <Button
            className='search-side-buttons'
            renderIcon={Renew16}
            onClick={() => onRefresh()}
            hasIconOnly
            tooltipPosition='bottom'
            iconDescription={t('refresh')}
            data-testid='refresh-btn'
          ></Button>
        ) : null}
        {actionButton ? actionButtonItem() : null}
        {/* <Button
          className='search-side-buttons setting-button'
          renderIcon={Settings16}
          onClick={() => SetShowFilterView(true)}
        ></Button> */}
      </Row>

      {showFilterView && (
        <div className='filter-and-filter-row'>
          <Row>
            {Array.isArray(filters) &&
              filters.map(filter => {
                if (filter.type === 'single')
                  return (
                    <Column
                      md={filter.mdWidth ? filter.mdWidth : 2}
                      lg={filter.lgWidth ? filter.lgWidth : 4}
                      key={filter.key}
                    >
                      <div className='dropdown'>
                        {filter.hasIcon && (
                          <Dropdown
                            id='item-to-element'
                            titleText={filterTitleText(filter)}
                            label={
                              filter?.placeHolderText
                                ? filter?.placeHolderText
                                : (t('filter') as string) +
                                  ' ' +
                                  filterDropdownLabelText(filter?.label)
                            }
                            items={filter.values}
                            itemToElement={item =>
                              filter.hasIcon && item ? (
                                <div className='dropdown-icon'>
                                  <span className='icon-filter-dropdown'>
                                    {item.icon}
                                  </span>
                                  <span>
                                    {filter?.translationReqd &&
                                    filter?.translationData
                                      ? filter?.translationData[item.label]
                                      : item.label}
                                  </span>
                                </div>
                              ) : null
                            }
                            translateWithId={t}
                            itemToString={item =>
                              filter?.translationReqd && filter?.translationData
                                ? filter?.translationData[item] ?? item
                                : item
                            }
                            selectedItem={
                              isSelected(filter)
                                ? getSelectedItems(filter)[0]?.event
                                    ?.selectedItem?.label
                                : null
                            }
                            onChange={e => ApplyFilter(e, filter.key, 'single')}
                          />
                        )}
                        {!filter.hasIcon && (
                          <Dropdown
                            id='item-to-element'
                            size='md'
                            titleText={filterTitleText(filter)}
                            label={
                              filter?.placeHolderText
                                ? filter?.placeHolderText
                                : (t('filter') as string) +
                                  ' ' +
                                  filterDropdownLabelText(filter?.label)
                            }
                            items={filter.values}
                            translateWithId={t}
                            itemToString={item =>
                              typeof item === 'object'
                                ? item.label
                                : filter?.translationReqd &&
                                  filter?.translationData
                                ? filter?.translationData[item]
                                : item
                            }
                            selectedItem={
                              isSelected(filter)
                                ? getSelectedItems(filter)[0]?.event
                                    ?.selectedItem
                                : null
                            }
                            onChange={e => ApplyFilter(e, filter.key, 'single')}
                          />
                        )}
                      </div>
                    </Column>
                  );
                else
                  return (
                    <Column
                      md={filter.mdWidth ? filter.mdWidth : 2}
                      lg={filter.lgWidth ? filter.lgWidth : 4}
                      key={filter.key}
                    >
                      <div className='dropdown'>
                        {filter.key === filterTypes.ALLLABELS ? (
                          filtersApplied.length === 0 ? (
                            <span>
                              {filterableLabel(filter, filtersApplied)}
                            </span>
                          ) : (
                            filterableLabel(filter, filtersApplied)
                          )
                        ) : (
                          <MultiSelect
                            id='default'
                            titleText={filterTitleText(filter)}
                            label={
                              filter?.placeHolderText
                                ? filter?.placeHolderText
                                : t('filter') +
                                  ' ' +
                                  filterDropdownLabelText(filter?.label)
                            }
                            items={filter.values.filter(Boolean)}
                            translateWithId={t}
                            itemToString={item =>
                              filter?.translationReqd && filter?.translationData
                                ? filter?.translationData[item] ?? item
                                : item
                            }
                            initialSelectedItems={
                              isSelected(filter)
                                ? getSelectedItems(filter)[0]?.event
                                    ?.selectedItems
                                : []
                            }
                            selectedItems={
                              isSelected(filter)
                                ? getSelectedItems(filter)[0]?.event
                                    ?.selectedItems
                                : []
                            }
                            onChange={e => ApplyFilter(e, filter.key, 'multi')}
                          />
                        )}
                      </div>
                    </Column>
                  );
              })}
          </Row>
        </div>
      )}

      {showClearButton && (
        <Row
          className={checkFilterApplied()}
          data-testid='filter-selected-container'
        >
          {showFilterTags()}
          {showClearTagsButton()}
        </Row>
      )}
    </div>
  );
};

export default FindAndFilterBar;
