import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Tile,
  Column,
  TooltipDefinition,
  Button,
} from 'carbon-components-react';
import { Edit16, Information16 } from '@carbon/icons-react';
import { EmptyState } from '../EmptyState/EmptyState';
import images from '../../images/images';
import './DetailsCard.scss';
import LabelTag from '../LabelTag/LabelTag';
import AutoDiscoverLabel from '../AutoDiscoverLabel/AutoDiscoverLabel';
import dateUtils from '../../lib/dates.js';
import GenericTruncateString from '../GenericTruncateString/GenericTruncateString';
import GenericStatusField from '../GenericStatusField/GenericStatusField';

type Data = {
  key: string;
  value: string | string[] | undefined | JSX.Element;
  type?: string[] | undefined;
  currentType?: string | undefined;
  dataTestId?: string | string[] | undefined;
};

const DetailsCard = (props: {
  data: Data[];
  type?: string;
  deploymentType?: string;
  isEditable?: boolean;
  isDisabled?: boolean;
  openEditModal?: () => void;
  detailsCardName?: string;
  dataTestId?: string;
  isUnManaged?: boolean | string;
  authorizationMap?: any;
}) => {
  const { t } = useTranslation('detailsCard');
  const {
    data,
    type,
    isEditable,
    openEditModal,
    isUnManaged,
    isDisabled,
    detailsCardName,
    dataTestId,
  } = props;

  const deploymentType = props?.deploymentType ? props.deploymentType : '';

  return (
    <Tile className='details-tile' data-testid={dataTestId}>
      <div className={!isEditable ? 'default header' : 'header'}>
        <div className='text'>{detailsCardName}</div>{' '}
        {isEditable ? (
          type === 'DEPLOYMENT_ENVIRONMENT' || type === 'LOCATION_DETAILS' ? (
            //same button
            !isUnManaged ? (
              <Button
                className='edit-link'
                renderIcon={Edit16}
                onClick={openEditModal}
                iconDescription={t('edit')}
                disabled={isDisabled}
                hasIconOnly
                tooltipPosition='bottom'
                data-testid='edit-link'
              ></Button>
            ) : (
              <Button
                kind='ghost'
                onClick={openEditModal}
                data-testid='edit-link-unmanaged'
              >
                {t('Manage')}
              </Button>
            )
          ) : (
            <Button
              className={
                type === 'POLICY' ? 'edit-link edit-policy-link' : 'edit-link'
              }
              renderIcon={Edit16}
              onClick={openEditModal}
              iconDescription={t('edit')}
              disabled={isDisabled}
              hasIconOnly
              tooltipPosition='bottom'
              data-testid='edit-link'
            ></Button>
          )
        ) : null}
      </div>
      {data.length === 0 ? (
        <EmptyState
          icon={images.noServicesIcon()}
          header={t('emptyState.header')}
        />
      ) : (
        <div className='data-container'>
          {type === 'LOCATION_DETAILS'
            ? data.map((obj, index) => {
                if (obj.key === 'labels') {
                  return (
                    <Column
                      key={index}
                      className='section'
                      lg={4}
                      md={2}
                      data-testid={t(obj.key)}
                    >
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        <LabelTag
                          labelArray={obj.value as string[]}
                          count={3}
                        ></LabelTag>
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'updatedAt') {
                  return (
                    <Column
                      key={index}
                      className='section'
                      lg={4}
                      md={2}
                      data-testid={t(obj.key)}
                    >
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        {`${
                          !!obj.value
                            ? dateUtils.getUserFriendlyDate(obj.value)
                            : '—'
                        }`}
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'lastUpdated') {
                  return (
                    <Column
                      key={index}
                      className='section'
                      lg={4}
                      md={2}
                      data-testid={t(obj.key)}
                    >
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        {`${
                          !!obj.value
                            ? dateUtils.getUserFriendlyDate(obj.value)
                            : '—'
                        }`}
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'lastRun') {
                  return (
                    <Column
                      key={index}
                      className='section'
                      lg={4}
                      md={2}
                      data-testid={t(obj.key)}
                    >
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        {`${
                          !!obj.value
                            ? dateUtils.getUserFriendlyDate(obj.value)
                            : '—'
                        }`}
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'coordinates') {
                  return (
                    <Column key={index} className='section' lg={4} md={2}>
                      <div className='label'>
                        <span>{t(obj.key)}</span>
                        <TooltipDefinition
                          className='tooltip'
                          tooltipText={t(`tooltip.${obj.key}`)}
                          direction='bottom'
                        >
                          <Information16 />
                        </TooltipDefinition>
                      </div>
                      <div className='value'>
                        <GenericTruncateString
                          str={!!obj.value ? obj.value : ('—' as any)}
                        />
                      </div>
                    </Column>
                  );
                } else {
                  return (
                    <Column
                      key={index}
                      lg={4}
                      md={2}
                      className='section'
                      data-testid={t(obj.key)}
                    >
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        <GenericTruncateString
                          str={!!obj.value ? obj.value : ('—' as any)}
                        />
                      </div>
                    </Column>
                  );
                }
              })
            : type === 'CLOUD_DETAILS'
            ? data.map((obj, index) => {
                if (obj.key === 'lastUpdated') {
                  return (
                    <Column key={index} className='section' lg={4} md={2}>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>{obj.value}</div>
                    </Column>
                  );
                } else if (obj.key === 'secretName') {
                  return (
                    <Column key={index} lg={4} md={2} className='section'>
                      <div className='label'>
                        <TooltipDefinition
                          tooltipText={t('tooltip.cloudSecret')}
                          direction='bottom'
                        >
                          {t(obj.key)}
                        </TooltipDefinition>
                      </div>

                      <div className='value'>
                        {obj?.value ? obj?.value : '—'}
                      </div>
                    </Column>
                  );
                } else {
                  return (
                    <Column key={index} lg={4} md={2} className='section'>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        <GenericTruncateString
                          str={obj?.value ? obj?.value : ('—' as any)}
                        />
                      </div>
                    </Column>
                  );
                }
              })
            : type === 'DEPLOYMENT_ENVIRONMENT'
            ? data.map((obj, index) => {
                if (
                  obj.key === 'labels' &&
                  obj?.type?.includes(deploymentType)
                ) {
                  return (
                    <Column
                      key={index}
                      md={deploymentType === 'vpc' ? 3 : 3}
                      lg={deploymentType === 'vpc' ? 5 : 5}
                      className='section'
                    >
                      <div
                        className='label'
                        data-testid={`details-data-field-label${index}`}
                      >
                        {t(obj.key)}
                      </div>
                      <div
                        className='value'
                        data-testid={`details-data-field-value${index}`}
                      >
                        {!!obj.value ? (
                          <LabelTag
                            labelArray={obj.value as string[]}
                            count={3}
                          ></LabelTag>
                        ) : (
                          '—'
                        )}
                      </div>
                    </Column>
                  );
                } else if (
                  obj.key === 'autoDiscovery' &&
                  obj?.type?.includes(deploymentType)
                ) {
                  return (
                    <Column key={index} className='section' md={3} lg={5}>
                      <div
                        className='label'
                        data-testid={`details-data-field-label${index}`}
                      >
                        {t(obj.key)}
                      </div>
                      {typeof obj.value === 'string' ? (
                        obj?.value === t('on') ? (
                          <div className='auto-discover-label-item'>
                            {obj?.currentType !== 'node' ? (
                              <div className='autoDiscoverIcon'>
                                {images.autoDiscoverLabelIcon()}
                              </div>
                            ) : null}
                            <div
                              className='value'
                              data-testid={`details-data-field-value${index}`}
                            >
                              {obj?.currentType === 'vpc'
                                ? t('appsSecurityGroup')
                                : obj?.currentType === 'cluster'
                                ? t('nameSpaceText')
                                : obj.value}
                            </div>
                          </div>
                        ) : (
                          <div
                            className='value'
                            data-testid={`details-data-field-value${index}`}
                          >
                            {obj.value}
                          </div>
                        )
                      ) : (
                        <div
                          className='value'
                          data-testid={`details-data-field-value${index}`}
                        >
                          <AutoDiscoverLabel
                            label={obj.value as string[]}
                          ></AutoDiscoverLabel>
                        </div>
                      )}
                    </Column>
                  );
                } else if (obj?.type?.includes(deploymentType)) {
                  return (
                    <Column key={index} md={3} lg={5} className='section'>
                      <div
                        className='label'
                        data-testid={`details-data-field-label${index}`}
                      >
                        {t(obj.key)}
                      </div>
                      <div
                      // className={
                      //   obj.key === 'gatewayId' ? 'value gateway' : 'value'
                      // }
                      //example where to list env type
                      >
                        {obj.key === 'name' ? (
                          <div
                            className='value nameContainer'
                            data-testid={`details-data-field-value${index}`}
                          >
                            {Array.isArray(obj?.value)
                              ? obj?.value
                              : obj?.value && (
                                  <GenericTruncateString
                                    str={
                                      (obj?.value ? obj?.value : '—') as string
                                    }
                                  />
                                )}
                          </div>
                        ) : (
                          <div
                            className='value nameContainer'
                            data-testid={`details-data-field-value${index}`}
                          >
                            <GenericTruncateString
                              str={obj?.value ? obj?.value : ('—' as any)}
                            />
                          </div>
                        )}
                      </div>
                    </Column>
                  );
                }
              }, deploymentType)
            : type === 'POLICY'
            ? data.map((obj, index) => {
                if (obj.key === 'labels') {
                  return (
                    <Column key={index} className='section'>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        <LabelTag
                          labelArray={obj.value as string[]}
                          count={3}
                        ></LabelTag>
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'description') {
                  return (
                    <Column key={index} lg={16} className='section'>
                      <div className='label'>{t(obj.key)}</div>

                      <div className='value'>
                        <GenericTruncateString
                          str={obj.value ? obj.value : ('—' as any)}
                        />
                      </div>
                    </Column>
                  );
                } else if (
                  obj.key === 'networkSegment' ||
                  obj.key === 'gatewaySet'
                ) {
                  if (!props.authorizationMap.networkSegment) {
                    return (
                      <Column key={index} lg={16} className='section'>
                        <div className='label'>{t(obj.key)}</div>
                        <GenericStatusField status='notAuthorised'></GenericStatusField>
                      </Column>
                    );
                  } else {
                    return (
                      <Column key={index} lg={16} className='section'>
                        <div className='label'>{t(obj.key)}</div>
                        <div className='value nameContainer'>
                          <GenericTruncateString
                            str={obj.value ? obj.value : ('—' as any)}
                          />
                        </div>
                      </Column>
                    );
                  }
                } else {
                  return (
                    <Column key={index} lg={16} className='section'>
                      <div className='label'>{t(obj.key)}</div>

                      {obj.key === 'Name' ? (
                        <div className='value nameContainer'>
                          {Array.isArray(obj?.value)
                            ? obj?.value
                            : obj?.value && (
                                <GenericTruncateString
                                  str={obj?.value as string}
                                />
                              )}
                        </div>
                      ) : (
                        <div className='value nameContainer'>
                          <GenericTruncateString
                            str={obj.value ? obj.value : ('—' as any)}
                          />
                        </div>
                      )}
                    </Column>
                  );
                }
              })
            : type === 'EVENT_DETAILS'
            ? data.map((obj, index) => {
                if (obj.key === 'labels') {
                  return (
                    <Column key={index} className='section' lg={8} md={2}>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        <LabelTag
                          labelArray={obj.value as string[]}
                          count={3}
                        ></LabelTag>
                      </div>
                    </Column>
                  );
                } else if (
                  obj.key === 'category' ||
                  obj.key === 'resourceName' ||
                  obj.key === 'timeOfEvent'
                ) {
                  return (
                    <Column key={index} lg={4} md={2} className='section'>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        {obj?.value ? obj?.value : '—'}
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'comment') {
                  return (
                    <Column key={index} lg={8} md={2} className='section'>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        {obj?.value ? obj?.value : '—'}
                      </div>
                    </Column>
                  );
                } else {
                  return (
                    <Column key={index} className='section' lg={8} md={2}>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        {obj?.value ? obj?.value : '—'}
                      </div>
                    </Column>
                  );
                }
              })
            : type === 'IDENTITY_DETAILS'
            ? data.map((obj, index) => {
                if (obj.key === 'labels') {
                  return (
                    <Column key={index} className='section' lg={4} md={2}>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        <LabelTag
                          labelArray={obj.value as string[]}
                          count={3}
                        ></LabelTag>
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'created') {
                  return (
                    <Column key={index} className='section' lg={4} md={2}>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        {`${dateUtils.getUserFriendlyDate(obj.value)}`}
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'updatedAt') {
                  if (obj.value != null) {
                    return (
                      <Column key={index} className='section' lg={4} md={2}>
                        <div className='label'>{t(obj.key)}</div>
                        <div className='value'>
                          {`${dateUtils.getUserFriendlyDate(obj.value)}`}
                        </div>
                      </Column>
                    );
                  }
                } else if (obj.key === 'lastRun') {
                  if (obj.value != null) {
                    return (
                      <Column key={index} className='section' lg={4} md={2}>
                        <div className='label'>{t(obj.key)}</div>
                        <div className='value'>
                          {`${dateUtils.getUserFriendlyDate(obj.value)}`}
                        </div>
                      </Column>
                    );
                  }
                } else {
                  return (
                    <Column key={index} lg={4} md={2} className='section'>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>{obj.value ? obj.value : '—'}</div>
                    </Column>
                  );
                }
              })
            : type === 'ROLE_DETAILS'
            ? data.map((obj, index) => {
                if (obj.key === 'labels') {
                  if (Array.isArray(obj.value) && obj.value.length === 0) {
                    return (
                      <Column key={index} className='section' lg={4} md={2}>
                        <div className='label'>{t(obj.key)}</div>
                        <div className='value'>{'—'}</div>
                      </Column>
                    );
                  }
                  return (
                    <Column key={index} className='section' lg={4} md={2}>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        <LabelTag
                          labelArray={obj.value as string[]}
                          count={3}
                        ></LabelTag>
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'updatedAt' || obj.key === 'created') {
                  return (
                    <Column key={index} className='section' lg={4} md={2}>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        {`${dateUtils.getUserFriendlyDate(obj.value)}`}
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'description') {
                  if (
                    obj.value != null &&
                    (obj.value as string[]).length === 0
                  ) {
                    return (
                      <Column key={index} lg={4} md={2} className='section'>
                        <div className='label'>{t(obj.key)}</div>
                        <div className='value'>{'—'}</div>
                      </Column>
                    );
                  }
                  return (
                    <Column key={index} lg={4} md={2} className='section'>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>{obj.value}</div>
                    </Column>
                  );
                } else {
                  return (
                    <Column key={index} lg={4} md={2} className='section'>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>{obj.value}</div>
                    </Column>
                  );
                }
              })
            : type === 'NETWORK_SEGMENT_DETAILS'
            ? data.map((obj, index) => {
                if (obj.key === 'labels') {
                  return (
                    <Column
                      key={index}
                      md={3}
                      lg={5}
                      className='section'
                      data-testid={t(obj.key)}
                    >
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        {!!obj.value ? (
                          <LabelTag
                            labelArray={obj.value as string[]}
                            count={3}
                          ></LabelTag>
                        ) : (
                          '—'
                        )}
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'updatedAt' || obj.key === 'created') {
                  return (
                    <Column
                      key={index}
                      className='section'
                      lg={5}
                      md={3}
                      data-testid={t(obj.key)}
                    >
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        {`${dateUtils.getUserFriendlyDate(obj.value)}`}
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'description') {
                  return (
                    <Column
                      key={index}
                      lg={5}
                      md={3}
                      className='section'
                      data-testid={t(obj.key)}
                    >
                      <div className='label'>{t(obj.key)}</div>

                      <div className='value'>
                        <GenericTruncateString
                          str={obj.value ? obj.value : ('—' as any)}
                        />
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'name') {
                  return (
                    <Column
                      key={index}
                      lg={5}
                      md={3}
                      className='section'
                      data-testid={t(obj.key)}
                    >
                      <div className='label'>{t(obj.key)}</div>
                      <div>
                        <div className='value nameContainer'>
                          <GenericTruncateString
                            str={obj?.value ? obj?.value : ('—' as any)}
                          />
                        </div>
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'metricCollection') {
                  return (
                    <Column
                      key={index}
                      lg={5}
                      md={3}
                      className='section'
                      data-testid={t(obj.key)}
                    >
                      <div className='label'>
                        <span>{t(obj.key)}</span>
                        <TooltipDefinition
                          className='tooltip'
                          tooltipText={t(`tooltip.${obj.key}`)}
                          direction='bottom'
                        >
                          <Information16 />
                        </TooltipDefinition>
                      </div>
                      {obj.value === 'true' ? (
                        <div className='value'>{t('enabled')}</div>
                      ) : (
                        <div className='value valueIconContainer'>
                          <div className='image-icon-cont'>
                            {images.majorIconSvg()}
                          </div>
                          <div>{t('notEnabled')}</div>
                        </div>
                      )}
                    </Column>
                  );
                } else {
                  return (
                    <Column
                      key={index}
                      lg={5}
                      md={3}
                      className='section'
                      data-testid={t(obj.key)}
                    >
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>{obj.value}</div>
                    </Column>
                  );
                }
              })
            : data.map((obj, index) => {
                if (obj.key === 'labels') {
                  return (
                    <Column key={index} lg={16} className='section'>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>
                        {obj.value && (obj.value as string[]).length > 0 ? (
                          <LabelTag
                            labelArray={obj.value as string[]}
                            count={3}
                          ></LabelTag>
                        ) : (
                          <div className='value'>—</div>
                        )}
                      </div>
                    </Column>
                  );
                } else if (obj.key === 'resourceGroup' && !obj.value) {
                  return (
                    <Column key={index} lg={4} md={2} className='section'>
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value tag-label'>
                        <span>—</span>
                      </div>
                    </Column>
                  );
                } else if (
                  obj.key === 'description' &&
                  type === 'APPLICATION'
                ) {
                  if (
                    obj.value != null &&
                    (obj.value as string[]).length === 0
                  ) {
                    return (
                      <Column key={index} lg={8} md={2} className='section'>
                        <div className='label'>{t(obj.key)}</div>
                        <div className='value'>{'—'}</div>
                      </Column>
                    );
                  }
                  return (
                    <Column
                      key={index}
                      lg={16}
                      md={16}
                      className='section text--wrap'
                    >
                      <div className='label'>{t(obj.key)}</div>
                      <div className='value'>{obj.value}</div>
                    </Column>
                  );
                } else {
                  return (
                    <Column
                      key={index}
                      lg={type === 'APPLICATION_DEPLYOMENT' ? 5 : 8}
                      className='section'
                    >
                      <div
                        className='label'
                        data-testid={`${obj?.dataTestId}-${obj.key}-label`}
                      >
                        {t(obj.key)}
                      </div>
                      <div
                        className='value'
                        data-testid={`${obj?.dataTestId}-${obj.key}`}
                      >
                        {Array.isArray(obj?.value)
                          ? obj?.value
                          : obj?.value && (
                              <GenericTruncateString
                                str={(obj?.value ? obj?.value : '—') as string}
                              />
                            )}
                      </div>
                    </Column>
                  );
                }
              })}
        </div>
      )}
    </Tile>
  );
};

export default DetailsCard;
