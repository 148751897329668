import React, { useState } from 'react';
import {
  TextInput,
  Column,
  Row,
  Checkbox,
  Dropdown,
  TextArea,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import AddLabels from '../../../components/AddLabels/AddLabels';
import { Item } from './EditGateway';
import { Cloud, Location } from '../../../models/master';
interface ingressType {
  name: string;
  id: string;
}
const SkupperGateway = (props: {
  onChange: any;
  onBlur: any;
  formData: any;
  resourceGroupList: any;
  type: 'edge' | 'waypoint' | 'GatewayTypeNotSet';
  subType?: 'AXON-WP' | 'RHSI-EDGE' | 'AXON-EDGE';
  onClickEditDeployedInVpcBtn: () => void;
  selectedDepEnv: Item;
  openDeploymentEnvTable: () => void;
  autoDeployed: any;
  cloudsList: Cloud[] | null;
  locationsList: Location[] | null;
}) => {
  const { onChange, formData, onBlur, type, subType, locationsList } = props;
  const { t } = useTranslation('editGateway');
  const [isIngressEnabled, setIngressEnabled] = useState(false);
  const [ingressTypeList, setIngressTypeList] = useState([
    { name: 'route', id: 'route' },
    { name: 'loadbalancer', id: 'loadbalancer' },
    { name: ' nginx-ingress-v1', id: ' nginx-ingress-v1' },
  ]);

  const [selectedCloud, setSelectedCloud] = useState<string | null>(null);
  const [filteredLocationList, setFilteredLocationList] = useState<
    any[] | null
  >(null);

  return (
    <div className='edit-gateway-form'>
      {type === 'edge' && (
        <>
          <div className='edit-gateway__cluster-details-section'>
            <div className='row edit-gateway__cluster-details__heading'>
              {t('editForm.clusterSection.heading') as string}
            </div>
            <Row className='row'>
              <Column md={3}>
                <TextInput
                  labelText={t('editForm.clusterSection.namespace') as string}
                  value={formData?.namespaceName?.value}
                  readOnly
                  autoComplete='off'
                  maxLength={60}
                  id='edit-gateway-namespace'
                />
              </Column>
              <Column md={3}>
                <TextInput
                  labelText={t('editForm.clusterSection.name') as string}
                  value={formData?.clusterName?.value}
                  readOnly
                  autoComplete='off'
                  maxLength={60}
                  id='edit-gateway-clusterId'
                />
              </Column>
            </Row>
            <Row className='row'>
              <Column md={3}>
                <TextInput
                  light={true}
                  labelText={t('editForm.clusterSection.location') as string}
                  id='edit-gateway-locationId'
                  value={formData?.locationName?.value?.name}
                  readOnly
                />
              </Column>
              <Column md={3}>
                <TextInput
                  labelText={t('editForm.clusterSection.cloud') as string}
                  id='edit-gateway-cloudId'
                  readOnly
                  value={formData?.cloudName?.value?.name}
                />
              </Column>
            </Row>
          </div>
          {!formData?.ingressEnabled && (
            <div className='edit-gateway__connection-ingress-section'>
              <div className='edit-gateway__connection-ingress__heading'>
                {t('editForm.connectionIngress.heading') as string}
              </div>
              <div className='edit-gateway__connection-ingress__subheading'>
                {t('editForm.connectionIngress.subHeading') as string}
              </div>
              <div className='edit-gateway__connection-ingress__checkbox'>
                <Checkbox
                  id={'is-ingress-enabled'}
                  labelText={t('editForm.connectionIngress.isIngressEnabled')}
                  checked={isIngressEnabled}
                  disabled={formData?.ingressEnabled}
                  onChange={(e: any) => setIngressEnabled(!isIngressEnabled)}
                />
              </div>{' '}
              {isIngressEnabled && (
                <Row className='row edit-gateway__add-connection-ingress'>
                  <Column md={3}>
                    <Dropdown
                      label={t<string>(
                        'editForm.connectionIngress.chooseOption'
                      )}
                      id='ingress-type'
                      titleText={t<string>(
                        'editForm.connectionIngress.ingressType'
                      )}
                      items={
                        Array.isArray(ingressTypeList) ? ingressTypeList : []
                      }
                      itemToString={(item: ingressType) => item?.name}
                      itemToElement={(item: ingressType) => (
                        <div className='identity-dropdown-option'>
                          <span>{item.name}</span>
                          <span>{`(${item.id})`}</span>
                        </div>
                      )}
                      onChange={(e: any) => {
                        onChange('ingress-type', e.selectedItem);
                      }}
                    />
                  </Column>
                  <Column md={3}>
                    <TextInput
                      labelText={
                        t('editForm.connectionIngress.hostname') as string
                      }
                      id='ingress-hostname'
                      value='mesh.ibm.com'
                      name='ingress-hostname'
                      placeholder={t('editForm.connectionIngress.hostname')}
                      onChange={e =>
                        onChange('ingress-hostname', e.target.value)
                      }
                      invalid={formData?.name.error}
                      invalidText={formData?.name.errorMessage}
                      autoComplete='off'
                      maxLength={60}
                      onBlur={e => onBlur('ingress-hostname', e.target.value)}
                    />
                  </Column>
                </Row>
              )}
            </div>
          )}
          <div className='edit-gateway__gWdetails-section'>
            <div className='edit-gateway__gWdetails-heading'>
              {t('editForm.gWdetails.heading') as string}
            </div>
            <Row className='row'>
              <Column md={3}>
                <TextInput
                  labelText={t('editForm.gWdetails.name') as string}
                  id='name'
                  value={formData?.name.value}
                  name='name'
                  placeholder={t('editForm.gWdetails.edgePlaceholder')}
                  onChange={e => onChange('name', e.target.value)}
                  invalid={formData?.name.error}
                  invalidText={formData?.name.errorMessage}
                  autoComplete='off'
                  maxLength={60}
                  onBlur={e => onBlur('name', e.target.value)}
                />
              </Column>
            </Row>
            <Row className='row'>
              <Column md={6}>
                <AddLabels
                  id='edit-gateway__gWdetails-add-labels'
                  labelText={t('editForm.gWdetails.label')}
                  placeholder={t('editForm.gWdetails.labelplaceholder')}
                  onChange={data => onChange('labels', data)}
                  btnText={t('editForm.gWdetails.buttonText')}
                  btnKind='secondary'
                  tagType='green'
                  defaultValues={formData.labels.value}
                />
              </Column>
            </Row>
            <Row className='row'>
              <Column md={6}>
                <TextArea
                  id='edit-gateway__gWdetails-description'
                  labelText={t('editForm.gWdetails.description')}
                  placeholder={t('editForm.gWdetails.desplaceholder')}
                  name='description'
                  value={formData.description.value}
                  onChange={e => onChange('description', e.target.value)}
                  maxCount={300}
                  enableCounter={true}
                />
              </Column>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};
export default SkupperGateway;
