import { TFunction } from 'react-i18next';
import images from '../../images/images';
import { Warning16 } from '@carbon/icons-react';
import { GatewayLifeCycleState, ProceduralStatus } from '../../lib/enums';
import ProceduralActivity from './ProceduralActivity';
import GatewayProcedure from './GatewayProcedure';

export type ProceduralStatusType =
  | 'notdeployed'
  | 'deploying'
  | 'provisioning'
  | 'operational'
  | 'terminating'
  | 'terminated'
  | 'errored'
  | 'redeploying'
  | 'promoting'
  | 'pending'
  | 'demoting'
  | 'upgrading'
  | 'post-upgrade-soaking'
  | 'post-upgrade-undeploying'
  | 'deployed'
  | 'in-progress'
  | 'not-started'
  | 'succeeded'
  | 'failed';

export type HealthStatusType =
  | 'operational'
  | 'minor'
  | 'critical'
  | 'unreachable'
  | 'notdeployed'
  | 'pending'
  | 'errored';

export type ProceduralActivityType =
  | 'gw-not-deployed'
  | 'deploying-gw-agent'
  | 'discover-gw'
  | 'deploying'
  | 'caching'
  | 'waiting-for-closure'
  | 'waiting-connection'
  | 'waiting-nseg-completion'
  | 'connecting-gateways'
  | 'finalizing-onboarding'
  | 'remove-mesh-agent'
  | 'undeploying-gw'
  | 'errored';

export type Procedure =
  | 'discover'
  | 'deploy'
  | 'onboard'
  | 'offboard'
  | 'delete'
  | 'undeploy';

export const showProcedureAttribute: any = {
  offboard: true,
  delete: true,
  undeploy: true,
  deploy: true,
};

export const getProceduralStatusConfig = (
  t: TFunction<'gatewayStatus', undefined>,
  status: ProceduralStatusType
) => {
  const config = {
    deploying: {
      icon: images.loadingStatusIcon(),
      label: t('deploying'),
      loadingIcon: true,
    },
    provisioning: {
      icon: images.loadingStatusIcon(),
      label: t('provisioning'),
      loadingIcon: true,
    },
    terminating: {
      icon: images.loadingStatusIcon(),
      label: t('terminating'),
      loadingIcon: true,
    },
    redeploying: {
      icon: images.loadingStatusIcon(),
      label: t('redeploying'),
      loadingIcon: true,
    },
    promoting: {
      icon: images.loadingStatusIcon(),
      label: t('promoting'),
      loadingIcon: true,
    },
    demoting: {
      icon: images.loadingStatusIcon(),
      label: t('demoting'),
      loadingIcon: true,
    },
    upgrading: {
      icon: images.loadingStatusIcon(),
      label: t('upgrading'),
      loadingIcon: true,
    },
    'post-upgrade-soaking': {
      icon: images.loadingStatusIcon(),
      label: t('post-upgrade-soaking'),
      loadingIcon: true,
    },
    'post-upgrade-undeploying': {
      icon: images.loadingStatusIcon(),
      label: t('post-upgrade-undeploying'),
      loadingIcon: true,
    },
    notdeployed: {
      icon: images.notDeployedIcon(),
      label: t('notdeployed'),
      loadingIcon: false,
    },
    operational: {
      icon: null,
      label: '—',
      loadingIcon: false,
    },
    terminated: {
      icon: images.notDeployedIcon(),
      label: t('terminated'),
      loadingIcon: false,
    },
    errored: {
      icon: images.majorStatusIcon(),
      label: t('major'),
      loadingIcon: false,
    },
    pending: {
      icon: images.pendingStatusIcon(),
      label: t('pending'),
      loadingIcon: false,
    },
    deployed: {
      icon: null,
      label: t('deployed'),
      loadingIcon: false,
    },
    'in-progress': {
      icon: images.loadingStatusIcon(),
      label: t('in-progress'),
      loadingIcon: true,
    },
    'not-started': {
      icon: images.loadingStatusIcon(),
      label: t('not-started'),
      loadingIcon: true,
    },
    succeeded: {
      icon: null,
      label: '-',
      loadingIcon: false,
    },
    failed: {
      icon: images.majorStatusIcon(),
      label: t('error'),
      loadingIcon: false,
    },
  };

  return config[status] || '';
};

export const getHealthStatusConfig = (
  t: TFunction<'gatewayStatus', undefined>,
  status: HealthStatusType
) => {
  const config = {
    operational: {
      icon: images.operationalStatusIcon(),
      label: t('operational'),
    },
    minor: {
      icon: images.minorStatusIcon(),
      label: t('minor'),
    },
    critical: {
      icon: images.criticalStatusIcon(),
      label: t('critical'),
    },
    unreachable: {
      icon: images.unReachableIcon(),
      label: t('unreachable'),
    },
    notdeployed: {
      icon: images.notDeployedIcon(),
      label: t('notdeployed'),
    },
    pending: {
      icon: images.pendingStatusIcon(),
      label: t('pending'),
    },
    errored: {
      icon: images.majorStatusIcon(),
      label: t('major'),
    },
  };

  return config[status];
};

export const getProceduralActivityConfig = (
  t: TFunction<'gatewayStatus', undefined>,
  status: ProceduralActivityType
) => {
  const config = {
    'deploying-gw-agent': {
      icon: images.loadingStatusIcon(),
      label: t('proceduralActivity.deployingGwAgent'),
      loadingIcon: true,
    },
    'discover-gw': {
      icon: images.loadingStatusIcon(),
      label: t('proceduralActivity.discoveringGateways'),
      loadingIcon: true,
    },
    deploying: {
      icon: images.loadingStatusIcon(),
      label: t('proceduralActivity.deploying'),
      loadingIcon: true,
    },
    caching: {
      icon: images.loadingStatusIcon(),
      label: t('proceduralActivity.caching'),
      loadingIcon: true,
    },
    'waiting-for-closure': {
      icon: <Warning16 />,
      label: t('proceduralActivity.waitingClosure'),
      loadingIcon: false,
    },
    'waiting-connection': {
      icon: <Warning16 />,
      label: t('proceduralActivity.waitingConnection'),
      loadingIcon: false,
    },
    'waiting-nseg-completion': {
      icon: null,
      label: t('proceduralActivity.waitingNsegCompletion'),
      loadingIcon: false,
    },
    'connecting-gateways': {
      icon: null,
      label: '—',
      loadingIcon: false,
    },
    'finalizing-onboarding': {
      icon: null,
      label: '—',
      loadingIcon: false,
    },
    'remove-mesh-agent': {
      icon: images.loadingStatusIcon(),
      label: t('proceduralActivity.removingMeshAgent'),
      loadingIcon: true,
    },
    'undeploying-gw': {
      icon: images.loadingStatusIcon(),
      label: t('proceduralActivity.undeployingGateway'),
      loadingIcon: true,
    },
    'gw-not-deployed': {
      icon: <Warning16 />,
      label: t('proceduralActivity.readyToDeploy'),
      loadingIcon: false,
    },
    errored: {
      icon: images.majorStatusIcon(),
      label: t('proceduralActivity.error'),
      loadingIcon: false,
    },
  };

  return config[status] || '';
};

export const getGatewayProcedureConfig = (
  t: TFunction<'gatewayStatus', undefined>,
  status: Procedure
) => {
  const config = {
    discover: {
      icon: images.loadingStatusIcon(),
      label: t('gatewayProcedure.discover'),
      loadingIcon: true,
    },
    deploy: {
      icon: images.loadingStatusIcon(),
      label: t('gatewayProcedure.deploy'),
      loadingIcon: true,
    },
    onboard: {
      icon: images.loadingStatusIcon(),
      label: t('gatewayProcedure.onboard'),
      loadingIcon: true,
    },
    offboard: {
      icon: images.loadingStatusIcon(),
      label: t('gatewayProcedure.offboard'),
      loadingIcon: true,
    },
    delete: {
      icon: images.loadingStatusIcon(),
      label: t('gatewayProcedure.delete'),
      loadingIcon: true,
    },
    undeploy: {
      icon: images.loadingStatusIcon(),
      label: t('gatewayProcedure.undeploy'),
      loadingIcon: true,
    },
  };

  return config[status] || '';
};

export const gatewayStatus = (status: string) => {
  let statusWithIcon;
  statusWithIcon = (
    <span className='procedural__status'>
      <GatewayProcedure status={status as Procedure} />
    </span>
  );
  return statusWithIcon;
};

export const proceduralStatusField = (row: any) => {
  if (row?.procedural_status === ProceduralStatus.ERRORED) {
    return <ProceduralActivity status={row?.procedural_status} />;
  } else if (row?.procedural_activity) {
    return (
      <ProceduralActivity
        status={row?.procedural_activity as ProceduralActivityType}
      />
    );
  } else if (row?.procedural_status === ProceduralStatus.IN_PROGRESS) {
    return gatewayStatus(row?.procedure);
  } else if (
    row?.lifecycle_state === GatewayLifeCycleState.GW_NOT_DEPLOYED &&
    row?.unmanaged
  ) {
    return <ProceduralActivity status={row?.lifecycle_state} />;
  } else return <>—</>;
};

export const showProceduralStatusField = (data: any) =>
  showProcedureAttribute[data?.procedure ?? ''] ||
  data?.procedural_activity ||
  (data?.lifecycle_state === GatewayLifeCycleState.GW_NOT_DEPLOYED &&
    data?.unmanaged);
