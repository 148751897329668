import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import _ from 'lodash';

import useAnalytics from '../../../lib/useAnalytics';

import { ConnectionSelectedTypes, PolicyDataType } from '../../../lib/enums';
import {
  ConnectionOptionTypes,
  Item,
  NetworkSegmentData,
  PolicyOptionFromTypes,
} from '../CreateConnectionAccessPolicy/config';
import {
  PolicyData,
  ResourceGroup,
  Partition,
  Service,
} from '../../../models/master';
import { updatePolicy } from '../../../controllers/policyApi';
import { getDeploymentEnvsPartitions } from '../../../controllers/deploymentEnv';
import { getApplicationService } from '../../../controllers/application';

import MultiStepTearSheet from '../../../components/MultiStepTearSheet/MultiStepTearSheet';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';

import PolicyDefineDetails from '../CreateConnectionAccessPolicy/PolicyDefineDetails/PolicyDefineDetails';
import PolicyOptions from '../CreateConnectionAccessPolicy/PolicyOptions/PolicyOptions';
import EditPolicyConnections from './EditPolicyConnections';

interface Props {
  open: boolean;
  onClose: () => void;
  onEdit: (data: any) => void;
  resourceGroups: ResourceGroup[] | null;
  hasResourceGroupAuth: boolean;
  policyList: PolicyData[] | null;
  policyData: PolicyData;
  networkSegment: NetworkSegmentData;
  hasNetworkSegmentAuth: boolean;
}

export interface EditPolicyPermissionMap {
  resourceGroup: boolean;
  networkSegment: boolean;
  namespace: boolean;
  service: boolean;
  application: boolean;
}

interface MutationObject {
  name: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  resourceGroup: {
    value: {
      resource_id: string;
      name: string;
    };
    error: boolean;
    errorMessage: string;
  };
  description: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  labels: {
    value: string[];
    error: boolean;
    errorMessage: string;
  };
}

const defaultMutationObject = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  resourceGroup: {
    value: {
      resource_id: '',
      name: '',
    },
    error: false,
    errorMessage: '',
  },
  description: {
    value: '',
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
    error: false,
    errorMessage: '',
  },
};

const EditPolicy: React.FC<Props> = ({
  open,
  onClose,
  onEdit,
  resourceGroups,
  policyList,
  policyData,
  networkSegment,
  hasNetworkSegmentAuth,
  hasResourceGroupAuth,
}) => {
  const defaultPermissionMap = {
    application: true,
    service: true,
    resourceGroup: true,
    namespace: true,
    networkSegment: true,
  };

  const { t } = useTranslation('createPolicy');
  const { trackButtonClicked } = useAnalytics();
  const notification = useContext(NotificationContext);
  const [permissionMap, setPermissionMap] =
    useState<EditPolicyPermissionMap>(defaultPermissionMap);
  const [dataLoading, setDataLoading] = useState(false);
  const [mutationObject, setMutationObject] = useState<MutationObject>(
    defaultMutationObject
  );
  const [fromType, setFromType] = useState<PolicyDataType>(
    PolicyDataType.NAMESPACE
  );

  const [networkSegmentPartitions, setNetworkSegmentPartitions] = useState<
    Item[] | null
  >(null);
  const [selectedData, setSelectedData] = useState<Item[]>([]);
  const [originalPartitions, setOriginalPartitions] = useState<Item[]>([]);
  const [svcData, setSvcData] = useState<Item | null>(null);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [authError, setAuthError] = useState(false);

  const [showViewOptions, setShowViewOptions] = useState(false);
  const [showServiceSyncNotification, toggleServiceSyncNotification] =
    useState(false);

  const checkFieldValid = (name: string, value: any) => {
    const valueEmpty = value === '' || value === null || value === undefined;
    switch (name) {
      case 'name':
        if (valueEmpty) {
          return t('policyDefineDetails.nameEmpty');
        }
        const filteredPolicies = policyList?.filter(
          policy =>
            policy.network_segment_id === policyData.network_segment_id &&
            policy.name === value
        );
        if (
          filteredPolicies &&
          filteredPolicies.length > 0 &&
          value !== policyData.name
        ) {
          return t('policyDefineDetails.nameExists');
        }
        break;
      case 'resourceGroup':
        if (valueEmpty) return t('policyDefineDetails.resourceGroupEmpty');
        break;
    }
    return '';
  };

  const formValid = () => {
    const changesMadeStep1 =
      mutationObject.name.value !== policyData?.name ||
      mutationObject.description.value !== policyData?.description ||
      mutationObject.labels.value !== policyData?.labels ||
      mutationObject.resourceGroup.value.resource_id !==
        policyData?.resource_group_id;
    let changesMadeStep2 = false;
    if (
      fromType !== policyData.from.type ||
      (fromType === PolicyDataType.NAMESPACE &&
        !_.isEqual(originalPartitions, selectedData))
    ) {
      changesMadeStep2 = true;
    }

    return (changesMadeStep1 || changesMadeStep2) && selectedData.length > 0;
  };

  const handleOnChange = (e: { target: { name: string; value: any } }) => {
    if (showErrorSnackbar) {
      setShowErrorSnackbar(false);
      setAuthError(false);
    }

    const name = e?.target?.name;
    const value = e?.target?.value;
    let errorMessage = checkFieldValid(name, value);
    setMutationObject(prev => ({
      ...prev,
      [name]: {
        value,
        error: errorMessage !== '' ? true : false,
        errorMessage,
      },
    }));
  };

  const renderErrorSnackbar = () => {
    <div className='edit-policy-error-snackbar'>
      <InlineNotification
        kind='error'
        title={
          authError
            ? (t('error.authTitle') as string)
            : errorMessage.length > 0
            ? (t('error.genericTitle') as string)
            : (t('error.title') as string)
        }
        subtitle={
          authError
            ? t('error.authSubtitle')
            : errorMessage.length > 0
            ? errorMessage
            : t('error.subtitle')
        }
        onClose={() => handleErrorBarClose() as any}
      />
    </div>;
  };

  const renderServiceSyncNotification = () => {
    return (
      <div className='edit-policy-service-sync-notification'>
        <InlineNotification
          lowContrast={true}
          kind='info'
          title={t('edit.serviceSyncNotification.title')}
          subtitle={t('edit.serviceSyncNotification.subtitle')}
          onClose={() => toggleServiceSyncNotification(false) as any}
        />
      </div>
    );
  };

  const handleErrorBarClose = () => {
    setShowErrorSnackbar(false);
    setAuthError(false);
  };

  const handleCheckedItem = (checked: boolean, item: Item) => {
    if (checked) {
      addItem(item, PolicyDataType.NAMESPACE);
    } else {
      removeItem(item);
    }
  };

  const removeItem = (data: any) => {
    if (fromType === PolicyDataType.NETWORKSEGMENT) {
      setSelectedData([]);
    } else {
      setSelectedData(
        selectedData.filter(partition => partition.id !== data.id)
      );
    }
  };

  const addItem = (data: Item, type: ConnectionOptionTypes) => {
    if (
      type === PolicyDataType.NAMESPACE &&
      fromType === PolicyDataType.NAMESPACE
    ) {
      setSelectedData(prev => [...prev, data]);
    } else {
      setSelectedData([
        {
          ...data,
        },
      ]);
    }
    if (fromType !== type) {
      setFromType(type);
    }
  };

  const onCloseViewOptions = (save: boolean) => {
    setShowViewOptions(false);
    if (!save) {
      if (fromType === PolicyDataType.NAMESPACE) {
        setSelectedData([...originalPartitions]);
      } else {
        setSelectedData([
          {
            id: networkSegment.resource_id,
            label: networkSegment.name,
            type: PolicyDataType.NETWORKSEGMENT,
          },
        ]);
      }

      setFromType(policyData?.from.type as PolicyOptionFromTypes);
    }
  };

  const closeTearsheet = () => {
    setSvcData(null);
    setSelectedData([]);
    setOriginalPartitions([]);
    setNetworkSegmentPartitions(null);
    setMutationObject(defaultMutationObject);
    handleErrorBarClose();
    onClose();
  };

  const fetchService = async (appId: string, svcId: string) => {
    try {
      const svc = (await getApplicationService(appId, svcId)) as Service;
      setSvcData({
        id: svc.resource_id,
        label: svc.name,
        parentId: svc.application_id,
        ports: svc.ports ?? [],
        protocol: svc.ports[0]?.protocol ?? '',
        labels: svc.labels ?? [],
        type: PolicyDataType.SERVICE,
      });
    } catch (error) {
      const err = error as AxiosError;
      if (err?.response?.status === 403) {
        setPermissionMap(prev => ({
          ...prev,
          service: false,
        }));
      }
    }
  };

  const refreshNamespaces = () => {
    setNetworkSegmentPartitions(null);
    return fetchNamespaces();
  };

  const fetchNamespaces = async () => {
    try {
      const envData = await getDeploymentEnvsPartitions();
      const partitions: Item[] = [];
      for (const env of envData) {
        env.partitions.map((partition: Partition) => {
          if (partition.network_segment_id === policyData.network_segment_id) {
            partitions.push({
              id: partition.resource_id,
              parentId: env.resource_id,
              parentName: env.name,
              label: partition.name,
              type: PolicyDataType.NAMESPACE,
              cloudName: env.cloud_name,
              cloudId: env.cloud_id,
              locationName: env.location_name,
              resourceGroupId: env.resource_group_id,
              resourceGroupName:
                resourceGroups?.find(
                  (rg: ResourceGroup) =>
                    rg.resource_id === env.resource_group_id
                )?.name ?? '',
            });
          }
        });
      }
      setNetworkSegmentPartitions(partitions);
    } catch (error) {
      const err = error as AxiosError;
      if (err?.response?.status === 403) {
        setPermissionMap(prev => ({
          ...prev,
          namespaces: false,
        }));
      }
    }
  };

  const handleEditPolicy = async () => {
    if (showErrorSnackbar) {
      setShowErrorSnackbar(false);
      setAuthError(false);
    }
    let finalMutationObject: any = {
      name: mutationObject.name.value,
      description: mutationObject.description.value,
      action: policyData.action,
      labels: mutationObject.labels.value,
      resource_group_id: mutationObject.resourceGroup.value.resource_id,
      to: policyData.to,
      network_segment_id: policyData.network_segment_id,
    };

    let modifiedFromData: any = null;

    if (fromType === PolicyDataType.NAMESPACE) {
      if (
        !_.isEqual(originalPartitions, selectedData) ||
        policyData.from.type !== PolicyDataType.NAMESPACE
      ) {
        modifiedFromData = {
          type: PolicyDataType.NAMESPACE,
          namespaces: selectedData?.map(item => ({ namespace_id: item.id })),
        };
      }
    } else {
      if (policyData.from.type !== PolicyDataType.NETWORKSEGMENT) {
        modifiedFromData = {
          type: PolicyDataType.NETWORKSEGMENT,
          network_segment: {
            network_segment_id: policyData?.network_segment_id,
          },
        };
      }
    }

    if (modifiedFromData !== null) {
      finalMutationObject.from = modifiedFromData;
    }

    try {
      const res = await updatePolicy(
        policyData.resource_id,
        finalMutationObject
      );
      onEdit(res);
      // Display Toast notification on successful policy update
      notification.onTrigger('TOAST', {
        title: t('toast.editSuccess.title'),
        subtitle: t('toast.editSuccess.subtitle', { name: policyData.name }),
      });
    } catch (error: any) {
      const err = error as AxiosError;
      const errorMsg: string =
        error?.response !== undefined
          ? error?.response['customErrorMessage']
          : '';

      if (err?.response?.status === 403) {
        setAuthError(true);
      }

      setShowErrorSnackbar(true);
      return Promise.reject(() => console.log(error));
    }
  };

  const fetchData = async () => {
    setMutationObject({
      name: {
        value: policyData.name,
        error: false,
        errorMessage: '',
      },
      description: {
        value: policyData.description,
        error: false,
        errorMessage: '',
      },
      labels: {
        value: policyData.labels ?? [],
        error: false,
        errorMessage: '',
      },
      resourceGroup: {
        value: {
          resource_id: policyData.resource_group_id,
          name:
            resourceGroups?.find(
              rg => rg.resource_id === policyData.resource_group_id
            )?.name ?? '',
        },
        error: false,
        errorMessage: '',
      },
    });

    await fetchNamespaces();
    await fetchService(
      policyData.to.service.application_id,
      policyData.to.service.service_id
    );
  };

  const formatData = () => {
    if (networkSegment?.service_sync ? true : false) {
      toggleServiceSyncNotification(true);
    }
    setFromType(policyData?.from.type as PolicyOptionFromTypes);
    if (policyData.from.type === PolicyDataType.NAMESPACE) {
      const policyNamespaces = policyData?.from.namespaces.map(
        (obj: any) => obj.namespace_id
      ) as string[];
      const fullPolicyNamespaces =
        networkSegmentPartitions?.filter((partition: Item) =>
          policyNamespaces.includes(partition.id)
        ) ?? [];
      setOriginalPartitions(fullPolicyNamespaces);
      setSelectedData(fullPolicyNamespaces);
    } else {
      setSelectedData([
        {
          id: networkSegment.resource_id,
          label: networkSegment.name,
          type: PolicyDataType.NETWORKSEGMENT,
        },
      ]);
    }
  };

  const handleFromTypeChange = (value: PolicyDataType) => {
    if (value === PolicyDataType.NETWORKSEGMENT) {
      setSelectedData([
        {
          id: networkSegment.resource_id,
          label: networkSegment.name,
          type: value,
        },
      ]);
    }
    setFromType(value);
  };

  useEffect(() => {
    if (open) {
      setDataLoading(true);

      if (policyData) {
        fetchData();
      }
    }
  }, [open, policyData]);

  useEffect(() => {
    if (networkSegmentPartitions && svcData) {
      formatData();
      setDataLoading(false);
    }
  }, [networkSegmentPartitions, svcData]);

  return (
    <div className='edit-policy-container'>
      <MultiStepTearSheet
        open={open}
        onClose={closeTearsheet}
        onRequestSubmit={handleEditPolicy}
        submitButtonText={t('edit.save')}
        cancelButtonText={t('cancelButtonText')}
        backButtonText={t('backButtonText')}
        nextButtonText={t('nextButtonText')}
        description={t('description')}
        title={t('edit.title', { name: policyData?.name })}
        className='edit-policy-tearsheet'
      >
        <PolicyDefineDetails
          resourceGroupList={resourceGroups ?? []}
          formData={mutationObject}
          onChange={handleOnChange}
          formValid={true}
          heading={t('edit.detailsHeading')}
        >
          {showErrorSnackbar && renderErrorSnackbar()}
        </PolicyDefineDetails>
        <EditPolicyConnections
          formValid={formValid()}
          isDataLoading={dataLoading}
          fromType={fromType}
          selectedOptions={selectedData}
          availableOptions={networkSegmentPartitions ?? []}
          service={svcData}
          onViewAllOptions={(open: boolean) => setShowViewOptions(open)}
          onRemoveOption={(data: Item) => removeItem(data)}
          onAddOption={addItem}
          networkSegment={networkSegment}
        >
          {showServiceSyncNotification && renderServiceSyncNotification()}
        </EditPolicyConnections>
      </MultiStepTearSheet>
      <PolicyOptions
        open={showViewOptions}
        availableData={networkSegmentPartitions ?? []}
        direction={ConnectionSelectedTypes.FROM}
        selectedData={selectedData}
        selectedFromType={fromType}
        onClose={(save: boolean) => onCloseViewOptions(save)}
        onRefresh={() => refreshNamespaces()}
        permissionMap={permissionMap}
        selectedNetworkSegment={networkSegment}
        onChangeFromType={(value: PolicyDataType) =>
          handleFromTypeChange(value)
        }
        onItemSelect={(checked: boolean, item: Item) =>
          handleCheckedItem(checked, item)
        }
      />
    </div>
  );
};

export default EditPolicy;
