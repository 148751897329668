import React from 'react';
import { TooltipDefinition } from 'carbon-components-react';
import Overflow from '../Overflow/Overflow';

import { getIcon, IconType } from './config';

import './PolicyTargetField.scss';
import { TooltipPositionTypes } from '../../lib/enums';

interface Props {
  label: string | string[];
  type: IconType;
  to_label?: string;
  toolTipDirection?: 'top' | 'bottom';
}

const PolicyTargetField: React.FC<Props> = ({
  label,
  type,
  to_label,
  toolTipDirection,
}) => {
  const PolicyIcon = getIcon(type);

  // To segregate target values from array and display in list in tooltip overlay
  const getSeparateTooltipLabels = (tooltipLabel: string[]) => {
    return (
      <ul>
        {tooltipLabel.map((el, i) => (
          <li key={`${el}_${i}`}>{el}</li>
        ))}
      </ul>
    );
  };

  //To handle display of target values. If there are multiple values, display remaining values in tooltip definition
  const renderPolicyTargetName = (label: string | string[]) => {
    if (Array.isArray(label) && label.length > 0) {
      return (
        <>
          <span className='policy-target-name' test-id={'policy-target-name'}>
            {label[0]}
          </span>
          {label.length > 1 ? (
            <>
              {`,`}
              <TooltipDefinition
                direction={TooltipPositionTypes.TOP}
                align='center'
                tooltipText={getSeparateTooltipLabels(
                  label.slice(1, label.length)
                )}
              >
                {`+${label.length - 1}`}
              </TooltipDefinition>
            </>
          ) : (
            ''
          )}
        </>
      );
    } else return label;
  };

  return (
    <div className='policy-container'>
      {' '}
      {PolicyIcon && <PolicyIcon />}
      <Overflow toolTipDirection={toolTipDirection}>
        <div className='policy-target-container'>
          {to_label && <span className='policy-to-label'>{to_label}</span>}

          {label && (
            <span className='policy-target-main-label'>
              {renderPolicyTargetName(label)}
            </span>
          )}
        </div>
      </Overflow>
    </div>
  );
};

export default PolicyTargetField;
