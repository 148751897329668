import React from 'react';
import { Tearsheet } from '@carbon/ibm-products';

import './WideTearsheet.scss';

interface Action {
  kind: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
}

interface Props {
  title: string | JSX.Element;
  description?: string;
  className?: string;
  open: boolean;
  actions: Action[];
  dataTestId?: string;
  onClose?: any;
}

const WideTearsheet: React.FC<Props> = ({
  title,
  description,
  children,
  open,
  actions,
  className = '',
  dataTestId = 'wide-tearsheet',
  onClose = () => {},
}) => {
  return (
    <Tearsheet
      title={title}
      description={description}
      actions={actions}
      open={open}
      className={`wide-tearsheet-container ${className}`}
      data-testid={dataTestId}
      onClose={onClose}
    >
      {children}
    </Tearsheet>
  );
};

export default WideTearsheet;
