import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Column,
  Tile,
  Button,
  SkeletonPlaceholder,
  Modal,
} from 'carbon-components-react';
import Header from '../../components/Header/Header';
import DetailsCard from '../../components/DetailsCard/DetailsCard';

import Application24 from '@carbon/icons-react/lib/application/24';
import images from '../../images/images';
import './ConnectionAccessPolicyDetails.scss';
import {
  getPolicy,
  deletePolicy,
  getPolicies,
} from '../../controllers/policyApi';
import { useEffect, useState } from 'react';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import { useSearchParams } from 'react-router-dom';
import LabelTag from '../../components/LabelTag/LabelTag';
import dateUtils from '../../lib/dates';
import {
  Policy,
  ApplicationData,
  Service,
  ResourceGroup,
  PartitionData,
  Error500Type,
} from '../../models/master';
import { useNavigate } from 'react-router-dom';
import { getResourceGroups } from '../../controllers/resourceGroupApi';
import { AxiosError } from 'axios';
import { getNetworkSegmentDetails } from '../../controllers/networksegmentsApi';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import Error500 from '../Errors/Error500';
import PolicyCard from './CreateConnectionAccessPolicy/PolicyAllowConnections/PolicyCard/PolicyCard';
import {
  ConnectionSelectedTypes,
  NotAuthorised,
  PolicyDataType,
} from '../../lib/enums';
import EditPolicy from './EditConnectionAccessPolicy/EditPolicy';

interface PolicyData extends Policy {
  appData: ApplicationData;
  serviceData: Service;
  partitionData: PartitionData;
}

interface ServiceData extends Service {
  id: string;
  applicationName: string;
}

const ConnectionAccessPolicyDetails = () => {
  const { t } = useTranslation('connectionAccessPolicyDetails');
  const defaultPermissionMap = {
    networkSegment: true,
    applications: true,
    service: true,
    resourceGroup: true,
  };

  const [policyDataLoading, togglePolicyDataLoading] = useState(false);
  const [networkSegmentLoading, toggleNetworkSegmentLoading] = useState(false);
  const [policyData, setPolicyData] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const policyId = searchParams.get('policyId');
  const [openDeletePolicy, setOpenDeletePolicy] = useState<boolean>(false);
  const [showEditPolicyDetails, setShowEditPolicyDetails] =
    useState<boolean>(false);
  const notification = useContext(NotificationContext);

  const [resourceGroups, setResourceGroups] = useState<ResourceGroup[] | null>(
    null
  );

  const [disableButton, setDisableButton] = useState(false);
  const [networkSegment, setNetworkSegment] = useState<any>(null);
  const [policyList, setPolicyList] = useState<any>(null);
  const [error500, setError500] = useState<null | Error500Type>(null);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);

  const navigate = useNavigate();
  const { trackButtonClicked } = useAnalytics();

  useEffect(() => {
    fetchResourceGroups();
  }, []);

  const fetchData = async () => {
    togglePolicyDataLoading(true);
    try {
      let res = await getPolicy(policyId);
      setPolicyData(res);
    } catch (error) {
      const err = error as AxiosError;

      if (err.response?.status === 404) {
        navigate('/404');
      }

      if (err.response!?.status >= 500) {
        setError500(err.response!?.status?.toString() as Error500Type);
      }
    } finally {
      togglePolicyDataLoading(false);
    }
  };

  const fetchPolicies = async () => {
    try {
      let response = await getPolicies(false, false);
      setPolicyList(response);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchNwSegment(policyData?.network_segment_id);
  }, [policyData]);

  useEffect(() => {
    fetchData();
  }, [policyId]);

  useEffect(() => {
    fetchPolicies();
  }, []);

  const fetchResourceGroups = async () => {
    try {
      const resourceGroupResponse: { resource_groups: ResourceGroup[] } | any =
        await getResourceGroups();
      setResourceGroups(resourceGroupResponse?.resource_groups ?? []);
    } catch (error) {
      const err = error as AxiosError;
      if (err?.response?.status === 403) {
        setPermissionMap(prev => ({
          ...prev,
          resourceGroup: false,
        }));
      }
    }
  };

  const DeletePolicyModalClose = () => {
    setOpenDeletePolicy(false);
  };

  const openDeletePolicyModal = () => {
    trackButtonClicked(
      analyticsData['Policy Details'].events.deletePolicy.props,
      analyticsData['Policy Details'].events.deletePolicy.event
    );
    setOpenDeletePolicy(true);
  };

  const showEditPolicyModal = () => {
    trackButtonClicked(
      analyticsData['Policy Details'].events.editPolicy.props,
      analyticsData['Policy Details'].events.editPolicy.event
    );
    setShowEditPolicyDetails(true);
  };

  const editedPolicy = (data: PolicyData) => {
    setShowEditPolicyDetails(false);
    togglePolicyDataLoading(true);
    setPolicyData(data);
    fetchData();
  };

  const proceedDeletePolicy = async () => {
    try {
      setDisableButton(true);
      await deletePolicy(policyId);
      notification.onTrigger('TOAST', {
        title: t('success'),
        subtitle: t('successMsg'),
      });
      navigate('/connectionAccessPolicies');
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        notification.onTrigger('TOAST', {
          title: t('authTitleError'),
          kind: 'error',
          subtitle: t('authSubtitleError'),
        });
      } else {
        const errorMessage: string =
          error.response !== undefined
            ? error.response['customErrorMessage']
            : '';
        notification.onTrigger('TOAST', {
          title: t('error'),
          kind: 'error',
          subtitle: errorMessage.length > 0 ? errorMessage : t('errorMsg'),
        });
      }
    } finally {
      setDisableButton(false);
      setOpenDeletePolicy(false);
    }
  };

  const fromData =
    policyData?.from?.application?.application_id !== ''
      ? policyData?.appData
      : policyData?.from?.partition?.partition_id !== ''
      ? policyData?.partitionData
      : undefined;

  const fetchNwSegment = async (id: string) => {
    toggleNetworkSegmentLoading(true);
    try {
      if (id) {
        const response = await getNetworkSegmentDetails(id);
        setNetworkSegment(response);
      }
    } catch (error) {
      const err = error as AxiosError;
      console.error(err);
      if (
        err?.response?.status === 403 &&
        err?.response?.statusText === 'Forbidden'
      ) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          networkSegment: false,
        }));
      }
    } finally {
      toggleNetworkSegmentLoading(false);
    }
  };

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='connection-access-policy-details'>
      <Header
        loading={policyDataLoading}
        title={policyData?.name ?? ''}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
          {
            url: '/connectionAccessPolicies',
            label: t('connectionAccessPolicies'),
          },
        ]}
      />
      <div className='canvas'>
        <Column lg={4} className='details-card-container'>
          {policyDataLoading ? (
            <SkeletonPlaceholder className='policy-details-skeleton' />
          ) : (
            <DetailsCard
              type={'POLICY'}
              data={[
                {
                  key: 'name',
                  value: policyData?.name,
                },

                {
                  key: 'networkSegment',
                  value: networkSegment?.name,
                },
                {
                  key: 'gatewaySet',
                  value: networkSegment?.compatibility_set
                    ? t(`${networkSegment?.compatibility_set}`)
                    : '—',
                },
                {
                  key: 'resourceGroup',
                  value: resourceGroups
                    ? resourceGroups?.find(
                        resource =>
                          resource?.resource_id ===
                          policyData?.resource_group_id
                      )?.name
                    : '',
                },
                {
                  key: 'action',
                  value: t('allow') as string,
                },
                {
                  key: 'created',
                  value: dateUtils.getUserFriendlyDate(policyData?.created_at),
                },
                {
                  key: 'lastUpdated',
                  value: dateUtils.getUserFriendlyDate(policyData?.updated_at),
                },
                {
                  key: 'labels',
                  value: policyData?.labels,
                },
                {
                  key: 'description',
                  value: policyData?.description,
                },
              ]}
              openEditModal={showEditPolicyModal}
              detailsCardName={t('detailsCardName')}
              isEditable={true}
              authorizationMap={permissionMap}
            />
          )}
          {
            <div className='delete-policy'>
              <Button
                kind='danger--ghost'
                disabled={policyDataLoading}
                onClick={() => {
                  openDeletePolicyModal();
                }}
              >
                {t('deletePolicy')}
              </Button>
            </div>
          }
        </Column>
        <Column lg={12} className='from-to-connections-container'>
          {' '}
          <div className='from-to-connections-titlebar'>
            <div className='policy-title'>{t('title')}</div>
          </div>
          <div className='from-to-connections-subtitlebar'>
            {t('policySubTitle')}
          </div>
          <div className='from-to-connections'>
            <Column lg={7}>
              <Tile className='connections-tile'>
                <div className='title'>{t('from')}</div>
                {policyDataLoading || networkSegmentLoading ? (
                  <SkeletonPlaceholder className='connection-from-skeleton' />
                ) : (
                  policyData && (
                    <Tile className='connection'>
                      {policyData?.from?.type === PolicyDataType.NAMESPACE ? (
                        policyData.partitionPermission ? (
                          policyData?.from?.namespaces.length > 0 ? (
                            policyData?.namespaces?.map((item: any) => (
                              <PolicyCard
                                key={item?.resource_id}
                                label={item?.environment + '/' + item?.name}
                                type={PolicyDataType.NAMESPACE}
                                heading={ConnectionSelectedTypes.FROM}
                                onRemove={() => ''}
                              />
                            ))
                          ) : (
                            '-'
                          )
                        ) : (
                          <PolicyCard
                            key={t('notAuthorised')}
                            label={t('notAuthorised')}
                            type={NotAuthorised}
                            heading={ConnectionSelectedTypes.FROM}
                            onRemove={() => ''}
                          />
                        )
                      ) : policyData?.from?.type ===
                        PolicyDataType.NETWORKSEGMENT ? (
                        permissionMap.networkSegment ? (
                          <>
                            {policyData?.network_segment_id &&
                            networkSegment?.name ? (
                              <PolicyCard
                                key={policyData?.network_segment_id}
                                label={networkSegment?.name}
                                type={PolicyDataType.NETWORKSEGMENT}
                                heading={ConnectionSelectedTypes.FROM}
                                onRemove={() => ''}
                              />
                            ) : (
                              '—'
                            )}
                          </>
                        ) : (
                          <PolicyCard
                            key={t('notAuthorised')}
                            label={t('notAuthorised')}
                            type={NotAuthorised}
                            heading={ConnectionSelectedTypes.FROM}
                            onRemove={() => ''}
                          />
                        )
                      ) : (
                        <>
                          <Row className='name-and-icon'>
                            {fromData ? (
                              <>
                                <div className='tile-icon'>
                                  {policyData?.from?.application
                                    .application_id !== '' ? (
                                    <Application24 className='icon' />
                                  ) : (
                                    images.partitionMediumIcon()
                                  )}
                                </div>
                                <div
                                  className='policy-header'
                                  title={fromData?.name}
                                >
                                  {fromData?.name}
                                </div>
                              </>
                            ) : (
                              '—'
                            )}
                          </Row>
                          <Row className='app-description'>
                            {fromData?.description}
                          </Row>

                          <Row>
                            <div className='labels-container'>
                              {fromData?.labels &&
                              Array.isArray(fromData?.labels) &&
                              fromData?.labels.length > 0 ? (
                                <LabelTag
                                  labelArray={fromData?.labels as string[]}
                                  count={3}
                                ></LabelTag>
                              ) : (
                                ''
                              )}
                            </div>
                          </Row>
                        </>
                      )}
                    </Tile>
                  )
                )}
              </Tile>
            </Column>
            <div className='connector'>
              <div>{images.policyConnectionIcon()}</div>
            </div>
            <Column lg={7}>
              <Tile className='connections-tile'>
                <div className='title'>{t('to')}</div>
                {policyDataLoading ? (
                  <SkeletonPlaceholder className='connection-to-skeleton' />
                ) : (
                  policyData && (
                    <Tile className='connection'>
                      <div className='to-container'>
                        {policyData.servicePermission ? (
                          <>
                            {policyData?.serviceData?.name ? (
                              <PolicyCard
                                key={policyData?.serviceData?.resource_id}
                                label={policyData?.serviceData?.name}
                                type={PolicyDataType.SERVICE}
                                heading={ConnectionSelectedTypes.TO}
                                onRemove={() => ''}
                              />
                            ) : (
                              '-'
                            )}
                          </>
                        ) : (
                          <PolicyCard
                            key={t('notAuthorised')}
                            label={t('notAuthorised')}
                            type={NotAuthorised}
                            heading={ConnectionSelectedTypes.TO}
                            onRemove={() => ''}
                          />
                        )}
                      </div>
                    </Tile>
                  )
                )}
              </Tile>
            </Column>
          </div>
        </Column>
      </div>
      <Modal
        open={openDeletePolicy}
        danger
        modalHeading={t('deleteMsg', { policyName: policyData?.name })}
        modalLabel={t('deletePolicy')}
        primaryButtonText={t('delete')}
        secondaryButtonText={t('cancel')}
        preventCloseOnClickOutside={true}
        onRequestSubmit={() => {
          proceedDeletePolicy();
        }}
        onRequestClose={() => {
          DeletePolicyModalClose();
        }}
        className='deletePolicyModel'
        size='sm'
        primaryButtonDisabled={disableButton}
      />

      {showEditPolicyDetails && policyData && (
        <EditPolicy
          open={showEditPolicyDetails}
          onClose={() => setShowEditPolicyDetails(false)}
          policyData={policyData}
          onEdit={editedPolicy}
          networkSegment={networkSegment}
          hasNetworkSegmentAuth={permissionMap.networkSegment}
          policyList={policyList}
          resourceGroups={resourceGroups}
          hasResourceGroupAuth={permissionMap.resourceGroup}
        />
      )}
    </div>
  );
};

export default ConnectionAccessPolicyDetails;
