import dateUtils from './dates';

export const sortData = (rows, sortKey, sortDirection) => {
  let sortedRows = Array.isArray(rows) ? rows : [];
  if (Array.isArray(rows) && rows.length > 0) {
    if (sortDirection === 'DESC') {
      if (typeof rows[0][sortKey] === 'number')
        sortedRows = rows.sort((a, b) => b[sortKey] - a[sortKey]);
      else if (dateUtils.isDate(rows[0][sortKey])) {
        sortedRows = rows.sort((a, b) => {
          let firstVal = new Date(a[sortKey]),
            secondVal = new Date(b[sortKey]);
          return firstVal < secondVal ? 1 : secondVal < firstVal ? -1 : 0;
        });
      } else if (typeof rows[0][sortKey] === 'string')
        sortedRows = rows.sort((a, b) => {
          const firstVal = a[sortKey]?.trim()?.toLowerCase();
          const secondVal = b[sortKey]?.trim()?.toLowerCase();

          return firstVal < secondVal ? 1 : secondVal < firstVal ? -1 : 0;
        });
      else if (Array.isArray(rows[0][sortKey])) {
        sortedRows = rows.sort(
          (a, b) => b[sortKey]?.length - a[sortKey]?.length
        );
      }
    } else if (sortDirection === 'ASC') {
      if (typeof rows[0][sortKey] === 'number')
        sortedRows = rows.sort((a, b) => a[sortKey] - b[sortKey]);
      else if (dateUtils.isDate(rows[0][sortKey])) {
        sortedRows = rows.sort((a, b) => {
          let firstVal = new Date(a[sortKey]),
            secondVal = new Date(b[sortKey]);
          return firstVal > secondVal ? 1 : secondVal > firstVal ? -1 : 0;
        });
      } else if (typeof rows[0][sortKey] === 'string')
        sortedRows = rows.sort((a, b) => {
          const firstVal = a[sortKey]?.trim()?.toLowerCase();
          const secondVal = b[sortKey]?.trim()?.toLowerCase();
          return firstVal > secondVal ? 1 : secondVal > firstVal ? -1 : 0;
        });
      else if (Array.isArray(rows[0][sortKey])) {
        sortedRows = rows.sort(
          (a, b) => a[sortKey]?.length - b[sortKey]?.length
        );
      }
    }
  }
  return sortedRows;
};
export default sortData;
