import React, { useState, useMemo, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';
import {
  ApplicationData,
  AppliedFilter,
  DeploymentEnvironment,
  Gateway,
  GatewayCustomData,
  NetworkSegment,
  ResourceData,
} from '../../../models/master';
import {
  useGateways,
  useGatewaysCloudDetails,
  useGatewaysLocationDetails,
  useGatewaysPartitionDetails,
} from '../../../hooks/useGateways';
import {
  useDeploymentEnvsData,
  useClustersNamespaces,
} from '../../../hooks/useDeploymentEnvs';
import {
  GatewayLifeCycleState,
  GatewaySubTypes,
  ProceduralStatus,
  VisibilityFlags,
} from '../../../lib/enums';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';
import { Tabs, Tab, Button, OnChangeData } from 'carbon-components-react';
import images from '../../../images/images.js';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import HealthStatus from '../../../components/GatewayStatus/HealthStatus';
import {
  HealthStatusType,
  proceduralStatusField,
  showProcedureAttribute,
} from '../../../components/GatewayStatus/config';
import GatewayProcedure from '../../../components/GatewayStatus/GatewayProcedure';
import ProceduralActivity from '../../../components/ProceduralActivity/ProceduralActivity';
import GenericStatusField from '../../../components/GenericStatusField/GenericStatusField';
import dateUtils from '../../../lib/dates';
import sortData from '../../../lib/tableSort';
import { AxiosError } from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import './GatewayListView.scss';
import ConnectGatewayCluster from '../../../components/ConnectGatewayCluster/ConnectGatewayCluster';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../../lib/constants.js';

interface GatewayTableRows {
  id: any;
  gateway_id: string;
  cloud_id: string | undefined;
  location_id: string | undefined;
  name: string | undefined;
  name__format: JSX.Element;
  network_segment_name: JSX.Element | string | undefined;
  networkSegmentPermission: boolean;
  type?: string;
  health_status: JSX.Element | undefined | string;
  health_status_format: JSX.Element | undefined | string;
  cloud_location: string;
  cluster_namespace: string;
  procedural_activity: JSX.Element | string | undefined;
  procedural_status: JSX.Element | undefined;

  deployedIn: string | undefined;
  radioButton?: JSX.Element;

  action_button: JSX.Element;
}

const GatewayListView = (props: {
  selectedNetworkSegment: NetworkSegment | null;
  refreshNetworkSegmentDetails: () => void;
  networkSegmentList: NetworkSegment[] | null;
  setAllGateways: React.Dispatch<React.SetStateAction<any>>;
  unknownNetworkSegmentSelected: boolean;
}) => {
  const { t } = useTranslation('networkSegments');
  const { trackButtonClicked } = useAnalytics();
  const [gatewaysCurrentPageNumber, setGatewaysPageNumber] = useState(1);
  const [gatewaysCurrentPageSize, setGatewaysPageSize] = useState(10);
  const [filteredGatewayData, setFilteredGatewayData] = useState<
    GatewayCustomData[] | []
  >([]);
  const [filterGatewayApplied, setFilterGatewayApplied] = useState<
    AppliedFilter[] | []
  >([]);
  const [gatewaysSortKey, setGatewaysSortKey] = useState('');
  const [gatewaysSortDirection, setGatewaysSortDirection] = useState<
    'ASC' | 'DESC' | 'NONE'
  >('NONE');
  const [selectedGateway, setSelectedGateway] =
    useState<GatewayCustomData | null>(null);
  const [showConnectGatewayClusterForm, setShowConnectGatewayClusterForm] =
    useState(false);

  const navigate = useNavigate();

  const permission = {
    deploymentEnv: true,
    gateways: true,
    applications: true,
    namespaces: true,
    policies: true,
    cloud: true,
    location: true,
    networkSegment: true,
  };
  const error500 = {
    deploymentEnv: false,
    gateways: false,
    applications: false,
    namespaces: false,
    policies: false,
    cloud: false,
  };

  // Queries deployment environment
  const {
    data: deploymentEnvironments,
    isLoading: loadingDeploymentEnvs,
    error: deploymentEnvError,
    isError: isDeploymentEnvError,
    refetch: refetchDeploymentEnvs,
    isRefetching: refetchingDeploymentEnvs,
  } = useDeploymentEnvsData(VisibilityFlags.MANAGED);
  if (isDeploymentEnvError) {
    const error = deploymentEnvError as AxiosError;
    permission.deploymentEnv = error?.response?.status === 403 ? false : true;
    error500.deploymentEnv = error.response!?.status >= 500 ? true : false;
  }
  // Queries gateways
  const {
    data: allGateways,
    isLoading: loadingGateways,
    error: gatewayError,
    isError: isGatewayError,
    refetch: refetchGateways,
    isRefetching: refetchingGateways,
  } = useGateways(VisibilityFlags.ALL);
  if (isGatewayError) {
    const error = gatewayError as AxiosError;
    permission.gateways = error?.response?.status === 403 ? false : true;
    error500.gateways = error.response!?.status >= 500 ? true : false;
  }

  const gateways = useMemo(() => allGateways, [allGateways]);
  props.setAllGateways(gateways);

  // TODO: Below is for getting the names of resources. This should be removed once the main gateways list api returns the specific resource names.
  const gatewayCloudDetails = useGatewaysCloudDetails(
    (allGateways as GatewayCustomData[])?.filter(
      gateway => gateway.subtype === GatewaySubTypes.RHSI_EDGE
    ),
    {
      enabled: !refetchingGateways,
    }
  );

  if (gatewayCloudDetails?.[0]?.isError) {
    const axiosError = gatewayCloudDetails[0].error as AxiosError;
    if (axiosError.response?.status === 403) {
      permission.cloud = false;
    }
  }

  const gatewayLocationDetails = useGatewaysLocationDetails(
    (allGateways as GatewayCustomData[])?.filter(
      gateway => gateway.subtype === GatewaySubTypes.RHSI_EDGE
    ),
    {
      enabled: !refetchingGateways,
    }
  );

  if (gatewayLocationDetails?.[0]?.isError) {
    const axiosError = gatewayLocationDetails[0].error as AxiosError;
    if (axiosError.response?.status === 403) {
      permission.location = false;
    }
  }

  const gatewayPartitionDetails = useGatewaysPartitionDetails(
    (allGateways as GatewayCustomData[])?.filter(
      gateway => gateway.subtype === GatewaySubTypes.RHSI_EDGE
    ),
    {
      enabled: !refetchingGateways,
    }
  );

  const getDeplEnvName = (gateway: Gateway) => {
    const depEnv = deploymentEnvironments?.find(
      (dep: DeploymentEnvironment) =>
        dep.resource_id === gateway?.deployed_in_depl_env_id
    );
    return depEnv?.name;
  };

  const getGatewayClusterNamespace = (gateway: GatewayCustomData) => {
    const namespaceName = gateway.namespace_name;
    const clusterName = getDeplEnvName(gateway) ?? '—';

    return `${clusterName}/${namespaceName}`;
  };

  const getCloudName = (gateway: GatewayCustomData) => {
    const cloudName =
      gatewayCloudDetails?.find(
        cloud => cloud?.data?.resource_id === gateway.cloud_id
      )?.data?.name ?? '';
    return cloudName;
  };

  const getLocationName = (gateway: GatewayCustomData) => {
    const locationName =
      gatewayLocationDetails?.find(
        location => location?.data?.resource_id === gateway.location_id
      )?.data?.name ?? '';

    return locationName;
  };

  const getGatewayCloudLocation = (gateway: GatewayCustomData) => {
    const cloudName = getCloudName(gateway);
    const locationName = getLocationName(gateway);

    return `${cloudName}/${locationName}`;
  };
  const getGatewayStatus = (gateway: GatewayCustomData) => {
    let statusWithIcon;

    if (gateway.procedural_status === ProceduralStatus.NOT_STARTED) {
      statusWithIcon = '—';
    } else if (gateway.procedural_activity) {
      return <ProceduralActivity status={gateway.procedural_activity} />;
    } else if (gateway.procedural_status === ProceduralStatus.IN_PROGRESS) {
      statusWithIcon = <GatewayProcedure status={gateway.procedure} />;
    } else if (
      gateway.lifecycle_state === GatewayLifeCycleState.GW_NOT_DEPLOYED &&
      gateway.unmanaged
    ) {
      return <ProceduralActivity status={gateway.lifecycle_state} />;
    } else {
      statusWithIcon = '—';
    }

    return statusWithIcon;
  };

  const getNetworkSegmentResources = <T,>(data: T[]) => {
    return data?.filter(
      (resource: T) =>
        (resource as ResourceData).network_segment_id ===
        props.selectedNetworkSegment?.resource_id
    );
  };

  const getNetworkSegmentGateways = (
    gateways: GatewayCustomData[],
    selectedNetworkSegment: NetworkSegment
  ) => {
    return selectedNetworkSegment
      ? gateways?.filter(
          gateway =>
            gateway.network_segment_id === selectedNetworkSegment?.resource_id
        )
      : null;
  };

  const getUnknowNetworkSegmentGateways = (gateways: GatewayCustomData[]) => {
    return gateways?.filter(gateway => !gateway.network_segment_id);
  };

  const getNetworkSegmentName = (networkSegmentId: string) => {
    if (Array.isArray(props.networkSegmentList) && networkSegmentId) {
      const networksegment = props.networkSegmentList.find(
        networksegment => networksegment.resource_id === networkSegmentId
      );

      return networksegment?.name ?? '—';
    }

    return '—';
  };

  const handleShowConnectGatewayClusterForm = (gateway: GatewayCustomData) => {
    setSelectedGateway(gateway);
    setShowConnectGatewayClusterForm(true);
  };

  const handleCloseConnectGatewayClusterForm = () => {
    setSelectedGateway(null);
    setShowConnectGatewayClusterForm(false);
  };

  const renderConnectClusterButton = (gateway: GatewayCustomData) => {
    if (
      gateway.unmanaged &&
      gateway.lifecycle_state === 'gw-deployed' &&
      gateway.procedural_activity === 'waiting-connection' &&
      !gateway.deployed_in_depl_env_id &&
      !gateway.deployed_in_partition_id
    ) {
      return (
        <Button
          className='connect-gateway-cluster-btn'
          kind='ghost'
          onClick={() => handleShowConnectGatewayClusterForm(gateway)}
          data-testid={`connect-gateway-cluster-btn-${gateway.resource_id}`}
        >
          {t('onboardingNetworkSegment.gateways.connectCluster')}
        </Button>
      );
    }

    return <></>;
  };

  const formatGateways = (
    selectedNetworkSegment: NetworkSegment | null,
    unknowNetworkSegmentSelected: boolean
  ) => {
    let formattedRows: GatewayTableRows[] = [];
    if (gateways && gateways.length === 0) return [];

    if (gateways) {
      const networkSegmentGateways = unknowNetworkSegmentSelected
        ? getUnknowNetworkSegmentGateways(gateways)
        : selectedNetworkSegment
        ? getNetworkSegmentGateways(gateways, selectedNetworkSegment)
        : gateways;

      networkSegmentGateways?.map((row: GatewayCustomData, index: number) => {
        const deployedInVal = getDeplEnvName(row);
        const siteconfig = row.site_configuration
          ? JSON.parse(row.site_configuration)
          : {};
        formattedRows.push({
          id: row.resource_id + index,
          gateway_id: row.resource_id,
          cloud_id: row.cloud_id,
          location_id: row.location_id,
          name: row.name ?? '—',
          name__format: (
            <Link
              className='gateway-name-link'
              onClick={() => {
                trackButtonClicked(
                  analyticsData['Gateways'].events.openGatewayDetails.props,
                  analyticsData['Gateways'].events.openGatewayDetails.event
                );
              }}
              to={`/gatewayDetails?gatewayId=${row.resource_id}`}
              data-testid='gateway-name-link'
            >
              <div
                className={`gateway-name-container ${
                  row.unmanaged ? 'unmanaged' : ''
                }`}
              >
                <div className='icon'>
                  {row.unmanaged && images.unmanagedIcon()}
                </div>
                <div className='name' data-testid={`gateway-name${index}`}>
                  <GenericTruncateString str={row?.name} tableView={true} />
                </div>
              </div>
            </Link>
          ),
          networkSegmentPermission: true,
          network_segment_name: getNetworkSegmentName(row?.network_segment_id),
          health_status: row?.health_status,
          health_status_format: row?.health_status ? (
            <HealthStatus
              status={row?.health_status as HealthStatusType}
              showLabel={true}
              viewEventsLink={true}
              gatewayName={row.name}
            />
          ) : (
            '—'
          ),
          deployedIn: deployedInVal?.concat('/', ''),
          cloud_location: getGatewayCloudLocation(row),
          cluster_namespace: getGatewayClusterNamespace(row),
          procedural_activity: getGatewayStatus(row),
          procedural_status: (
            <div
              className={
                'procedural-status-row' + (row.unmanaged ? ' unmanaged' : '')
              }
            >
              {proceduralStatusField(row)}
            </div>
          ),
          action_button: renderConnectClusterButton(row),
        });
        return 0;
      });
    } else return null;
    return formattedRows;
  };

  const formattedGateways = useMemo(
    () =>
      formatGateways(
        props.selectedNetworkSegment,
        props.unknownNetworkSegmentSelected
      ),
    [
      gateways,
      deploymentEnvironments,
      gatewayCloudDetails,
      gatewayLocationDetails,
      gatewayPartitionDetails,
    ]
  );
  const setSortDirection = (value: 'ASC' | 'DESC' | 'NONE') => {
    setGatewaysSortDirection(value);
  };

  const setSortKey = (value: string) => {
    setGatewaysSortKey(value);
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };
  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const handleRefresh = () => {
    refetchDeploymentEnvs();
    refetchGateways();
  };

  const setPageNumber = (value: number) => {
    setGatewaysPageNumber(value);
  };

  const setPageSize = (value: number) => {
    setGatewaysPageSize(value);
  };

  const isLoading = () =>
    loadingGateways ||
    refetchingGateways ||
    gatewayCloudDetails.some(details => details.isFetching) ||
    gatewayLocationDetails.some(details => details.isFetching) ||
    gatewayPartitionDetails.some(details => details.isFetching);

  useEffect(() => {
    handleRefresh();
  }, [props.selectedNetworkSegment, props.unknownNetworkSegmentSelected]);

  return (
    <div className='network-segment-resources-list'>
      <div className='table' data-testid={`resource-tab-gateway-view-table`}>
        {!props.unknownNetworkSegmentSelected &&
          props.selectedNetworkSegment && (
            <div className='filterNetworkSegmentTitle'>
              <Trans
                i18nKey={t(
                  'onboardingNetworkSegment.filterNetworkSegmentTitle',
                  {
                    name: props.selectedNetworkSegment?.name,
                  }
                )}
              ></Trans>
            </div>
          )}
        <GenericTableWithFilters
          id='gateways-view-table'
          hasFilter={true}
          showSearch={false}
          rows={
            formattedGateways!?.length > 0
              ? [
                  ...sortData(
                    formatGateways(
                      props.selectedNetworkSegment,
                      props.unknownNetworkSegmentSelected
                    ),
                    gatewaysSortKey,
                    gatewaysSortDirection
                  ),
                ]
              : []
          }
          data={
            formattedGateways
              ? filterGatewayApplied?.length > 0
                ? filteredGatewayData
                : formattedGateways
              : []
          }
          headers={[
            {
              key: 'name__format',
              originalKey: 'name',
              header: t('onboardingNetworkSegment.gateways.name'),
              sort: true,
            },
            {
              key: 'network_segment_name',
              originalKey: 'network_segment_name',
              header: t('onboardingNetworkSegment.gateways.network_segment'),
              sort: true,
            },
            {
              key: 'health_status_format',
              originalKey: 'health_status',
              header: t('onboardingNetworkSegment.gateways.health_status'),
              sort: true,
            },
            {
              key: 'cloud_location',
              originalKey: 'cloud_location',
              header: t('onboardingNetworkSegment.gateways.cloud_location'),
              sort: true,
            },
            {
              key: 'cluster_namespace',
              originalKey: 'cluster_namespace',
              header: t('onboardingNetworkSegment.gateways.cluster_namespace'),
              sort: true,
            },
            {
              key: 'procedural_status',
              originalKey: 'procedural_activity',
              header: t('onboardingNetworkSegment.gateways.onboarding_status'),
              sort: true,
              style: { minWidth: '10.5rem' },
            },
            {
              key: 'action_button',
              originalKey: 'action_button',
              header: '',
              sort: false,
            },
          ]}
          isSortable={true}
          totalElementsCount={formattedGateways?.length || 0}
          fullData={formattedGateways}
          onTableRefresh={() => {
            handleRefresh();
          }}
          filteredDataCallback={data => {
            data && setFilteredGatewayData(data as GatewayCustomData[] | []);
          }}
          selectedFiltersVal={[]}
          setFilterApplied={(data: AppliedFilter[]) =>
            setFilterGatewayApplied(data)
          }
          filters={[]}
          currentPageNumber={gatewaysCurrentPageNumber}
          currentPageSize={gatewaysCurrentPageSize}
          onPageChange={(pageData: any) => setPageChange(pageData)}
          emptyState={{
            icon: images.emptyNetworkSegmentSvg(),
            notFoundIcon: images.NotFoundSmall(),
            emptyStateHeader: t('emptyState.emptyContainerHeader'),
            emptyStateDescription: t('todeployagent'),
            link: '/',
            buttonText: t('resourcesList.emptyState.gateways.buttonText'),
          }}
          sortRows={(
            data: { id: string; text: string },
            sortDirection: 'ASC' | 'DESC' | 'NONE'
          ) => handleTableSort(data, sortDirection)}
          render403Container={!permission.gateways}
          render500Container={error500.gateways}
          tableDataLoading={isLoading()}
        />
      </div>

      <ConnectGatewayCluster
        open={showConnectGatewayClusterForm}
        onClose={() => handleCloseConnectGatewayClusterForm()}
        onGatewayConnect={() => {
          refetchDeploymentEnvs();
          refetchGateways();
          props.refreshNetworkSegmentDetails();
        }}
        gateway={selectedGateway}
      />
    </div>
  );
};

export default GatewayListView;
