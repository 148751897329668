import { MCNM_NETWORK_API_URL } from './config';
import { axiosInstance } from './api';
import { VisibilityFlags, EnvironmentTypes } from '../lib/enums';
//Get all of the deploymentenvs that MCNM oversees
export async function getDeploymentEnvs(
  view = 'all',
  throwError = false,
  includeInfraOnly = 'all'
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/deploymentenv?include_infra_only=${includeInfraOnly}&visibility=${view}`,
    });
    if (response.data.deploymentenvs === null) {
      return [];
    }
    return response.data.deploymentenvs;
  } catch (error) {
    // Note: I added an extra throwError parameter here to make this function backward compatible.
    if (throwError) {
      throw error;
    }

    if (error.response.status === 404) {
      return [];
    }
    console.error(error);
  }
}

//Get one of the deploymentenvs that MCNM oversees
export async function getDeploymentEnv(deploymentenv_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/deploymentenv/${deploymentenv_id}`,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Get all of the partitions that MCNM oversees
export async function getPartitions(deploymentenv_id, view = 'all') {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/deploymentenv/${deploymentenv_id}/partition?visibility=${view}`,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//et one of the partitions that MCNM oversees
export async function getPartition(deploymentenv_id, partition_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/deploymentenv/${deploymentenv_id}/partition/${partition_id}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getDeploymentEnvSubtypes() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/deploymentenvsubtypes`,
    });
    return response.data.deploymentenvsubtypes;
  } catch (error) {
    if (
      (error.response.status === 403 &&
        error.response.statusText === 'Forbidden') ||
      error.response.status === 404
    ) {
      return [];
    }
    console.log(error);
  }
}

//Update an existing namespace that MCNM oversees
export async function updatePartitionNamespace(
  cloud_id,
  cluster_id,
  namespace_id,
  data
) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/cluster/${cluster_id}/namespace/${namespace_id}`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Update an existing namespace that MCNM oversees
export async function updateNamespaceData(
  cloud_id,
  cluster_id,
  namespace_id,
  data
) {
  try {
    const response = await axiosInstance({
      method: 'PATCH',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/cluster/${cluster_id}/namespace/${namespace_id}`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Update an existing security-group that MCNM oversees
export async function updatePratitionSecurityGroup(
  cloud_id,
  vpc_id,
  securitygroup_id,
  data
) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/vpc/${vpc_id}/securitygroup/${securitygroup_id}`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Delete one of the deploymentenvs that MCNM oversees
export async function deleteDeploymentEnv(deploymentenv_id, envType, cloud_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/${envType}/${deploymentenv_id}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Update an existing namespace/security group that MCNM oversees
export async function updatePartition(
  depEnvType,
  partitionType,
  cloud_id,
  deploymentenv_id,
  partition_id,
  data
) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/${depEnvType}/${deploymentenv_id}/${partitionType}/${partition_id}`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

/**
 * Fetches all partitions(namespaces and security groups) for all deployment environments(cluster and vpc)
 */
export async function getDeploymentEnvsPartitions(view = 'all') {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/deploymentenv?visibility=${view}`,
    });
    if (response.data.deploymentenvs === null) {
      return [];
    }

    let partitions = [];
    const partitionsData = await Promise.all(
      response.data.deploymentenvs.map(async deplEnv => {
        const envId = deplEnv.resource_id;
        if (
          deplEnv.type === EnvironmentTypes.CLUSTER &&
          deplEnv.type !== EnvironmentTypes.NODE &&
          deplEnv.type !== EnvironmentTypes.VPC
        ) {
          partitions = await getPartitions(envId, VisibilityFlags.MANAGED).then(
            data => data.partitions
          );
        }

        return {
          ...deplEnv,
          partitions,
        };
      })
    );
    return partitionsData;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

/**
 * Get details of existing namespace that MCNM oversees
 */
export async function getNamespaceDetails(cloudId, clusterId, namespaceId) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloudId}/cluster/${clusterId}/namespace/${namespaceId}`,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Get all of the namespace in a particular cluster that MCNM oversees
export async function getNamespace(cloud_id, cluster_id, view = 'all') {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/cluster/${cluster_id}/namespace?visibility=${view}`,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
