import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericTableWithFilters from '../../components/GenericTableWithFilters/GenericTableWithFilters';
import LabelTag from '../../components/LabelTag/LabelTag';
import { Link } from 'react-router-dom';
import {
  AppliedFilter,
  NetworkSegment,
  ResourceGroup,
} from '../../models/master';

import './NetworkSegmentsTable.scss';
import GenericTruncateString from '../../components/GenericTruncateString/GenericTruncateString';
import images from '../../images/images';
import { TooltipDefinition } from 'carbon-components-react';
import GenericStatusField from '../../components/GenericStatusField/GenericStatusField';
import { Filter } from '../../components/FindAndFilterBar/FindAndFilterBar';
import ProceduralActivity from '../../components/ProceduralActivity/ProceduralActivity';
import GatewayProcedure from '../../components/GatewayStatus/GatewayProcedure';
import { ProceduralStatus } from '../../lib/enums';
import Overflow from '../../components/Overflow/Overflow';

interface NwSegmentTableRows {
  id: any;
  name:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  name__format: JSX.Element;
  gatewaySet: JSX.Element;
  infrastructureGroup: JSX.Element;
  description: JSX.Element;
  labels: JSX.Element | null | string;
  flow_collector: JSX.Element | string;
  procedural_status: JSX.Element | string;
}

const NetworkSegmentsTable = (props: {
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  rows: NetworkSegment[] | null;
  data: NetworkSegment[] | null;
  filteredDataSet: NetworkSegment[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  resourceGroupsList: ResourceGroup[] | null;
  persistFilter?: boolean;
  error403Flag: boolean;
  error500Flag: boolean;
  resourceGroupPermission: boolean;
  leftInlineFilters?: Filter[];
  visibilityFlag?: string;
  dataLoading?: boolean;
}) => {
  const { t } = useTranslation('networkSegments');
  const { rows, onRefresh } = props;
  const error403Flag = props.error403Flag;
  const error500Flag = props.error500Flag;
  const formatTags = (data: string[]) => {
    return <LabelTag labelArray={data} count={3} />;
  };

  const myheaders = [
    {
      key: 'name__format',
      header: t('tableHeaders.name'),
      sort: 'sortByName',
      originalKey: 'name',
    },
    {
      key: 'gatewaySet',
      originalKey: 'gatewaySet',
      header: t('tableHeaders.gatewaySet'),
    },
    {
      key: 'infrastructureGroup',
      originalKey: 'infrastructureGroup',
      header: t('tableHeaders.infrastructureGroup'),
    },
    {
      key: 'procedural_status',
      originalKey: 'procedural_status',
      header: t('tableHeaders.procedural_status'),
      style: { minWidth: '9rem' },
    },
    {
      key: 'flow_collector',
      originalKey: 'flow_collector',
      header: t('tableHeaders.metricCollection'),
      style: { minWidth: '9rem' },
    },
    {
      key: 'labels',
      originalKey: 'labels',
      header: t('tableHeaders.labels'),
    },
    {
      key: 'description',
      originalKey: 'description',
      header: t('tableHeaders.description'),
    },
  ];

  const getResourceGroupName = (id: string) => {
    const resouceGroup =
      id &&
      Array.isArray(props.resourceGroupsList) &&
      props.resourceGroupsList.find(
        resouceGroup => resouceGroup.resource_id === id
      );

    return resouceGroup ? resouceGroup.name : '';
  };

  /**
   * @description - Function to format the raw data into table rows format.
   * @returns {NwSegmentTableRows[] | [] | null} - Returns the formatted data to be rendered as table rows.
   */

  const setRowsData = () => {
    let formattedRows: NwSegmentTableRows[] = [];
    if (error403Flag) return [];
    if (error500Flag) return [];
    if (Array.isArray(rows) && rows.length === 0) return [];

    if (rows)
      rows.map((row: NetworkSegment) => {
        formattedRows.push({
          id: row.resource_id,
          name: !!row.name ? row.name : '—',
          name__format: (
            <div
              className={
                'networksegment-name ' + (row?.unmanaged ? 'unmanaged' : '')
              }
            >
              {row.unmanaged ? <div className='unmanaged-icon'></div> : ''}
              <Link
                className='no-underline-link'
                to={`/networkSegmentDetails?nwSegId=${row.resource_id}`}
              >
                <GenericTruncateString str={row?.name} tableView={true} />
              </Link>
            </div>
          ),
          gatewaySet: (
            <div
              className={
                'networksegment-gatewaySet ' +
                (row?.unmanaged ? 'unmanaged' : '')
              }
            >
              <GenericTruncateString
                str={
                  row?.compatibility_set ? t(`${row?.compatibility_set}`) : '—'
                }
                tableView={true}
                maxLength={20}
                limit={15}
              />
            </div>
          ),
          infrastructureGroup: (
            <div
              className={
                'networksegment-infrastructureGroup ' +
                (row?.unmanaged ? 'unmanaged' : '')
              }
            >
              {!props.resourceGroupPermission ? (
                <GenericStatusField status={'notAuthorised'} />
              ) : (
                <GenericTruncateString
                  str={getResourceGroupName(row.resource_group_id)}
                  tableView={true}
                  maxLength={35}
                  limit={20}
                />
              )}
            </div>
          ),
          description: (
            <div
              className={
                'networksegment-description ' +
                (row?.unmanaged ? 'unmanaged' : '')
              }
            >
              {row.description ? (
                <Overflow
                  align='start'
                  toolTipDirection='top'
                  key={`title-${row.description}`}
                >
                  <div>{row.description || '—'}</div>
                </Overflow>
              ) : (
                '—'
              )}
            </div>
          ),
          labels: (
            <div
              className={
                'networksegment-label ' + (row?.unmanaged ? 'unmanaged' : '')
              }
            >
              {!!row.labels ? formatTags(row.labels) : '—'}
            </div>
          ),
          flow_collector: (
            <div
              className={
                'networksegment-metricsCollection ' +
                (row?.unmanaged ? 'unmanaged' : '')
              }
            >
              {row?.flow_collector ? t('enabled') : '—'}
            </div>
          ),
          procedural_status: getProceduralStatus(row),
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const getProceduralStatus = (row: NetworkSegment) => {
    if (row?.procedural_activity) {
      return <ProceduralActivity status={row?.procedural_activity} />;
    } else if (
      row?.procedural_status === ProceduralStatus.IN_PROGRESS &&
      row?.procedure
    ) {
      return <GatewayProcedure status={row.procedure} />;
    } else return <>—</>;
  };

  const emptyStateData = {
    icon: <></>,
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
  };

  return (
    <GenericTableWithFilters
      id='table-with-filter-panel'
      rows={setRowsData()}
      data={props.filteredDataSet}
      headers={myheaders}
      isSortable={true}
      totalElementsCount={props.elementCount ? props.elementCount : 0}
      fullData={props.data}
      onTableRefresh={() => onRefresh()}
      filteredDataCallback={(data: NwSegmentTableRows[]) =>
        props.filteredDataCallback(data)
      }
      selectedFiltersVal={props.filtersSelected as any}
      setFilterApplied={(data: AppliedFilter[]) =>
        props.filtersAppliedCallback(data)
      }
      filters={props.filters}
      currentPageNumber={props.currentPageNumber}
      currentPageSize={props.currentPageSize}
      onPageChange={(pageData: any) => props.onPageChange(pageData)}
      emptyState={emptyStateData}
      sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
      persistFilter={props.persistFilter}
      render403Container={props.error403Flag}
      render500Container={props.error500Flag}
      leftInlineFilters={props.leftInlineFilters}
      visibilityFlag={props.visibilityFlag}
      tableDataLoading={props.dataLoading}
    />
  );
};
export default NetworkSegmentsTable;
