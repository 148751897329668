import {
  Application16,
  EdgeService16,
  Folders16,
  Network_116,
} from '@carbon/icons-react';
import images from '../../images/images';

export type IconType =
  | 'application'
  | 'service'
  | 'deploymentEnvironment'
  | 'deploymentGroup'
  | 'partition'
  | 'networkSegment';

const iconConfig = {
  application: Application16,
  service: EdgeService16,
  deploymentEnvironment: Network_116,
  deploymentGroup: Folders16,
  partition: images.partitionIcon,
  namespace: images.partitionIcon,
  networkSegment: images.NetworkSegmentIcon,
};

export const getIcon = (type: IconType) => iconConfig[type];
