import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  Column,
  Row,
  SkeletonPlaceholder,
  Tab,
  Tabs,
} from 'carbon-components-react';
import CustomSidePanel from '../../../CustomSidePanel/CustomSidePanel';
import SidePanelDetailsCard from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsCard';
import SidePanelDetailsComponent from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsComponent';
import { EdgeSidePaneDetailsComponentProps } from '../config';
import { Gateway, Location, NetworkSegment } from '../../../../models/master';
import { Connect16 } from '@carbon/icons-react';
import {
  getGateway,
  getGatewayCompatibilitySet,
} from '../../../../controllers/gateawayApis';
import { getLocationDetails } from '../../../../controllers/locationApis';
import {
  ProceduralActivityType,
  Procedure,
} from '../../../GatewayStatus/config';
import { GatewayLifeCycleState, ProceduralStatus } from '../../../../lib/enums';

import './GatewayConnection.scss';
import { getNetworkSegmentDetails } from '../../../../controllers/networksegmentsApi';
import { Connections } from '../../../../lib/enums';
import ProceduralActivity from '../../../GatewayStatus/ProceduralActivity';
import GatewayProcedure from '../../../GatewayStatus/GatewayProcedure';

const defaultPermissionMap = {
  fromGateway: true,
  toGateway: true,
  fromLocation: true,
  toLocation: true,
};

const defaultServerErrorMap = {
  gateway: '',
};

const GatewayConnection: React.FC<EdgeSidePaneDetailsComponentProps> = ({
  source,
  target,
  open,
  onClose,
  sidePanelWidth,
  handleSidePanelWidth,
  handleHeaderWidth,
  initialHeaderWidth,
  headerWidth,
}) => {
  const { t } = useTranslation('topologySidePanelGatewayConnection');

  const [loading, setLoading] = useState(false);
  const [sourceGateway, setSourceGatewayData] = useState<Gateway | null>(null);
  const [targetGateway, setTargetGatewayData] = useState<Gateway | null>(null);
  const [sourceLocation, setSourceLocation] = useState<Location>();
  const [targetLocation, setTargetLocation] = useState<Location>();
  const [compatibilityData, setCompatibilityData] = useState<any>();
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [serverErrorMap, setServerErrorMap] = useState(defaultServerErrorMap);
  const [nwSeg, setNwSeg] = useState<NetworkSegment | null>(null);

  useEffect(() => {
    const fetchGatewayData = async () => {
      try {
        setLoading(true);
        let gwDataFrom: Gateway;
        try {
          gwDataFrom = await getGateway(source.uniqueId);
          setTargetGatewayData(gwDataFrom);
        } catch (error) {
          const err = error as AxiosError;
          if (
            err?.response?.status === 403 &&
            err?.response?.statusText === 'Forbidden'
          ) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              fromGateway: false,
            }));
          }

          const errorCode = err.response!?.status;
          if (errorCode >= 500) {
            setServerErrorMap(permissionMap => ({
              ...permissionMap,
              gateway: errorCode.toString(),
            }));
          }
        }

        try {
          const gwDataTo: Gateway = await getGateway(target.uniqueId);
          setSourceGatewayData(gwDataTo);
        } catch (error) {
          const err = error as AxiosError;
          if (
            err?.response?.status === 403 &&
            err?.response?.statusText === 'Forbidden'
          ) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              toGateway: false,
            }));
          }

          const errorCode = err.response!?.status;
          if (errorCode >= 500) {
            setServerErrorMap(permissionMap => ({
              ...permissionMap,
              gateway: errorCode.toString(),
            }));
          }
        }

        try {
          const response = await getGatewayCompatibilitySet();
          const compatibilitySubtype = response?.subtypes.filter(
            (e: any) => e?.resource_id === gwDataFrom?.subtype
          );
          setCompatibilityData(compatibilitySubtype[0]);
        } catch (error) {
          console.error(error);
        }

        try {
          const gwLocationFrom: Location = await getLocationDetails(
            source.cloud_id,
            source.location_uniqueId,
            'all',
            true
          );
          if (source?.location_uniqueId === target?.location_uniqueId) {
            setTargetLocation(gwLocationFrom);
          }
          setSourceLocation(gwLocationFrom);
        } catch (error) {
          const err = error as AxiosError;
          if (
            err?.response?.status === 403 &&
            err?.response?.statusText === 'Forbidden'
          ) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              fromLocation: false,
            }));
          }
        }
        if (source?.location_uniqueId !== target?.location_uniqueId) {
          try {
            const gwLocationTo: Location = await getLocationDetails(
              target.cloud_id,
              target.location_uniqueId,
              'all',
              true
            );
            setTargetLocation(gwLocationTo);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                toLocation: false,
              }));
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchGatewayData();
  }, []);

  useEffect(() => {
    setNwSeg(null);
    if (source || target) {
      getNetworkSeg(source?.network_segment_id);
    }
  }, [source, target]);

  const getHeaderTitle = () => {
    if (source && target) {
      const srcLink = `/gatewayDetails?gatewayId=${source.resource_id}`;
      const targetLink = `/gatewayDetails?gatewayId=${target.resource_id}`;
      return (
        <div className='header-title'>
          <Row>
            <Column className='title-styling'>
              <Row className='gateway-from'>
                <div className='gateway-from-icon'>
                  <Connect16 />
                </div>
                <div
                  className={
                    source?.name?.length > 15
                      ? 'gateway-from-title-truncate'
                      : 'gateway-from-title'
                  }
                >
                  <Link
                    className='no-underline-link'
                    to={''}
                    onClick={() =>
                      window.open(
                        window.location.origin +
                          process.env.PUBLIC_URL +
                          srcLink
                      )
                    }
                  >
                    {source?.name}
                  </Link>
                </div>
                {source?.name?.length > 15 ? (
                  <div className='gateway-to-icon-shift'>:</div>
                ) : (
                  <div className='gateway-to-icon'>:</div>
                )}
              </Row>
              <Row>
                <div className='gateway-to'>
                  <Link
                    className='no-underline-link'
                    to={''}
                    onClick={() =>
                      window.open(
                        window.location.origin +
                          process.env.PUBLIC_URL +
                          targetLink
                      )
                    }
                  >
                    {target?.name}
                  </Link>
                </div>
              </Row>
            </Column>
          </Row>
        </div>
      );
    }

    return '';
  };

  const getGatewayType = (node: Gateway | null) => {
    const type =
      node?.type === 'edge' ? t('edgeGateway') : t('waypointGateway');

    if (
      node?.type &&
      compatibilityData &&
      (compatibilityData?.resource_id === 'AXON-EDGE' ||
        'AXON-WP' ||
        'RHSI-EDGE')
    ) {
      return compatibilityData?.name;
    }

    return type;
  };

  const getNetworkSeg = async (id: string | undefined) => {
    if (id && !nwSeg) {
      try {
        const nwSeg = await getNetworkSegmentDetails(id);
        setNwSeg(nwSeg);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const formatGateWayDetailsData = (
    gatewayEntity: Gateway | null,
    locationName: string | undefined,
    direction: Connections.FROM | Connections.TO
  ) => {
    const formattedData = [
      {
        label: t('nwSegment'),
        value: nwSeg?.name,
        valueType: 'displayField',
        notAuthorized:
          direction === Connections.FROM
            ? !permissionMap['fromGateway']
            : !permissionMap['toGateway'],
      },
      {
        label: t('type'),
        value: getGatewayType(gatewayEntity),
        valueType: 'displayField',
        notAuthorized:
          direction === Connections.FROM
            ? !permissionMap['fromGateway']
            : !permissionMap['toGateway'],
      },
      {
        label: t('version'),
        value: gatewayEntity?.intended_version,
        valueType: 'displayField',
        notAuthorized:
          direction === Connections.FROM
            ? !permissionMap['fromGateway']
            : !permissionMap['toGateway'],
      },
      {
        label: t('location'),
        value:
          locationName != null
            ? locationName.charAt(0).toUpperCase() + locationName.slice(1)
            : '',
        valueType: 'displayField',
        notAuthorized:
          direction === Connections.FROM
            ? !permissionMap['fromLocation']
            : !permissionMap['toLocation'],
      },
    ];

    if (gatewayEntity?.procedural_status === ProceduralStatus.ERRORED) {
      formattedData.splice(2, 0, {
        label: t('status'),
        value: (
          <ProceduralActivity status={gatewayEntity?.procedural_status} />
        ) as any,
        valueType: 'displayField',
        notAuthorized:
          direction === Connections.FROM
            ? !permissionMap['fromGateway']
            : !permissionMap['toGateway'],
      });
    } else if (gatewayEntity?.procedural_activity) {
      formattedData.splice(2, 0, {
        label: t('status'),
        value: (
          <ProceduralActivity
            status={
              gatewayEntity?.procedural_activity as ProceduralActivityType
            }
          />
        ) as any,
        valueType: 'displayField',
        notAuthorized:
          direction === Connections.FROM
            ? !permissionMap['fromGateway']
            : !permissionMap['toGateway'],
      });
    } else if (
      gatewayEntity?.procedural_status === ProceduralStatus.IN_PROGRESS
    ) {
      formattedData.splice(2, 0, {
        label: t('status'),
        value: (
          <GatewayProcedure status={gatewayEntity?.procedure as Procedure} />
        ) as any,
        valueType: 'displayField',
        notAuthorized:
          direction === Connections.FROM
            ? !permissionMap['fromGateway']
            : !permissionMap['toGateway'],
      });
    } else if (
      gatewayEntity?.lifecycle_state ===
        GatewayLifeCycleState.GW_NOT_DEPLOYED &&
      gatewayEntity?.unmanaged
    ) {
      formattedData.splice(2, 0, {
        label: t('status'),
        value: (
          <ProceduralActivity status={gatewayEntity?.lifecycle_state} />
        ) as any,
        valueType: 'displayField',
        notAuthorized:
          direction === Connections.FROM
            ? !permissionMap['fromGateway']
            : !permissionMap['toGateway'],
      });
    } else {
      formattedData.splice(2, 0, {
        label: t('status'),
        value: <span>—</span>,
        valueType: 'displayField',
        notAuthorized:
          direction === Connections.FROM
            ? !permissionMap['fromGateway']
            : !permissionMap['toGateway'],
      });
    }

    return formattedData;
  };

  const title = getHeaderTitle();

  return (
    <div className='topology-gateway-connection-details-container'>
      <CustomSidePanel
        open={open}
        onClose={onClose}
        title={title}
        sidePanelWidth={sidePanelWidth}
        handleSidePanelWidth={handleSidePanelWidth}
        handleHeaderWidth={handleHeaderWidth}
        initialHeaderWidth={initialHeaderWidth}
        headerWidth={headerWidth}
      >
        <Tabs>
          <Tab id='topology-details-tab-1' label={t('details')}>
            <SidePanelDetailsComponent title={source?.name}>
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                </div>
              )}
              {!loading && (
                <SidePanelDetailsCard
                  data={formatGateWayDetailsData(
                    sourceGateway,
                    sourceLocation?.name,
                    Connections.FROM
                  )}
                  notAuthorized={
                    !permissionMap['fromGateway'] &&
                    !permissionMap['fromLocation']
                  }
                  error500={!!serverErrorMap['gateway']}
                />
              )}
            </SidePanelDetailsComponent>
            <SidePanelDetailsComponent title={target?.name}>
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                  <SkeletonPlaceholder className='gateway-connection-details-skeleton' />
                </div>
              )}
              {!loading && (
                <SidePanelDetailsCard
                  data={formatGateWayDetailsData(
                    targetGateway,
                    targetLocation?.name,
                    Connections.TO
                  )}
                  notAuthorized={
                    !permissionMap['toGateway'] && !permissionMap['toLocation']
                  }
                  error500={!!serverErrorMap['gateway']}
                />
              )}
            </SidePanelDetailsComponent>
          </Tab>
        </Tabs>
      </CustomSidePanel>
    </div>
  );
};

export default GatewayConnection;
