import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import {
  TableListType,
  getResourcesEmptyStateValue,
  getResourcesTableFilters,
  getResourcesTableHeaders,
  getTabs,
} from '../config';
import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';
import {
  ApplicationData,
  AppliedFilter,
  DeploymentEnvironment,
  Gateway,
  GatewayCustomData,
  NetworkSegment,
  PartitionData,
  PolicyData,
  ResourceData,
  ResourceGroup,
} from '../../../models/master';
import {
  useGateways,
  useGatewaysCloudDetails,
  useGatewaysLocationDetails,
  useGatewaysPartitionDetails,
} from '../../../hooks/useGateways';
import {
  useDeploymentEnvsData,
  useClustersNamespaces,
} from '../../../hooks/useDeploymentEnvs';
import {
  GatewayLifeCycleState,
  GatewaySubTypes,
  GatewayTypes,
  PolicyDataType,
  ProceduralStatus,
  ResourcesList,
  VisibilityFlags,
} from '../../../lib/enums';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';
import { Tabs, Tab, Button, OnChangeData } from 'carbon-components-react';
import images from '../../../images/images.js';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import HealthStatus from '../../../components/GatewayStatus/HealthStatus';
import {
  HealthStatusType,
  showProcedureAttribute,
} from '../../../components/GatewayStatus/config';
import GatewayProcedure from '../../../components/GatewayStatus/GatewayProcedure';
import ProceduralActivity from '../../../components/ProceduralActivity/ProceduralActivity';
import GenericStatusField from '../../../components/GenericStatusField/GenericStatusField';
import dateUtils from '../../../lib/dates';
import sortData from '../../../lib/tableSort';
import { AxiosError } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useApplications } from '../../../hooks/useApplications';
import LabelTag from '../../../components/LabelTag/LabelTag';
import { usePoliciesData } from '../../../hooks/usePolicies';

import './NetworkSegmentResourcesList.scss';
import { IconType } from '../../../components/PolicyTargetField/config';
import PolicyTargetField from '../../../components/PolicyTargetField/PolicyTargetField';
import ConnectGatewayCluster from '../../../components/ConnectGatewayCluster/ConnectGatewayCluster';

interface GatewayTableRows {
  id: any;
  gateway_id: string;
  cloud_id: string | undefined;
  location_id: string | undefined;
  name: string | undefined;
  name__format: JSX.Element;
  type?: string;
  health_status: JSX.Element | undefined | string;
  health_status_format: JSX.Element | undefined | string;
  cluster_namespace: string;
  procedural_activity: JSX.Element | string | undefined;
  cloud: JSX.Element;
  location_name: string;
  location: JSX.Element;
  updated_at: string;
  deployedIn: string | undefined;
  radioButton?: JSX.Element;
  metric_collection: JSX.Element | string;
  action_button: JSX.Element;
}

interface ApplicationTableRows {
  id: any;
  name: string | undefined;
  name__format: JSX.Element;
  resource_group_name: string | JSX.Element;
  lastUpdated: string;
  deployments: number | string | JSX.Element;
  allLabels: JSX.Element | null | string;
  updated_at: string;
  labels: string[];
}

interface NamespaceTableRows {
  id: any;
  name: string | undefined;
  name__format: JSX.Element;
  infrastructure_group: string | JSX.Element;
  deployment_env_name: string | JSX.Element | undefined;
  cloud_name: string | JSX.Element;
  location_name: string | JSX.Element;
  auto_discover: string | undefined;
  allLabels: JSX.Element | null | string;
  updated_at: string;
  labels: string[];
}

interface PolicyTableRows {
  id: any;
  name: string | undefined;
  name__format: JSX.Element;
  resource_group_name: string | JSX.Element;
  from: string | JSX.Element | undefined;
  to_svc_name: string | JSX.Element;
  allLabels: JSX.Element | null | string;
  updated_at: string;
  labels: string[];
}

const NetworkSegmentResourcesList = (props: {
  networkSegmentData: NetworkSegment | null;
  resourceGroups: ResourceGroup[] | null;
  resourceGroupPermission: boolean;
  refreshNetworkSegmentDetails: () => void;
}) => {
  const { t } = useTranslation('networkSegmentDetails');
  const { trackButtonClicked } = useAnalytics();
  const [tabIndex, setTabIndex] = useState(0);
  const [gatewaysCurrentPageNumber, setGatewaysPageNumber] = useState(1);
  const [gatewaysCurrentPageSize, setGatewaysPageSize] = useState(10);
  const [applicationsCurrentPageNumber, setApplicationsPageNumber] =
    useState(1);
  const [applicationsCurrentPageSize, setApplicationsPageSize] = useState(10);
  const [namespacesCurrentPageNumber, setNamespacesPageNumber] = useState(1);
  const [namespacesCurrentPageSize, setNamespacesPageSize] = useState(10);
  const [policiesCurrentPageNumber, setPoliciesPageNumber] = useState(1);
  const [policiesCurrentPageSize, setPoliciesPageSize] = useState(10);
  const [filteredGatewayData, setFilteredGatewayData] = useState<
    GatewayCustomData[] | []
  >([]);
  const [filteredAppsData, setFilteredAppsData] = useState<
    ApplicationData[] | []
  >([]);
  const [filteredNamespacesData, setFilteredNamespacesData] = useState<
    PartitionData[] | []
  >([]);
  const [filteredPoliciesData, setFilteredPoliciesData] = useState<
    PolicyData[] | []
  >([]);

  const [appsFilterApplied, setAppsFilterApplied] = useState<
    AppliedFilter[] | []
  >([]);
  const [gatewaysFilterApplied, setGatewaysFilterApplied] = useState<
    AppliedFilter[] | []
  >([]);
  const [namespacesFilterApplied, setNamespacesFilterApplied] = useState<
    AppliedFilter[] | []
  >([]);
  const [policiesFilterApplied, setPoliciesFilterApplied] = useState<
    AppliedFilter[] | []
  >([]);
  const [gatewaysSortKey, setGatewaysSortKey] = useState('');
  const [gatewaysSortDirection, setGatewaysSortDirection] = useState<
    'ASC' | 'DESC' | 'NONE'
  >('NONE');
  const [applicationsSortKey, setApplicationsSortKey] = useState('');
  const [applicationsSortDirection, setApplicationsSortDirection] = useState<
    'ASC' | 'DESC' | 'NONE'
  >('NONE');
  const [namespacesSortKey, setNamespacesSortKey] = useState('');
  const [namespacesSortDirection, setNamespacesSortDirection] = useState<
    'ASC' | 'DESC' | 'NONE'
  >('NONE');
  const [polciciesSortKey, setPoliciesSortKey] = useState('');
  const [policiesSortDirection, setPoliciesSortDirection] = useState<
    'ASC' | 'DESC' | 'NONE'
  >('NONE');
  const [selectedGateway, setSelectedGateway] =
    useState<GatewayCustomData | null>(null);
  const [showConnectGatewayClusterForm, setShowConnectGatewayClusterForm] =
    useState(false);
  const [visibilityFilterFlag, setVisibilityFilterFlag] = useState(
    VisibilityFlags.ALL
  );

  const tabs = getTabs(t);
  const navigate = useNavigate();

  const permission = {
    deploymentEnv: true,
    gateways: true,
    applications: true,
    namespaces: true,
    policies: true,
    cloud: true,
    location: true,
  };
  const error500 = {
    deploymentEnv: false,
    gateways: false,
    applications: false,
    namespaces: false,
    policies: false,
    cloud: false,
  };

  // Queries deployment environment
  const {
    data: deploymentEnvironments,
    isLoading: loadingDeploymentEnvs,
    error: deploymentEnvError,
    isError: isDeploymentEnvError,
    refetch: refetchDeploymentEnvs,
    isRefetching: refetchingDeploymentEnvs,
  } = useDeploymentEnvsData(VisibilityFlags.MANAGED);
  if (isDeploymentEnvError) {
    const error = deploymentEnvError as AxiosError;
    permission.deploymentEnv = error?.response?.status === 403 ? false : true;
    error500.deploymentEnv = error.response!?.status >= 500 ? true : false;
  }
  // Queries gateways
  const {
    data: allGateways,
    isLoading: loadingGateways,
    error: gatewayError,
    isError: isGatewayError,
    refetch: refetchGateways,
    isRefetching: refetchingGateways,
  } = useGateways(VisibilityFlags.ALL);
  if (isGatewayError) {
    const error = gatewayError as AxiosError;
    permission.gateways = error?.response?.status === 403 ? false : true;
    error500.gateways = error.response!?.status >= 500 ? true : false;
  }

  const getFilteredGateways = () => {
    if (Array.isArray(allGateways)) {
      if (visibilityFilterFlag === VisibilityFlags.UNMANAGED) {
        return allGateways.filter(gateway => gateway.unmanaged);
      } else if (visibilityFilterFlag === VisibilityFlags.MANAGED) {
        return allGateways.filter(gateway => !gateway.unmanaged);
      } else {
        return allGateways;
      }
    }

    return null;
  };

  const gateways = useMemo(
    () => getFilteredGateways(),
    [allGateways, visibilityFilterFlag]
  );

  // TODO: Below is for getting the names of resources. This should be removed once the main gateways list api returns the specific resource names.
  const gatewayCloudDetails = useGatewaysCloudDetails(
    (allGateways as GatewayCustomData[])?.filter(
      gateway => gateway.subtype === GatewaySubTypes.RHSI_EDGE
    ),
    {
      enabled: !refetchingGateways,
    }
  );

  if (gatewayCloudDetails?.[0]?.isError) {
    const axiosError = gatewayCloudDetails[0].error as AxiosError;
    if (axiosError.response?.status === 403) {
      permission.cloud = false;
    }
  }

  const gatewayLocationDetails = useGatewaysLocationDetails(
    (allGateways as GatewayCustomData[])?.filter(
      gateway => gateway.subtype === GatewaySubTypes.RHSI_EDGE
    ),
    {
      enabled: !refetchingGateways,
    }
  );

  if (gatewayLocationDetails?.[0]?.isError) {
    const axiosError = gatewayLocationDetails[0].error as AxiosError;
    if (axiosError.response?.status === 403) {
      permission.location = false;
    }
  }

  const gatewayPartitionDetails = useGatewaysPartitionDetails(
    (allGateways as GatewayCustomData[])?.filter(
      gateway => gateway.subtype === GatewaySubTypes.RHSI_EDGE
    ),
    {
      enabled: !refetchingGateways,
    }
  );

  // Queries applications
  const {
    data: applications,
    isLoading: loadingApplications,
    error: applicationError,
    isError: isApplicationError,
    refetch: refetchApplications,
    isRefetching: refetchingApplications,
  } = useApplications(true, false);
  if (isApplicationError) {
    const error = applicationError as AxiosError;
    permission.applications = error?.response?.status === 403 ? false : true;
    error500.applications = error.response!?.status >= 500 ? true : false;
  }

  // Queries namespaces
  const {
    data: namespaces,
    isLoading: loadingNamespaces,
    error: namespaceError,
    isError: isNamespaceError,
    refetch: refetchNamespaces,
    isRefetching: refetchingNamespaces,
    fetchStatus: namespaceFetchingStatus,
  } = useClustersNamespaces(
    deploymentEnvironments?.filter(
      (deplenv: DeploymentEnvironment) => deplenv?.type === 'cluster'
    ),
    { enabled: deploymentEnvironments?.length > 0 }
  );
  if (isNamespaceError) {
    const error = namespaceError as AxiosError;
    permission.namespaces = error?.response?.status === 403 ? false : true;
    error500.namespaces = error.response!?.status >= 500 ? true : false;
  }

  // Queries policies
  const {
    data: policies,
    isLoading: loadingPolicies,
    error: policyError,
    isError: isPolicyError,
    refetch: refetchPolicies,
    isRefetching: refetchingPolicies,
  } = usePoliciesData({
    fetchApplications: false,
    fetchServices: true,
  });
  if (isPolicyError) {
    const error = policyError as AxiosError;
    permission.policies = error?.response?.status === 403 ? false : true;
    error500.policies = error.response!?.status >= 500 ? true : false;
  }

  const getDeplEnvName = (gateway: Gateway) => {
    const depEnv = deploymentEnvironments?.find(
      (dep: DeploymentEnvironment) =>
        dep.resource_id === gateway?.deployed_in_depl_env_id
    );
    return depEnv?.name;
  };

  const getGatewayClusterNamespace = (gateway: GatewayCustomData) => {
    const namespaceName = gateway.namespace_name;
    const clusterName = getDeplEnvName(gateway) ?? '—';

    return `${clusterName}/${namespaceName}`;
  };

  const getGatewayStatus = (gateway: GatewayCustomData) => {
    let statusWithIcon;

    if (gateway.procedural_status === ProceduralStatus.NOT_STARTED) {
      statusWithIcon = '—';
    } else if (gateway.procedural_activity) {
      return <ProceduralActivity status={gateway.procedural_activity} />;
    } else if (gateway.procedural_status === ProceduralStatus.IN_PROGRESS) {
      statusWithIcon = <GatewayProcedure status={gateway.procedure} />;
    } else if (
      gateway.lifecycle_state === GatewayLifeCycleState.GW_NOT_DEPLOYED &&
      gateway.unmanaged
    ) {
      return <ProceduralActivity status={gateway.lifecycle_state} />;
    } else {
      statusWithIcon = '—';
    }

    return statusWithIcon;
  };

  const getNetworkSegmentResources = <T,>(data: T[]) => {
    return data?.filter(
      (resource: T) =>
        (resource as ResourceData).network_segment_id ===
        props.networkSegmentData?.resource_id
    );
  };

  const getNetworkSegmentGateways = (gateways: GatewayCustomData[]) => {
    return gateways?.filter(
      gateway =>
        gateway.network_segment_id === props.networkSegmentData?.resource_id
    );
  };

  const getCloudName = (gateway: GatewayCustomData) => {
    const cloudName =
      gatewayCloudDetails?.find(
        cloud => cloud?.data?.resource_id === gateway.cloud_id
      )?.data?.name ?? '';
    return !permission.cloud ? (
      <GenericStatusField status='notAuthorised'></GenericStatusField>
    ) : gateway.cloud_id && cloudName ? (
      <Link
        className='no-underline-link location-name-link'
        to={`/cloudDetails?cloudId=${gateway.cloud_id}`}
      >
        <GenericTruncateString str={cloudName} tableView={true} />
      </Link>
    ) : (
      <div>—</div>
    );
  };

  const getLocationName = (gateway: GatewayCustomData) => {
    const locationName =
      gatewayLocationDetails?.find(
        location => location?.data?.resource_id === gateway.location_id
      )?.data?.name ?? '';
    return !permission.location ? (
      <GenericStatusField status='notAuthorised'></GenericStatusField>
    ) : gateway.location_id && locationName ? (
      <Link
        className='no-underline-link location-name-link'
        to={`/locationDetails?cloudId=${gateway.cloud_id}&locationId=${gateway.location_id}`}
      >
        <GenericTruncateString str={locationName} tableView={true} />
      </Link>
    ) : (
      <div>—</div>
    );
  };

  const handleShowConnectGatewayClusterForm = (gateway: GatewayCustomData) => {
    setSelectedGateway(gateway);
    setShowConnectGatewayClusterForm(true);
  };

  const handleCloseConnectGatewayClusterForm = () => {
    setSelectedGateway(null);
    setShowConnectGatewayClusterForm(false);
  };

  const renderConnectClusterButton = (gateway: GatewayCustomData) => {
    if (
      gateway.unmanaged &&
      gateway.lifecycle_state === 'gw-deployed' &&
      gateway.procedural_activity === 'waiting-connection' &&
      !gateway.deployed_in_depl_env_id &&
      !gateway.deployed_in_partition_id
    ) {
      return (
        <Button
          className='connect-gateway-cluster-btn'
          kind='ghost'
          onClick={() => handleShowConnectGatewayClusterForm(gateway)}
          data-testid={`connect-gateway-cluster-btn-${gateway.resource_id}`}
        >
          {t('resourcesList.gateways.connectCluster')}
        </Button>
      );
    }

    return <></>;
  };

  const formatGateways = () => {
    let formattedRows: GatewayTableRows[] = [];
    if (gateways && gateways.length === 0) return [];

    if (gateways) {
      const networkSegmentGateways = getNetworkSegmentGateways(gateways);
      networkSegmentGateways?.map((row: GatewayCustomData, index: number) => {
        const deployedInVal = getDeplEnvName(row);
        const siteconfig = row.site_configuration
          ? JSON.parse(row.site_configuration)
          : {};
        formattedRows.push({
          id: row.resource_id + index,
          gateway_id: row.resource_id,
          cloud_id: row.cloud_id,
          location_id: row.location_id,
          name: row.name ?? '—',
          name__format: (
            <Link
              className='gateway-name-link'
              onClick={() => {
                trackButtonClicked(
                  analyticsData['Gateways'].events.openGatewayDetails.props,
                  analyticsData['Gateways'].events.openGatewayDetails.event
                );
              }}
              to={`/gatewayDetails?gatewayId=${row.resource_id}`}
              data-testid='gateway-name-link'
            >
              <div
                className={`gateway-name-container ${
                  row.unmanaged ? 'unmanaged' : ''
                }`}
              >
                <div className='icon'>
                  {row.unmanaged && images.unmanagedIcon()}
                </div>
                <div className='name' data-testid={`gateway-name${index}`}>
                  <GenericTruncateString str={row?.name} tableView={true} />
                </div>
              </div>
            </Link>
          ),
          health_status: row?.health_status,
          health_status_format: row?.health_status ? (
            <HealthStatus
              status={row?.health_status as HealthStatusType}
              showLabel={true}
              viewEventsLink={true}
              gatewayName={row.name}
            />
          ) : (
            '—'
          ),
          deployedIn: deployedInVal?.concat('/', ''),
          cluster_namespace: getGatewayClusterNamespace(row),
          procedural_activity: getGatewayStatus(row),
          cloud: getCloudName(row),
          location_name:
            gatewayLocationDetails?.find(
              location => location?.data?.resource_id === row.location_id
            )?.data?.name ?? '',
          location: getLocationName(row),
          metric_collection: siteconfig?.flow_collector
            ? images.checkMarkIconSvg()
            : '—',
          updated_at: row?.updated_at
            ? dateUtils.getUserFriendlyDate(row?.updated_at)
            : '—',
          action_button: renderConnectClusterButton(row),
        });
        return 0;
      });
    } else return null;
    return formattedRows;
  };

  const getResourceGroupName = (id: string) => {
    const resouceGroup =
      id &&
      Array.isArray(props.resourceGroups) &&
      props.resourceGroups.find(
        resouceGroup => resouceGroup.resource_id === id
      );

    return resouceGroup ? resouceGroup.name : '';
  };
  const getNotAuthorizedContent = () => {
    return <GenericStatusField status='notAuthorised'></GenericStatusField>;
  };

  const getNamespacesNames = (
    policy: PolicyData,
    networkSegmentNamespaces: PartitionData[]
  ) => {
    if (policy?.from?.type === PolicyDataType.NAMESPACE) {
      const fromPolicyNamespaces = policy?.from?.namespaces ?? [];
      let fromPolicyData = fromPolicyNamespaces
        .map(
          fromNamespace =>
            networkSegmentNamespaces?.find(
              namespace => namespace.resource_id === fromNamespace.namespace_id
            )?.name
        )
        .filter((value): value is string => value !== undefined);

      return fromPolicyData.length > 0 ? (
        <PolicyTargetField
          label={fromPolicyData}
          type={policy.from.type as IconType}
        />
      ) : (
        '-'
      );
    }
  };

  const formatTags = (
    labels: string[] | null,
    discoverdLabels: string[] | null
  ) => {
    const labelsList: any = [];
    if (labels) {
      labels.forEach(el => {
        labelsList.push(el);
      });
    }
    if (discoverdLabels) {
      discoverdLabels.forEach(el => {
        labelsList.push({
          default: true,
          value: el,
        });
      });
    }
    return <LabelTag labelArray={labelsList} count={3} />;
  };
  const formatApplications = () => {
    let formattedRows: ApplicationTableRows[] = [];
    if (!permission.applications) return [];
    if (error500.applications) return [];
    if (applications && applications.length === 0) return [];
    if (applications) {
      const networkSegmentApps = getNetworkSegmentResources(applications);
      networkSegmentApps?.map((row: ApplicationData, index: number) => {
        formattedRows.push({
          id: row.resource_id,
          name: row.name ?? '—',
          name__format: (
            <Link
              className='no-underline-link application-name-link'
              to={`/applicationDetails?appId=${row.resource_id}`}
              data-testid={`application-name${index}`}
            >
              <GenericTruncateString str={row?.name} tableView={true} />
            </Link>
          ),
          resource_group_name: props.resourceGroupPermission
            ? getResourceGroupName(row.resource_group_id)
            : getNotAuthorizedContent(),
          lastUpdated: !!row.updated_at
            ? dateUtils.getUserFriendlyDate(row.updated_at)
            : '—',
          deployments: row?.deploymentPermission
            ? row.deploymentCount ?? 0
            : getNotAuthorizedContent(),
          labels: row.labels || row?.discovered_labels,
          allLabels:
            row.labels || row?.discovered_labels
              ? formatTags(row.labels, row?.discovered_labels)
              : '—',
          updated_at: row?.updated_at
            ? dateUtils.getUserFriendlyDate(row?.updated_at)
            : '—',
        });
        return 0;
      });
    } else return null;
    return formattedRows;
  };
  const formatNamespaces = () => {
    let formattedRows: NamespaceTableRows[] = [];
    if (!permission.namespaces) return [];
    if (error500.namespaces) return [];
    if (namespaces && namespaces.length === 0) return [];
    if (namespaces) {
      const networkSegmentNamespaces = getNetworkSegmentResources(namespaces);
      networkSegmentNamespaces?.map((row: PartitionData, index: number) => {
        formattedRows.push({
          id: row.resource_id,
          name: row.name ?? '—',
          name__format: (
            <Link
              className='no-underline-link namespace-name-link'
              to={`/partitionDetails?depEnvId=${row.cluster_id}&partitionId=${row.resource_id}`}
              data-testid={`namespace-name${index}`}
            >
              <GenericTruncateString str={row?.name} tableView={true} />
            </Link>
          ),
          infrastructure_group: props.resourceGroupPermission
            ? getResourceGroupName(row.resource_group_id)
            : getNotAuthorizedContent(),
          deployment_env_name: (
            <Link
              className='no-underline-link deployment-env-name-link'
              to={`/deploymentEnvironmentDetails?deplId=${row.cluster_id}`}
            >
              <GenericTruncateString
                str={row?.deployment_env_name ?? ''}
                tableView={true}
              />
            </Link>
          ),
          cloud_name: row.cloud_name ?? '—',
          location_name: row.location_name ?? '—',
          auto_discover: row.auto_discover
            ? t('resourcesList.namespaces.autodiscover_on')
            : t('resourcesList.namespaces.autodiscover_off'),
          labels: row.labels || row?.discovered_labels,
          allLabels:
            row.labels || row?.discovered_labels
              ? formatTags(row.labels, row?.discovered_labels)
              : '—',
          updated_at: !!row?.updated_at
            ? dateUtils.getUserFriendlyDate(row?.updated_at)
            : '—',
        });
      });
    } else return null;
    return formattedRows;
  };
  const formatPolicies = () => {
    let formattedRows: PolicyTableRows[] = [];
    if (!permission.policies) return [];
    if (error500.policies) return [];
    if (policies && policies.length === 0) return [];
    if (policies) {
      const networkSegmentPolicies = getNetworkSegmentResources(policies);
      const networkSegmentNamespaces =
        namespaces && namespaces.length > 0
          ? getNetworkSegmentResources(namespaces)
          : [];
      networkSegmentPolicies?.map((row: PolicyData, index: number) => {
        formattedRows.push({
          id: row.resource_id,
          name: row.name ?? '—',
          name__format: (
            <Link
              className='no-underline-link policy-name-link'
              to={`/connectionaccesspolicydetails?policyId=${row.resource_id}`}
              data-testid={`policy-name${index}`}
            >
              <GenericTruncateString str={row?.name} tableView={true} />
            </Link>
          ),
          resource_group_name: props.resourceGroupPermission
            ? getResourceGroupName(row.resource_group_id)
            : getNotAuthorizedContent(),
          from:
            row?.from?.type === PolicyDataType.NAMESPACE ? (
              permission.namespaces ? (
                getNamespacesNames(row, networkSegmentNamespaces)
              ) : (
                getNotAuthorizedContent()
              )
            ) : row?.from?.type === PolicyDataType.NETWORKSEGMENT ? (
              <PolicyTargetField
                label={props.networkSegmentData?.name ?? ''}
                type={row.from?.type as IconType}
              />
            ) : (
              ''
            ),

          to_svc_name: row.to_svc_name ?? '—',
          labels: row.labels || row?.discovered_labels,
          allLabels:
            row.labels || row?.discovered_labels
              ? formatTags(row.labels, row?.discovered_labels)
              : '—',
          updated_at: !!row?.updated_at
            ? dateUtils.getUserFriendlyDate(row?.updated_at)
            : '—',
        });
      });
    } else return null;
    return formattedRows;
  };

  const formattedGateways = useMemo(
    () => formatGateways(),
    [
      gateways,
      deploymentEnvironments,
      gatewayCloudDetails,
      gatewayLocationDetails,
      gatewayPartitionDetails,
    ]
  );
  const formattedApplications = useMemo(
    () => formatApplications(),
    [
      applications,
      props.resourceGroups,
      props.resourceGroupPermission,
      permission.applications,
    ]
  );

  const formattedNamespaces = useMemo(
    () => formatNamespaces(),
    [
      namespaces,
      props.resourceGroups,
      permission.namespaces,
      permission.deploymentEnv,
    ]
  );

  const formattedPolicies = useMemo(
    () => formatPolicies(),
    [policies, props.resourceGroups, namespaces, permission.namespaces]
  );

  const setSortDirection = (tab: string, value: 'ASC' | 'DESC' | 'NONE') => {
    if (tab === ResourcesList.GATEWAYS) {
      setGatewaysSortDirection(value);
    } else if (tab === ResourcesList.APPLICATIONS) {
      setApplicationsSortDirection(value);
    } else if (tab === ResourcesList.NAMESPACES) {
      setNamespacesSortDirection(value);
    } else if (tab === ResourcesList.POLICIES) {
      setPoliciesSortDirection(value);
    }
  };

  const setSortKey = (tab: string, value: string) => {
    if (tab === ResourcesList.GATEWAYS) {
      setGatewaysSortKey(value);
    } else if (tab === ResourcesList.APPLICATIONS) {
      setApplicationsSortKey(value);
    } else if (tab === ResourcesList.NAMESPACES) {
      setNamespacesSortKey(value);
    } else if (tab === ResourcesList.POLICIES) {
      setPoliciesSortKey(value);
    }
  };

  const setPageChange = (
    tab: string,
    pageData: { page: number; pageSize: number }
  ) => {
    setPageNumber(tab, pageData.page);
    setPageSize(tab, pageData.pageSize);
  };
  const handleTableSort = (
    tab: string,
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(tab, sortDirection);
    setSortKey(tab, data.id);
  };

  const getTableData = (tabname?: TableListType) => {
    return tabname === ResourcesList.GATEWAYS
      ? formattedGateways
      : tabname === ResourcesList.APPLICATIONS
      ? formattedApplications
      : tabname === ResourcesList.NAMESPACES
      ? formattedNamespaces
      : tabname === ResourcesList.POLICIES
      ? formattedPolicies
      : [];
  };

  const getFilterdItems = (tabname?: TableListType) => {
    return tabname === ResourcesList.GATEWAYS
      ? filteredGatewayData
      : tabname === ResourcesList.APPLICATIONS
      ? filteredAppsData
      : tabname === ResourcesList.NAMESPACES
      ? filteredNamespacesData
      : tabname === ResourcesList.POLICIES
      ? filteredPoliciesData
      : [];
  };

  const setFilterApplied = (tab: string, data: AppliedFilter[]) => {
    tab === ResourcesList.GATEWAYS
      ? setGatewaysFilterApplied(data)
      : tab === ResourcesList.APPLICATIONS
      ? setAppsFilterApplied(data)
      : tab === ResourcesList.NAMESPACES
      ? setNamespacesFilterApplied(data)
      : setPoliciesFilterApplied(data);
  };

  const setFilteredData = <T,>(tab: string, data: T[]) => {
    tab === ResourcesList.GATEWAYS
      ? setFilteredGatewayData(data as GatewayCustomData[])
      : tab === ResourcesList.APPLICATIONS
      ? setFilteredAppsData(data as ApplicationData[])
      : tab === ResourcesList.NAMESPACES
      ? setFilteredNamespacesData(data as PartitionData[])
      : setFilteredPoliciesData(data as PolicyData[]);
  };

  const handleRefresh = (tab: string) => {
    if (tab === ResourcesList.GATEWAYS) {
      refetchDeploymentEnvs();
      refetchGateways();
    } else if (tab === ResourcesList.APPLICATIONS) {
      refetchApplications();
    } else if (tab === ResourcesList.NAMESPACES) {
      refetchNamespaces();
    } else if (tab === ResourcesList.POLICIES) {
      refetchPolicies();
    }
  };

  const setPageNumber = (tab: string, value: number) => {
    if (tab === ResourcesList.GATEWAYS) {
      setGatewaysPageNumber(value);
    } else if (tab === ResourcesList.APPLICATIONS) {
      setApplicationsPageNumber(value);
    } else if (tab === ResourcesList.NAMESPACES) {
      setNamespacesPageNumber(value);
    } else if (tab === ResourcesList.POLICIES) {
      setPoliciesPageNumber(value);
    }
  };

  const setPageSize = (tab: string, value: number) => {
    if (tab === ResourcesList.GATEWAYS) {
      setGatewaysPageSize(value);
    } else if (tab === ResourcesList.APPLICATIONS) {
      setApplicationsPageSize(value);
    } else if (tab === ResourcesList.NAMESPACES) {
      setNamespacesPageSize(value);
    } else if (tab === ResourcesList.POLICIES) {
      setPoliciesPageSize(value);
    }
  };

  const isLoading = () => {
    if (tabIndex === 0) {
      return (
        loadingGateways ||
        refetchingGateways ||
        gatewayCloudDetails.some(details => details.isFetching) ||
        gatewayLocationDetails.some(details => details.isFetching) ||
        gatewayPartitionDetails.some(details => details.isFetching)
      );
    } else if (tabIndex === 1) {
      return loadingApplications || refetchingApplications;
    } else if (tabIndex === 2) {
      return (
        (loadingNamespaces || refetchingNamespaces) &&
        namespaceFetchingStatus !== 'idle'
      );
    } else if (tabIndex === 3) {
      return loadingPolicies || refetchingPolicies;
    }
  };

  return (
    <div className='network-segment-resources-list'>
      <div
        className='resources-list-header'
        data-testid='ns-resources-list-header'
      >
        {t('resourcesList.header')}
      </div>
      <Tabs
        selected={tabIndex}
        onSelectionChange={(index: any) => setTabIndex(index)}
        type='container'
        className='network-segment-resources-list-tabs'
      >
        {Array.isArray(tabs) &&
          tabs.map(tab => {
            const headers = getResourcesTableHeaders(
              tab.name as TableListType,
              t
            );
            let filters: any[] = [];
            let leftInlineFilters: any;

            let appliedFilters: AppliedFilter[],
              currentPageNumber = 1,
              currentPageSize = 10,
              sortKey = '',
              sortDirection = 'NONE',
              show403Container = false,
              show500Container = false;
            if (tab.name === ResourcesList.GATEWAYS) {
              if (props.networkSegmentData?.unmanaged) {
                headers.splice(5, 0, {
                  key: 'procedural_activity',
                  originalKey: 'procedural_activity',
                  header: t('resourcesList.gateways.onboarding_status'),
                  sort: true,
                });
              } else {
                headers.splice(5, 0, {
                  key: 'procedural_activity',
                  originalKey: 'procedural_status',
                  header: t('resourcesList.gateways.procedural_status'),
                  sort: true,
                });
              }

              filters = getResourcesTableFilters(
                tab.name as TableListType,
                t,
                formattedGateways ?? []
              );
              appliedFilters = gatewaysFilterApplied;
              currentPageNumber = gatewaysCurrentPageNumber;
              currentPageSize = gatewaysCurrentPageSize;
              sortKey = gatewaysSortKey;
              sortDirection = gatewaysSortDirection;
              show403Container = !permission.gateways;
              show500Container = error500.gateways;

              leftInlineFilters = [
                {
                  key: 'view',
                  label: t('view'),
                  type: '',
                  values: [
                    {
                      value: 'all',
                      label: t('all'),
                    },
                    {
                      value: 'managed',
                      label: t('managed'),
                    },
                    {
                      value: 'unmanaged',
                      label: t('unmanaged'),
                    },
                  ],
                  filterCallback: (e: OnChangeData<any>) => {
                    if (e.selectedItem !== visibilityFilterFlag) {
                      setGatewaysFilterApplied([]);
                      setVisibilityFilterFlag(e.selectedItem?.value);
                    }
                  },
                },
              ];
            } else if (tab.name === ResourcesList.APPLICATIONS) {
              filters = getResourcesTableFilters(
                tab.name as TableListType,
                t,
                formattedApplications ?? []
              );
              appliedFilters = appsFilterApplied;
              currentPageNumber = applicationsCurrentPageNumber;
              currentPageSize = applicationsCurrentPageSize;
              sortKey = applicationsSortKey;
              sortDirection = applicationsSortDirection;
              show403Container = !permission.applications;
              show500Container = error500.applications;
            } else if (tab.name === ResourcesList.NAMESPACES) {
              filters = getResourcesTableFilters(
                tab.name as TableListType,
                t,
                formattedNamespaces ?? []
              );
              appliedFilters = namespacesFilterApplied;
              currentPageNumber = namespacesCurrentPageNumber;
              currentPageSize = namespacesCurrentPageSize;
              sortKey = namespacesSortKey;
              sortDirection = namespacesSortDirection;
              show403Container =
                !permission.deploymentEnv || !permission.namespaces;
              show500Container = error500.namespaces;
            } else if (tab.name === ResourcesList.POLICIES) {
              filters = getResourcesTableFilters(
                tab.name as TableListType,
                t,
                formattedPolicies ?? []
              );
              appliedFilters = policiesFilterApplied;
              currentPageNumber = policiesCurrentPageNumber;
              currentPageSize = policiesCurrentPageSize;
              sortKey = polciciesSortKey;
              sortDirection = policiesSortDirection;
              show403Container = !permission.policies;
              show500Container = error500.policies;
            } else {
              appliedFilters = [];
            }

            const emptyState = getResourcesEmptyStateValue(
              tab.name,
              t,
              navigate
            );
            const dataLoading = isLoading();
            const dataCount = dataLoading
              ? 0
              : appliedFilters.length > 0
              ? getFilterdItems(tab.name)?.length || 0
              : getTableData(tab.name)?.length || 0;
            const tableData = getTableData(tab.name as TableListType);

            return (
              <Tab
                className={`tab ${tab.name}`}
                key={tab.name}
                label={
                  <span className='tab-name-container'>
                    {dataCount > 0 ? `${tab.label} (${dataCount})` : tab.label}
                  </span>
                }
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  return true;
                }}
                data-testid={`resource-tab-${tab.name}`}
              >
                <div
                  className='table'
                  data-testid={`resource-tab-${tab.name}-table`}
                >
                  <GenericTableWithFilters
                    id={tab.name}
                    rows={
                      tableData && appliedFilters?.length > 0
                        ? [
                            ...sortData(
                              getFilterdItems(tab.name as TableListType),
                              sortKey,
                              sortDirection
                            ),
                          ]
                        : tableData!?.length > 0
                        ? [...sortData(tableData, sortKey, sortDirection)]
                        : []
                    }
                    data={
                      appliedFilters.length > 0
                        ? getFilterdItems(tab.name)
                        : tableData
                    }
                    headers={headers}
                    isSortable={true}
                    totalElementsCount={
                      appliedFilters.length > 0
                        ? getFilterdItems(tab.name)?.length || 0
                        : tableData?.length || 0
                    }
                    fullData={tableData}
                    onTableRefresh={() => {
                      handleRefresh(tabs[tabIndex].name);
                    }}
                    filteredDataCallback={<T,>(data: T[]) => {
                      if (!isEqual(getFilterdItems(tab.name), data)) {
                        setFilteredData(tab.name, data);
                        setPageNumber(tab.name, 1);
                      }
                    }}
                    selectedFiltersVal={appliedFilters as any}
                    setFilterApplied={(data: AppliedFilter[]) =>
                      setFilterApplied(tab.name, data)
                    }
                    filters={filters}
                    leftInlineFilters={leftInlineFilters}
                    visibilityFlag={visibilityFilterFlag}
                    currentPageNumber={currentPageNumber}
                    currentPageSize={currentPageSize}
                    onPageChange={(pageData: any) =>
                      setPageChange(tab.name, pageData)
                    }
                    emptyState={emptyState}
                    sortRows={(
                      data: { id: string; text: string },
                      sortDirection: 'ASC' | 'DESC' | 'NONE'
                    ) =>
                      handleTableSort(tabs[tabIndex].name, data, sortDirection)
                    }
                    render403Container={show403Container}
                    render500Container={show500Container}
                    tableDataLoading={dataLoading}
                  />
                </div>
              </Tab>
            );
          })}
      </Tabs>

      <ConnectGatewayCluster
        open={showConnectGatewayClusterForm}
        onClose={() => handleCloseConnectGatewayClusterForm()}
        onGatewayConnect={() => {
          refetchDeploymentEnvs();
          refetchGateways();
          props.refreshNetworkSegmentDetails();
        }}
        gateway={selectedGateway}
      />
    </div>
  );
};

export default NetworkSegmentResourcesList;
