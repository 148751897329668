export interface QueryOptions {
  enabled?: boolean;
  retry?: boolean;
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
  refetchOnReconnect?: boolean;
  staleTime?: number;
  view?: string;
}

export const queryDefaultOptions = {
  enabled: true,
  retry: false,
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  refetchOnReconnect: true,
};

export const defaultStaleTime = 1000 * 30;

export const useQueriesDataToPick = [
  'data',
  'error',
  'isLoading',
  'isFetching',
];
