import React from 'react';
import { Button, InlineNotification } from 'carbon-components-react';
import { SubtractAlt16 } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';

import {
  ConnectionSelectedTypes,
  PolicyDataType,
} from '../../../../../lib/enums';
import { IconType, getIcon } from './config';
import { NetworkSegment } from '../../../../../models/master';

import './PolicyCard.scss';

interface Props {
  label: string;
  type: IconType;
  onRemove: () => void;
  heading: string;
  onButtonHover?: (hovered: boolean) => void;
  disabled?: boolean;
  serviceSyncFlag?: boolean;
}

const PolicyCard: React.FC<Props> = ({
  label,
  type,
  onRemove,
  heading,
  onButtonHover,
  disabled = false,
  serviceSyncFlag = false,
}) => {
  const Icon = getIcon(type);

  const { t } = useTranslation('createPolicyAllowConnections');

  const renderSycSvcCard = () => {
    return (
      <div className='sycSvc-card'>
        <InlineNotification
          lowContrast
          kind='info'
          title={t('serviceSync.title')}
          subtitle={t('serviceSync.subtitle')}
        />
        {renderPolicyCard()}
      </div>
    );
  };

  const renderPolicyCard = () => {
    return (
      <div
        className={
          (type === PolicyDataType.NETWORKSEGMENT
            ? 'policy-card network-segment-card'
            : 'policy-card') + (disabled ? '' : ' allowDropShadow')
        }
      >
        <div className='policy-card-label-section'>
          <div className='label'>
            <div className='icon'>{Icon && <Icon />}</div>
            <div className='text'>{label}</div>
          </div>
          {!disabled && (
            <Button
              className='remove-icon'
              kind='ghost'
              size='small'
              onClick={onRemove}
              onMouseEnter={() => onButtonHover && onButtonHover(true)}
              onMouseLeave={() => onButtonHover && onButtonHover(false)}
              iconDescription=' '
              renderIcon={SubtractAlt16}
              data-testid={'remove-icon'}
            />
          )}
        </div>
        {type === PolicyDataType.NETWORKSEGMENT && (
          <div className='subtext'>
            {t('serviceSync.networkSegmentSublabel')}
          </div>
        )}
      </div>
    );
  };

  return serviceSyncFlag && heading === ConnectionSelectedTypes.FROM
    ? renderSycSvcCard()
    : renderPolicyCard();
};

export default PolicyCard;
