import React from 'react';
import {
  Button,
  Column,
  Dropdown,
  TooltipDefinition,
} from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';

import './EmptyState.scss';

const EmptyState = (props: {
  icon: JSX.Element;
  header: string;
  description?: string;
  buttonText?: string;
  link?: string | boolean;
  additionalStyle?: any;
  click?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dropdown?: {
    label: string;
    items: any;
  };
}) => {
  const {
    icon,
    header,
    description,
    buttonText,
    link,
    click,
    additionalStyle,
  } = props;
  return (
    <div className='empty-state' style={additionalStyle}>
      <Column className='empty-state-icon-container' sm={1} md={3} lg={3}>
        <div className='empty-state-icon'>{icon}</div>
      </Column>
      <Column className='text-container'>
        <div className='header'>{header}</div>
        {description && <div className='description'>{description}</div>}
        {link && (
          <Button
            className='action-button'
            size='small'
            kind='tertiary'
            renderIcon={Add16}
            onClick={click}
          >
            {buttonText}
          </Button>
        )}
      </Column>
    </div>
  );
};

const VerticalEmptyState = (props: {
  icon: JSX.Element | JSX.Element[];
  header: string;
  description: string;
  buttonText?: string;
  link?: boolean | string;
  buttonIcon?: boolean;
  horizontalLine?: boolean;
  additionalStyle?: any;
  additionalClassName?: string;
  click?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dropdownClick?: (item: any) => void;
  dropdown?: {
    label: string;
    items: any;
  } | null;
  btnDisabled?: boolean;
  btnDisabledText?: string;
  onHover?: (value: boolean) => void;
}) => {
  const {
    icon,
    header,
    description,
    buttonText,
    link,
    buttonIcon,
    horizontalLine,
    additionalStyle,
    additionalClassName,
    click,
    dropdown,
    dropdownClick,
    btnDisabled,
    btnDisabledText,
    onHover,
  } = props;

  return (
    <div
      className={`vertical-empty-state ${additionalClassName ?? ''}`}
      style={additionalStyle ? additionalStyle : {}}
    >
      <div className='empty-state-icon' data-testid='vertical-empty-state-icon'>
        {Array.isArray(icon) ? icon.map(eachIcon => eachIcon) : icon}
      </div>
      <div className='header' data-testid='vertical-empty-state-header'>
        {header}
      </div>
      <div
        className='description'
        data-testid='vertical-empty-state-description'
      >
        {description}
      </div>
      {link
        ? !dropdown &&
          (!btnDisabled ? (
            <Button
              className='action-button'
              size='small'
              kind='tertiary'
              renderIcon={Add16}
              onClick={click}
              data-testid='vertical-empty-state-action-btn'
              onMouseEnter={() => onHover && onHover(true)}
              onMouseLeave={() => onHover && onHover(false)}
            >
              {buttonText}
            </Button>
          ) : (
            <TooltipDefinition
              tooltipText={btnDisabledText as string}
              direction='bottom'
            >
              <Button
                className='action-button'
                size='small'
                kind='tertiary'
                renderIcon={Add16}
                onClick={click}
                data-testid='vertical-empty-state-action-btn1'
                disabled
              >
                {buttonText}
              </Button>
            </TooltipDefinition>
          ))
        : null}
      {link
        ? dropdown != null && (
            <Dropdown
              id='inline'
              label={dropdown.label}
              type='inline'
              items={dropdown.items}
              itemToString={item => item.text}
              itemToElement={(item: any) => (
                <span className='test'>{item.text}</span>
              )}
              selectedItem={null}
              onChange={
                dropdownClick ? e => dropdownClick(e.selectedItem) : () => {}
              }
            />
          )
        : null}
      {horizontalLine ? <div className='horizontal-line' /> : null}
    </div>
  );
};

export { EmptyState, VerticalEmptyState };
