import { useContext, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Column, Dropdown, Row } from 'carbon-components-react';

import ClusterTable from './ClusterTable';
import WideTearsheet from '../../components/WideTearsheet/WideTearsheet';
import { NotificationContext } from '../Notifications/Context/NotificationProvider';
import InlineNotification from '../../components/Notifications/Inline/Notification';

import { useResourceGroupsData } from '../../hooks/useResourceGroups';
import {
  useClustersNamespaces,
  useDeploymentEnvSubtypes,
  useDeploymentEnvsData,
} from '../../hooks/useDeploymentEnvs';
import { registerNamespace } from '../../controllers/partitionApi';
import { updateGateway } from '../../controllers/gateawayApis';
import { updateNamespaceData } from '../../controllers/deploymentEnv';

import {
  EnvironmentTypes,
  ResourceGroupTypes,
  VisibilityFlags,
} from '../../lib/enums';
import {
  DeploymentEnvironment,
  Gateway,
  Namespace,
  ResourceGroup,
} from '../../models/master';
import {
  DEFAULT_APPLICATION_ID,
  DEFAULT_APPLICATION_NAME,
} from '../../lib/constants';

import './ConnectGatewayCluster.scss';

const defaultAppGroup = {
  resource_id: DEFAULT_APPLICATION_ID,
  name: DEFAULT_APPLICATION_NAME,
};

export interface Cluster extends DeploymentEnvironment {
  namespace: Namespace | null;
}

type ErrorNotificationType =
  | 'registerNamespace'
  | 'editNamespace'
  | 'connectCluster';

interface Props {
  open: boolean;
  onClose: () => void;
  onGatewayConnect: () => void;
  gateway: Gateway | null;
}

const ConnectGatewayCluster: React.FC<Props> = ({
  open,
  onClose,
  onGatewayConnect,
  gateway,
}) => {
  const { t } = useTranslation('connectGatewayCluster');

  const notification = useContext(NotificationContext);

  const [selectedCluster, setSelectedCluster] = useState<Cluster | null>(null);
  const [appGroup, setAppGroup] = useState(defaultAppGroup);
  const [saving, setSaving] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [errorNotificationType, setErrorNotificationType] = useState<
    ErrorNotificationType | ''
  >('');

  let show403Container = false;
  let show500Container = false;

  const { data: resourceGroups } = useResourceGroupsData({
    refetchOnWindowFocus: false,
  });

  const getAppResourceGroups = () => {
    if (Array.isArray(resourceGroups)) {
      return resourceGroups.filter(
        (resourceGroup: ResourceGroup) =>
          resourceGroup.type === ResourceGroupTypes.APPLICATION
      ) as ResourceGroup[];
    }

    return null;
  };

  const appResourceGroupList = useMemo(
    () => getAppResourceGroups(),
    [resourceGroups]
  );

  const { data: deploymentEnvironmentSubtypes = [] } =
    useDeploymentEnvSubtypes();

  const {
    data: deploymentEnvironments,
    isLoading: isDeploymentEnvsLoading,
    refetch: refetchDeploymentEnvs,
    isRefetching: isRefetchingDeploymentEnvs,
    error: deploymentEnvError,
    isError: isDeploymentEnvError,
  } = useDeploymentEnvsData(VisibilityFlags.MANAGED);

  if (isDeploymentEnvError) {
    const error = deploymentEnvError as AxiosError;
    if (error?.response?.status === 403) {
      show403Container = true;
    }

    if (error.response!?.status >= 500) {
      show500Container = true;
    }
  }

  // Queries namespaces
  const {
    data: namespaces,
    isLoading: isNamespaceLoading,
    refetch: refetchNamespaces,
    isRefetching: isRefetchingNamespaces,
  } = useClustersNamespaces(
    deploymentEnvironments?.filter(
      (deplenv: DeploymentEnvironment) => deplenv?.type === 'cluster'
    ),
    { enabled: deploymentEnvironments?.length > 0 }
  );

  const checkNamespaceExit = (clusterId: string) => {
    if (Array.isArray(namespaces)) {
      return namespaces.find(
        namespace =>
          namespace.cluster_id === clusterId &&
          namespace.name === gateway?.namespace_name
      );
    }

    return null;
  };

  const getFilteredClusters = () => {
    const filteredClusters: Cluster[] = [];

    if (gateway && Array.isArray(deploymentEnvironments)) {
      for (const deplEnv of deploymentEnvironments) {
        if (deplEnv.type === EnvironmentTypes.CLUSTER) {
          const existingNamespace = checkNamespaceExit(deplEnv.resource_id);

          // Check whether cluster already has namespace with gateway namespace name
          if (existingNamespace) {
            // If so, then check wether that namespace is in same network segment and doesn't contain any gateway connected to it
            if (
              existingNamespace.network_segment_id ===
                gateway.network_segment_id &&
              !existingNamespace.gateway_id
            ) {
              filteredClusters.push({
                ...deplEnv,
                namespace: existingNamespace,
              });
            }
          } else {
            filteredClusters.push({ ...deplEnv, namespace: null });
          }
        }
      }
    }

    return filteredClusters;
  };

  const clusters = useMemo(
    () => getFilteredClusters(),
    [gateway, deploymentEnvironments, namespaces]
  );

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
    setSubTitleErrorMsg('');
    setErrorNotificationType('');
  };

  const handleOnClose = () => {
    setSaving(false);
    setSelectedCluster(null);
    setAppGroup(defaultAppGroup);

    handleErrorBarClose();

    onClose();
  };

  const handleSubmit = async () => {
    let namespace = selectedCluster?.namespace ?? null;

    try {
      setSaving(true);

      if (selectedCluster?.namespace) {
        try {
          // Connect gateway to namespace if namespace already exit without gateway
          await updateNamespaceData(
            selectedCluster?.cloud_id,
            selectedCluster?.resource_id,
            namespace?.resource_id,
            {
              ...namespace,
              unmanaged: false,
              gateway_id: gateway?.resource_id,
              app_resource_group_id: appGroup.resource_id,
              auto_discover: true,
            }
          );

          notification.onTrigger('TOAST', {
            title: t('successNotification.editNamespace.title'),
            subtitle: t('successNotification.editNamespace.subtitle', {
              namespaceName: selectedCluster?.namespace.name,
              gatewayName: gateway?.name,
            }),
          });
        } catch (error) {
          const err = error as AxiosError;
          const errorMessage: string =
            err.response !== undefined
              ? (err as any).response['customErrorMessage']
              : '';
          if (err.response?.status === 403) {
            setAuthError(true);
          }

          toggleFailNotification(true);
          setErrorNotificationType('editNamespace');
          errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);

          return;
        }
      } else {
        try {
          // Register a new namespace if namespace doesn't exit
          const payload = {
            name: gateway?.namespace_name,
            network_segment_id: gateway?.network_segment_id,
            gateway_id: gateway?.resource_id,
            app_resource_group_id: appGroup.resource_id,
            auto_discover: true,
          };

          namespace = await registerNamespace(
            selectedCluster?.cloud_id,
            selectedCluster?.resource_id,
            payload
          );

          notification.onTrigger('TOAST', {
            title: t('successNotification.registerNamespace.title'),
            subtitle: t('successNotification.registerNamespace.subtitle', {
              namespaceName: namespace?.name,
            }),
          });
        } catch (error) {
          const err = error as AxiosError;
          const errorMessage: string =
            err.response !== undefined
              ? (err as any).response['customErrorMessage']
              : '';
          if (err.response?.status === 403) {
            setAuthError(true);
          }

          toggleFailNotification(true);
          setErrorNotificationType('registerNamespace');
          errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);

          return;
        }
      }

      // Update gateway details
      const payload = {
        cloud_id: selectedCluster?.cloud_id,
        location_id: selectedCluster?.location_id,
        deployed_in_type: 'namespace',
        deployed_in_depl_env_id: selectedCluster?.resource_id,
        deployed_in_partition_id: namespace?.resource_id,
      };
      await updateGateway(gateway?.resource_id, payload);

      notification.onTrigger('TOAST', {
        title: t('successNotification.connectCluster.title'),
        subtitle: t('successNotification.connectCluster.subtitle', {
          gatewayName: gateway?.name,
          clusterName: selectedCluster?.name,
        }),
      });

      onGatewayConnect();
      handleOnClose();
    } catch (error) {
      const err = error as AxiosError;
      const errorMessage: string =
        err.response !== undefined
          ? (err as any).response['customErrorMessage']
          : '';
      if (err.response?.status === 403) {
        setAuthError(true);
      }

      toggleFailNotification(true);
      setErrorNotificationType('connectCluster');
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);

      // Disconnect gateway from namespace if update gateway API failed
      await updateNamespaceData(
        selectedCluster?.cloud_id,
        selectedCluster?.resource_id,
        namespace?.resource_id,
        {
          ...namespace,
          gateway_id: '',
          router_site_cor_id: '',
          auto_discover: false,
        }
      );
    } finally {
      setSaving(false);
    }
  };

  const isLoading =
    (isDeploymentEnvsLoading ||
      isRefetchingDeploymentEnvs ||
      isNamespaceLoading ||
      isRefetchingNamespaces) &&
    !show403Container &&
    !show500Container;

  return (
    <div className='connect-gateway-cluster-component'>
      <WideTearsheet
        title={t('title', { gatewayName: gateway?.name })}
        open={open}
        actions={[
          {
            kind: 'primary',
            label: t('submitButtonText'),
            onClick: () => handleSubmit(),
            disabled: !selectedCluster,
            loading: saving,
          },
          {
            kind: 'secondary',
            label: t('cancelButtonText'),
            onClick: () => handleOnClose(),
          },
        ]}
      >
        <div className='connect-gateway-cluster-tearsheet'>
          {showFailNotification && (
            <InlineNotification
              onClose={() => handleErrorBarClose() as any}
              kind={'error'}
              title={
                authError
                  ? (t('failureNotification.authTitle') as string)
                  : (t(
                      `failureNotification.${errorNotificationType}`
                    ) as string)
              }
              subtitle={
                authError
                  ? (t('failureNotification.authSubtitle') as string)
                  : subTitleErrorMsg.length > 0
                  ? subTitleErrorMsg
                  : (t('failureNotification.subtitle') as string)
              }
            />
          )}

          <div className='header'>
            <div className='title'>{t('selectClusterTitle')}</div>
            <div className='description'>{t('selectClusterDescription')}</div>
          </div>

          <div className='table'>
            <ClusterTable
              show403Container={show403Container}
              show500Container={show500Container}
              isLoading={isLoading}
              clusters={clusters}
              resourceGroups={resourceGroups}
              deploymentEnvironmentSubtypes={deploymentEnvironmentSubtypes}
              selectedCluster={selectedCluster}
              onClusterSelect={cluster => setSelectedCluster(cluster)}
              onClusterRefresh={() => {
                refetchDeploymentEnvs();
                refetchNamespaces();
              }}
            />
          </div>

          <div className='header'>
            <div className='title'>{t('autoDiscoverAppTitle')}</div>
            <div className='description'>{t('autoDiscoverAppDescription')}</div>
          </div>

          <div className='form'>
            <Row className='row'>
              <Column md={4}>
                <Dropdown
                  id='infra-resource-group'
                  label={''}
                  selectedItem={appGroup}
                  onChange={data => {
                    if (data.selectedItem) {
                      setAppGroup({
                        resource_id: data.selectedItem.resource_id,
                        name: data.selectedItem.name,
                      });
                    }
                  }}
                  items={appResourceGroupList ?? []}
                  itemToString={item => (item ? item.name : '')}
                  titleText={t('autoDiscoverAppLabel')}
                  placeholder={t('')}
                  translateWithId={t}
                />
              </Column>
            </Row>
          </div>
        </div>
      </WideTearsheet>
    </div>
  );
};

export default ConnectGatewayCluster;
