import {
  ClickableTile,
  Column,
  OverflowMenu,
  OverflowMenuItem,
  Row,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import HealthStatus from '../../../components/GatewayStatus/HealthStatus';
import {
  HealthStatusType,
  proceduralStatusField,
} from '../../../components/GatewayStatus/config';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import dateUtils from '../../../lib/dates';

import GenericStatusField from '../../../components/GenericStatusField/GenericStatusField';
import './GatewaysCard.scss';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../lib/constants';
import { GatewayLifeCycleState } from '../../../lib/enums';
import { Gateway } from '../../../models/master';

const GatewaysCard = ({
  name,
  id,
  status,
  health_status,
  subType,
  location,
  cloud,
  deploymentEnvironment,
  partition,
  cloud_id,
  location_id,
  partition_id,
  environment_id,
  path,
  updated_at,
  locationPermission,
  cloudPermission,
  deploymentEnvironmentPermission,
  networkSegmentPermission,
  network_segment_name,
  network_segment_id,
  version,
  unmanaged,
  lifeCycleState,
  proceduralActivity,
  procedure,
  openDeployGateway,
  proceduralStatus,
  openConnectGatewayClusterTearSheet,
  gateway,
}: {
  name: string;
  id: string;
  status: string;
  health_status: string;
  type: string;
  subType: string | undefined;
  location: string;
  cloud: string | undefined;
  deploymentEnvironment: string | undefined;
  partition: string | undefined;
  resourceGroup: string;
  publicIp: string;
  cloud_id: string | undefined;
  location_id: string | undefined;
  partition_id: string | undefined;
  environment_id: string | undefined;
  path?: string;
  updated_at: string;
  locationPermission: boolean;
  cloudPermission: boolean;
  deploymentEnvironmentPermission: boolean;
  networkSegmentPermission: boolean;
  network_segment_name: string | undefined;
  network_segment_id: string | undefined;
  version: string | number;
  unmanaged: boolean;
  lifeCycleState: string;
  proceduralActivity: string;
  procedure: string;
  openDeployGateway: (data: any) => void;
  proceduralStatus: string;
  openConnectGatewayClusterTearSheet: (gateway: Gateway) => void;
  gateway: Gateway;
}) => {
  const { t } = useTranslation('gatewaysContainer');
  const navigate = useNavigate();

  const gatewayHealthStatusIcon = () => {
    let icon;
    icon = (
      <HealthStatus
        status={health_status as HealthStatusType}
        showLabel={false}
        viewEventsLink={true}
        gatewayName={name}
      />
    );
    return icon;
  };

  const performActionButton = () => {
    if (lifeCycleState === GatewayLifeCycleState.GW_NOT_DEPLOYED && unmanaged) {
      return (
        <div className='perform-action-button'>
          <OverflowMenu
            flipped={document?.dir === 'ltr'}
            aria-label='overflow-menu'
          >
            <OverflowMenuItem
              itemText={t('performActionButton.deployGateway')}
              onClick={() => openDeployGateway(gateway)}
            />
          </OverflowMenu>
        </div>
      );
    }
    if (
      lifeCycleState === GatewayLifeCycleState.GW_DEPLOYED &&
      proceduralActivity === 'waiting-connection'
    ) {
      return (
        <div className='perform-action-button'>
          <OverflowMenu flipped={true} aria-label='overflow-menu'>
            <OverflowMenuItem
              itemText={t('performActionButton.connectCluster')}
              onClick={() => openConnectGatewayClusterTearSheet(gateway)}
            />
          </OverflowMenu>
        </div>
      );
    }
  };

  return (
    <ClickableTile
      className='resource-card1'
      key={id}
      onClick={() => path && navigate(path)}
    >
      <Row className='header'>
        <Column lg={11}>
          <div className={'name' + (unmanaged ? ' unmanaged' : ' managed')}>
            {
              <>
                {unmanaged ? <div className='unmanaged-icon'></div> : ''}
                <GenericTruncateString str={name} tableView={false} limit={9} />
              </>
            }
          </div>
        </Column>
        <Column lg={5} className='health-status-column'>
          <div
            className={`health-status status-${health_status?.toLowerCase()}`}
          >
            {gatewayHealthStatusIcon()}
          </div>
          {performActionButton()}
        </Column>
      </Row>
      <Row className='data'>
        <div className='bx--col-md-4'>
          <div className='label-text'>{t('cardSubheading.type')}</div>
          <div className='value' id={`gateway-type-${id}`}>
            {subType ? (
              <GenericTruncateString
                str={t(`gatewayTypes.${subType}`)}
                tableView={false}
                truncateEndOfString={true}
                containerId={`gateway-type-${id}`}
              />
            ) : (
              '—'
            )}
          </div>
        </div>
        <div className='bx--col-md-4'>
          <div className='label-text'>{t('cardSubheading.cloud')}</div>
          <div className='value'>
            {!cloudPermission ? (
              <GenericStatusField status='notAuthorised'></GenericStatusField>
            ) : !!cloud ? (
              <Link
                className='no-underline-link'
                to={`/cloudDetails?cloudId=${cloud_id}`}
                id={`gateway-cloud-${id}`}
              >
                {
                  <GenericTruncateString
                    str={cloud}
                    truncateEndOfString={true}
                    containerId={`gateway-cloud-${id}`}
                  />
                }
              </Link>
            ) : (
              '—'
            )}
          </div>
        </div>
      </Row>

      <Row className='data'>
        <div className='bx--col-md-4'>
          <div className='label-text'>{t('cardSubheading.location')}</div>
          <div className='value'>
            {!locationPermission ? (
              <GenericStatusField status='notAuthorised'></GenericStatusField>
            ) : !!location ? (
              <Link
                className='no-underline-link'
                to={`/locationDetails?cloudId=${cloud_id}&locationId=${location_id}`}
                id={`gateway-location-${id}`}
              >
                {
                  <GenericTruncateString
                    str={location}
                    truncateEndOfString={true}
                    containerId={`gateway-location-${id}`}
                  />
                }
              </Link>
            ) : (
              '—'
            )}
          </div>
        </div>
        <div className='bx--col-md-4'>
          <div className='label-text'>{t('cardSubheading.networkSegment')}</div>
          <div className='value'>
            {!networkSegmentPermission ? (
              <GenericStatusField status='notAuthorised'></GenericStatusField>
            ) : !!network_segment_name ? (
              network_segment_id === DEFAULT_NETWORK_SEGMENT_ID ? (
                <GenericTruncateString
                  str={network_segment_name}
                  truncateEndOfString={true}
                  containerId={`gateway-networksegment-${id}`}
                />
              ) : (
                <Link
                  className='no-underline-link'
                  to={`/networkSegmentDetails?nwSegId=${network_segment_id}`}
                  id={`gateway-networksegment-${id}`}
                >
                  {
                    <GenericTruncateString
                      str={network_segment_name}
                      truncateEndOfString={true}
                      containerId={`gateway-networksegment-${id}`}
                    />
                  }
                </Link>
              )
            ) : (
              '—'
            )}
          </div>
        </div>
      </Row>

      <Row className='data'>
        <div className='bx--col-md-4'>
          <div className='label-text'>{t('cardSubheading.environment')}</div>
          <div className='value'>
            {!deploymentEnvironmentPermission ? (
              <GenericStatusField status='notAuthorised'></GenericStatusField>
            ) : environment_id ? (
              <Link
                className='no-underline-link'
                to={`/deploymentEnvironmentDetails?deplId=${environment_id}`}
                id={`gateway-environment-${id}`}
              >
                {
                  <GenericTruncateString
                    str={deploymentEnvironment || '—'}
                    truncateEndOfString={true}
                    containerId={`gateway-environment-${id}`}
                  />
                }
              </Link>
            ) : (
              <div>—</div>
            )}
          </div>
        </div>
        <div className='bx--col-md-4'>
          <div className='label-text'>{t('cardSubheading.partition')}</div>
          <div className='value'>
            {environment_id && partition_id ? (
              <Link
                className='no-underline-link'
                to={`/partitionDetails?depEnvId=${environment_id}&partitionId=${partition_id}`}
                id={`gateway-partition-${id}`}
              >
                {
                  <GenericTruncateString
                    str={partition || '—'}
                    truncateEndOfString={true}
                    containerId={`gateway-partition-${id}`}
                  />
                }
              </Link>
            ) : (
              <div>—</div>
            )}
          </div>
        </div>
      </Row>
      <Row className='data'>
        <div className='bx--col-md-8'>
          <div className='label-text'>
            {t('cardSubheading.proceduralStatus')}
          </div>
          <div className='value'>
            {proceduralStatusField({
              procedural_status: proceduralStatus,
              procedural_activity: proceduralActivity,
              lifecycle_state: lifeCycleState,
              unmanaged: unmanaged,
            })}
          </div>
        </div>
      </Row>

      <Row className='card-footer'>
        <Column>
          <div className='helper-text'>
            {t('cardSubheading.updated_at', {
              date: !!updated_at
                ? dateUtils.getUserFriendlyDate(updated_at)
                : updated_at,
            })}
          </div>
        </Column>
      </Row>
    </ClickableTile>
  );
};

export default GatewaysCard;
