import { TFunction } from 'react-i18next';
import images from '../../images/images';
import { ProceduralActivityType } from '../../models/master';

export const getProceduralActivityConfig = (
  t: TFunction<'gatewayStatus', undefined>,
  status: ProceduralActivityType | 'gw-not-deployed'
) => {
  const config = {
    'deploying-gw-agent': {
      icon: null,
      label: t('deploying-gw-agent'),
      loadingIcon: true,
    },
    'discover-gw': {
      icon: null,
      label: t('discover-gw'),
      loadingIcon: true,
    },
    deploying: {
      icon: null,
      label: t('deploying'),
      loadingIcon: true,
    },
    caching: {
      icon: null,
      label: t('caching'),
      loadingIcon: true,
    },
    'waiting-for-closure': {
      icon: images.warningIcon(),
      label: t('waiting-for-closure'),
      loadingIcon: false,
    },
    'waiting-connection': {
      icon: images.warningIcon(),
      label: t('waiting-connection'),
      loadingIcon: false,
    },
    'waiting-nseg-completion': {
      icon: null,
      label: t('waiting-nseg-completion'),
      loadingIcon: false,
    },
    'connecting-gateways': {
      icon: images.warningIcon(),
      label: t('connecting-gateways'),
      loadingIcon: false,
    },
    'finalizing-onboarding': {
      icon: null,
      label: t('finalizing-onboarding'),
      loadingIcon: true,
    },
    'remove-mesh-agent': {
      icon: null,
      label: '—',
      loadingIcon: false,
    },
    'undeploying-gw': {
      icon: null,
      label: '—',
      loadingIcon: false,
    },
    'gw-not-deployed': {
      icon: images.warningIcon(),
      label: t('gw-not-deployed'),
      loadingIcon: false,
    },
  };

  return config[status];
};
