import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MultiStepTearsheet from '../../components/MultiStepTearSheet/MultiStepTearSheet';
import { defaultFormValue } from './config';
import GatewayDetails from './GatewayDetails/GatewayDetails';
import {
  useClustersNamespaces,
  useDeploymentEnvSubtypes,
  useDeploymentEnvsData,
} from '../../hooks/useDeploymentEnvs';
import { useResourceGroupsData } from '../../hooks/useResourceGroups';
import {
  EnvironmentTypes,
  GatewaySubTypes,
  IngressTypes,
  QueryKeys,
  RHSIComputeProfiles,
  ResourceGroupTypes,
  VisibilityFlags,
} from '../../lib/enums';
import {
  DeploymentEnvironment,
  Gateway,
  Namespace,
  NetworkSegment,
  PartitionData,
  ResourceGroup,
} from '../../models/master';
import { useNetworksegments } from '../../hooks/useNetworksegment';
import {
  alphaNumbericRegexPattern,
  domainNameValidation,
  ingressAnnotationRegex,
  ipRegexPattern,
} from '../../lib/regex';

import './DeployGateway.scss';
import { DEFAULT_INFRASTRUCTURE_ID } from '../../lib/constants';
import CreateNetworkSegment from '../NetworkSegmentsContainer/CreateNetworkSegment/CreateNetworkSegment';
import ConfigureGateway from './ConfigureGateway/ConfigureGateway';
import WideTearsheet from '../../components/WideTearsheet/WideTearsheet';
import ConnectCluster from './ConnectCluster/ConnectCluster';
import {
  Column,
  Dropdown,
  InlineNotification,
  Row,
} from 'carbon-components-react';
import { updateNamespaceData } from '../../controllers/deploymentEnv';
import { AxiosError } from 'axios';
import useAnalytics from '../../lib/useAnalytics';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import { registerNamespace } from '../../controllers/partitionApi';
import { updateGateway } from '../../controllers/gateawayApis';
import { inValidateListCacheFn } from '../../lib/invalidateQueriesFunctions';
import { removeExtraSpace } from '../../lib/utils';

interface Props {
  open: boolean;
  onClose: (isRefreshRequired: boolean) => void;
  gatewayData: any;
  gatewayNamesList: string[];
  gatewayList?: Gateway[] | null;
}

export interface Item {
  depEnvid: string;
  type: string;
  resourceGroupId: string;
  depEnvName: string;
  cloud_id: string;
  cloudName: string;
  location_id: string;
  locationName: string;
}

export interface Cluster extends DeploymentEnvironment {
  namespace: PartitionData | null;
}

const DeployGateway: React.FC<Props> = ({
  open,
  onClose,
  gatewayData,
  gatewayNamesList,
  gatewayList,
}) => {
  const [simulatedDelay] = useState(650);
  const [formData, setFormData] = useState<any>(defaultFormValue);
  const [optionalParamInValid, setOptionalParamInValid] = useState({
    ingress_hostname: false,
    router_ingress_hostname: false,
    controller_ingress_hostname: false,
    ingress_annotations: false,
    router_cpu_limit: false,
    router_memory_limit: false,
    router_cpu: false,
    router_memory: false,
  });
  const [openCreateNetworkSegment, toggleCreateNetworkSegment] =
    useState(false);
  const [selectedNetworkSegment, setSelectedNetworkSegment] =
    useState<NetworkSegment | null>(null);

  const [openDeplEnvTable, setOpenDeplEnvTable] = useState<boolean>(false);
  const [selectedAppResourceGroup, setSelectedAppResourceGroup] =
    useState<ResourceGroup | null>(null);
  const [selectedDepEnv, setSelectedDepEnv] = useState<Cluster | null>(null);
  const [selectedDepEnvTemp, setSelectedDepEnvTemp] = useState<Cluster | null>(
    null
  );
  const [filteredDeploymentEnvsList, setFilteredDeploymentEnvsList] = useState<
    Cluster[] | null
  >(null);
  const [saving, setSaving] = useState(false);
  const [newNetworkSegmentId, setNewNetworkSegmentId] = useState<string>('');
  const permissionError = {
    depEnvList: false,
  };
  const error500 = {
    depEnvList: false,
  };
  // Error storing state

  const [selectedClusterError, setSelectedClusterError] =
    useState<boolean>(false);
  const [selectedClusterErrorType, setSelectedClusterErrorType] =
    useState<string>('');
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [inlineNotificationTitle, setInlineNotificationTitle] =
    useState<string>('');
  const [inlineNotificationSubTitle, setInlineNotificationSubTitle] =
    useState<string>('');
  const [inlineNotificationAuthTitle, setInlineNotificationAuthTitle] =
    useState<string>('');
  const [inlineNotificationAuthSubTitle, setInlineNotificationAuthSubTitle] =
    useState<string>('');

  const { t } = useTranslation('deployGateway');
  const { trackButtonClicked } = useAnalytics();
  const notification = useContext(NotificationContext);

  const requiredFields = ['name', 'resourceGroup', 'rhsi_compute_profile'];
  let isDepEnvListFilteringInProgress = false;
  let submitInProgress = false;

  const { data: depEnvSubTypesList } = useDeploymentEnvSubtypes({
    enabled: open,
  });

  const {
    data: resourceGroups,
    isRefetching: isRefetchingResourceGroups,
    isLoading: isResourceGroupsLoading,
  } = useResourceGroupsData({ enabled: open });

  const resourceGroupList = useMemo(() => {
    return resourceGroups?.filter(
      (resourceGroup: any) =>
        resourceGroup.type === ResourceGroupTypes.INFRASTRUCTURE
    );
  }, [resourceGroups]);

  // Queries list of all dep env
  const {
    data: deploymentEnvironments = [],
    isLoading: isDepEnvsLoading,
    isRefetching: isRefetchingDepEnvs,
    error: deploymentEnvError,
    isError: isDeploymentEnvError,
  } = useDeploymentEnvsData(VisibilityFlags.ALL, 'all', { enabled: open });

  if (isDeploymentEnvError) {
    const error = deploymentEnvError as AxiosError;
    if (error?.response?.status === 403) {
      permissionError['depEnvList'] = true;
    }

    if (error.response!?.status >= 500) {
      error500['depEnvList'] = true;
    }
  }

  // queries all namespaces under all clusters
  const {
    data: nameSpacesList,
    isLoading: isNamespaceLoading,
    refetch: refetchNamespaces,
    isRefetching: isRefetchingNamespaces,
  } = useClustersNamespaces(
    deploymentEnvironments?.filter(
      (deplenv: DeploymentEnvironment) =>
        deplenv?.type === EnvironmentTypes.CLUSTER
    ),
    { enabled: open && deploymentEnvironments?.length > 0 }
  );

  const {
    data: networkSegments,
    refetch: refetchNetworksegments,
    isLoading: loadingNetworkSegments,
    isRefetching: refetchingNetworkSegments,
  } = useNetworksegments(VisibilityFlags.ALL, { enabled: open });

  const deploymentEnvsList = useMemo(() => {
    let envsList = [];
    if (
      deploymentEnvironments != null &&
      Array.isArray(deploymentEnvironments)
    ) {
      for (const env of deploymentEnvironments) {
        if (env.unmanaged === false && env.type === EnvironmentTypes.CLUSTER) {
          const resourceGroup =
            env.resource_group_id && Array.isArray(resourceGroupList)
              ? resourceGroupList?.find(
                  resourceGroup =>
                    resourceGroup.resource_id === env.resource_group_id
                )
              : null;
          const subTypeName =
            (Array.isArray(depEnvSubTypesList) &&
              depEnvSubTypesList.length > 0 &&
              depEnvSubTypesList?.find(
                depEnvSubType => depEnvSubType.type_code === env.subtype
              )?.type_name) ??
            '';

          env['resourceGroup'] = resourceGroup ? resourceGroup.name : '';
          env['subTypeName'] = subTypeName;
          envsList.push(env);
        }
      }
    }
    return envsList;
  }, [resourceGroupList, depEnvSubTypesList, deploymentEnvironments]);

  const filterClusters = (selectedNetworkSegment: NetworkSegment | null) => {
    isDepEnvListFilteringInProgress = true;
    if (
      selectedNetworkSegment &&
      gatewayData &&
      Array.isArray(deploymentEnvsList)
    ) {
      const filteredClusters: Cluster[] = [];
      deploymentEnvsList?.forEach((env: DeploymentEnvironment) => {
        const exisitingSameNamespaceUnderDepEnv = Array.isArray(nameSpacesList)
          ? nameSpacesList.find(
              namespace =>
                namespace?.cluster_id === env?.resource_id &&
                namespace?.name === gatewayData?.namespace_name
            )
          : null;
        // Check whether cluster already has namespace with gateway namespace name
        if (exisitingSameNamespaceUnderDepEnv) {
          // If so, then check wether that namespace is in same network segment and doesn't contain any gateway connected to it
          if (
            exisitingSameNamespaceUnderDepEnv.network_segment_id ===
              selectedNetworkSegment?.resource_id &&
            !exisitingSameNamespaceUnderDepEnv.gateway_id
          ) {
            filteredClusters.push({
              ...env,
              namespace: exisitingSameNamespaceUnderDepEnv,
            });
          }
        } else {
          filteredClusters.push({ ...env, namespace: null });
        }
      });
      setFilteredDeploymentEnvsList(filteredClusters);
    } else {
      setFilteredDeploymentEnvsList(null);
    }
    isDepEnvListFilteringInProgress = false;
  };

  const getSiteConfigurations = () => {
    const gatewaySiteConfiguration = gatewayData?.site_configuration
      ? JSON.parse(gatewayData?.site_configuration)
      : {};
    const siteConfig = {
      ingress_type: {
        value: gatewaySiteConfiguration?.ingress
          ? { id: gatewaySiteConfiguration?.ingress, label: '' }
          : formData.skupper_site_configuration.ingress_type.value,
        error: false,
        errorMessage: '',
      },
      ingress_hostname: {
        value:
          gatewaySiteConfiguration?.ingress_host ||
          formData.skupper_site_configuration.ingress_hostname.value,
        error: false,
        errorMessage: '',
      },
      router_ingress_hostname: {
        value:
          gatewaySiteConfiguration?.router_ingress_host ||
          formData.skupper_site_configuration.router_ingress_hostname.value,
        error: false,
        errorMessage: '',
      },
      controller_ingress_hostname: {
        value:
          gatewaySiteConfiguration?.controller_ingress_host ||
          formData.skupper_site_configuration.controller_ingress_hostname.value,
        error: false,
        errorMessage: '',
      },
      ingress_annotations: {
        value:
          gatewaySiteConfiguration?.ingress_annotations ||
          formData.skupper_site_configuration.ingress_annotations.value,
        error: false,
        errorMessage: '',
      },
      site_name: {
        value: gatewaySiteConfiguration?.site_name || '',
        error: false,
        errorMessage: '',
      },
      router_mode: {
        value: gatewaySiteConfiguration?.router_mode
          ? { id: gatewaySiteConfiguration?.router_mode, label: '' }
          : formData.skupper_site_configuration.router_mode.value,
        error: false,
        errorMessage: '',
      },
      router_logging: {
        value: gatewaySiteConfiguration?.router_logging
          ? { id: gatewaySiteConfiguration?.router_logging, label: '' }
          : formData.skupper_site_configuration.router_logging.value,
        error: false,
        errorMessage: '',
      },
      annotations: {
        value:
          gatewaySiteConfiguration?.annotations ||
          formData.skupper_site_configuration.annotations.value,
        error: false,
        errorMessage: '',
      },
      router_service_annotations: {
        value:
          gatewaySiteConfiguration?.router_service_annotations ||
          formData.skupper_site_configuration.router_service_annotations.value,
        error: false,
        errorMessage: '',
      },
      router_pod_annotations: {
        value:
          gatewaySiteConfiguration?.router_pod_annotations ||
          formData.skupper_site_configuration.router_pod_annotations.value,
        error: false,
        errorMessage: '',
      },
      routers: {
        value:
          gatewaySiteConfiguration?.routers ||
          formData.skupper_site_configuration.routers.value,
        error: false,
        errorMessage: '',
      },
      router_data_connection_count: {
        value:
          gatewaySiteConfiguration?.router_data_connection_count ||
          formData.skupper_site_configuration.router_data_connection_count
            .value,
        error: false,
        errorMessage: '',
      },
      router_load_balancer_ip: {
        value:
          gatewaySiteConfiguration?.router_load_balancer_ip ||
          formData.skupper_site_configuration.router_load_balancer_ip.value,
        error: false,
        errorMessage: '',
      },
      create_network_policy: {
        value: !!gatewaySiteConfiguration?.create_network_policy,
        error: false,
        errorMessage: '',
      },
      enable_service_sync: {
        value:
          gatewaySiteConfiguration?.enable_service_sync === false
            ? gatewaySiteConfiguration?.enable_service_sync
            : formData.skupper_site_configuration.enable_service_sync.value,
        error: false,
        errorMessage: '',
      },
      router_cpu_limit: {
        value:
          gatewaySiteConfiguration?.router_cpu_limit ||
          formData.skupper_site_configuration.router_cpu_limit.value,
        error: false,
        errorMessage: '',
      },
      router_cpu: {
        value:
          gatewaySiteConfiguration?.router_cpu ||
          formData.skupper_site_configuration.router_cpu.value,
        error: false,
        errorMessage: '',
      },
      router_memory_limit: {
        value:
          gatewaySiteConfiguration?.router_memory_limit ||
          formData.skupper_site_configuration.router_memory_limit.value,
        error: false,
        errorMessage: '',
      },
      router_memory: {
        value:
          gatewaySiteConfiguration?.router_memory ||
          formData.skupper_site_configuration.router_memory.value,
        error: false,
        errorMessage: '',
      },
      labels: {
        value: gatewaySiteConfiguration?.labels
          ? gatewaySiteConfiguration?.labels?.split(',')
          : formData.skupper_site_configuration.labels.value,
        error: false,
        errorMessage: '',
      },
    };

    return siteConfig;
  };

  const updateFormData = async () => {
    const resourceGroupData = resourceGroups?.find(
      (rg: any) => rg.resource_id === gatewayData?.resource_group_id
    );
    if (!selectedAppResourceGroup && resourceGroups) {
      const defaultAppRg = resourceGroups?.find(
        (rg: any) => rg.resource_id === 'default-app'
      );
      setSelectedAppResourceGroup(defaultAppRg);
    }
    const fData = {
      ...formData,
      name: { ...formData.name, value: gatewayData?.name },
      resourceGroup: {
        value: resourceGroupData,
        error: false,
        errorMessage: '',
      },
      appResourceGroup: {
        value: {
          resource_id: 'default-app',
          name: 'Default_Application_Group',
        },
        error: false,
        errorMessage: '',
      },
      location: {
        value: {
          resource_id: '',
          name: '',
        },
        error: false,
        errorMessage: '',
      },
      cloud: {
        value: {
          resource_id: '',
          name: '',
        },
        error: false,
        errorMessage: '',
      },
      labels: {
        ...formData.labels,
        value: Array.isArray(gatewayData?.labels)
          ? [...gatewayData?.labels]
          : null,
      },
      description: {
        ...formData.description,
        value: gatewayData?.description ?? '',
      },
      intended_compute_profile: {
        ...formData.intended_compute_profile,
        value: gatewayData?.intended_compute_profile ?? '',
      },
      rhsi_compute_profile: {
        ...formData.rhsi_compute_profile,
      },
      skupper_site_configuration: {
        ...getSiteConfigurations(),
      },
      version: {
        ...formData.version,
      },
    };
    setFormData(fData);
    //  isEditDataLoading(false);
  };

  useEffect(() => {
    if (open) updateFormData();
  }, [resourceGroups, networkSegments, open]);

  const checkFieldValidation = (name: string, value: any, subKey: string) => {
    let errorMessage = '';

    switch (name) {
      case 'name':
        const valueEmpty = value === '';
        const notUnique = gatewayNamesList.find(
          name =>
            name?.trim() === value?.trim() &&
            name?.trim() !== gatewayData?.name?.trim()
        )
          ? true
          : false;
        const error = valueEmpty || notUnique;
        const message = valueEmpty
          ? t('validation.name.required')
          : t('validation.name.notUnique');
        errorMessage = error ? message : '';
        break;
      case 'resourceGroup':
        errorMessage = !value?.resource_id
          ? t('validation.resourceGroup.required')
          : '';
        break;
      case 'cloud':
        errorMessage = !value?.resource_id
          ? t('validation.cloud.required')
          : '';
        break;
      case 'location':
        errorMessage = !value?.resource_id
          ? t('validation.location.required')
          : '';
        break;

      case 'skupper_site_configuration':
        // if (
        //   (subKey === 'routers' || subKey === 'router_data_connection_count') &&
        //   (value === '' || Number(value) < 0)
        // ) {
        //   errorMessage = t(`validation.${subKey}.errorMessage`);
        // } else
        if (
          (subKey === 'router_cpu_limit' ||
            subKey === 'router_memory_limit' ||
            subKey === 'router_cpu' ||
            subKey === 'router_memory') &&
          !value
        ) {
          errorMessage = t('validation.custom_router_sizing.emptyErrorMessage');
        } else if (value) {
          if (
            subKey === 'ingress_hostname' ||
            subKey === 'router_ingress_hostname' ||
            subKey === 'controller_ingress_hostname'
          ) {
            let domainNameRegex = domainNameValidation();
            let valid = domainNameRegex.test(value);
            errorMessage = !valid ? t('validation.hostname.errorMessage') : '';
          } else if (
            subKey === 'ingress_annotations' ||
            subKey === 'annotations' ||
            subKey === 'router_service_annotations' ||
            subKey === 'router_pod_annotations'
          ) {
            let ingressAnnotation: any = ingressAnnotationRegex();
            let annotationArr = value.split(' ');
            let valid = annotationArr.every((annotation: string) =>
              ingressAnnotation.test(annotation)
            );
            errorMessage = !valid ? t(`validation.${subKey}.errorMessage`) : '';
          } else if (
            subKey === 'routers' ||
            subKey === 'router_data_connection_count'
          ) {
            errorMessage = !value ? t(`validation.${subKey}.errorMessage`) : '';
          } else if (subKey === 'site_name') {
            let alphaNumericRegex: any = alphaNumbericRegexPattern();
            const valid = alphaNumericRegex.test(value.trim());
            errorMessage = !valid ? t('validation.site_name.errorMessage') : '';
          } else if (subKey === 'router_load_balancer_ip') {
            let alphaNumericRegex: any = ipRegexPattern();
            const valid = alphaNumericRegex.test(value.trim());
            errorMessage = !valid
              ? t('validation.router_load_balancer_ip.errorMessage')
              : '';
          }
        }
        setOptionalParamInValid(prevState => ({
          ...prevState,
          [subKey]: !!errorMessage,
        }));

        break;
    }

    return errorMessage;
  };

  const handleOnChange = (name: string, value: any, subKey: string = '') => {
    let defaultSkupperConfig = JSON.parse(
      JSON.stringify(defaultFormValue.skupper_site_configuration)
    );
    let errorMessage = checkFieldValidation(name, value, subKey);

    if (name === 'skupper_site_configuration') {
      if (
        subKey === 'ingress_type' &&
        (value.id.length === 0 || value.id === IngressTypes.NONE)
      ) {
        setFormData((prevState: any) => ({
          ...prevState,
          skupper_site_configuration: {
            ...prevState.skupper_site_configuration,
            ingress_type: { ...defaultSkupperConfig.ingress_type, value },
            ingress_hostname: defaultSkupperConfig.ingress_hostname,
            router_ingress_hostname:
              defaultSkupperConfig.router_ingress_hostname,
            controller_ingress_hostname:
              defaultSkupperConfig.controller_ingress_hostname,
            ingress_annotations: defaultSkupperConfig.ingress_annotations,
          },
        }));
        setOptionalParamInValid((prevState: any) => ({
          ...prevState,
          ingress_hostname: false,
          router_ingress_hostname: false,
          controller_ingress_hostname: false,
          ingress_annotations: false,
        }));
      } else if (subKey === 'router_logging' && !value) {
        setFormData((prevState: any) => ({
          ...prevState,
          skupper_site_configuration: {
            ...prevState.skupper_site_configuration,
            [subKey]: {
              value: defaultSkupperConfig.router_logging.value,
              error: !!errorMessage,
              errorMessage,
            },
          },
        }));
      } else {
        setFormData((prevState: any) => ({
          ...prevState,
          skupper_site_configuration: {
            ...prevState.skupper_site_configuration,
            [subKey]: {
              value,
              error: !!errorMessage,
              errorMessage,
            },
          },
        }));
      }
    } else if (name === 'rhsi_compute_profile') {
      if (value?.profile !== RHSIComputeProfiles.CUSTOM) {
        setFormData((prevState: any) => ({
          ...prevState,
          skupper_site_configuration: {
            ...prevState.skupper_site_configuration,
            router_cpu_limit: defaultSkupperConfig.router_cpu_limit,
            router_cpu: defaultSkupperConfig.router_cpu,
            router_memory_limit: defaultSkupperConfig.router_memory_limit,
            router_memory: defaultSkupperConfig.router_memory,
          },
          [name]: {
            value,
            error: !!errorMessage,
            errorMessage,
          },
        }));
        setOptionalParamInValid((prevState: any) => ({
          ...prevState,
          router_cpu_limit: false,
          router_memory_limit: false,
          router_cpu: false,
          router_memory: false,
        }));
      } else {
        setFormData((prevState: any) => ({
          ...prevState,
          skupper_site_configuration: {
            ...prevState.skupper_site_configuration,
            router_cpu_limit: {
              ...formData.skupper_site_configuration?.router_cpu_limit,
              error: formData.skupper_site_configuration?.router_cpu_limit
                ?.value
                ? false
                : true,
              errorMessage: formData.skupper_site_configuration
                ?.router_cpu_limit?.value
                ? ''
                : t('validation.custom_router_sizing.emptyErrorMessage'),
            },
            router_cpu: {
              ...formData.skupper_site_configuration?.router_cpu,
              error: formData.skupper_site_configuration?.router_cpu?.value
                ? false
                : true,
              errorMessage: formData.skupper_site_configuration?.router_cpu
                ?.value
                ? ''
                : t('validation.custom_router_sizing.emptyErrorMessage'),
            },
            router_memory_limit: {
              ...formData.skupper_site_configuration?.router_memory_limit,
              error: formData.skupper_site_configuration?.router_memory_limit
                ?.value
                ? false
                : true,
              errorMessage: formData.skupper_site_configuration
                ?.router_memory_limit?.value
                ? ''
                : t('validation.custom_router_sizing.emptyErrorMessage'),
            },
            router_memory: {
              ...formData.skupper_site_configuration?.router_memory,
              error: formData.skupper_site_configuration?.router_memory?.value
                ? false
                : true,
              errorMessage: formData.skupper_site_configuration?.router_memory
                ?.value
                ? ''
                : t('validation.custom_router_sizing.emptyErrorMessage'),
            },
          },
          [name]: {
            value,
            error: !!errorMessage,
            errorMessage,
          },
        }));
        setOptionalParamInValid((prevState: any) => ({
          ...prevState,
          router_cpu_limit: formData.skupper_site_configuration
            ?.router_memory_limit?.value
            ? false
            : true,
          router_memory_limit: formData.skupper_site_configuration
            ?.router_memory_limit?.value
            ? false
            : true,
          router_cpu: formData.skupper_site_configuration?.router_cpu?.value
            ? false
            : true,
          router_memory: formData.skupper_site_configuration?.router_memory
            ?.value
            ? false
            : true,
        }));
      }
    } else {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
      }));
    }
  };

  const updateInfrastructureGroup = (networkSegment: NetworkSegment) => {
    let infragroup = resourceGroupList?.find(
      (group: ResourceGroup) =>
        group.resource_id === networkSegment?.resource_group_id
    );
    if (!infragroup) {
      infragroup = resourceGroupList?.find(
        (group: ResourceGroup) =>
          group.resource_id === DEFAULT_INFRASTRUCTURE_ID
      );
    }
    handleOnChange('resourceGroup', infragroup);
  };

  const networkSegmentList = useMemo(() => {
    if (newNetworkSegmentId) {
      const networkSegment =
        networkSegments != null
          ? networkSegments.find(
              (segment: NetworkSegment) =>
                segment.resource_id === newNetworkSegmentId
            ) ?? null
          : null;
      setSelectedNetworkSegment(networkSegment);
      updateInfrastructureGroup(networkSegment);
      setSelectedDepEnv(null);
      filterClusters(networkSegment);
      setNewNetworkSegmentId('');
    }
    return networkSegments !== null ? networkSegments : [];
  }, [networkSegments]);

  const closeNetworkSegmentTearsheet = () => {
    toggleCreateNetworkSegment(false);
  };

  const openNetworkSegmentTearsheet = () => {
    toggleCreateNetworkSegment(true);
  };

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = (formData as any)[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue, '')) {
        return false;
      }
    }

    return true;
  };

  const handleSelectDeploymentEnv = () => {
    const updatedFormData = {
      cloud: {
        ...formData.cloud,
        value: {
          resource_id: selectedDepEnvTemp?.cloud_id,
          name: selectedDepEnvTemp?.cloud_name,
        },
      },
      location: {
        ...formData.location,
        value: {
          resource_id: selectedDepEnvTemp?.location_id,
          name: selectedDepEnvTemp?.location_name,
        },
      },
      deployed_in_depl_env_id: {
        ...formData.deployed_in_depl_env_id,
        value: {
          resource_id: selectedDepEnvTemp?.resource_id,
          name: selectedDepEnvTemp?.name,
        },
      },
      deployed_in_partition_id: {
        ...formData.deployed_in_partition_id,
        value: {
          resource_id: '',
          name: gatewayData?.namespace_name,
        },
      },
      deployed_in_type: {
        ...formData.deployed_in_type,
        value: 'namespace',
      },
      appResourceGroup: {
        ...formData.appResourceGroup,
        value: selectedAppResourceGroup ?? {
          resource_id: 'default-app',
          name: 'Default_Application_Group',
        },
      },
    };

    setFormData((formData: any) => ({
      ...formData,
      ...updatedFormData,
    }));
    setOpenDeplEnvTable(false);
    setSelectedClusterError(false);
    setSelectedClusterErrorType('');
    setSelectedDepEnv(selectedDepEnvTemp);
    setSelectedDepEnvTemp(selectedDepEnvTemp);
  };

  const handleClickDeploymentEnv = (item: Cluster) => {
    setSelectedDepEnvTemp(item);
    setSelectedClusterError(false);
    setSelectedClusterErrorType('');
  };

  const handleCloseTearsheet = (isRefreshRequired = false) => {
    setFormData(defaultFormValue);
    setSelectedNetworkSegment(null);
    setSelectedDepEnvTemp(null);
    setSelectedDepEnv(null);
    toggleFailNotification(false);
    setAuthError(false);
    setInlineNotificationAuthSubTitle('');
    setInlineNotificationAuthTitle('');
    setInlineNotificationTitle('');
    setInlineNotificationSubTitle('');
    onClose(isRefreshRequired);
  };

  const updateGatewayDetails = async () => {
    try {
      // Update gateway details
      let skupperConfigArgs = {
        ingress: formData?.skupper_site_configuration?.ingress_type?.value?.id,
        ingress_host:
          formData?.skupper_site_configuration?.ingress_hostname?.value,
        router_ingress_host:
          formData?.skupper_site_configuration?.router_ingress_hostname?.value,
        controller_ingress_host:
          formData?.skupper_site_configuration?.controller_ingress_hostname
            ?.value,
        ingress_annotations:
          formData?.skupper_site_configuration?.ingress_annotations?.value,
        site_name:
          formData?.skupper_site_configuration?.site_name?.value?.trim(),
        router_mode:
          formData?.skupper_site_configuration?.router_mode?.value?.id,
        router_logging:
          formData?.skupper_site_configuration?.router_logging?.value?.id,
        router_cpu_limit:
          formData?.skupper_site_configuration?.router_cpu_limit?.value || '',
        router_cpu:
          formData?.skupper_site_configuration?.router_cpu?.value || '',
        router_memory_limit:
          formData?.skupper_site_configuration?.router_memory_limit?.value ||
          '',
        router_memory:
          formData?.skupper_site_configuration?.router_memory?.value || '',
        annotations: formData?.skupper_site_configuration?.annotations?.value,
        router_service_annotations:
          formData?.skupper_site_configuration?.router_service_annotations
            ?.value,
        router_pod_annotations:
          formData?.skupper_site_configuration?.router_pod_annotations?.value,
        create_network_policy:
          formData?.skupper_site_configuration?.create_network_policy?.value,
        enable_service_sync:
          formData?.skupper_site_configuration?.enable_service_sync?.value,
        router_load_balancer_ip:
          formData?.skupper_site_configuration?.router_load_balancer_ip?.value,
        router_data_connection_count:
          formData.skupper_site_configuration?.router_data_connection_count?.value?.toString(),
        routers:
          formData.skupper_site_configuration?.routers?.value?.toString(),
        labels: formData?.skupper_site_configuration?.labels?.value?.length
          ? formData?.skupper_site_configuration?.labels?.value?.join(',')
          : '',
      };
      const payload = {
        name: formData?.name?.value,
        cloud_id: selectedDepEnv?.cloud_id,
        location_id: selectedDepEnv?.location_id,
        network_segment_id: formData?.networkSegment?.value?.resource_id,
        labels: formData?.labels?.value,
        description: formData?.description?.value?.trim(),
        deployed_in_type: 'namespace',
        resource_group_id: formData?.resourceGroup?.value?.resource_id,
        deployed_in_depl_env_id: selectedDepEnv?.resource_id,
        deployed_in_partition_id: selectedDepEnv?.namespace?.resource_id,
        site_configuration: JSON.stringify(skupperConfigArgs),
        intended_compute_profile: formData.rhsi_compute_profile?.value?.profile,
        unmanaged: false,
      };
      await updateGateway(gatewayData?.resource_id, payload);

      notification.onTrigger('TOAST', {
        title: t('successNotification.connectCluster.title'),
        subtitle: t('successNotification.connectCluster.subtitle', {
          gatewayName: formData?.name?.value,
          clusterName: selectedDepEnv?.name,
        }),
      });

      // only deploy gateway if updateGateway is successful
      deployGatewayFn();
    } catch (error) {
      console.log('Error updating gateway');
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        notification.onTrigger('TOAST', {
          title: t('failureNotification.updateGatewayAuthErrorTitle') as string,
          kind: 'error',
          subtitle: t(
            'failureNotification.updateGatewayAuthErrorSubTitle'
          ) as string,
        });
      }

      const errorMessage: string =
        err.response !== undefined
          ? (err as any).response['customErrorMessage']
          : '';

      notification.onTrigger('TOAST', {
        title: t('failureNotification.updateGatewayErrorTitle') as string,
        kind: 'error',
        subtitle: t(
          errorMessage && errorMessage.length > 0
            ? errorMessage
            : 'failureNotification.updateGatewayErrorSubTitle'
        ) as string,
      });
    }
  };

  const deployGatewayFn = async () => {
    setSaving(true);
    toggleFailNotification(false);
    setAuthError(false);
    // setSubTitleErrorMsg('');
    setInlineNotificationAuthSubTitle('');
    setInlineNotificationAuthTitle('');
    setInlineNotificationTitle('');
    setInlineNotificationSubTitle('');
    if (selectedDepEnv?.namespace) {
      try {
        // Connect gateway to namespace if namespace already exit without gateway
        await updateNamespaceData(
          selectedDepEnv?.cloud_id,
          selectedDepEnv?.resource_id,
          selectedDepEnv?.namespace?.resource_id,
          {
            unmanaged: false,
            gateway_id: gatewayData?.resource_id,
            network_segment_id: selectedNetworkSegment?.resource_id,
            app_resource_group_id:
              formData?.appResourceGroup?.value?.resource_id,
            auto_discover: true,
          }
        );

        notification.onTrigger('TOAST', {
          title: t('successNotification.editNamespace.title'),
          subtitle: t('successNotification.editNamespace.subtitle', {
            namespaceName: selectedDepEnv?.namespace?.name,
            gatewayName: gatewayData?.name,
          }),
        });
        inValidateListCacheFn(QueryKeys.CLOUD_NAMESPACES);
        inValidateListCacheFn(QueryKeys.NAMESPACES);
        inValidateListCacheFn(QueryKeys.GATEWAYS);

        // onGatewayConnect();
        handleCloseTearsheet(true);
      } catch (error) {
        console.log('Error updating existing namespace');
        const err = error as AxiosError;
        const errorMessage: string =
          err.response !== undefined
            ? (err as any).response['customErrorMessage']
            : '';
        toggleFailNotification(true);
        if (err.response?.status === 403) {
          setAuthError(true);
          setInlineNotificationAuthTitle(
            t('failureNotification.editNamespaceAuthErrorTitle')
          );
          setInlineNotificationAuthSubTitle(
            t('failureNotification.editNamespaceAuthErrorSubTitle')
          );
        }

        setInlineNotificationTitle(
          t('failureNotification.editNamespaceErrorTitle')
        );
        errorMessage.length > 0 && setInlineNotificationSubTitle(errorMessage);

        return;
      } finally {
        submitInProgress = false;
        setSaving(false);
      }
    } else {
      try {
        // Register a new namespace if namespace doesn't exit
        const payload = {
          name: gatewayData?.namespace_name,
          network_segment_id: selectedNetworkSegment?.resource_id,
          gateway_id: gatewayData?.resource_id,
          app_resource_group_id: formData?.appResourceGroup?.value?.resource_id,
          auto_discover: true,
          unmanaged: false,
        };

        await registerNamespace(
          selectedDepEnv?.cloud_id,
          selectedDepEnv?.resource_id,
          payload
        );

        notification.onTrigger('TOAST', {
          title: t('successNotification.registerNamespace.title'),
          subtitle: t('successNotification.registerNamespace.subtitle', {
            namespaceName: gatewayData?.namespace_name,
          }),
        });

        inValidateListCacheFn(QueryKeys.CLOUD_NAMESPACES);
        inValidateListCacheFn(QueryKeys.NAMESPACES);
        inValidateListCacheFn(QueryKeys.GATEWAYS);

        // onGatewayConnect();
        handleCloseTearsheet(true);
      } catch (error) {
        console.log('Error registering namespace');
        const err = error as AxiosError;
        const errorMessage: string =
          err.response !== undefined
            ? (err as any).response['customErrorMessage']
            : '';

        toggleFailNotification(true);
        if (err.response?.status === 403) {
          setAuthError(true);
          setInlineNotificationAuthTitle(t('registerNamespaceAuthErrorTitle'));
          setInlineNotificationAuthSubTitle(
            t('registerNamespaceAuthErrorSubTitle')
          );
        }

        setInlineNotificationTitle(t('registerNamespaceErrorTitle'));
        errorMessage.length > 0 && setInlineNotificationSubTitle(errorMessage);

        return;
      } finally {
        submitInProgress = false;
        setSaving(false);
      }
    }
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
    setInlineNotificationAuthSubTitle('');
    setInlineNotificationAuthTitle('');
    setInlineNotificationTitle('');
    setInlineNotificationSubTitle('');
  };

  const isDropDownListDataLoading =
    isRefetchingResourceGroups &&
    isResourceGroupsLoading &&
    loadingNetworkSegments &&
    refetchingNetworkSegments &&
    isDepEnvsLoading &&
    isNamespaceLoading;

  const connectClusterDataLoading =
    isDepEnvsLoading &&
    isRefetchingDepEnvs &&
    isNamespaceLoading &&
    isRefetchingNamespaces &&
    isDepEnvListFilteringInProgress;

  return (
    <>
      <MultiStepTearsheet
        className='deploy-gateway-tearsheet'
        title={t('deployGateway', { gatewayName: gatewayData?.name })}
        description={''}
        submitButtonText={t('deployButtonText')}
        cancelButtonText={t('cancelButtonText')}
        backButtonText={t('backButtonText')}
        nextButtonText={t('nextButtonText')}
        open={open}
        onClose={() => {
          handleCloseTearsheet();
        }}
        onRequestSubmit={() =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              // reject the promise to keep the tearsheet in open state
              reject();
              submitInProgress = true;
              updateGatewayDetails();
            }, simulatedDelay);
          })
        }
        initialStep={1}
        isSubmitting={submitInProgress}
      >
        <GatewayDetails
          formData={formData}
          gatewayData={gatewayData}
          id='manage-gateway-toggle'
          onChange={handleOnChange}
          networkSegments={networkSegmentList}
          resourceGroupList={resourceGroupList}
          openNetworkSegmentTearsheet={openNetworkSegmentTearsheet}
          selectedDepEnv={selectedDepEnv}
          onSelectNetworkSegment={(networkSegment: NetworkSegment) => {
            // set infra group to infra group of network segment
            updateInfrastructureGroup(networkSegment);
            setSelectedNetworkSegment(networkSegment);
            setSelectedDepEnv(null);
            filterClusters(networkSegment);
          }}
          openDeploymentEnvTable={() => {
            if (selectedDepEnv) setSelectedDepEnvTemp(selectedDepEnv);
            setOpenDeplEnvTable(true);
          }}
          selectedNetworkSegment={selectedNetworkSegment}
          isDropDownListDataLoading={isDropDownListDataLoading}
          connectClusterDataLoading={connectClusterDataLoading}
        />
        <ConfigureGateway
          formData={formData}
          onChange={handleOnChange}
          onBlur={handleOnChange}
          isFormValid={isFormValid()}
          optionalParamInValid={Object.values(optionalParamInValid).some(
            Boolean
          )}
          open={open}
        >
          {showFailNotification && (
            <InlineNotification
              onClose={() => handleErrorBarClose() as any}
              kind={'error'}
              title={
                authError
                  ? inlineNotificationAuthTitle
                  : inlineNotificationTitle
              }
              subtitle={
                authError
                  ? inlineNotificationAuthSubTitle
                  : inlineNotificationSubTitle
              }
              lowContrast
            />
          )}
        </ConfigureGateway>
        {/* {openCreateNetworkSegment && ( */}
        <CreateNetworkSegment
          open={openCreateNetworkSegment}
          onClose={closeNetworkSegmentTearsheet}
          networkSegments={networkSegmentList}
          groups={resourceGroupList}
          fromPage='registerNamespace'
          refreshData={(networkSegmentId?: string) => {
            setNewNetworkSegmentId(networkSegmentId ?? '');
            refetchNetworksegments();
          }}
          actionType={'ADD'}
          className={'create-network-segment'}
        />
        {/* )} */}
      </MultiStepTearsheet>
      <WideTearsheet
        className='deployment-env-table-wide-tearsheet'
        title={t('deploymentEnvTable.connectClusterTitle', {
          gatewayName: gatewayData?.name,
        })}
        // description={t('deploymentEnvTable.clusterDescription')}
        open={openDeplEnvTable}
        actions={[
          {
            kind: 'primary',
            label: t('deploymentEnvTable.selectBtn'),
            onClick: () => {
              handleSelectDeploymentEnv();
            },
            disabled: !selectedDepEnvTemp,
          },
          {
            kind: 'secondary',
            label: t('deploymentEnvTable.cancelBtn'),
            onClick: () => {
              setOpenDeplEnvTable(false);
              setSelectedClusterError(false);
              setSelectedClusterErrorType('');
              setSelectedDepEnvTemp(null);
              setSelectedAppResourceGroup(formData?.appResourceGroup?.value);
            },
          },
        ]}
      >
        <>
          {
            <ConnectCluster
              deploymentEnvs={filteredDeploymentEnvsList}
              resourceGroupsData={resourceGroupList}
              deploymentEnvSubtypesData={depEnvSubTypesList}
              handleSelectDepEnv={(item: Cluster) =>
                handleClickDeploymentEnv(item)
              }
              selectedDepEnv={selectedDepEnv}
              selectedDepEnvTemp={selectedDepEnvTemp}
              error={selectedClusterError}
              errorType={selectedClusterErrorType}
              onCloseError={() => {
                setSelectedClusterError(false);
                setSelectedClusterErrorType('');
              }}
              depEnvPermissionError={permissionError?.depEnvList}
              depEnv500Error={error500?.depEnvList}
            />
          }
          <div className='appGroupContainer'>
            <Row>
              <Column md={4}>
                <div className='clusterTitle'>
                  {t('connectCluster.appGroupTitle')}
                </div>
                <div className='clusterSubTitle'>
                  {t('connectCluster.appGroupSubTitle')}
                </div>
              </Column>
            </Row>
            <Row>
              <Column md={3}>
                <Dropdown
                  id='gateway-app-resource-group'
                  className='appResourceGroup'
                  selectedItem={selectedAppResourceGroup as any}
                  onChange={data =>
                    setSelectedAppResourceGroup(data?.selectedItem)
                  }
                  items={
                    resourceGroups?.filter(
                      (rg: ResourceGroup) =>
                        rg?.type === ResourceGroupTypes.APPLICATION
                    ) ?? []
                  }
                  itemToString={item => (item ? item.name : '')}
                  titleText={t('gatewayDetailsForm.appResourceGroup.label')}
                  label={''}
                  placeholder={t(
                    'gatewayDetailsForm.appResourceGroup.placeholder'
                  )}
                  translateWithId={t}
                />
              </Column>
            </Row>
          </div>
        </>
      </WideTearsheet>
    </>
  );
};

export default DeployGateway;
