export const faqSectionsData = [
  {
    question: 'whatIsNS',
    answer: 'whatIsNSAns',
  },
  {
    question: 'timesToinstallNs',
    answer: 'timesToinstallNsAns',
  },
  {
    question: 'whatIsUnknownSegment',
    answer: 'whatIsUnknownSegmentAns',
  },
  {
    question: 'renameNS',
    answer: 'renameNSAns',
  },
  {
    question: 'isAllOnboarded',
    answer: 'isAllOnboardedAns',
  },
];
